import { useQuery } from '@tanstack/react-query';
import Api, { getResp } from './Api';

export type NotificationResp = {
  name: string;
  description: string;
  type: 'attendance' | 'automated-routines' | 'batch-changes';
  contractId?: string;
};

export function useNotification() {
  return useQuery([`notificationList`], async () => {
    const resp = await Api.get(`/Notification/current-person`);
    return getResp<NotificationResp[]>(resp);
  });
}
