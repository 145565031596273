import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import HeaderCardComponent from '../../atoms/HeaderCardComponent/HeaderCardComponent';

type tBodyItems = React.ReactNode[];
type DashboardTableComponentProps = {
  tHead?: string[];
  tBody?: tBodyItems[];
  title?: string;
  subTitle?: string;
};

export default function DashboardTableComponent(
  props: DashboardTableComponentProps
) {
  return (
    <>
      <TableContainer p={2} borderWidth={1}>
        <Flex mb={4} flexDirection={'column'}>
          <HeaderCardComponent mb={'0px'} title={props.title || ''} />
          <Flex color={'gray'}>{props.subTitle}</Flex>
        </Flex>
        <Table variant="simple" colorScheme="blue">
          <Thead>
            <Tr>
              {props.tHead?.map((e, i) => (
                <Th key={i}>{e}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {props.tBody?.map((e) => (
              <Tr>
                {e.map((e) => (
                  <Td>{e}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
