import { Flex, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  saveTowerType,
  TowerType,
  TypeTowerType,
} from '../../../../services/TowerTypeService';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';

export function CreateTowerModalComponent(props: {
  showModal: boolean;
  selectedTower?: TowerType;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      description: '',
      type: 0,
    }),
    []
  );
  const [form, setForm] = React.useState<TowerType>(defaultForm);
  React.useEffect(() => {
    if (props.selectedTower)
      setForm({
        ...props.selectedTower,
      });
    else setForm(defaultForm);
  }, [props.selectedTower, defaultForm]);

  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={props.selectedTower ? 'Editar Cadastro' : `Novo Cadastro`}
      maxW="1200px"
      size="xl"
    >
      <Text
        fontSize={'2xl'}
        fontWeight={'light'}
        color="gray.500"
        marginBottom={7}
      >
        O sistema gera um campo de ID automático para as torres a partir do
        cadastro. O campo proprietário é preenchido automaticamente com o nome
        da empresa do usuário logado.
      </Text>
      <Flex mb={5} flexDirection={'row'}>
        <InputComponent
          label="Descrição"
          margin={'0 4% 0 0'}
          w={'48%'}
          value={form.description}
          onChange={(input) => {
            setForm({ ...form, description: input.target.value });
          }}
        />

        <Flex direction={'column'} w={'48%'}>
          <InputSelectComponent
            defaultValue={String(form.type)}
            disabled={!!props.selectedTower}
            onChange={(input) => {
              setForm({ ...form, type: Number(input.target.value) });
            }}
            options={[
              {
                id: String(TypeTowerType.Retail),
                name: 'Varejo',
              },
              {
                id: String(TypeTowerType.Supermarket),
                name: 'Supermercado',
              },
              {
                id: String(TypeTowerType.LogisticShed),
                name: 'Galpão Logístico',
              },
              {
                id: String(TypeTowerType.CommercialHalls),
                name: 'Salas Comerciais',
              },
              {
                id: String(TypeTowerType.Telecommunications),
                name: 'Telecominicações',
              },
            ]}
            placeholder="Selecione o tipo"
            label="Tipo"
          />
        </Flex>
      </Flex>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setForm(defaultForm);
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          isLoading={isLoading}
          disabled={isLoading}
          onSubmit={() => {
            setIsLoading(true);
            saveTowerType(form)
              .then((res) => {
                setForm(defaultForm);
                props.setShowModal();
                props.reloadData?.();
                showToast({
                  toast,
                  status: 'success',
                  title: 'Sucesso',
                  description: 'Torre salva com sucesso',
                });
              })
              .finally(() => setIsLoading(false));
          }}
        >
          {props.selectedTower ? 'Salvar' : 'Criar Torre'}
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
