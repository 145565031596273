import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';

export type ClauseResp = {
  title: string;
  description: string;
  type: TypeClause;
  id: string;
  createdAt?: string;
  typeDescription?: string;
  hasContracts?: boolean;
  status?: StatusClause;
};

export enum TypeClause {
  General = 0,
  Renovation = 1,
  Closing = 2,
}
export enum StatusClause {
  Active = 0,
  Inactive = 1,
}

export function useClause(
  page: number,
  pageSize: number | null,
  status: StatusClause
) {
  return useQuery([`ClauseList`, page, pageSize, status], async () => {
    const resp = await Api.get(`/Clause`, {
      params: {
        Offset: page,
        Limit: pageSize,
        Status: status,
      },
    });
    return getRespContent<ClauseResp[]>(resp);
  });
}

export function useClauseSelect() {
  return useQuery([`ClauseListSelect`], async () => {
    const resp = await Api.get(`/Clause`, {
      params: {
        Limit: 100,
      },
    });
    return getResp<ClauseResp[]>(resp);
  });
}

export function DeleteClause(id: string) {
  return Api.delete(`/Clause/${id}`);
}

export function saveClause(props: ClauseResp) {
  if (props.id) {
    return putClause(props);
  }
  return postClause(props);
}

export function postClause(props: ClauseResp) {
  return Api.post('/Clause', props);
}

export function putClause(props: ClauseResp) {
  return Api.put(`/Clause/${props.id}`, props);
}
export function putClauseActive(id: string) {
  return Api.put(`/Clause/active/${id}`);
}
export function putClauseDisable(id: string) {
  return Api.put(`/Clause/disable/${id}`);
}
