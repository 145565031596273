import { CSSProperties } from 'react';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { Flex } from '@chakra-ui/react';
import ImageComponent from '../../atoms/ImageComponent/ImageComponent';
import tellink from '../../../../assets/images/tellink.png';

export type LoginStructureProps = {
  children: React.ReactNode;
  subtitle: string;
  style?: CSSProperties;
};

export default function LoginStructureComponent(props: LoginStructureProps) {
  return (
    <Flex>
      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        h={'100vh'}
        w={'50%'}
        backgroundColor={'brand.50'}
      >
        <ImageComponent height="100%" width="50%" src={tellink} />
      </Flex>
      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        h={'100vh'}
        w={'50%'}
      >
        <TextComponent
          fontSize={'38px'}
          fontWeight={500}
          style={{ marginBottom: 8, letterSpacing: '3%' }}
        >
          TELLINK ENGENHARIA
        </TextComponent>
        <TextComponent
          fontSize={'18px'}
          fontWeight={400}
          style={{ marginBottom: 10, letterSpacing: '3%' }}
        >
          {props.subtitle}
        </TextComponent>

        <Flex w={'315px'} alignItems={'center'} flexDirection={'column'}>
          {props.children}
        </Flex>
      </Flex>
    </Flex>
  );
}
