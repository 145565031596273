export function generateYearList(
  startYear: string | number
): { id: string; name: string }[] {
  const currentYear = new Date().getFullYear();
  const startYearNumber =
    typeof startYear === 'string' ? parseInt(startYear, 10) : startYear;

  if (isNaN(startYearNumber)) {
    throw new Error(
      'Invalid year format. Please provide a valid year as a string or number.'
    );
  }

  const yearsList: { id: string; name: string }[] = [];

  for (let year = startYearNumber; year <= currentYear; year++) {
    yearsList.push({ id: year.toString(), name: year.toString() });
  }

  return yearsList.sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));
}
