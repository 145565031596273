import { Alert, AlertIcon, Button, Flex } from '@chakra-ui/react';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';

type ModalContentIconProps = {
  iconStatus?: 'error' | 'info' | 'warning' | 'success' | 'loading';
  title?: string;
  subTitle?: string;
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  secondaryButton?: {
    onClick: () => void;
    label: string;
  };
  body?: React.ReactNode;
  button?: {
    onClick: () => void;
    label: string;
  };
};
export function ContentModalIconComponent(props: ModalContentIconProps) {
  return (
    <Flex flexDirection={'column'}>
      <Flex
        justifyContent={'center'}
        alignItems={props.alignItems || 'center'}
        flexDirection={'column'}
        textAlign={'center'}
        marginY={2}
      >
        {props.body || (
          <>
            <Alert
              backgroundColor={'white'}
              status={props.iconStatus}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon boxSize="40px" mr={0} />
            </Alert>
            <HeaderTextComponent>{props.title}</HeaderTextComponent>
            <HeaderTextComponent maxW="350px" subTitle>
              {props.subTitle}
            </HeaderTextComponent>
          </>
        )}
      </Flex>
      <Flex mt={8} justifyContent={'end'}>
        {props.secondaryButton && (
          <Button
            variant={'outline'}
            colorScheme={'black'}
            mr={3}
            onClick={props.secondaryButton?.onClick}
          >
            {props.secondaryButton?.label}
          </Button>
        )}
        {props.button && (
          <Button colorScheme={'brand'} onClick={props.button?.onClick}>
            {props.button?.label}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
