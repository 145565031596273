import { ContractResp } from '../../../../services/ContractService';
import { parseDateAndHour } from '../../../../utils/functions/utility';
import { getStatusColorByTypeAttendance } from '../../../templates/AttendanceTemplate/AttendanceTemplateComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import React from 'react';

export function AttendanceModalComponent(props: {
  showModal: boolean;
  setShowModal: () => void;
  attendances: ContractResp['locatorAttendances'];
}) {
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Atendimentos`}
      maxW="1200px"
      size="xl"
    >
      <TableComponent
        ItemsHeader={[
          {
            item: 'Assunto',
          },
          {
            item: 'Abertura',
          },
          {
            item: 'Status',
          },
        ]}
        isLoading={false}
        centered={true}
        emptyState={props.attendances.length === 0}
        data={
          props.attendances.map((e, i) => ({
            items: [
              e.subject,
              parseDateAndHour(e.createdAt),
              getStatusColorByTypeAttendance(e.status),
            ],
          })) ?? []
        }
      />
    </ModalStructureComponent>
  );
}
