import { FaEdit, FaInfo, FaRegTimesCircle, FaTrash } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import React from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { showToast } from '../../../utils/showToast';
import DetailsModalComponent from '../../UI/molecules/DetailsModalComponent/DetailsModalComponent';
import ConfirmModalComponent from '../../UI/organisms/ConfirmModalComponent/ConfirmModalComponent';
import {
  ConsiderationResp,
  DeleteConsideration,
  StatusConsideration,
  putConsiderationActive,
  putConsiderationDisable,
  useConsideration,
} from '../../../services/ConsiderationService';
import { formatDate } from '../../../utils/functions/formatDate';
import { CreateConsiderationModalComponent } from '../../UI/organisms/CreateConsiderationModalTemplateComponent/CreateConsiderationModalTemplateComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';

export default function ConsiderationTemplateComponent() {
  const [selectedConsideration, setSelectedConsideration] = React.useState<
    ConsiderationResp | undefined
  >();
  const toast = useToast();
  const modalCardInfo = [
    {
      item: 'Criado em',
      description: formatDate(selectedConsideration?.createdAt),
    },
    {
      item: 'Conteúdo',
      description: selectedConsideration?.content,
      descriptionDirectionColumn: true,
    },
  ];
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModal, setShowModal] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [considerationId, setConsiderationId] = React.useState('');
  const [statusConsideration, setStatusConsideration] =
    React.useState<StatusConsideration>(StatusConsideration.Active);
  const listConsiderations = useConsideration(
    selectedPage,
    pageSize,
    statusConsideration
  );
  const [detailsDialog, setDetailsDialog] = React.useState(false);
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const tabName = [
    {
      title: 'Ativas',
      onClick: () => {
        setStatusConsideration(StatusConsideration.Active);
      },
    },
    {
      title: 'Desabilitadas',
      onClick: () => {
        setStatusConsideration(StatusConsideration.Inactive);
      },
    },
  ];
  return (
    <>
      <Stack mb={4}>
        <HeaderTextComponent goBack>Considerações</HeaderTextComponent>
      </Stack>
      <Flex mb={2} justifyContent={'space-between'}>
        <TabsFilterComponent tabList={tabName} />
        <ButtonComponent
          margin={'0 10px 0 0'}
          onSubmit={() => {
            setSelectedConsideration(undefined);
            setShowModal(true);
          }}
        >
          Novo Cadastro
        </ButtonComponent>
      </Flex>
      <TableComponent
        ItemsHeader={[
          { item: 'Criado em' },
          { item: 'Descrição' },
          { item: 'Contrato Vinculado' },
          { item: 'Ação' },
        ]}
        isLoading={listConsiderations.isLoading}
        centered={true}
        data={
          listConsiderations.data?.data.map((e, i) => ({
            items: [
              formatDate(e?.createdAt),
              e.content,
              e.hasContracts ? (
                <TagComponent size="md" colorScheme="green" text="Sim" />
              ) : (
                <TagComponent size="md" colorScheme="red" text="Não" />
              ),
              <>
                <IconButtonComponent
                  icon={
                    e.status === StatusConsideration.Active ? (
                      <FaRegTimesCircle color="white" />
                    ) : (
                      <CheckCircleIcon />
                    )
                  }
                  toolTipText={
                    e.status === StatusConsideration.Active
                      ? 'Desabilitar'
                      : 'Ativar'
                  }
                  ariaLabel="Disabled"
                  colorScheme={
                    e.status === StatusConsideration.Active ? 'yellow' : 'green'
                  }
                  onSubmit={() => {
                    e.status === StatusConsideration.Active
                      ? putConsiderationDisable(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description:
                              'Consideração desabilitada com sucesso',
                          });
                          listConsiderations.refetch();
                        })
                      : putConsiderationActive(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description: 'Consideração ativada com sucesso',
                          });
                          listConsiderations.refetch();
                        });
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedConsideration(e);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaInfo />}
                  ariaLabel="info"
                  toolTipText="Detalhes"
                  backgroundColor="#3182CE"
                  onSubmit={() => {
                    setSelectedConsideration(e);
                    setDetailsDialog(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTrash />}
                  marginX={2}
                  disabled={e.hasContracts}
                  toolTipText="Apagar"
                  ariaLabel="Delet"
                  colorScheme={'red'}
                  onSubmit={() => {
                    setConsiderationId(e.id);
                    setShowConfirmModal(true);
                  }}
                />
              </>,
            ],
          })) ?? []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listConsiderations.data?.metadata.dataSize ?? 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateConsiderationModalComponent
        selectedConsideration={selectedConsideration}
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
        reloadData={() => listConsiderations.refetch()}
      />
      <DetailsModalComponent
        isOpen={detailsDialog}
        title={'Consideração'}
        onClose={() => setDetailsDialog(false)}
        data={modalCardInfo}
      />
      <ConfirmModalComponent
        isOpen={showConfirmModal}
        title={'Deseja realmente apagar essa consideração?'}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          DeleteConsideration(considerationId).then((res) => {
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: 'Consideração apagada com sucesso',
            });
            listConsiderations.refetch();
          });
        }}
      />
    </>
  );
}
