import { useQuery } from '@tanstack/react-query';
import { Middleware, getApi, getResp, getRespContent } from './Api';
import { ListResp } from './StateServices';

export type ProfileResp = {
  id: string;
  document: string;
  displayName: string;
  date: string;
  firstName: string;
  lastName: string;
  gender: number;
  detail: string;
  profilePicUrl: string;
  companyId: string;
  employee: {
    position: string;
    code: string;
    salary: number;
    technicalHourValue: number;
    contractionDate: string;
    resignationDate: string;
  };
  user: {
    username: string;
    email: string;
    profileId: string;
    permissions: string[];
    customPermissions: boolean;
  };
  emails: string[];
  phones: {
    number: string;
    type: number;
    typeDescription: string;
  }[];
  socialMedias: {
    url: string;
    type: number;
  }[];
  addresses: {
    owner: string;
    ownerId: string;
    id?: string;
    street: string;
    district: string;
    complement: string;
    zipcode: string;
    type: number;
    cityId: string;
    stateId?: string;
    countryId?: string;
  }[];
};

export type Profile = {
  userId: string;
  profileId: string;
  franchiseId?: string;
  entityId: string;
};
export type permissionResp = {
  id: string;
  name: string;
  description: string;
  discriminator: string;
}[];
export type PermissionGroup = {
  id: string;
  name: string;
  description: string;
  permissionIds: string[];
};
export type PermissionGroupResp = {
  id: string;
  name: string;
  description: string;
  isEditable: boolean;
  permissions: {
    permissionDescription: string;
    permissionId: string;
    permissionName: string;
    discriminator: string;
  }[];
};

export function useProfile() {
  return useQuery([`profileList`], async () => {
    const resp = await getApi().get(`/profiles`);
    return getResp<ProfileResp[]>(resp);
  });
}
export function usePermissionGroup(
  page?: number,
  pageSize?: number | null,
  search: string = ''
) {
  return useQuery([`profileList`, page, pageSize, search], async () => {
    const resp = await Middleware.get(`/profiles`, {
      params: {
        Offset: page,
        Limit: pageSize,
        QuickSearch: search,
      },
    });
    return getRespContent<PermissionGroupResp[]>(resp);
  });
}
export function usePermission() {
  return useQuery([`permissionList`], async () => {
    const resp = await Middleware.get(`/permission/select `);
    return getResp<permissionResp>(resp);
  });
}
export function savePermissionGroup(props: PermissionGroup) {
  if (props.id) {
    return putPermissionGroup(props);
  }
  return postPermissionGroup(props);
}
export function postPermissionGroup(props: PermissionGroup) {
  return Middleware.post(`/profiles`, props);
}
export function putPermissionGroup(props: PermissionGroup) {
  return Middleware.put(`/profiles/${props.id}`, props);
}
export function deletePermissionGroup(id: string) {
  return Middleware.delete(`/profiles/${id}`);
}
export function useProfileSelect() {
  return useQuery([`profileSelectList`], async () => {
    const resp = await getApi().get(`/profiles/select`);
    return getResp<ListResp[]>(resp);
  });
}

export function profileRemove(props: Profile) {
  return Middleware.put(
    `/profiles/RemoveRelationshipBetweenUserAndProfile`,
    props
  );
}

export function filterProfiles(profileList: ListResp[]) {
  return profileList.filter(
    (item) =>
      item.name.includes('Gestor de') ||
      item.name.includes('Admin') ||
      item.name.includes('Aten') ||
      item.name.includes('Loca')
  );
}
