import { FaEdit, FaInfo, FaRegTimesCircle, FaTrash } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import { DescriptionLabelComponent } from '../../UI/molecules/DescriptionlabelComponent/DescriptionLabelComponent';
import React from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { showToast } from '../../../utils/showToast';
import DetailsModalComponent from '../../UI/molecules/DetailsModalComponent/DetailsModalComponent';
import { parseBrazilDate } from '../../../utils/functions/utility';
import {
  deleteTechnology,
  putTechnologyActive,
  putTechnologyDisable,
  StatusTechnology,
  TechnologyResp,
  useTechnology,
} from '../../../services/TechnologyService';
import { CreateTechnologyModalComponent } from '../../UI/organisms/CreateTechnologyModalComponent/CreateTechnologyModalComponent';
import { useUserData } from '../../../services/LoginService';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import { CheckCircleIcon } from '@chakra-ui/icons';

export default function TechnologyTemplateComponent() {
  const [selectedTechnology, setSelectedTechnology] = React.useState<
    TechnologyResp | undefined
  >();
  const toast = useToast();
  const user = useUserData();
  const modalCardInfo = [
    { item: 'Número', description: selectedTechnology?.serialNumber },
    { item: 'Descrição', description: selectedTechnology?.description },
    {
      item: 'Criado em',
      description: parseBrazilDate(selectedTechnology?.createdAt),
    },
    { item: 'Tipo', description: selectedTechnology?.typeDescription },
    { item: 'Status', description: selectedTechnology?.statusDescription },
    {
      item: 'Contrato Ativo?',
      description: selectedTechnology?.hasActiveContract ? 'Sim' : 'Não',
    },
  ];
  const [statusTechnology, setStatusTechnology] =
    React.useState<StatusTechnology>(StatusTechnology.Active);
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModal, setShowModal] = React.useState(false);
  const listTechnology = useTechnology(
    selectedPage,
    pageSize,
    '',
    'serialNumber',
    'serialNumber',
    'asc',
    statusTechnology
  );
  const [detailsDialog, setDetailsDialog] = React.useState(false);

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const tabName = [
    {
      title: 'Ativas',
      onClick: () => {
        setStatusTechnology(StatusTechnology.Active);
      },
    },
    {
      title: 'Desabilitadas',
      onClick: () => {
        setStatusTechnology(StatusTechnology.Inactive);
      },
    },
  ];
  return (
    <>
      <HeaderTextComponent goBack>Tecnologias</HeaderTextComponent>
      <Flex mt={4} mb={2} justifyContent={'space-between'}>
        <TabsFilterComponent tabList={tabName} />
        <Stack flexDirection={'row'} alignItems={'flex-end'}>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              setSelectedTechnology(undefined);
              setShowModal(true);
            }}
          >
            Novo Cadastro
          </ButtonComponent>
        </Stack>
      </Flex>

      <TableComponent
        ItemsHeader={[
          { item: 'Descrição' },
          { item: 'Proprietário' },
          { item: 'Status' },
          { item: 'Tipo' },
          { item: 'Contrato Vinculado' },
          { item: 'Ação' },
        ]}
        isLoading={listTechnology.isLoading}
        centered={true}
        data={
          listTechnology.data?.data.map((e, i) => ({
            items: [
              <DescriptionLabelComponent
                label={e.description}
                subLabel={e.serialNumber}
                key={i}
              />,
              user?.entity?.name,
              e.status === StatusTechnology.Active ? (
                <TagComponent size={'md'} colorScheme={'green'} text="Ativo" />
              ) : (
                <TagComponent size={'md'} colorScheme={'red'} text="Inativo" />
              ),
              e.typeDescription,

              e.hasActiveContract ? (
                <TagComponent size={'md'} colorScheme={'green'} text="Sim" />
              ) : (
                <TagComponent size={'md'} colorScheme={'red'} text="Não" />
              ),
              <>
                <IconButtonComponent
                  icon={
                    e.status === StatusTechnology.Active ? (
                      <FaRegTimesCircle color="white" />
                    ) : (
                      <CheckCircleIcon />
                    )
                  }
                  toolTipText={
                    e.status === StatusTechnology.Active
                      ? 'Desabilitar'
                      : 'Ativar'
                  }
                  ariaLabel="Disabled"
                  colorScheme={
                    e.status === StatusTechnology.Active ? 'yellow' : 'green'
                  }
                  onSubmit={() => {
                    e.status === StatusTechnology.Active
                      ? putTechnologyDisable(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description: 'Tecnologia desabilitada com sucesso',
                          });
                          listTechnology.refetch();
                        })
                      : putTechnologyActive(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description: 'Tecnologia ativada com sucesso',
                          });
                          listTechnology.refetch();
                        });
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedTechnology(e);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaInfo />}
                  ariaLabel="info"
                  toolTipText="Detalhes"
                  backgroundColor="#3182CE"
                  onSubmit={() => {
                    setSelectedTechnology(e);
                    setDetailsDialog(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTrash />}
                  disabled={e.hasActiveContract}
                  toolTipText="Apagar"
                  marginX={2}
                  ariaLabel="delete"
                  colorScheme={'red'}
                  onSubmit={() => {
                    deleteTechnology(e.id).then((res) => {
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description: 'Tecnologia excluida com sucesso',
                      });
                      listTechnology.refetch();
                    });
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listTechnology.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateTechnologyModalComponent
        selectedTechnology={selectedTechnology}
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
        reloadData={() => listTechnology.refetch()}
      />
      <DetailsModalComponent
        isOpen={detailsDialog}
        title={'Tecnologia'}
        onClose={() => setDetailsDialog(false)}
        data={modalCardInfo}
      />
    </>
  );
}
