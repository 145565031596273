import { Flex } from '@chakra-ui/react';
import React from 'react';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import {
  ReadjustmentIndex,
  postReadjustmentIndex,
} from '../../../../services/ReadjustmentIndexService';
import { replaceCommaByDot } from '../../../../utils/functions/masks';

export default function AddPercentageModalComponent(props: {
  year: string;
  month: string;
  index: ReadjustmentIndex;
  showModal: boolean;
  setShowModal: () => void;
  reloadData: () => void;
}) {
  const [porcentage, setPorcentage] = React.useState<string>('');

  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal();
        setPorcentage('');
        props.reloadData();
      }}
      title={`Adicionar porcentagem`}
      size="lg"
    >
      <Flex>
        <TextComponent>
          Adicione o valor em porcentagem para o mês
        </TextComponent>
      </Flex>
      <Flex mb={5} margin={'60px 0px 70px 0px'} flexDirection={'column'}>
        <InputComponent
          marginBottom={2}
          w={170}
          label={props.month}
          placeholder="Porcentagem"
          value={replaceCommaByDot(porcentage)}
          onChange={(input) => {
            setPorcentage(input.target.value);
          }}
        />
      </Flex>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setPorcentage('');
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          onSubmit={() => {
            postReadjustmentIndex({
              date: new Date(Number(props.year), MonthToNumber(props.month)),
              index: props.index,
              value: Number(porcentage),
            }).then((res) => {
              setPorcentage('');
              props.reloadData();
              props.setShowModal();
            });
          }}
        >
          Salvar
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}

export function MonthToNumber(month: string): number {
  switch (month) {
    case 'janeiro':
      return 0;
    case 'fevereiro':
      return 1;
    case 'março':
      return 2;
    case 'abril':
      return 3;
    case 'maio':
      return 4;
    case 'junho':
      return 5;
    case 'julho':
      return 6;
    case 'agosto':
      return 7;
    case 'setembro':
      return 8;
    case 'outubro':
      return 9;
    case 'novembro':
      return 10;
    default:
      return 11;
  }
}
