import { QueryClient, useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';

export type ReadjustmentIndexResp = {
  date: string;
  index: number;
  value: number;
  id: string;
  createdAt: string;
  indexDescription: string;
  createdById: string;
  createdByName: string;
  hasContracts: boolean;
  percentage: number;
};

export type ReadjustmentIndexRespResume = {
  id: string;
  index: ReadjustmentIndex;
  indexDescription: string;
  year: string;
  lastIndex: ReadjustmentIndexResp;
  previousIndex: ReadjustmentIndexResp;
};

export type ReadjustmentIndexSelectResp = {
  id: string;
  index: ReadjustmentIndex;
  indexDescription: string;
};

export type ReadjustmentIndexYearResp = {
  id: string;
  createdAt: Date;
  indexCreated: true;
  index: ReadjustmentIndex;
  value: number;
  date: Date;
  monthName: string;
  hasContracts: boolean;
};

export enum ReadjustmentIndex {
  IGPDI = 1,
  IGPM = 2,
  INPC = 3,
  IPCDI = 4,
  IPCA = 5,
  IPCAE = 6,
  SELIC = 7,
}

export type ReadjustmentIndexPost = {
  date: Date;
  index: ReadjustmentIndex;
  value: number;
};

export function useReadjustmentIndex(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'title',
  sort: string = 'title'
) {
  return useQuery(
    [`ReadjustmentIndexList`, page, search, searchField, sort],
    async () => {
      const resp = await Api.get(`/ReadjustmentIndex`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
        },
      });
      return getRespContent<ReadjustmentIndexResp[]>(resp);
    }
  );
}

export function useReadjustmentIndexFind(type: number, reference: string) {
  return useQuery(
    [`ReadjustmentIndexFind`, type, reference],
    async () => {
      const resp = await Api.get(`/ReadjustmentIndex/find-by-type-and-date`, {
        params: {
          type,
          reference,
        },
      });
      return getResp<ReadjustmentIndexResp>(resp);
    },
    { enabled: !!type && !!reference }
  );
}

export function useReadjustmentIndexResume() {
  return useQuery([`ReadjustmentIndexListResume`], async () => {
    const resp = await Api.get(`/ReadjustmentIndex/Resume`);
    return getRespContent<ReadjustmentIndexRespResume[]>(resp);
  });
}

export function useReadjustmentIndexSelect() {
  return useQuery([`ReadjustmentIndexListSelect`], async () => {
    const resp = await Api.get(`/ReadjustmentIndex`);
    return getResp<ReadjustmentIndexResp[]>(resp);
  });
}

export function useReadjustmentIndexYearReferences(
  index: ReadjustmentIndex | undefined,
  year: string
) {
  return useQuery([`ReadjustmentIndexList/YearReferences`, index], async () => {
    const resp = await Api.get(
      `/ReadjustmentIndex/${index}/year-references/${year}`
    );
    return getResp<ReadjustmentIndexYearResp[]>(resp);
  });
}

export function postReadjustmentIndex(props: ReadjustmentIndexPost) {
  return Api.post('/ReadjustmentIndex', props);
}
export function deleteReadjustmentIndex(id: string) {
  return Api.delete(`/ReadjustmentIndex/${id}`);
}

export function invalidateReadjustmentIndexYear(
  index: ReadjustmentIndex | undefined,
  queryClient: QueryClient
) {
  queryClient.invalidateQueries({
    queryKey: [`ReadjustmentIndexList/YearReferences`, index],
  });
}
