// import NavbarItemsComponent, {
//   NavbarItemsProps,
// } from '../../atoms/NavbarItemsComponent/NavbarItemsComponent';
// import { useSetUserData, useUserData } from '../../../../services/LoginService';
// import {
//   Box,
//   IconButton,
//   Drawer,
//   DrawerBody,
//   DrawerCloseButton,
//   DrawerContent,
//   DrawerHeader,
//   Flex,
//   Image,
//   useDisclosure,
// } from '@chakra-ui/react';
// import {
//   FaEllipsisH,
//   FaHamburger,
//   FaRegCommentDots,
//   FaRegUser,
// } from 'react-icons/fa';
// import tellink from '../../../../assets/images/tellink.png';
// import NotificationMenuComponent from '../NotificationMenuComponent/NotificationMenuComponent';
// import { useNavigate } from 'react-router-dom';
// import { getMessageReport } from '../../../../services/MessagesService';
// import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
// import { useEntitiesSelectUser } from '../../../../services/EntitiesService';
// import { ENABLE_MIDDLEWARE } from '../../../../environment';
// import { useQueryClient } from '@tanstack/react-query';
// import GoBackButtonComponent from '../GoBackButtonComponent/GoBackButtonComponent';

// export default function NavbarComponent(props: NavbarItemsProps) {
//   const user = useUserData();
//   const setUser = useSetUserData();
//   const entities = useEntitiesSelectUser();
//   const navigate = useNavigate();
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const queryClient = useQueryClient();
//   return (
//     <>
//       <Flex
//         p={4}
//         as="nav"
//         align="center"
//         justify="space-between"
//         wrap="wrap"
//         w="100%"
//         backgroundColor={'white'}
//         position={'fixed'}
//         top={0}
//         zIndex={50}
//         boxShadow={'0px 0px 20px rgba(0, 0, 0, 0.08)'}
//       >
//         <Flex paddingLeft={4} w={'600px'}>
//           <Image src={tellink} width={'200px'} h={'30px'} margin={'auto 0'} />
//           <IconButton
//             aria-label="Open drawer"
//             data-testid={'open-drawer'}
//             variant={'guost'}
//             ml={'20px'}
//             icon={<FaHamburger />}
//             onClick={onOpen}
//           />
//         </Flex>
//         <Flex paddingRight={4}>
//           {ENABLE_MIDDLEWARE && (
//             <InputSelectComponent
//               label=""
//               defaultValue={user?.entity?.id}
//               options={entities.data || []}
//               onChange={(input) => {
//                 setUser({
//                   token: user?.token || '',
//                   personId: user?.personId || '',
//                   permissions: user?.permissions || [],
//                   userId: user?.userId || '',
//                   onBoarding: user?.onBoarding || false,
//                   entity: entities.data?.filter(
//                     (item) => item.id === input.target.value
//                   )[0],
//                 });
//                 queryClient.invalidateQueries();
//               }}
//             />
//           )}
//           <NotificationMenuComponent
//             items={[
//               {
//                 person: 'Raphael Moreira',
//                 subject: 'Produto da Sprint Entregue',
//                 time: '9:30 am',
//                 action: () => {
//                   console.log('item');
//                 },
//               },
//               {
//                 person: 'Rander Gabriel',
//                 subject: 'Reunião com cliente marcada',
//                 time: '10:30 am',
//                 action: () => {
//                   console.log('item 2');
//                 },
//               },
//               {
//                 person: 'David Mota',
//                 subject: 'Problemas com Front',
//                 time: '11:30 am',
//                 action: () => {
//                   console.log('item 3');
//                 },
//               },
//               {
//                 person: 'Erik Neves',
//                 subject: 'Problemas com Back',
//                 time: '9:00 am',
//                 action: () => {
//                   console.log('item 4');
//                 },
//               },
//               {
//                 person: 'Vinicius X',
//                 subject: 'Testes em andamento',
//                 time: '9:00 am',
//                 action: () => {
//                   console.log('item 5');
//                 },
//               },
//             ]}
//           />
//           <IconButton
//             aria-label="message report"
//             variant={'guost'}
//             ml={'10px'}
//             icon={<FaRegCommentDots />}
//             onClick={() => {
//               getMessageReport();
//             }}
//           />
//           <IconButton
//             aria-label=""
//             variant={'guost'}
//             ml={'10px'}
//             icon={<FaRegUser />}
//             onClick={() => {
//               navigate('/profile');
//             }}
//           />
//           <IconButton
//             aria-label=""
//             variant={'guost'}
//             ml={'10px'}
//             icon={<FaEllipsisH />}
//             onClick={() => {}}
//           />
//         </Flex>
//         <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
//           <DrawerContent>
//             <DrawerCloseButton />
//             <DrawerHeader>
//               <Image
//                 src={tellink}
//                 width={'200px'}
//                 h={'30px'}
//                 margin={'auto 0'}
//               />
//             </DrawerHeader>
//             <DrawerBody>
//               <NavbarItemsComponent buttons={props.buttons} />
//             </DrawerBody>
//           </DrawerContent>
//         </Drawer>
//       </Flex>
//       <Box mt={'80px'}></Box>
//       <GoBackButtonComponent />
//     </>
//   );
// }
import NavbarItemsComponent, {
  NavbarItemsProps,
} from '../../atoms/NavbarItemsComponent/NavbarItemsComponent';
import {
  useLogoff,
  useSetUserData,
  useUserData,
} from '../../../../services/LoginService';
import {
  Box,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Image,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  FaAngleDoubleRight,
  FaBars,
  FaRegUser,
  FaSignOutAlt,
} from 'react-icons/fa';
import TelLinkLogo from '../../../../assets/images/tellink.png';
import { useNavigate } from 'react-router-dom';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import { useEntitiesSelectUser } from '../../../../services/EntitiesService';
import { ENABLE_MIDDLEWARE } from '../../../../environment';
import { useQueryClient } from '@tanstack/react-query';
import { AvatarSimpleComponent } from '../../atoms/AvatarSimpleComponent/AvatarSimpleComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { useMyProfile } from '../../../../services/PhysicalPersonService';
import BarImage from '../../../../assets/images/bgMenu.jpg';
import NotificationMenuComponent from '../NotificationMenuComponent/NotificationMenuComponent';
import { Tooltip } from '@chakra-ui/react';
import {
  NotificationResp,
  useNotification,
} from '../../../../services/NotificationsService';

export default function NavbarComponent(props: NavbarItemsProps) {
  const user = useUserData();
  const setUser = useSetUserData();
  const entities = useEntitiesSelectUser();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const person = useMyProfile();
  const logoff = useLogoff();
  const notificationList = useNotification();

  return (
    <>
      <Flex
        p={2}
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        backgroundColor={'white'}
        position={'fixed'}
        top={0}
        zIndex={50}
        boxShadow={'0px 0px 20px rgba(0, 0, 0, 0.08)'}
      >
        <Flex ml={isOpen ? '260px' : 0} transition={'.4s'}>
          {isLargerThan768 ? (
            <>
              {!isOpen && (
                <Image
                  cursor={'pointer'}
                  src={TelLinkLogo}
                  width={'80px'}
                  mr={4}
                  ml={4}
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                />
              )}
              <Flex
                onClick={onOpen}
                cursor={'pointer'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {!isOpen && (
                  <Tooltip label={'Abrir menu'}>
                    <IconButton
                      variant="ghost"
                      aria-label=""
                      fontSize="20px"
                      bg={'gray.200'}
                      icon={<FaAngleDoubleRight size={'14px'} />}
                    />
                  </Tooltip>
                )}
              </Flex>
            </>
          ) : (
            <IconButton
              aria-label="Open drawer"
              data-testid={'open-drawer'}
              variant={'gost'}
              icon={<FaBars />}
              onClick={onOpen}
            />
          )}
        </Flex>
        <Flex mr={6}>
          {ENABLE_MIDDLEWARE && (
            <Flex mr={4} alignItems={'center'} justifyContent={'center'}>
              {entities.data?.length === 1 ? (
                <TextComponent mr={4} fontWeight={'semibold'}>
                  {user?.entity?.name}
                </TextComponent>
              ) : (
                <InputSelectComponent
                  label=""
                  defaultValue={user?.entity?.id}
                  options={entities.data || []}
                  onChange={(input) => {
                    setUser({
                      token: user?.token || '',
                      personId: user?.personId || '',
                      permissions: user?.permissions || [],
                      userId: user?.userId || '',
                      onBoarding: user?.onBoarding || false,
                      entity: entities.data?.find(
                        (item) => item.id === input.target.value
                      ),
                    });
                    queryClient.invalidateQueries();
                    window.location.reload();
                  }}
                />
              )}
            </Flex>
          )}

          <NotificationMenuComponent
            items={
              notificationList?.data?.map((item: NotificationResp) => ({
                name: item.name,
                description: item.description,
                type: item.type,
                id: item.contractId,
              })) || []
            }
          />

          <Tooltip label="Meu perfil">
            <IconButton
              aria-label=""
              variant={'guost'}
              ml={'10px'}
              icon={<FaRegUser />}
              onClick={() => {
                navigate('/my-profile');
              }}
            />
          </Tooltip>
          <Tooltip label="Sair">
            <IconButton
              aria-label=""
              variant={'guost'}
              ml={'10px'}
              icon={<FaSignOutAlt />}
              onClick={() => {
                logoff();
              }}
            />
          </Tooltip>
        </Flex>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerContent maxW={260}>
            <DrawerCloseButton color={'white'} />

            <DrawerHeader
              minH={130}
              backgroundSize={'cover'}
              backgroundPosition={'center'}
              bgImg={BarImage}
            >
              <Flex justifyContent={'end'} flexDirection={'column'}>
                <AvatarSimpleComponent
                  src={person.data?.profilePicUrl}
                  size="md"
                />
                <TextComponent fontSize={16} color={'white'}>
                  {person.data?.name}
                </TextComponent>
                <TextComponent
                  fontWeight={'light'}
                  fontSize={10}
                  color={'white'}
                >
                  {person.data?.user?.email}
                </TextComponent>
              </Flex>
            </DrawerHeader>
            <DrawerBody p={2}>
              <NavbarItemsComponent
                onCloseMenu={onClose}
                buttons={props.buttons}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
      <Box mt={'68px'}></Box>
    </>
  );
}
