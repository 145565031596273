import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import {
  FaCheckCircle,
  FaEdit,
  FaReceipt,
  FaTimesCircle,
} from 'react-icons/fa';
import {
  LocatorResp,
  getLocatorDetails,
} from '../../../../services/LocatorService';
import {
  personDocumentMask,
  phoneMask,
} from '../../../../utils/functions/masks';
import { getFirstIfAny } from '../../../../utils/functions/utility';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import ContainerStepComponent from '../../atoms/ContainerStepComponent/ContainerStepComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import { DescriptionLabelComponent } from '../../molecules/DescriptionlabelComponent/DescriptionLabelComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import CreatePersonModalComponent from '../CreateLocatorModalComponent/CreateLocatorModalComponent';
import { SelectLocatorModalComponent } from '../SelectLocatorModalComponent/SelectLocatorModalComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import { SelectReceiverModalComponent } from '../SelectIntermediaryModalComponent/SelectIntermediaryModalComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import { RelatedEntity } from '../../../../services/RelatedEntityService';
import { SelectRelatedEntityModalComponent } from '../SelectRelatedEntityModalComponent/SelectRelatedEntityModalComponent';
import { PersonFunction } from '../../../../services/PhysicalPersonService';
import { SelectAttorneyModalComponent } from '../SelectAttorneyModalComponent/SelectAttorneyModalComponent';

type ContractStepperLocatorComponentProps = {
  selectedLocators: LocatorResp[];
  setSelectedLocators: Dispatch<SetStateAction<LocatorResp[]>>;
  selectedRelatedEntity: RelatedEntity | undefined;
  setSelectedRelatedEntity: Dispatch<SetStateAction<RelatedEntity | undefined>>;
};

export default function ContractStepperLocatorComponent(
  props: ContractStepperLocatorComponentProps
) {
  const [showModalLocator, setShowModalLocator] = React.useState(false);
  const [showModalCreateLocator, setShowModalCreateLocator] =
    React.useState(false);
  const [autoQuota, setAutoQuota] = React.useState(true);
  const [selectedLocator, setSelectedLocator] = React.useState<
    (LocatorResp & { physicalOrJuridicalPersonId?: string }) | undefined
  >();

  const [showModalSelectReceiver, setShowModalSelectReceiver] =
    React.useState(false);
  const [showModalSelectRelatedEntity, setShowModalSelectRelatedEntity] =
    React.useState(false);
  const [showModalSelectAttorney, setShowModalSelectAttorney] =
    React.useState(false);

  const [uniqueReceiver, setUniqueReceiver] = React.useState(false);
  const [uniqueAttorney, setUniqueAttorney] = React.useState(false);
  function setQuota(locators: LocatorResp[]) {
    const length = locators.length;
    const quota = Math.floor((100 / length) * 100) / 100;
    const haveRemainder = 100 % length > 0;
    const remainder = 100 - quota * locators.length;
    const selectedLocators = locators.map((item, i) => ({
      ...item,
      quota: String(quota + (i === 0 && haveRemainder ? remainder : 0)),
    }));
    props.setSelectedLocators(selectedLocators);
  }
  return (
    <ContainerStepComponent title="Escolha o locador e defina a cota">
      <Flex mb={8} alignItems={'flex-top'}>
        <Flex direction="column">
          <TextComponent fontWeight={'bold'}>
            Entidade Relacionada:
          </TextComponent>
          {props.selectedRelatedEntity ? (
            <AvatarLabelComponent
              label={props.selectedRelatedEntity.name}
              subLabel={'Entidade Relacionada'}
              src={''}
            />
          ) : (
            <TextComponent>Entidade relacionada não selecionada</TextComponent>
          )}
        </Flex>
        <ButtonComponent
          margin={'0 20px'}
          onSubmit={() => setShowModalSelectRelatedEntity(true)}
        >
          Selecione a entidade relacionada
        </ButtonComponent>
      </Flex>
      <Flex justifyContent={'space-between'}>
        <Flex>
          <ButtonComponent
            margin={'0 4px 4px 0'}
            onSubmit={() => {
              setShowModalLocator(true);
            }}
          >
            Selecionar Locadores
          </ButtonComponent>
          <ButtonComponent
            margin={'0 4px 4px 0'}
            onSubmit={() => {
              setSelectedLocator(undefined);
              setShowModalCreateLocator(true);
            }}
          >
            Cadastrar Locador
          </ButtonComponent>
        </Flex>
        <InputSelectComponent
          label={'Tipo de divisão'}
          m={'-25px 0 0 0'}
          w={'300px'}
          defaultValue={autoQuota.toString()}
          onChange={(input) => {
            if (input.target.value === 'true') {
              setAutoQuota(true);
              setQuota(props.selectedLocators);
            } else {
              setAutoQuota(false);
              props.setSelectedLocators((loc) =>
                loc.map((item) => ({ ...item, quota: '' }))
              );
            }
          }}
          options={[
            {
              id: 'true',
              name: 'Divisão Igual',
            },
            {
              id: 'false',
              name: 'Divisão Diferenciada',
            },
          ]}
        />
      </Flex>

      <TableComponent
        isLoading={false}
        ItemsHeader={[
          { item: 'Responsável' },
          { item: 'Nome' },
          { item: 'Documento' },
          { item: 'Telefone' },
          { item: 'Dados bancários' },
          { item: 'Cota %' },
          {
            item: (
              <>
                <TextComponent mr={2}>Recebedor</TextComponent>
                <Tooltip label={'Modo único recebedor'}>
                  <Flex>
                    <InputCheckComponent
                      onChange={() => {
                        setUniqueReceiver((unique) => {
                          const newValue = !unique;
                          if (newValue && props.selectedLocators[0].receiver) {
                            props.setSelectedLocators((loc) =>
                              loc.map((item) => {
                                return {
                                  ...item,
                                  receiver: props.selectedLocators[0].receiver,
                                };
                              })
                            );
                          }
                          return newValue;
                        });
                      }}
                    ></InputCheckComponent>
                  </Flex>
                </Tooltip>
              </>
            ),
          },
          {
            item: (
              <>
                <TextComponent mr={2}>Procurador</TextComponent>
                <Tooltip label={'Modo único procurador'}>
                  <Flex>
                    <InputCheckComponent
                      onChange={() => {
                        setUniqueAttorney((unique) => {
                          const newValue = !unique;
                          if (newValue && props.selectedLocators[0].attorney) {
                            props.setSelectedLocators((loc) =>
                              loc.map((item) => {
                                return {
                                  ...item,
                                  attorney: props.selectedLocators[0].attorney,
                                };
                              })
                            );
                          }
                          return newValue;
                        });
                      }}
                    ></InputCheckComponent>
                  </Flex>
                </Tooltip>
              </>
            ),
          },
          { item: 'Ação' },
        ]}
        data={
          props.selectedLocators.map((e, i: number) => ({
            items: [
              <InputCheckComponent
                key={i}
                onChange={() => {}}
              ></InputCheckComponent>,
              <DescriptionLabelComponent
                key={i}
                label={e.name}
                subLabel={'Locador'}
              />,
              personDocumentMask(e.document, e.type),
              phoneMask(getFirstIfAny(e.phones)?.number),
              e.hasBankAccounts ? (
                <>
                  <FaCheckCircle size={20} color="green" />
                </>
              ) : (
                <>
                  <FaTimesCircle size={20} color="red" />
                </>
              ),
              <>
                <InputComponent
                  type="number"
                  placeholder="0 - 100"
                  value={e.quota}
                  onChange={(input) => {
                    props.setSelectedLocators(
                      props.selectedLocators.map((item) =>
                        item.locatorId === e.locatorId
                          ? { ...item, quota: input.target.value }
                          : { ...item }
                      )
                    );
                  }}
                />
              </>,
              e.receiver ? (
                <DescriptionLabelComponent
                  key={i}
                  label={e.receiver?.name}
                  subLabel={'Recebedor'}
                />
              ) : (
                'Sem recebedor'
              ),
              e.attorney ? (
                <DescriptionLabelComponent
                  key={i}
                  label={e.attorney.name}
                  subLabel={'Procurador'}
                />
              ) : (
                'sem procurador'
              ),
              <Box textAlign={'center'} key={i}>
                <IconButtonComponent
                  icon={<FaReceipt />}
                  margin={'0 8px 8px 0'}
                  ariaLabel="selectReceiver"
                  toolTipText="Selecionar recebedor"
                  colorScheme="green"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedLocator(e);
                    setShowModalSelectReceiver(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaReceipt />}
                  margin={'0 8px 8px 0'}
                  ariaLabel="selectAttorney"
                  toolTipText="Selecionar procurador"
                  colorScheme="blue"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedLocator(e);
                    setShowModalSelectAttorney(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  margin={'0 8px 8px 0'}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedLocator({
                      ...e,
                      physicalOrJuridicalPersonId: e.id || e.personId,
                    });
                    setShowModalCreateLocator(true);
                  }}
                />
                <IconButtonComponent
                  margin={'0 8px 8px 0'}
                  icon={<CloseIcon />}
                  ariaLabel="Remover"
                  colorScheme={'red'}
                  onSubmit={() => {
                    if (autoQuota) {
                      setQuota(
                        props.selectedLocators.filter(
                          (item) => item.locatorId !== e.locatorId
                        )
                      );
                    } else {
                      props.setSelectedLocators(
                        props.selectedLocators.filter(
                          (item) => item.locatorId !== e.locatorId
                        )
                      );
                    }
                  }}
                />
              </Box>,
            ],
          })) || []
        }
      />
      <SelectLocatorModalComponent
        onAcceptModal={(result) => {
          const filteredArray = result.filter(
            (item) =>
              !props.selectedLocators.some(
                (locator) => locator.locatorId === item.locatorId
              )
          );
          if (autoQuota) {
            setQuota([...props.selectedLocators, ...filteredArray]);
          } else {
            props.setSelectedLocators([
              ...props.selectedLocators,
              ...filteredArray,
            ]);
          }
        }}
        showModal={showModalLocator}
        setShowModal={() => setShowModalLocator(false)}
      />
      <CreatePersonModalComponent
        showModal={showModalCreateLocator}
        selectedPerson={selectedLocator}
        function={PersonFunction.Locator}
        setShowModal={() => setShowModalCreateLocator(false)}
        onSuccess={async (locator) => {
          const newLocator = await getLocatorDetails(locator.personId);
          if (autoQuota) {
            setQuota([...props.selectedLocators, newLocator]);
          } else {
            props.setSelectedLocators([...props.selectedLocators, newLocator]);
          }
        }}
      />
      <SelectReceiverModalComponent
        showModal={showModalSelectReceiver}
        setShowModal={() => setShowModalSelectReceiver(false)}
        selectedIntermediaryReceiver={selectedLocator?.receiver}
        setSelectedIntermediaryReceiver={(receiver) => {
          props.setSelectedLocators((loc) =>
            loc.map((item) => {
              if (uniqueReceiver && selectedLocator) {
                return { ...item, receiver: receiver };
              } else
                return selectedLocator?.personId === item.personId
                  ? { ...item, receiver: receiver }
                  : item;
            })
          );
          setSelectedLocator((loc) => {
            return loc ? { ...loc, receiver: receiver } : loc;
          });
        }}
        isReceiver={true}
      />
      <SelectAttorneyModalComponent
        showModal={showModalSelectAttorney}
        setShowModal={() => setShowModalSelectAttorney(false)}
        selectedAttorney={selectedLocator?.attorney}
        setSelectedAttorney={(attorney) => {
          props.setSelectedLocators((loc) =>
            loc.map((item) => {
              if (uniqueAttorney && selectedLocator) {
                return { ...item, attorney: attorney };
              } else
                return selectedLocator?.personId === item.personId
                  ? { ...item, attorney: attorney }
                  : item;
            })
          );
          setSelectedLocator((loc) => {
            return loc ? { ...loc, attorney: attorney } : loc;
          });
        }}
      />
      <SelectRelatedEntityModalComponent
        showModal={showModalSelectRelatedEntity}
        setShowModal={() => setShowModalSelectRelatedEntity(false)}
        selectedRelatedEntity={props.selectedRelatedEntity}
        setSelectedRelatedEntity={props.setSelectedRelatedEntity}
      />
    </ContainerStepComponent>
  );
}
