import { Flex, FormControl, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  CoordenatesLocationType,
  Location,
  LocationType,
  saveLocation,
} from '../../../../services/LocationService';
import {
  LocatorResp,
  useLocatorSelect,
} from '../../../../services/LocatorService';
import {
  useAddressCep,
  useCityList,
  useStateListNoFilter,
} from '../../../../services/StateServices';
import { cepMask, cleanNumberMask } from '../../../../utils/functions/masks';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import { useDebounce } from '../../../../utils/functions/debounce';
import MyMapComponent from '../../../MyMapComponent';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

export function CreateLocationModalComponent(props: {
  showModal: boolean;
  selectedLocation?: Location;
  customLocators?: LocatorResp[];
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      owner: '',
      ownerId: '',
      street: '',
      district: '',
      number: '',
      complement: '',
      zipcode: '',
      type: LocationType.Nenhum,
      stateId: '',
      cityId: '',
      coordenateId: '',
      footage: 0,
      coordinates: {
        latitude: '',
        longitude: '',
        description: '',
        localizationType: CoordenatesLocationType.Address,
      },
    }),
    []
  );
  const [form, setForm] = React.useState<Location>(defaultForm);
  const { data: locators } = useLocatorSelect();
  const { data: states } = useStateListNoFilter();
  const { data: cities } = useCityList(form.stateId);
  React.useEffect(() => {
    if (props.selectedLocation)
      setForm({
        ...props.selectedLocation,
        zipcode: cepMask(props.selectedLocation.zipcode),
      });
    else setForm(defaultForm);
  }, [props.selectedLocation, defaultForm]);
  const [, searchCep, setSearchCep] = useDebounce('');
  const { data: dataCep } = useAddressCep(searchCep);
  React.useEffect(() => {
    if (dataCep) {
      setForm((form) => ({
        ...form,
        street: dataCep.street,
        district: dataCep.district,
        countryId: dataCep.countryId,
        stateId: dataCep.stateId,
        cityId: dataCep.cityId,
      }));
    }
  }, [dataCep]);
  const [isLoading, setIsLoading] = useState(false);
  const [point, setPoint] = useState<google.maps.LatLngLiteral>({
    lat: -23.6814347,
    lng: -46.9249408,
  });

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: 'AIzaSyDSTdz7Lm_KzF41vymmvu9n2FMKD5xn9kQ',
    });

  useEffect(() => {
    if (form.zipcode.length < 10) return;
    const input = `${form.street}, ${form.district} - ${form.cityName} - ${form.zipcode}`;
    getPlacePredictions({ input: input });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.street, form.number, form.zipcode, form.district, form.cityName]);

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails) => {
          

          setPoint({
            lat: placeDetails?.geometry?.location?.lat() ?? 0,
            lng: placeDetails?.geometry?.location?.lng() ?? 0,
          });
        }
      );
  }, [placePredictions, placesService]);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Novo Local`}
      maxW="80%"
      maxH="800px"
      size="xl"
    >
      <Text
        fontSize={'xl'}
        fontWeight={'light'}
        color="gray.500"
        marginBottom={7}
      >
        O sistema gera um campo de ID automático para os itens de tecnologia a
        partir do cadastro. O campo proprietário é preenchido automaticamente
        com o nome da empresa do usuário logado.
      </Text>
      <Flex mb={5} flexDirection={'row'}>
        <FormControl w={'24%'} margin={'0 1% 0 0'}>
          <InputSelectComponent
            defaultValue={form.locatorId}
            onChange={(input) => {
              setForm({ ...form, locatorId: input.target.value });
            }}
            options={(props.customLocators
              ? props.customLocators
              : locators
            )?.map((input) => ({
              id: input.locatorId,
              name: input.name,
            }))}
            placeholder="Selecione o Locador"
            label="Locador"
          />
        </FormControl>
        <FormControl w={'24%'} margin={'0 1% 0 0'}>
          <InputSelectComponent
            defaultValue={String(form.type)}
            onChange={(input) => {
              setForm({ ...form, type: Number(input.target.value) });
            }}
            options={[
              {
                id: String(LocationType.Residencial),
                name: 'Residencial',
              },
              {
                id: String(LocationType.Comercial),
                name: 'Comercial',
              },
              {
                id: String(LocationType.Outros),
                name: 'Outros',
              },
            ]}
            placeholder="Selecione o tipo"
            label="Tipo de local"
          />
        </FormControl>
        <InputComponent
          label="Metragem (em m²)"
          type="number"
          placeholder="50"
          margin={'0 1% 0 0'}
          w={'24%'}
          value={String(form.footage)}
          onChange={(input) => {
            setForm({ ...form, footage: Number(input.target.value) });
          }}
        />
        <InputComponent
          label="Cep"
          placeholder="00.000-000"
          margin={'0 1% 0 0'}
          w={'24%'}
          value={form.zipcode}
          onChange={(input) => {
            setSearchCep(cleanNumberMask(input.target.value));
            setForm({ ...form, zipcode: cepMask(input.target.value) });
          }}
        />
      </Flex>
      <Flex mb={5} flexDirection={'row'}>
        <InputComponent
          label="Local - Logradouro"
          placeholder="Digite o Logradouro"
          w={'24%'}
          margin={'0 1% 0 0'}
          value={form.street}
          onChange={(input) => {
            setForm({ ...form, street: input.target.value });
          }}
        />
        <InputComponent
          margin={'0 1% 0 0'}
          w={'24%'}
          value={form.number}
          onChange={(input) => setForm({ ...form, number: input.target.value })}
          label="Número"
          placeholder="Digite o número"
        />
        <InputComponent
          label="Bairro"
          placeholder="Digite o bairro"
          w={'24%'}
          margin={'0 1% 0 0'}
          value={form.district}
          onChange={(input) => {
            setForm({ ...form, district: input.target.value });
          }}
        />
        <FormControl w={'24%'} margin={'0 1% 0 0'}>
          <InputSelectComponent
            defaultValue={form.stateId}
            onChange={(input) => {
              setForm({ ...form, stateId: input.target.value });
            }}
            options={states}
            placeholder="Selecione o estado"
            label="Estado"
          />
        </FormControl>
      </Flex>
      <Flex mb={5} flexDirection={'row'}>
        <InputSelectComponent
          w={'24%'}
          m={'0 1% 0 0'}
          defaultValue={form.cityId}
          options={cities}
          onChange={(input) => {
            setForm({ ...form, cityId: input.target.value });
          }}
          label="Cidade"
          placeholder="Selecione uma cidade"
        />
        <InputComponent
          label="Complemento"
          placeholder="Digite o complemento"
          w={'24%'}
          margin={'0 1% 0 0'}
          value={form.complement}
          onChange={(input) => {
            setForm({ ...form, complement: input.target.value });
          }}
        />
      </Flex>

      <Flex mb={4}>
        <MyMapComponent zoom={18} point={point} setPoint={setPoint} />
      </Flex>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setForm(defaultForm);
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          isLoading={isLoading}
          disabled={isLoading}
          // disabled={validateOptionalFields(form, [
          //   'footage',
          //   'owner',
          //   'ownerId',
          //   'id',
          //   'coordenateId',
          //   'coordenates',
          //   'complement',
          // ])}
          onSubmit={() => {
            setIsLoading(true);
            saveLocation({
              ...form,
              zipcode: cleanNumberMask(form.zipcode),
              coordinates: {
                ...form.coordinates,
                latitude: point.lat.toString(),
                longitude: point.lng.toString(),
              },
            })
              .then((res) => {
                props.setShowModal();
                props.reloadData?.();
                showToast({
                  toast,
                  status: 'success',
                  title: 'Sucesso',
                  description: 'Local salvo com sucesso',
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        >
          {props.selectedLocation ? 'Salvar' : 'Criar Local'}
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
