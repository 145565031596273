import { As, Text, TextProps as ChakraTextProps } from '@chakra-ui/react';
import { CSSProperties } from 'react';

export type TextProps = Pick<
  ChakraTextProps,
  | 'color'
  | 'fontSize'
  | 'fontWeight'
  | 'textAlign'
  | 'textTransform'
  | 'mb'
  | 'mt'
> & {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  style?: CSSProperties;
  lineHeight?: string;
  mr?: string | number;
  ml?: string | number;
  mb?: string | number;
  mt?: string | number;
  as?: As;
};

export default function TextComponent(props: TextProps) {
  return (
    <Text
      mr={props.mr}
      ml={props.ml}
      as={props.as}
      style={props.style}
      onClick={() => props.onClick?.()}
      className={`${props.className}`}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      color={props.color}
      lineHeight={props.lineHeight}
      textAlign={props.textAlign}
      textTransform={props.textTransform}
    >
      {props.children}
    </Text>
  );
}
