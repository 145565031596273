import React, { useEffect, useState } from 'react';

export function getLocalStorageValue<T>(
  key: string,
  defaultValue: T | undefined
) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial =
      saved && saved !== 'undefined' ? JSON.parse(saved) : defaultValue;
    return initial as T | undefined;
  }
  return undefined;
}

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T | undefined
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] => {
  const [value, setValue] = useState(() => {
    return getLocalStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const removeLocalStorage = (key: string) => {
  localStorage.setItem(key, JSON.stringify(null));
};
