import { FaCheck, FaInfo, FaTimes } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, Stack, Tag, useToast } from '@chakra-ui/react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { formatDate } from '../../../utils/functions/formatDate';
import {
  StatusContractRoutines,
  putContractRoutinesApproveDisapprove,
  useContractRoutines,
} from '../../../services/ContractRoutinesService';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import React from 'react';
import { AcceptMessageModalComponent } from '../../UI/organisms/AcceptMessageModalComponent/AcceptMessageModalComponent';
import { showToast } from '../../../utils/showToast';
import { CreateContractRoutinesModalComponent } from '../../UI/organisms/CreateContractRoutinesModalComponent/CreateContractRoutinesModalComponent';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import DashboardTableComponent from '../../UI/molecules/DashboardTableComponent/DashboardTableComponent';

export default function ContractRoutinesTemplateComponent() {
  const toast = useToast();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModal, setShowModal] = React.useState(false);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [index, setIndex] = React.useState<number>(0);
  const [status, setStatus] = React.useState<
    StatusContractRoutines | undefined
  >(undefined);
  const listContractRoutines = useContractRoutines(
    selectedPage,
    pageSize,
    status
  );
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const tabName = [
    {
      title: 'Todos',
      onClick: () => {
        setStatus(undefined);
      },
    },
    {
      title: 'Pendentes',
      onClick: () => {
        setStatus(StatusContractRoutines.pending);
      },
    },
    {
      title: 'Aprovados',
      onClick: () => {
        setStatus(StatusContractRoutines.approved);
      },
    },
    {
      title: 'Reprovados',
      onClick: () => {
        setStatus(StatusContractRoutines.disapproved);
      },
    },
  ];
  const [approve, setApprove] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [showModalApprove, setShowModalApprove] = React.useState(false);

  function getBgColrByStatus(status: StatusContractRoutines | undefined) {
    switch (status) {
      case StatusContractRoutines.approved:
        return 'green';
      case StatusContractRoutines.disapproved:
        return 'red';
      case StatusContractRoutines.pending:
        return 'yellow';
      default:
        return 'gray';
    }
  }
  const affectedContractsList =
    listContractRoutines?.data?.data[index]?.affectedContracts;

  return (
    <>
      <Stack
        paddingBottom={5}
        alignItems={'center'}
        justifyContent={'space-between'}
        direction="row"
      >
        <HeaderTextComponent goBack>Rotinas Automatizadas</HeaderTextComponent>
      </Stack>
      <Stack
        direction={'row'}
        paddingBottom={5}
        justifyContent={'space-between'}
      >
        <TabsFilterComponent tabList={tabName} />
        <ButtonComponent onSubmit={() => setShowModal(true)}>
          Novo Cadastro
        </ButtonComponent>
      </Stack>
      <TableComponent
        ItemsHeader={[
          { item: 'Tipo' },
          { item: 'Criado por' },
          { item: 'Data de criação' },
          { item: 'Tipo' },
          { item: 'Status' },
          { item: 'Ação' },
        ]}
        isLoading={listContractRoutines.isLoading}
        centered={true}
        emptyState={listContractRoutines.data?.metadata.dataSize === 0}
        data={
          listContractRoutines.data?.data.map((e, i) => ({
            items: [
              e.type,
              e.createdByName,
              formatDate(e.createdAt),
              e.typeDescription,
              <Tag colorScheme={getBgColrByStatus(e.status)}>
                {e.statusDescription}
              </Tag>,
              <>
                <IconButtonComponent
                  icon={<FaInfo />}
                  toolTipText={'Detalhes'}
                  ariaLabel="Detalhes"
                  colorScheme={'blue'}
                  onSubmit={() => {
                    setIndex(i);
                    setShowDetailsModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaCheck />}
                  disabled={e.status !== StatusContractRoutines.pending}
                  toolTipText={
                    e.status === StatusContractRoutines.pending
                      ? 'Aprovar'
                      : 'Já Respondido'
                  }
                  ariaLabel="Aprovar"
                  marginX={'8px'}
                  colorScheme={'green'}
                  onSubmit={() => {
                    setApprove(true);
                    setSelectedId(e.id);
                    setShowModalApprove(true);
                  }}
                />
                <IconButtonComponent
                  disabled={e.status !== StatusContractRoutines.pending}
                  toolTipText={
                    e.status === StatusContractRoutines.pending
                      ? 'Reprovar'
                      : 'Já Respondido'
                  }
                  icon={<FaTimes />}
                  ariaLabel="Reprovar"
                  colorScheme={'red'}
                  onSubmit={() => {
                    setApprove(false);
                    setSelectedId(e.id);
                    setShowModalApprove(true);
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listContractRoutines.data?.metadata.dataSize ?? 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateContractRoutinesModalComponent
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
        reloadData={() => listContractRoutines.refetch()}
      />
      <AcceptMessageModalComponent
        approve={approve}
        showModal={showModalApprove}
        setShowModal={() => setShowModalApprove(false)}
        onAcceptModal={(message) => {
          putContractRoutinesApproveDisapprove(
            selectedId,
            approve,
            message
          ).then((res) => {
            setShowModalApprove(false);
            listContractRoutines.refetch();
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: `Renovações automáticas ${
                approve ? 'Aprovadas' : 'Reprovadas'
              } com sucesso`,
            });
          });
        }}
      />
      <ModalStructureComponent
        title={'Detalhes da alteração em lote'}
        size={'6xl'}
        isOpen={showDetailsModal}
        onClose={() => {
          setShowDetailsModal(false);
        }}
        isCentered={true}
      >
        <Flex justifyContent={'center'}>
          <DashboardTableComponent
            title="Contratos afetados"
            subTitle="(contratos que serão alterados com a rotina)"
            tHead={[
              'ID do contrato',
              'Numero de série',
              'Numero de renovações restantes',
              'Índice',
              'Valor',
            ]}
            tBody={
              affectedContractsList?.map((e) => [
                e.contractId,
                e.serialNumber,
                e.numberOfRenewalsRemaining <= 0
                  ? '-'
                  : e.numberOfRenewalsRemaining,
                e.indexDescription === null ? '-' : e.indexDescription,
                e.value ?? 0,
              ]) || []
            }
          />
        </Flex>
      </ModalStructureComponent>
    </>
  );
}
