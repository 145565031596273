import { ChangeEventHandler } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteList,
  AutoCompleteItem,
} from '@choc-ui/chakra-autocomplete';
import TextComponent from '../TextComponent/TextComponent';

export type InputAutoCompleteProps = {
  label: string;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  m?: string | number;
  w?: string | number;
  p?: string | number;
  onChangeText?: ChangeEventHandler<HTMLInputElement>;
  onChangeOption?: (values: string) => void;
  options?: { id: string; name: string }[];
  error?: string;
};

export default function InputAutoCompleteComponent(
  props: InputAutoCompleteProps
) {
  return (
    <FormControl
      w={props.w}
      p={props.p}
      margin={props.m}
      isInvalid={!!props.error}
    >
      <FormLabel mb={0}>{props.label}</FormLabel>
      <AutoComplete
        openOnFocus
        onChange={props.onChangeOption}
        emptyState={
          <TextComponent textAlign={'center'}>
            sem valores encontrados
          </TextComponent>
        }
      >
        <AutoCompleteInput
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChangeText}
        />
        <AutoCompleteList>
          {props.options?.map((option, i) => (
            <AutoCompleteItem key={i} value={option.id}>
              {option.name}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
      <FormErrorMessage mt={'0'}>{props.error}</FormErrorMessage>
    </FormControl>
  );
}
