import { useQuery } from '@tanstack/react-query';
import Api, { getResp } from './Api';

export type BankAgencyResp = {
  bankAgencyId: string;
  number: string;
  name: string;
};

export function useBankAgencyList() {
  return useQuery([`BankAgencyList`], async () => {
    const resp = await Api.get(`/BankAgencies`, {
      params: {
        Limit: 1_000_000,
      },
    });
    return getResp<BankAgencyResp[]>(resp);
  });
}
