import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ResponsiveValue,
} from '@chakra-ui/react';
import React from 'react';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import { ModalFooter } from 'react-bootstrap';

export type ModalStructureComponentProps = {
  children: React.ReactNode;
  size:
    | ResponsiveValue<
        | (string & {})
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | '2xl'
        | 'xs'
        | '3xl'
        | '4xl'
        | '5xl'
        | '6xl'
        | 'full'
      >
    | undefined;
  maxW?: string;
  maxH?: string;
  overflowX?: ResponsiveValue<any>;
  isCentered?: boolean;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  description?: string;
  footer?: React.ReactNode;
};

export default function ModalStructureComponent(
  props: ModalStructureComponentProps
) {
  return (
    <Modal
      closeOnOverlayClick={false}
      size={props.size}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered={props.isCentered}
    >
      <ModalOverlay />
      <ModalContent
        maxW={props.maxW}
        maxH={props?.maxH}
        overflowX={props?.overflowX}
      >
        <ModalHeader pb={'5px'} m={'0 0 10px 0'}>
          <TextComponent fontWeight={'500'} color={'black'} fontSize={'22px'}>
            {props.title}
          </TextComponent>
          <HeaderTextComponent mt={4} subTitle>
            {props.description}
          </HeaderTextComponent>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow={'auto'} pb={8} pt={'0'}>
          {props.children}
        </ModalBody>

        <ModalFooter>{props.footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}
