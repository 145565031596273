import { EngineeringTypeResp } from './EngineeringTypeService';
import { ClauseResp } from './ClauseService';
import { LocationResp } from './LocationService';
import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { TowerTypeResp } from './TowerTypeService';
import { EquipmentResp } from './EquipmentService';
import { TechnologyResp } from './TechnologyService';
import { SortDirection } from './PhysicalPersonService';
import { openFile } from '../utils/functions/utility';
import { LocatorResp } from './LocatorService';
import { AttendanceStatus } from './Attendance';

export type ContractResp = {
  activationDate: string;
  hasPendingFields: boolean;
  indexDescription: string;
  index: number;
  expireDate: string;
  baseReadjustmentDate: string;
  startDate: string;
  dueDate: string;
  installationDate: string;
  firstPaymentDate: string;
  contractStatus: StatusContract;
  frequency: FrequencyContract;
  advancePayment: boolean;
  value: number;
  area: number;
  iptuRegistration: string;
  iptuPayment: boolean;
  iptuValue: number;
  automaticRenovation: boolean;
  automaticReadjustment: boolean;
  expirationAlert: boolean;
  origin: string;
  data: string;
  comments: string;
  regionalId: string;
  clusterId: string;
  readjustmentIndexId: string;
  towerTypeId: string;
  technologyId: string;
  equipmentId: string;
  engineeringTypeId: string;
  intermediaryId: string;
  manualIdentifier: string;
  id: string;
  serialNumber: string;
  createdByName: string;
  createdByDocument: string;
  contractStatusDescription: string;
  frequencyDescription: string;
  hasCampaign: boolean;
  hasPDFContract: boolean;
  signatureDate: string;
  technology: TechnologyResp;
  equipment: EquipmentResp;
  towerType: TowerTypeResp;
  engineeringType: EngineeringTypeResp;
  locators: LocatorResp[];
  locations: LocationResp[];
  clauses: ClauseResp[];
  intermediary: LocatorResp;
  attachments: {
    id: string;
    type: string;
    owner: string;
    ownerId: string;
    url: string;
    externalId: string;
    displayName: string;
    createdAt: string;
  }[];
  documents: {
    id: string;
    createdAt: string;
    type: number;
    typeDescription: string;
    url: string;
    displayName: string;
    attachmentId: string;
    replacingDocumentId: string;
    personId: string;
    personName: string;
    signers: {
      email: string;
      name: string;
      personId: string;
      hasSigned: true;
      signedAt: string;
    }[];
  }[];
  termination: {
    id: string;
    personId: string;
    personName: string;
    motivation: string;
    createdAt: string;
  };
  transactions: {
    contractId: string;
    lastMonth: number;
    lastYear: number;
    totalValue: number;
    overdueValue: number;
    lastTransactionUpdate: string;
    itens: {
      id: string;
      locatorName: string;
      value: number;
      effected: boolean;
      contractSerialNumber: string;
      reference: string;
      lastUpdateAt: string;
    }[];
  };
  signers: {
    email: string | null;
    hasSigned: boolean;
    name: string;
    personId: string | null;
  }[];
  bankAccounts: {
    id: string;
    bankAgencyId: string;
    number: string;
    name: string;
    agency: string;
    account: string;
    corporateName: string;
    corporateDocument: string;
    primaryAccount: boolean;
    owner: string;
    ownerId: string;
  }[];
  locatorAttendances: {
    locatorId: string;
    attendanceId: string;
    locatorName: string;
    subject: string;
    createdAt: string;
    status: AttendanceStatus;
  }[];
  considerations: {
    content: string;
    id: string;
    createdAt: string;
  }[];
  witnesses: Witnesses[];
};

export type Witnesses = {
  id: string;
  name: string;
  email: string;
  document: string;
};

type ContractBankAccounts = {
  id: string;
  locatorId: string;
  receiverId: string;
  bankAgencyId: string;
  number: string;
  name: string;
  agency: string;
  account: string;
};

export type Contract = {
  id?: string;
  activationDate: string;
  expireDate: string;
  baseReadjustmentDate: string;
  startDate: string;
  installationDate: string;
  firstPaymentDate: string;
  contractStatus?: StatusContract | null;
  frequency: FrequencyContract | null;
  advancePayment: boolean | null;
  value: number | string;
  area: number;
  iptuRegistration: string;
  iptuPayment: boolean | null;
  // iptuTextValue: string;
  iptuValue: number | string;
  // textValue?: string;
  automaticRenovation: boolean | null;
  automaticReadjustment: boolean | null;
  expirationAlert: boolean | null;
  origin: string;
  data: string;
  comments: string;
  regionalId: string;
  clusterId: string;
  towerTypeId: string;
  technologyId: string;
  equipmentId: string;
  engineeringTypeId: string;
  intermediaryId: string;
  manualIdentifier: string;
  readjustmentIndexOption: ReadjustmentIndexContract;
  locators?: {
    locatorId: string;
    percentage: number;
    receiver?: {
      id: string;
      name: string;
      displayName: string;
      profilePicUrl: string;
    };
    attorney?: {
      id: string;
      name: string;
      displayName: string;
      profilePicUrl: string;
    };
    receiverId?: string;
    attorneyId?: string;
  }[];
  witnesses?: Witnesses[];
  locationIds?: string[];
  clauseIds: string[];
  considerationIds: string[];
};

export enum StatusContract {
  EmCadastramento = 0,
  PendenteDeAssinatura = 1,
  Assinado = 2,
  Inativo = 3,
  Cancelado = 4,
  EmElaboraçãoDeMinuta = 5,
  PendenteDeAtivação = 6,
}

export enum FrequencyContract {
  Anual = 0,
  Mensal = 1,
  Bimestral = 2,
  Semestral = 3,
}

enum ReadjustmentIndexContract {
  IGP_DI_FGV = 1,
  IGP_M_FGV = 2,
  INPC_IBGE = 3,
  IPC_DI_FGV = 4,
  IPCA_IBGE = 5,
  IPCA_E_IBGE = 6,
  SELIC = 7,
}

export function getReadjustmentIndexContract() {
  return [
    {
      id: ReadjustmentIndexContract.IGP_DI_FGV.toString(),
      name: 'IGP-DI(FGV)',
    },
    {
      id: ReadjustmentIndexContract.IGP_M_FGV.toString(),
      name: 'IGP-M(FGV)',
    },
    {
      id: ReadjustmentIndexContract.INPC_IBGE.toString(),
      name: 'INPC(IBGE)',
    },
    {
      id: ReadjustmentIndexContract.IPC_DI_FGV.toString(),
      name: 'IPC-DI(FGV)',
    },
    {
      id: ReadjustmentIndexContract.IPCA_IBGE.toString(),
      name: 'IPCA(IBGE)',
    },
    {
      id: ReadjustmentIndexContract.IPCA_E_IBGE.toString(),
      name: 'IPCA-E(IBGE)',
    },
    {
      id: ReadjustmentIndexContract.SELIC.toString(),
      name: 'SELIC',
    },
  ];
}

function searchFormatter(searchField: string, search: string) {
  if (searchField === 'ContractStatus' && search) {
    if (
      search === String(StatusContract.EmCadastramento) ||
      'pendente'.includes(search.toLowerCase())
    ) {
      return 0;
    }
    if (
      search === String(StatusContract.PendenteDeAssinatura) ||
      'análise'.includes(search.toLowerCase())
    ) {
      return 1;
    }
    if (
      search === String(StatusContract.Cancelado) ||
      'concluído'.includes(search.toLowerCase())
    ) {
      return 3;
    }
  }
  return search;
}

export function useContract(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'locator',
  sort: string = 'createdAt',
  direction: SortDirection = 'desc',
  active: boolean | null = null,
  hasPendingChangesRequest: boolean | null = null,
  startingSignatureDate?: Date | string,
  endingSignatureDate?: Date | string,
  startingDueDate?: Date | string,
  endingDueDate?: Date | string,
  contractStatus?: number | string
) {
  return useQuery(
    [
      `ContractList`,
      page,
      search,
      searchField,
      sort,
      active,
      direction,
      hasPendingChangesRequest,
      startingSignatureDate,
      endingSignatureDate,
      startingDueDate,
      endingDueDate,
      contractStatus,
    ],
    async () => {
      const resp = await Api.get(`/Contract`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: searchFormatter(searchField, search),
          SortColumn: sort,
          SortDirection: direction,
          Active: active,
          hasPendingChangesRequest,
          StartingSignatureDate: startingSignatureDate,
          EndingSignatureDate: endingSignatureDate,
          StartingDueDate: startingDueDate,
          EndingDueDate: endingDueDate,
          ContractStatus: contractStatus,
        },
      });
      return getRespContent<ContractResp[]>(resp);
    }
  );
}

export type useContractFilteredSearch = {
  serialNumber?: string;
  locator?: string;
  locationId?: string;
  activationDate?: string;
  expireDate?: string;
  readjustmentIndexId?: string;
  towerTypeId?: string;
  equipmentId?: string;
  frequency: number | null;
  technologyId?: string;
  engineeringTypeId?: string;
  automaticRenovation: boolean | null;
  expirationAlert: boolean | null;
  value: number | null;
  textValue?: string;
  origin?: string;
  data?: string;
  comments?: string;
  status: number | null;
  active: boolean | null;
  myContracts: boolean | null;
  clauseId?: string;
};

export function useContractFiltered(
  page: number,
  pageSize: number | null,
  sort: string = 'createdAt',
  direction: SortDirection = 'desc',
  search?: useContractFilteredSearch
) {
  return useQuery(
    [
      `ContractListFilter`,
      page,
      sort,
      direction,
      search?.serialNumber,
      search?.locator,
      search?.locationId,
      search?.activationDate,
      search?.expireDate,
      search?.readjustmentIndexId,
      search?.towerTypeId,
      search?.equipmentId,
      search?.frequency,
      search?.technologyId,
      search?.engineeringTypeId,
      search?.automaticRenovation,
      search?.expirationAlert,
      search?.value,
      search?.origin,
      search?.data,
      search?.comments,
      search?.status,
      search?.active,
      search?.myContracts,
      search?.clauseId,
    ],
    async () => {
      const resp = await Api.get(`/Contract`, {
        params: {
          Offset: page,
          Limit: pageSize,
          SortColumn: sort,
          SortDirection: direction,
          serialNumber: search?.serialNumber,
          locator: search?.locator,
          locationId: search?.locationId,
          activationDate: search?.activationDate,
          expireDate: search?.expireDate,
          readjustmentIndexId: search?.readjustmentIndexId,
          towerTypeId: search?.towerTypeId,
          equipmentId: search?.equipmentId,
          frequency: search?.frequency,
          technologyId: search?.technologyId,
          engineeringTypeId: search?.engineeringTypeId,
          automaticRenovation: search?.automaticRenovation,
          expirationAlert: search?.expirationAlert,
          value: search?.value,
          origin: search?.origin,
          data: search?.data,
          comments: search?.comments,
          contractStatus: search?.status,
          active: search?.active,
          myContracts: search?.myContracts,
          clauseId: search?.clauseId,
        },
      });
      return getRespContent<ContractResp[]>(resp);
    }
  );
}

export function useAllContractFiltered(
  sort: string = 'createdAt',
  direction: SortDirection = 'desc',
  search?: useContractFilteredSearch
) {
  return useQuery(
    [
      `ContractAll`,
      sort,
      direction,
      search?.serialNumber,
      search?.locator,
      search?.activationDate,
      search?.expireDate,
      search?.readjustmentIndexId,
      search?.towerTypeId,
      search?.equipmentId,
      search?.frequency,
      search?.technologyId,
      search?.engineeringTypeId,
      search?.automaticRenovation,
      search?.expirationAlert,
      search?.value,
      search?.origin,
      search?.data,
      search?.comments,
      search?.status,
      search?.active,
      search?.myContracts,
      search?.clauseId,
    ],
    async () => {
      const resp = await Api.get(`/Contract`, {
        params: {
          SortColumn: sort,
          SortDirection: direction,
          serialNumber: search?.serialNumber,
          locator: search?.locator,
          activationDate: search?.activationDate,
          expireDate: search?.expireDate,
          readjustmentIndexId: search?.readjustmentIndexId,
          towerTypeId: search?.towerTypeId,
          equipmentId: search?.equipmentId,
          frequency: search?.frequency,
          technologyId: search?.technologyId,
          engineeringTypeId: search?.engineeringTypeId,
          automaticRenovation: search?.automaticRenovation,
          expirationAlert: search?.expirationAlert,
          value: search?.value,
          origin: search?.origin,
          data: search?.data,
          comments: search?.comments,
          contractStatus: search?.status,
          active: search?.active,
          myContracts: search?.myContracts,
          clauseId: search?.clauseId,
        },
      });
      return getResp<ContractResp[]>(resp);
    }
  );
}

export function useContractDetails(id: string) {
  return useQuery(
    [`ContractDetails`],
    async () => {
      const resp = await Api.get(`/Contract/${id}`);
      return getResp<ContractResp>(resp);
    },
    { enabled: !!id }
  );
}

export function getContractReport(
  search: string,
  searchField: string = 'locator',
  sort: string = 'locator',
  direction: SortDirection = 'asc',
  active: boolean | null = null
) {
  Api.get(`/Contract/report-complete`, {
    params: {
      [searchField]: searchFormatter(searchField, search),
      SortColumn: sort,
      SortDirection: direction,
      Active: active,
    },
    responseType: 'blob',
  }).then((response) => {
    openFile(response);
  });
}

export function saveContract(props: Contract) {
  if (props.id) {
    return putContract(props);
  }
  return postContract(props);
}

export function postContract(props: Contract) {
  return Api.post('/Contract', props);
}

export function putContract(props: Contract) {
  return Api.put(`/Contract/${props.id}`, props);
}
export function putContractStatus(id: string, status: StatusContract) {
  return Api.put(`contract/${id}/contractstatus/${status}`);
}

export function contractRenovation(props: {
  id: string;
  newExpirationDate: string;
}) {
  return Api.post(`/Contract/${props.id}/renovation`, props);
}
export enum ReadjustmentType {
  VALUE = 0,
  INDEX = 1,
}
export function contractAdjustment(props: {
  id: string;
  newValue: number;
  description: string;
  readjustmentIndexId: string;
  type: ReadjustmentType;
}) {
  return Api.post(`/Contract/${props.id}/adjustment`, props);
}

export function contractTermination(props: {
  id: string;
  motivation: string;
  terminationDate: string;
  detachedPaymentId: string;
}) {
  return Api.post(`/Contract/${props.id}/termination`, props);
}

export function contractSendForSignature(id: string) {
  return Api.post(`/Contract/${id}/send-for-signature`);
}

export function postContractImport(attachmentId: string) {
  return Api.post(`/Contract/import-from-csv/${attachmentId}`);
}
export function postContractExelImport(attachmentId: string) {
  return Api.post(`/contract/import-from-excel/${attachmentId}`);
}

export function postContractGeneratePDF(id: string) {
  return Api.post(`/Contract/${id}/generate-pdf`);
}

export function putContractDisableDocument(id: string) {
  return Api.put(`/Contract/document/${id}/disable`);
}
export function putContractReplaceDocument(
  contractId: string,
  documentId: string,
  attachmentId: string
) {
  return Api.put(
    `/Contract/document/${contractId}/replaceAttachment/${documentId}`,
    {
      attachmentId: attachmentId,
    }
  );
}

export function useContractBankAccounts(id?: string) {
  return useQuery(
    [`ContractBankAccounts`],
    async () => {
      const resp = await Api.get(`/Contract/${id}/accounts`);
      return getResp<ContractBankAccounts[]>(resp);
    },
    { enabled: !!id }
  );
}
export function useContractParamsValues(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'locator',
  sort: string = 'createdAt',
  direction: SortDirection = 'desc',
  active: boolean | null = null,
  hasPendingChangesRequest: boolean | null = null,
  startingSignatureDate?: Date | string,
  endingSignatureDate?: Date | string,
  startingDueDate?: Date | string,
  endingDueDate?: Date | string,
  contractStatus?: number | string
) {
  return useQuery(
    [
      `ContractDetails`,
      page,
      pageSize,
      search,
      searchField,
      sort,
      direction,
      active,
      hasPendingChangesRequest,
      startingSignatureDate,
      endingSignatureDate,
      startingDueDate,
      endingDueDate,
      contractStatus,
    ],
    async () => {
      const resp = await Api.get(`contract/params-values`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: searchFormatter(searchField, search),
          SortColumn: sort,
          SortDirection: direction,
          Active: active,
          hasPendingChangesRequest,
          StartingSignatureDate: startingSignatureDate,
          EndingSignatureDate: endingSignatureDate,
          StartingDueDate: startingDueDate,
          EndingDueDate: endingDueDate,
          ContractStatus: contractStatus,
        },
      });
      return getResp<{
        currentValue: number;
        totalValue: number;
      }>(resp);
    }
  );
}
export function usePendingFilds(id: string) {
  return useQuery([`PendingFilds`, id], async () => {
    const resp = await Api.get(`contract/${id}/pending-fields`);
    return getRespContent<string[]>(resp);
  });
}
