import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';

export type TechnologyResp = {
  description: string;
  type: TypeTechnology;
  id: string;
  createdAt: string;
  serialNumber: string;
  hasActiveContract: boolean;
  status: StatusTechnology;
  statusDescription: string;
  typeDescription: string;
};

export type Technology = {
  id?: string;
  description: string;
  type: TypeTechnology;
};

export enum TypeTechnology {
  Null = 0,
  C5G = 1,
  C4G = 2,
  C3G = 3,
  Radio = 4,
  Fiber = 5,
  Central = 6,
}

export enum StatusTechnology {
  Active = 0,
  Inactive = 1,
}

export function useTechnology(
  page: number,
  pageSize: number | null,
  search: string = '',
  searchField: string = 'serialNumber',
  sort: string = 'serialNumber',
  direction: SortDirection = 'asc',
  status?: StatusTechnology
) {
  return useQuery(
    [`TechnologyList`, page, search, searchField, sort, direction, status],
    async () => {
      const resp = await Api.get(`/Technology`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          Status: status,
        },
      });
      return getRespContent<TechnologyResp[]>(resp);
    }
  );
}

export function useTechnologySelect() {
  return useQuery([`TechnologyListSelect`], async () => {
    const resp = await Api.get(`/Technology/select`);
    return getResp<TechnologyResp[]>(resp);
  });
}

export function saveTechnology(props: Technology) {
  if (props.id) {
    return putTechnology(props);
  }
  return postTechnology(props);
}

export function postTechnology(props: Technology) {
  return Api.post('/Technology', props);
}

export function putTechnology(props: Technology) {
  return Api.put(`/Technology/${props.id}`, props);
}

export function putTechnologyActive(id: string) {
  return Api.put(`/Technology/active/${id}`);
}

export function putTechnologyDisable(id: string) {
  return Api.put(`/Technology/disable/${id}`);
}

export function deleteTechnology(id: string) {
  return Api.delete(`/Technology/${id}`);
}
