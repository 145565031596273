import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex } from '@chakra-ui/react';
import { useContractDetails } from '../../../services/ContractService';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { convertToMonetaryValue } from '../../../utils/functions/masks';
import {
  useTransactionContractId,
  useTransactionContractIdTotalizer,
} from '../../../services/TransactionService';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import { formatDate } from '../../../utils/functions/formatDate';
import { notInfo } from '../../../pages/MyProfilePage';
import { Totalizer } from '../../UI/molecules/Totalizer/Totalizer';

export default function TransactionTemplateComponent() {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const contractInfo = useContractDetails(params.id || '');
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  const listTrasactionId = useTransactionContractId(
    params.id || '',
    selectedPage,
    pageSize
  );
  const itens = listTrasactionId.data?.data || [];

  const transactionValues = useTransactionContractIdTotalizer(
    params.id || '',
    selectedPage,
    pageSize
  );

  return (
    <>
      <Flex mb={2} justify={'space-between'}>
        <HeaderTextComponent goBack backLink={`/contract-details/${params.id}`}>
          Transações{' '}
          {params.id && `contrato: ${contractInfo.data?.serialNumber}`}
        </HeaderTextComponent>
        <ButtonComponent
          onSubmit={() => navigate(`/detached-payment/${params.id}`)}
        >
          Pagamentos extras
        </ButtonComponent>
      </Flex>
      <TableComponent
        ItemsHeader={[
          { item: 'Locador' },
          { item: 'Valor' },
          { item: 'Efetuado' },
          { item: 'Previsão de pagamento' },
          { item: 'Data de pagamento' },
        ]}
        isLoading={listTrasactionId.isLoading}
        centered={true}
        emptyState={listTrasactionId.data?.data.length === 0}
        data={
          listTrasactionId.data?.data.map((e) => ({
            items: [
              <AvatarLabelComponent label={e.locatorName} />,
              convertToMonetaryValue(e.value),
              e.effected ? 'Sim' : 'Não',
              notInfo(formatDate(e.reference), 'não efetuado'),
              notInfo(formatDate(e.effectedAt), 'não efetuado'),
            ],
          })) || []
        }
      />
      <Totalizer
        total={transactionValues.data?.data.totalValue ?? 0}
        currentPage={transactionValues.data?.data.currentPageValue ?? 0}
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={itens.length || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
    </>
  );
}
