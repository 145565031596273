import { Flex, Stack, useToast } from '@chakra-ui/react';
import React from 'react';
import {
  clearMoneyMask,
  cpfMask,
  moneyMask,
  phoneMask,
} from '../../../../utils/functions/masks';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import {
  ContractResp,
} from '../../../../services/ContractService';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import { showToast } from '../../../../utils/showToast';
import { postDetachedPayment } from '../../../../services/DetachedPaymentService';

export function CreateDetachedPaymentModalComponent(props: {
  showModal: boolean;
  setShowModal: () => void;
  contractDetails?: ContractResp;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [paymentDate, setPaymentDate] = React.useState('');
  const [value, setValue] = React.useState('');
  const [selectedLocatorsIds, setSelectedLocatorsIds] = React.useState<
    string[]
  >([]);

  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Criar Pagamento Extra`}
      maxW="1200px"
      size="xl"
    >
      <Stack direction={'row'} spacing={'2%'} mb={'10px'}>
        <InputComponent
          value={title}
          onChange={(item) => setTitle(item.target.value)}
          placeholder="Digite o Título"
          label="Título"
        />
        <InputComponent
          label="Valor"
          placeholder="Digite o valor"
          value={moneyMask(value)}
          onChange={(input) => {
            setValue(input.target.value);
          }}
        />
      </Stack>
      <Stack direction={'row'} spacing={'2%'} mb={'10px'}>
        <InputComponent
          value={paymentDate}
          onChange={(item) => setPaymentDate(item.target.value)}
          type="date"
          label="Data de Pagamento"
        />
      </Stack>
      <Stack direction={'row'} spacing={'2%'} mb={'10px'}>
        <InputComponent
          value={description}
          onChange={(item) => setDescription(item.target.value)}
          placeholder="Digite a Descrição"
          label="Descrição"
        />
      </Stack>
      <TableComponent
        ItemsHeader={[
          {
            item: (
              <>
                Selecionar
                <InputCheckComponent
                  borderColor="black"
                  m={'0 0 0 10px'}
                  isChecked={props.contractDetails?.locators?.every((item) =>
                    selectedLocatorsIds.includes(item.locatorId)
                  )}
                  onChange={() => {
                    if (
                      !props.contractDetails?.locators?.every((item) =>
                        selectedLocatorsIds.includes(item.locatorId)
                      )
                    )
                      props.contractDetails?.locators?.forEach((e) => {
                        if (
                          !selectedLocatorsIds.some(
                            (item) => item === e.locatorId
                          )
                        )
                          setSelectedLocatorsIds((contractIds) => [
                            ...contractIds,
                            e.locatorId,
                          ]);
                      });
                    else setSelectedLocatorsIds([]);
                  }}
                ></InputCheckComponent>
              </>
            ),
          },
          { item: 'Nome' },
          { item: 'Documento' },
          { item: 'Email' },
          { item: 'Telefone' },
          { item: 'Tipo' },
        ]}
        isLoading={false}
        centered={true}
        emptyState={props.contractDetails?.locators.length === 0}
        data={
          props.contractDetails?.locators.map((e, i) => ({
            items: [
              <InputCheckComponent
                key={i}
                isChecked={selectedLocatorsIds.some(
                  (item) => item === e.locatorId
                )}
                onChange={() => {
                  if (!selectedLocatorsIds.some((item) => item === e.locatorId))
                    setSelectedLocatorsIds([
                      ...selectedLocatorsIds,
                      e.locatorId,
                    ]);
                  else {
                    setSelectedLocatorsIds(
                      selectedLocatorsIds.filter((item) => item !== e.locatorId)
                    );
                  }
                }}
              ></InputCheckComponent>,
              e.name,
              cpfMask(e.document),
              e.email,
              phoneMask(e.phone),
              e.typeDescription,
            ],
          })) ?? []
        }
      />
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setSelectedLocatorsIds([]);
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          disabled={!title || !description || !paymentDate}
          onSubmit={() => {
            postDetachedPayment({
              contractId: props.contractDetails?.id ?? '',
              title: title,
              description: description,
              value: Number(clearMoneyMask(value)),
              paymentDate: paymentDate,
              locatorIds: selectedLocatorsIds,
            }).then((res) => {
              props.setShowModal();
              props.reloadData?.();
              showToast({
                toast,
                status: 'success',
                title: 'Sucesso',
                description: `Pagamento extra criado com sucesso`,
              });
            });
          }}
        >
          Adicionar
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
