import { Flex, useToast } from '@chakra-ui/react';
import {
  ContractResp,
  contractSendForSignature,
} from '../../../../services/ContractService';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import { FaExclamationCircle } from 'react-icons/fa';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { useState } from 'react';

export function ContractSendForSignatureModalComponent(props: {
  showModal: boolean;
  selectedContract?: ContractResp;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Atenção`}
      size="lg"
      footer={
        <Flex p={4} justifyContent={'space-between'}>
          <ButtonComponent
            margin={'0 10px 0 0'}
            variant={'outline'}
            onSubmit={() => {
              props.setShowModal();
            }}
          >
            Cancelar
          </ButtonComponent>
          <ButtonComponent
            isLoading={isLoading}
            disabled={isLoading}
            onSubmit={() => {
              setIsLoading(true);
              contractSendForSignature(props.selectedContract?.id ?? '')
                .then((res) => {
                  props.setShowModal();
                  props.reloadData?.();
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description:
                      'Contrato enviado para assinatura com sucesso!',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            Enviar
          </ButtonComponent>
        </Flex>
      }
    >
      <Flex
        mb={5}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <FaExclamationCircle size={50} />
        <TextComponent fontWeight={'bold'} fontSize={'18px'}>
          Atenção
        </TextComponent>
        <TextComponent fontWeight={'light'} fontSize={'14px'}>
          Tem certeza que quer enviar o contrato para ser assinado?
        </TextComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
