import { Flex, Grid, useToast } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useAttendaceDetails,
  postTakeResponsibility,
  putAttendanceFinish,
  postTransferResponsibility,
  invalidateAttendance,
  putAuthorizeRegistration,
  putAttendanceCancel,
} from '../../../services/Attendance';
import { PersonModalComponent } from '../../UI/organisms/PersonModalComponent/PersonModalComponent';
import {
  PhysicalPersonResp,
  usePhysicalPerson,
} from '../../../services/PhysicalPersonService';
import { useDebounce } from '../../../utils/functions/debounce';
import { usePagination } from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { ChatTalkComponent } from '../../UI/organisms/ChatTalkComponent/ChatTalkComponent';
import { ChatDetailsComponent } from '../../UI/organisms/ChatDetailsComponent/ChatDetailsComponent';
import { showToast } from '../../../utils/showToast';
import { useQueryClient } from '@tanstack/react-query';
import {
  putAttendanceViewers,
  useAttendanceAttendants,
} from '../../../services/AttendanceService';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../UI/atoms/InputComponent/InputComponent';

export function ChatTemplateComponent() {
  const params = useParams<{ id: string }>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const attendance = useAttendaceDetails(params.id);
  const attendanceDetails = attendance.data;
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [message, setMessage] = React.useState('');
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('name');
  const [transferModal, setTransferModal] = React.useState(false);
  const [showModalAddViewer, setShowModalAddViewer] = React.useState(false);
  const [showModalCancel, setShowModaCancel] = React.useState(false);
  const [viwersModal, setViewersModal] = React.useState(false);
  const Person = usePhysicalPerson(
    selectedPage,
    pageSize,
    search,
    searchField,
    'name',
    'asc',
    true
  );
  const Anttendance = useAttendanceAttendants(
    selectedPage,
    pageSize,
    search,
    searchField,
    'name',
    'asc'
  );
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  const transferViewer =
    Anttendance.data?.data.map((e: PhysicalPersonResp) => ({
      name: e.name,
      onSubmit: () => {
        postTransferResponsibility({
          id: params.id || '',
          personId: e.personId,
        }).then((res) => {
          invalidateAttendance(params.id || '', queryClient);
          showToast({
            toast,
            status: 'success',
            title: 'Sucesso',
            description: 'Chamado transferido com sucesso',
          });
        });
        setTransferModal(false);
      },
    })) || [];

  const addViewer =
    Person.data?.data.map((e: PhysicalPersonResp) => ({
      name: e.name,
      onSubmit: () => {
        putAttendanceViewers(attendance.data?.id ?? '', e.personId)
          .then((res) => {
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: 'Observador Adicionado com sucesso',
            });
          })
          .finally(() => {
            attendance.refetch();
          });
        setShowModalAddViewer(false);
      },
    })) || [];

  const viewers =
    attendanceDetails?.viewers.map((e) => ({
      name: e.personName,
      id: e.id,
    })) || [];
  function cancelAttendence() {
    putAttendanceCancel(attendance.data?.id, message)
      .then(() => {
        showToast({
          toast,
          status: 'success',
          title: 'Sucesso',
          description: 'Cancelado com sucesso',
        });
      })
      .finally(() => {
        setShowModaCancel(false);
        attendance.refetch();
      });
  }
  const validateMessage = message.length > 0;
  return (
    <Grid margin={0} templateColumns="repeat(12, 2fr)">
      <ChatTalkComponent
        attendanceId={params.id || ''}
        onSubmitHeaderButton={() => setViewersModal(true)}
        onSubmitAddViewer={() => setShowModalAddViewer(true)}
      />
      <ChatDetailsComponent
        onSubmitCancelButton={() => {
          setShowModaCancel(true);
        }}
        AttendanceId={params.id || ''}
        onSubmitTransferButton={() => {
          setTransferModal(true);
        }}
        onSubmitToAssumeButton={() =>
          postTakeResponsibility({ id: params.id || '' }).then((res) => {
            invalidateAttendance(params.id || '', queryClient);
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: 'Atendimento assumido com sucesso',
            });
          })
        }
        onSubmitConcludeButton={() =>
          putAttendanceFinish(params.id).then((res) => {
            invalidateAttendance(params.id || '', queryClient);
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: 'Atendimento concluido com sucesso',
            });
          })
        }
        onSubmitRejectRegisterButton={() =>
          putAttendanceFinish(params.id).then((res) => {
            invalidateAttendance(params.id || '', queryClient);
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description:
                'Cadastro rejeitado e atendimento concluido com sucesso',
            });
          })
        }
        onSubmitAcceptRegisterButton={() => {
          putAuthorizeRegistration(
            attendanceDetails?.content.PersonId,
            attendanceDetails?.content.Type
          ).then((res) => {
            putAttendanceFinish(params.id).then((res) => {
              invalidateAttendance(params.id || '', queryClient);
            });
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description:
                'Cadastro Aceito e atendimento concluido com sucesso',
            });
          });
        }}
      />
      <PersonModalComponent
        onChangeText={(input) => setSearch(input.target.value)}
        inputValue={searchInput}
        isOpen={transferModal}
        title={'Atendentes'}
        onClose={() => setTransferModal(false)}
        onClean={() => setSearch('')}
        onChangeSelect={(item) => {
          setSearchField(item.target.value);
        }}
        options={[
          { id: 'name', name: 'Nome' },
          { id: 'email', name: 'Email' },
          { id: 'phone', name: 'Telefone' },
          { id: 'document', name: 'Documento' },
        ]}
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={Anttendance.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
        items={transferViewer}
        isLoading={Anttendance.isLoading}
      />
      <PersonModalComponent
        title="Observadores"
        onClose={() => {
          attendance.refetch();
          setViewersModal(false);
        }}
        isOpen={viwersModal}
        items={viewers}
        isLoading={attendance.isLoading}
      />
      <PersonModalComponent
        onChangeText={(input) => setSearch(input.target.value)}
        inputValue={searchInput}
        isOpen={showModalAddViewer}
        title={'Adicionar observador'}
        onClose={() => {
          setShowModalAddViewer(false);
        }}
        onClean={() => setSearch('')}
        onChangeSelect={(item) => {
          setSearchField(item.target.value);
        }}
        options={[
          { id: 'name', name: 'Nome' },
          { id: 'email', name: 'Email' },
          { id: 'phone', name: 'Telefone' },
          { id: 'document', name: 'Documento' },
        ]}
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={Person.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
        items={addViewer}
        isLoading={Person.isLoading}
      />
      <ModalStructureComponent
        isOpen={showModalCancel}
        onClose={() => {
          setMessage('');
          setShowModaCancel(false);
        }}
        title="Deseja mesmo cancelar ?"
        description="Digite o motivo do cancelamento"
        children={
          <>
            <InputComponent
              type="textArea"
              label="Motivo"
              placeholder="Digite o motivo"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              error={!validateMessage ? 'Campo obrigatório' : undefined}
            />
          </>
        }
        footer={
          <Flex m={4} justifyContent={'flex-end'}>
            <ButtonComponent
              variant={'outline'}
              margin={'0 10px 0 0'}
              onSubmit={() => {
                setShowModaCancel(false);
                setMessage('');
              }}
            >
              Fechar
            </ButtonComponent>
            <ButtonComponent
              disabled={!validateMessage}
              margin={'0 10px 0 0'}
              onSubmit={() => cancelAttendence()}
            >
              Cancelar
            </ButtonComponent>
          </Flex>
        }
        size={'2xl'}
      />
    </Grid>
  );
}
