import { Flex, useToast } from '@chakra-ui/react';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import {
  ContractChangeRequestResp,
  putContractChangeRequest,
  useContractChangeRequestAll,
  useContractChangeRequestContractId,
  useContractChangeRequestTotalizer,
} from '../../../services/ContractChangeRequestService';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import React, { useState } from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { FaCheck, FaInfo, FaTimes } from 'react-icons/fa';
import { formatDate } from '../../../utils/functions/formatDate';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { ContractChangeRequestValueModalComponent } from '../../UI/molecules/ContractChangeRequestValueModalComponent copy/ContractChangeRequestValueModalComponent';
import { AcceptMessageModalComponent } from '../../UI/organisms/AcceptMessageModalComponent/AcceptMessageModalComponent';
import { showToast } from '../../../utils/showToast';
import { useParams } from 'react-router-dom';
import { Totalizer } from '../../UI/molecules/Totalizer/Totalizer';
import { notInfo } from '../../../pages/MyProfilePage';
import { StatusColorByTypeContract } from '../ContractTemplateComponent/ContractTemplateComponent';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import SearchSelectComponent from '../../UI/molecules/SearchSelectComponent/SearchSelectComponent';
import { useDebounce } from '../../../utils/functions/debounce';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';

export default function ContractChangeRequestTemplateComponent() {
  const toast = useToast();
  const params = useParams<{ id: string }>();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [filterWasAproved, setFilterWasAproved] = useState<boolean | null>(
    null
  );
  const [filterWasAnswered, setFilterWasAnswered] = useState<boolean | null>(
    null
  );
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('title');
  const [approve, setApprove] = React.useState(false);
  const [selectedChange, setSelectedChange] =
    React.useState<ContractChangeRequestResp>();
  const [showModalApprove, setShowModalApprove] = React.useState(false);
  const [showModalValue, setShowModalValue] = React.useState(false);
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const listContractChangeRequestContractId =
    useContractChangeRequestContractId(
      params.id ?? '',
      selectedPage,
      pageSize,
      search,
      searchField,
      'createAt',
      'desc',
      filterWasAproved,
      filterWasAnswered
    );
  const listContractChangeRequestAll = useContractChangeRequestAll(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createAt',
    'desc',
    filterWasAproved,
    filterWasAnswered
  );
  const contractChangeRequestTotalizer = useContractChangeRequestTotalizer(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createAt',
    'desc',
    filterWasAproved,
    filterWasAnswered
  ).data?.data;
  const renderList = params.id
    ? listContractChangeRequestContractId
    : listContractChangeRequestAll;
  const tabName = [
    {
      title: 'Todos',
      onClick: () => {
        setFilterWasAproved(null);
        setFilterWasAnswered(null);
      },
    },
    {
      title: 'Aprovados',
      onClick: () => {
        setFilterWasAproved(true);
        setFilterWasAnswered(null);
      },
    },
    {
      title: 'Reprovados',
      onClick: () => {
        setFilterWasAproved(false);
        setFilterWasAnswered(null);
      },
    },
    {
      title: 'Não respondidos',
      onClick: () => {
        setFilterWasAproved(null);
        setFilterWasAnswered(false);
      },
    },
  ];
  return (
    <>
      <Flex mb={4}>
        <HeaderTextComponent goBack>
          Pedidos de alterações dos contratos
        </HeaderTextComponent>
      </Flex>
      <Flex mb={2} justifyContent={'space-between'}>
        <TabsFilterComponent tabList={tabName} />
        <SearchSelectComponent
          onChangeText={(input) => setSearch(input.target.value)}
          inputValue={searchInput}
          onClean={() => setSearch('')}
          onChange={(item) => {
            setSearch('');
            setSearchField(item.target.value);
          }}
          options={[
            { id: '', name: 'Selecionar' },
            { id: 'SerialNumber', name: 'Número do contrato' },
            { id: 'RequestedByName', name: 'Pedido por' },
            { id: 'AnsweredByName', name: 'Respondido por' },
          ]}
        />
      </Flex>
      <TableComponent
        ItemsHeader={[
          { item: 'Número do contrato' },
          { item: 'Locador' },
          { item: 'Status' },
          { item: 'Pedido por' },
          { item: 'Respondido por' },
          { item: 'Foi aprovado' },
          { item: 'Criado em' },
          { item: 'Ações' },
        ]}
        isLoading={renderList.isLoading}
        centered={true}
        emptyState={renderList.data?.data.length === 0}
        data={
          renderList.data?.data.map((e, i) => ({
            items: [
              e.contractSerialNumber,
              <AvatarLabelComponent label={e.locators[0]} />,
              <StatusColorByTypeContract type={e.contractStatus} />,
              notInfo(e.requestedBy?.name),
              notInfo(e.answeredBy?.name, 'Ainda não respondido'),
              e.wasAproved ? 'sim' : 'não',
              formatDate(e.createdAt),

              <>
                <IconButtonComponent
                  icon={<FaInfo />}
                  ariaLabel="Valores"
                  onSubmit={() => {
                    setSelectedChange(e);
                    setShowModalValue(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaCheck />}
                  marginX={'8px'}
                  ariaLabel="Permitir"
                  colorScheme={'green'}
                  onSubmit={() => {
                    setApprove(true);
                    setSelectedChange(e);
                    setShowModalApprove(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTimes />}
                  ariaLabel="Não permitir"
                  colorScheme={'red'}
                  onSubmit={() => {
                    setApprove(false);
                    setSelectedChange(e);
                    setShowModalApprove(true);
                  }}
                />
              </>,
            ],
          })) ?? []
        }
      />
      <Totalizer
        currentPage={contractChangeRequestTotalizer?.currentPageValue ?? 0}
        total={contractChangeRequestTotalizer?.curretFilterValue ?? 0}
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={renderList.data?.metadata.dataSize ?? 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <AcceptMessageModalComponent
        approve={approve}
        showModal={showModalApprove}
        setShowModal={() => setShowModalApprove(false)}
        onAcceptModal={(message) => {
          putContractChangeRequest({
            id: selectedChange?.id || '',
            message: message,
            approve: approve,
          }).then((res) => {
            setShowModalApprove(false);
            renderList.refetch();
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: `Mudança ${
                approve ? 'Aprovada' : 'Reprovada'
              } com sucesso`,
            });
          });
        }}
      />
      <ContractChangeRequestValueModalComponent
        selectedContractChangeRequest={selectedChange}
        showModal={showModalValue}
        setShowModal={() => setShowModalValue(false)}
      />
    </>
  );
}
