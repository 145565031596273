import { Flex } from '@chakra-ui/react';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';

export type ConfirmModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function ConfirmModalComponent(props: ConfirmModalProps) {
  return (
    <ModalStructureComponent
      title={props.title}
      size={'sm'}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered={true}
    >
      <Flex display={'flex'} justifyContent={'space-evenly'}>
        <ButtonComponent
          onSubmit={() => {
            props.onConfirm();
            props.onClose();
          }}
          margin={'auto 0 10px 0'}
        >
          Confirmar
        </ButtonComponent>
        <ButtonComponent
          variant={'outline'}
          onSubmit={props.onClose}
          margin={'auto 0 10px 0'}
        >
          Cancelar
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
