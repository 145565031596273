import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Flex,
} from '@chakra-ui/react';
import { FaRegBell } from 'react-icons/fa';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@chakra-ui/react';
import EmptyStateComponent from '../../atoms/EmptyState/EmptyStateComponente';

export type NotificationMenuProps = {
  items?: {
    name: string;
    description: string;
    type: 'attendance' | 'automated-routines' | 'batch-changes' | 'payments';
    action?: () => void;
    id?: string;
  }[];
};
export default function NotificationMenuComponent(
  props: NotificationMenuProps
) {
  const navigate = useNavigate();

  function navigateTo(type: string, id?: string) {
    if (type === 'attendance') {
      navigate('/called');
    } else if (type === 'automated-routines') {
      navigate('/contract-routines');
    } else if (type === 'batch-changes') {
      navigate('/batch-changes');
    } else if (type === 'payments') {
      navigate(`/detached-payment/${id}`);
    }
  }
  function nameType(type: string) {
    if (type === 'attendance') {
      return 'Atendimento';
    } else if (type === 'automated-routines') {
      return 'Rotinas Automatizadas';
    } else if (type === 'batch-changes') {
      return 'Alterações em Lote';
    } else if (type === 'payments') {
      return 'Pagamento';
    }
  }
  return (
    <Menu closeOnSelect={true}>
      <Tooltip label={'Notificações'}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FaRegBell />}
          variant="guost"
        />
      </Tooltip>
      <MenuList w={'400px'} p={'10px'} maxH={'500px'} overflow={'auto'}>
        <Box p={'6px 12px'}>
          <TextComponent fontSize={'20px'} fontWeight={'700'}>
            Notificações
          </TextComponent>
        </Box>
        {props.items?.length === 0 && (
          <Flex>
            <EmptyStateComponent title="Não possui notificações" />
          </Flex>
        )}

        {props.items?.map((item, i) => (
          <MenuItem
            key={i}
            mt={'10px'}
            onClick={() => {
              navigateTo(item.type, item.id);
            }}
          >
            <Stack>
              <TextComponent
                fontSize={'14px'}
                color={'brand.500'}
                fontWeight={'700'}
              >
                {item.name}
              </TextComponent>
              <TextComponent fontSize={'14px'}>
                {item.description}
              </TextComponent>
              <TextComponent fontSize={'12px'} color={'brand.500'}>
                {nameType(item.type)}
              </TextComponent>
            </Stack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
