import {
  Flex,
  Grid,
  GridItem,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useBankAgencyList } from '../../../../services/BankAgencyService';
import {
  LocatorJuridicalPerson,
  LocatorPersonType,
  LocatorPhysicalPerson,
  saveLocator,
  useJuridicalLocator,
  usePhysicalLocator,
} from '../../../../services/LocatorService';
import {
  profileRemove,
  usePermissionGroup,
} from '../../../../services/ProfileService';
import useStateList, {
  useAddressCep,
  useCityList,
  useCountryList,
} from '../../../../services/StateServices';
import {
  cepMask,
  cnpjMask,
  cpfMask,
  phoneMask,
  stateRegistrationMask,
  cleanNumberMask,
} from '../../../../utils/functions/masks';
import {
  capitalizeFirstLetter,
  getFirstIfAny,
  nacionalityList,
  parseDate,
} from '../../../../utils/functions/utility';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import RadioGroupComponent from '../../atoms/RadioGroupComponent/RadioGroupComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import DropzoneModel from '../uploadModel/DropZoneModel';
import { useUserData } from '../../../../services/LoginService';
import { useDebounce } from '../../../../utils/functions/debounce';
import InputAutoCompleteComponent from '../../atoms/InputAutoCompleteComponent/InputAutoCompleteComponent';
import { JuridicalPerson } from '../../../../services/JuridicalPersonService';
import {
  deletePhysicalPerson,
  PersonFunction,
  PhysicalPerson,
  SavePersonResponse,
  usePhysicalPersonSpouseSelect,
} from '../../../../services/PhysicalPersonService';

type MandatoryFields =
  | 'firstName'
  | 'lastName'
  | 'nameCompany'
  | 'document'
  | 'gender'
  | 'phoneNumber'
  | 'date'
  // TODO: Change to permission group
  | 'profile'
  | 'email'
  | 'function'
  | 'cnpj'
  | 'companyPhoneNumber'
  | 'stateRegistration';

function fieldValidation(
  type: LocatorPersonType,
  physicalForm: LocatorPhysicalPerson,
  juridicalForm: LocatorJuridicalPerson,
  func: PersonFunction
): Record<MandatoryFields, string> {
  const noUserFunc = !![
    PersonFunction.Intermediary,
    PersonFunction.Receiver,
    PersonFunction.Locator,
  ].find((f) => f === func);
  if (type === LocatorPersonType.Physical) {
    return {
      stateRegistration: '',
      document: physicalForm.document ? '' : 'CPF é obrigatório',
      firstName: physicalForm.firstName ? '' : 'Nome é obrigatório',
      gender: physicalForm.gender === undefined ? 'Gênero é obrigatório' : '',
      lastName: physicalForm.lastName ? '' : 'Sobrenome é obrigatório',
      phoneNumber: physicalForm.phones[0]?.number
        ? ''
        : 'Telefone é obrigatório',
      profile:
        noUserFunc || physicalForm.user?.profileId
          ? ''
          : 'Perfil é obrigatório',
      email:
        noUserFunc || physicalForm.user?.email ? '' : 'Email é obrigatório',
      nameCompany: '',
      date: physicalForm.date ? '' : 'Data de nascimento é obrigatório',
      function:
        physicalForm.function !== undefined ? '' : 'Função é obrigatório',
      cnpj: '',
      companyPhoneNumber: '',
    };
  }

  return {
    firstName: physicalForm?.firstName ? '' : 'Nome é obrigatório',
    document: physicalForm.document ? '' : 'CPF é obrigatório',
    nameCompany: juridicalForm.name ? '' : 'Nome é obrigatório',
    cnpj: juridicalForm.document ? '' : 'CNPJ é obrigatório',
    phoneNumber: physicalForm?.phones[0]?.number
      ? ''
      : 'Telefone é obrigatório',
    companyPhoneNumber: juridicalForm.phones[0]?.number
      ? ''
      : 'Telefone é obrigatório',
    profile:
      noUserFunc || physicalForm?.user?.profileId ? '' : 'Perfil é obrigatório',
    email: noUserFunc || physicalForm?.user?.email ? '' : 'Email é obrigatório',
    gender: physicalForm?.gender === undefined ? 'Gênero é obrigatório' : '',
    lastName: physicalForm?.lastName ? '' : 'Sobrenome é obrigatório',
    date: physicalForm.date ? '' : 'Data de nasimento é obrigatório',
    function: physicalForm.function !== undefined ? '' : 'Função é obrigatório',
    stateRegistration: juridicalForm.stateRegistration
      ? ''
      : 'Inscrição estadual é obrigatório',
  };
}

const defaultPhysicalForm: LocatorPhysicalPerson = {
  companyName: '',
  companyPosition: '',
  document: '',
  name: '',
  displayName: '',
  profilePicUrl: '',
  date: '',
  emails: [],
  nationality: 'Brasileira',
  phones: [
    {
      number: '',
      type: 3,
      typeDescription: 'mobile',
    },
  ],
  socialMedias: [],
  addresses: [
    {
      owner: '',
      ownerId: '',
      street: '',
      district: '',
      complement: '',
      zipcode: '',
      type: 1,
      cityId: '',
      coordenateId: '',
      number: '',
    },
  ],
  accounts: [
    {
      agency: '',
      account: '',
      corporateName: '',
      corporateDocument: '',
      bankAgencyId: '',
      name: '',
    },
  ],
  firstName: '',
  lastName: '',
  gender: undefined as any as number,
  detail: '',
  companyId: '',
  employee: null,
  user: {
    username: '',
    email: '',
    profileId: '',
    permissions: [],
    customPermissions: false,
  },
  function: undefined as any as PersonFunction,
  rg: '',
  spouseId: '',
  spouce: {
    name: '',
    id: '',
  },
};

const defaultJuridicalForm: LocatorJuridicalPerson = {
  companyName: '',
  companyPosition: '',
  document: '',
  name: '',
  displayName: '',
  profilePicUrl: '',
  date: '',
  emails: [],
  phones: [
    {
      number: '',
      type: 0,
      typeDescription: '',
    },
  ],
  socialMedias: [],
  addresses: [],
  accounts: [],
  stateRegistration: '',
  establishmentFormat: '',
  physicalPerson: null,
};

export default function CreatePersonModalComponent(props: {
  showModal: boolean;
  selectedPerson?: {
    physicalOrJuridicalPersonId?: string;
    type?: LocatorPersonType;
  };
  setShowModal: () => void;
  reloadData?: () => void;
  onSuccess?: (response: SavePersonResponse) => void;
  function?: PersonFunction;
  setFunction?: () => void;
}) {
  const toast = useToast();
  const user = useUserData();
  const spouseList = usePhysicalPersonSpouseSelect();
  const [loading, setLoading] = React.useState(false);
  const [profileUrlPhysical, setProfileUrlPhysical] = React.useState('');
  const [profileUrlJuridical, setProfileUrlJuridical] = React.useState('');
  const colSpan = useBreakpointValue({ base: 12, sm: 3 });
  const listProfiles = usePermissionGroup();
  const listBankAgencies = useBankAgencyList();
  const [radioPersonValue, setRadioPersonValue] =
    React.useState<LocatorPersonType>(LocatorPersonType.Physical);

  const [physicalForm, setPhysicalForm] =
    React.useState<LocatorPhysicalPerson>(defaultPhysicalForm);
  const [juridicalForm, setJuridicalForm] =
    React.useState<LocatorJuridicalPerson>(defaultJuridicalForm);
  const { data: countries } = useCountryList();
  const { data: states } = useStateList(
    getFirstIfAny(physicalForm.addresses)?.countryId || ''
  );
  const { data: cities } = useCityList(
    getFirstIfAny(physicalForm.addresses)?.stateId || ''
  );

  const person = props.selectedPerson?.type ?? radioPersonValue;

  const physicalPerson = usePhysicalLocator(
    props.selectedPerson?.physicalOrJuridicalPersonId || '',
    person === LocatorPersonType.Physical
  );

  const juridicalPerson = useJuridicalLocator(
    props.selectedPerson?.physicalOrJuridicalPersonId || '',
    person === LocatorPersonType.Juridical
  );
  const [, searchCep, setSearchCep] = useDebounce('');
  const { data: dataCep } = useAddressCep(searchCep);
  React.useEffect(() => {
    if (dataCep) {
      setPhysicalForm((physical) => ({
        ...physical,
        addresses: [
          {
            ...physical.addresses[0],
            street: dataCep.street,
            district: dataCep.district,
            countryId: dataCep.countryId,
            stateId: dataCep.stateId,
            cityId: dataCep.cityId,
          },
        ],
      }));
    }
  }, [dataCep]);

  React.useEffect(() => {
    if (props.function !== undefined) {
      setPhysicalForm({
        ...physicalForm,
        function: props.function,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.function]);

  React.useEffect(() => {
    if (listProfiles.data && physicalForm.function === PersonFunction.Locator) {
      const locatorProfile = getLocatorProfile();
      if (
        locatorProfile &&
        physicalForm.user?.profileId !== locatorProfile.id
      ) {
        setPhysicalForm({
          ...physicalForm,
          user: {
            ...physicalForm.user,
            profileId: locatorProfile.id,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProfiles.data, physicalForm]);
  React.useEffect(() => {
    if (props.selectedPerson) {
      if (
        props.selectedPerson?.type === LocatorPersonType.Juridical &&
        juridicalPerson.data
      ) {
        setJuridicalForm({
          ...juridicalPerson.data,
          date: parseDate(juridicalPerson.data.date),
          phones: [
            {
              ...juridicalPerson.data.phones[0],
              number: phoneMask(juridicalPerson.data.phones[0]?.number),
            },
          ],
          document: cnpjMask(juridicalPerson.data.document),
          accounts: juridicalPerson.data?.bankAccounts || [
            ...defaultJuridicalForm.accounts,
          ],
        });
        setProfileUrlJuridical(juridicalPerson.data.profilePicUrl);
        if (juridicalPerson.data.physicalPerson) {
          const person = juridicalPerson.data.physicalPerson;
          updatePhysicalForm(person);
        }
      }
      if (
        props.selectedPerson.type === LocatorPersonType.Physical &&
        physicalPerson.data
      ) {
        updatePhysicalForm(physicalPerson.data);
        setProfileUrlPhysical(physicalPerson.data.profilePicUrl);
        if (
          listBankAgencies.data &&
          physicalPerson.data?.bankAccounts?.[0]?.bankAgencyId
        ) {
          updatePhysicalForm(physicalPerson.data);
        }
      }
    } else {
      updatePhysicalForm({ ...defaultPhysicalForm });
      setJuridicalForm({ ...defaultJuridicalForm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedPerson?.physicalOrJuridicalPersonId,
    props.selectedPerson?.type,
    physicalPerson.data,
    juridicalPerson.data,
    props.showModal,
  ]);

  const getLocatorProfile = React.useCallback(() => {
    if (
      !listProfiles.data ||
      (physicalForm.function !== PersonFunction.Locator &&
        physicalForm.function !== PersonFunction.Intermediary &&
        physicalForm.function !== PersonFunction.Receiver)
    ) {
      return undefined;
    }
    return listProfiles.data.data.find((p) => p.name === 'Aplicativo');
  }, [listProfiles.data, physicalForm]);

  function updatePhysicalForm(person: LocatorPhysicalPerson) {
    setPhysicalForm({
      ...person,
      spouseId: person?.spouce?.id ?? '',
      companyName: person.companyName,
      document: cpfMask(person.document),
      companyPosition: person.companyPosition,
      function: props.function ?? person.function,
      date: parseDate(person.date),
      accounts: person.bankAccounts?.length
        ? person.bankAccounts
        : [...defaultPhysicalForm.accounts],
      user: {
        ...person.user,
        profileId:
          getLocatorProfile()?.id ?? (getFirstIfAny(person.profiles)?.id || ''),
      },
      phones: person.phones?.length
        ? [
            {
              ...person.phones[0],
              number: phoneMask(person.phones[0]?.number),
            },
          ]
        : [
            {
              number: '',
              type: 3,
              typeDescription: 'mobile',
            },
          ],
      bankAccounts: person.bankAccounts?.length
        ? person.bankAccounts
        : [
            {
              agency: '',
              account: '',
              corporateName: '',
              corporateDocument: '',
              bankAgencyId: '',
            },
          ],
      addresses: person.addresses?.length
        ? [
            {
              ...person.addresses[0],
              zipcode: cepMask(person.addresses[0]?.zipcode),
            },
          ]
        : [...defaultPhysicalForm.addresses],
    });
    setProfileUrlPhysical(person.profilePicUrl);
  }

  function getSaveObjectPhysical(): PhysicalPerson {
    const phone = getFirstIfAny(physicalForm.phones)?.number
      ? getFirstIfAny(physicalForm.phones)
      : null;
    const address = getFirstIfAny(physicalForm.addresses)?.zipcode
      ? getFirstIfAny(physicalForm.addresses)
      : null;
    const account = getFirstIfAny(physicalForm.accounts)?.account
      ? getFirstIfAny(physicalForm.accounts)
      : null;
    const userToUpdate = physicalForm.user?.email
      ? {
          ...(physicalForm.user || defaultPhysicalForm.user),
          username: physicalForm.user?.email || '',
          profileId: physicalForm.user?.profileId,
          entities: [user?.entity?.id || ''],
        }
      : undefined;

    return {
      ...physicalForm,
      employee: null,
      companyName: physicalForm.companyName,
      companyPosition: physicalForm.companyPosition,
      name: `${physicalForm.firstName} ${physicalForm.lastName}`,
      document: cleanNumberMask(physicalForm.document),
      profilePicUrl: profileUrlPhysical,
      phones: phone
        ? [
            {
              ...(getFirstIfAny(physicalForm.phones) ||
                defaultPhysicalForm.phones[0]),
              number: cleanNumberMask(
                getFirstIfAny(physicalForm.phones)?.number
              ),
            },
          ]
        : [],
      addresses: address
        ? [
            {
              ...(getFirstIfAny(physicalForm.addresses) ||
                defaultPhysicalForm.addresses[0]),
              zipcode: cleanNumberMask(
                getFirstIfAny(physicalForm.addresses)?.zipcode
              ),
            },
          ]
        : [],
      accounts: account
        ? [
            {
              ...(account || defaultPhysicalForm.accounts[0]),
            },
          ]
        : [],
      user: userToUpdate,
    };
  }

  function getSaveObjectJuridical(): JuridicalPerson {
    const phone = getFirstIfAny(juridicalForm.phones)?.number
      ? getFirstIfAny(juridicalForm.phones)
      : null;
    const address = getFirstIfAny(juridicalForm.addresses)?.zipcode
      ? getFirstIfAny(juridicalForm.addresses)
      : null;
    const account = getFirstIfAny(juridicalForm.accounts)?.account
      ? getFirstIfAny(juridicalForm.accounts)
      : null;
    const physicalPhone = getFirstIfAny(physicalForm.phones)?.number
      ? getFirstIfAny(physicalForm.phones)
      : null;
    const userToUpdate = physicalForm.user?.email
      ? {
          ...(physicalForm.user || defaultPhysicalForm.user),
          username: physicalForm.user?.email || '',
          profileId: physicalForm.user?.profileId,
          entities: [user?.entity?.id || ''],
        }
      : undefined;
    return {
      ...juridicalForm,
      companyName: juridicalForm.companyName,
      companyPosition: juridicalForm.companyPosition,
      document: cleanNumberMask(juridicalForm.document),
      function: physicalForm.function,
      profilePicUrl: profileUrlJuridical,
      stateRegistration: cleanNumberMask(juridicalForm.stateRegistration),
      phones: phone
        ? [
            {
              ...(phone || defaultJuridicalForm.phones[0]),
              number: cleanNumberMask(phone.number),
            },
          ]
        : [],
      addresses: address
        ? [
            {
              ...(address || defaultPhysicalForm.addresses[0]),
              zipcode: cleanNumberMask(address?.zipcode),
            },
          ]
        : [],
      accounts: account
        ? [
            {
              ...(account || defaultPhysicalForm.accounts[0]),
            },
          ]
        : [],
      physicalPerson: {
        ...physicalForm,
        employee: null,
        name: `${physicalForm.firstName} ${physicalForm.lastName}`,
        function: physicalForm.function,
        companyName: physicalForm.companyName,
        companyPosition: physicalForm.companyPosition,
        document: cleanNumberMask(physicalForm.document),
        profilePicUrl: profileUrlPhysical,
        phones: physicalPhone
          ? [
              {
                ...(physicalPhone || defaultPhysicalForm.phones[0]),
                number: cleanNumberMask(physicalPhone?.number),
              },
            ]
          : [],
        user: userToUpdate,
        accounts: [],
      },
    };
  }

  const validation = fieldValidation(
    person,
    physicalForm,
    juridicalForm,
    physicalForm.function
  );

  const hasValidationError = Object.values(validation).some(
    (message) => message
  );

  const optionSpouseList = spouseList.data?.map((e) => ({
    id: e.id,
    name: e.firstName,
  }));
  function newListSpouseOptions() {
    if (!physicalForm.spouce) {
      return optionSpouseList;
    } else {
      return optionSpouseList?.concat(physicalForm.spouce);
    }
  }

  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={
        props.selectedPerson
          ? () => {
              setPhysicalForm(defaultPhysicalForm);
              props.setShowModal();
            }
          : () => {
              setRadioPersonValue(LocatorPersonType.Physical);
              setProfileUrlJuridical('');
              setProfileUrlPhysical('');
              props.function
                ? setPhysicalForm({
                    ...defaultPhysicalForm,
                    user: { email: '' },
                    function: props.function || PersonFunction.common,
                  })
                : setPhysicalForm({
                    ...defaultPhysicalForm,
                    user: { email: '' },
                  });
              setJuridicalForm(defaultJuridicalForm);
              props.setShowModal();
            }
      }
      title={props.selectedPerson ? 'Editar Cadastro' : 'Novo Cadastro'}
      size="6xl"
      maxH="80vh"
      footer={
        <Flex mb={6} mr={6} justifyContent={'end'}>
          <ButtonComponent
            variant={'outline'}
            margin={'0px 10px 0px 0px'}
            onSubmit={
              props.selectedPerson
                ? () => {
                    setPhysicalForm(defaultPhysicalForm);
                    props.setShowModal();
                  }
                : () => {
                    props.function
                      ? setPhysicalForm({
                          ...defaultPhysicalForm,
                          function: props.function,
                        })
                      : setPhysicalForm(defaultPhysicalForm);
                    setJuridicalForm(defaultJuridicalForm);
                    setRadioPersonValue(LocatorPersonType.Physical);
                    props.setShowModal();
                  }
            }
          >
            Cancelar
          </ButtonComponent>
          
          {person === LocatorPersonType.Physical && physicalForm.id && (
            <ButtonComponent
              variant={'solid'}
              colorScheme={'red'}
              disabled={!physicalForm.user?.id || loading}
              margin={'0px 10px 0px 0px'}
              isLoading={loading}
              onSubmit={() => {
                setLoading(true);
                deletePhysicalPerson(physicalForm.id as string)
                .then(() => {
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Inativado com sucesso',
                  });
                  props.reloadData?.();
                })
                .catch(() => {
                  showToast({
                    toast,
                    status: 'error',
                    title: 'Erro',
                    description: 'Erro ao inativar',
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
              }}
              >
                Inativar
              </ButtonComponent>
          )}
          <ButtonComponent
            disabled={hasValidationError || loading}
            isLoading={loading}
            onSubmit={() => {
              if (hasValidationError) {
                return;
              }
              setLoading(true);
              saveLocator(
                getSaveObjectJuridical(),
                getSaveObjectPhysical(),
                person || LocatorPersonType.Physical
              )
                .then((res) => {
                  setLoading(false);
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Salvo com sucesso',
                  });
                  if (
                    props.selectedPerson?.physicalOrJuridicalPersonId &&
                    getFirstIfAny(physicalForm.profiles)?.id !==
                      physicalForm.user?.profileId
                  ) {
                    const profile = getFirstIfAny(physicalForm.profiles);
                    if (profile?.id) {
                      profileRemove({
                        profileId: profile.id,
                        userId: physicalForm.user?.id || '',
                        entityId: user?.entity?.id || '',
                      });
                    }
                  }
                  setRadioPersonValue(LocatorPersonType.Physical);
                  props.reloadData?.();
                  props.onSuccess?.(res.data.content.data);
                  props.setShowModal();
                  props.function
                    ? setPhysicalForm({
                        ...defaultPhysicalForm,
                        function: props.function,
                      })
                    : setPhysicalForm(defaultPhysicalForm);
                  setJuridicalForm(defaultJuridicalForm);
                })
                .finally(() => setLoading(false));
            }}
          >
            {'Salvar'}
          </ButtonComponent>
        </Flex>
      }
    >
      {!props.selectedPerson && (
        <RadioGroupComponent
          onChange={(text) => setRadioPersonValue(Number(text))}
          value={String(radioPersonValue)}
          margin={'26px 0 26px 0'}
          options={[
            { id: String(LocatorPersonType.Physical), name: 'Pessoa Física' },
            {
              id: String(LocatorPersonType.Juridical),
              name: 'Pessoa Jurídica',
            },
          ]}
          direction={'row'}
        />
      )}
      {person === LocatorPersonType.Juridical && (
        <>
          <HeaderTextComponent mb={4} subTitle>
            Informações de pessoa jurídica
          </HeaderTextComponent>
          <Flex mb={14}>
            <Flex w={'80%'} flexDir={'column'}>
              <Flex>
                <InputComponent
                  margin={'0 20px 20px 0'}
                  value={juridicalForm.name}
                  onChange={(input) =>
                    setJuridicalForm({
                      ...juridicalForm,
                      name: input.target.value,
                    })
                  }
                  label="Razão social"
                  placeholder="Digite a razão social"
                  error={validation.nameCompany}
                />
                <InputComponent
                  margin={'0 20px 20px 0'}
                  value={juridicalForm.stateRegistration}
                  onChange={(input) =>
                    setJuridicalForm({
                      ...juridicalForm,
                      stateRegistration: stateRegistrationMask(
                        input.target.value
                      ),
                    })
                  }
                  label="Inscrição Estadual"
                  placeholder="000.000.000.000"
                  error={validation.stateRegistration}
                />
                <InputComponent
                  margin={'0 20px 20px 0'}
                  value={juridicalForm.document}
                  onChange={(input) => {
                    setJuridicalForm({
                      ...juridicalForm,
                      document: cnpjMask(input.target.value),
                    });
                    setPhysicalForm({
                      ...physicalForm,
                      accounts: [
                        {
                          ...physicalForm.accounts[0],
                          corporateDocument: cnpjMask(input.target.value),
                        },
                      ],
                    });
                  }}
                  label="CNPJ"
                  placeholder="00.000.000/0001-00"
                  error={validation.cnpj}
                />
              </Flex>
              <Flex>
                <InputComponent
                  margin={'0 20px 20px 0'}
                  value={juridicalForm.displayName}
                  onChange={(input) =>
                    setJuridicalForm({
                      ...juridicalForm,
                      displayName: input.target.value,
                    })
                  }
                  label="Nome Fantasia"
                  placeholder="Digite o nome fantasia"
                />
                <InputComponent
                  margin={'0 20px 20px 0'}
                  value={juridicalForm.date}
                  onChange={(input) =>
                    setJuridicalForm({
                      ...juridicalForm,
                      date: input.target.value,
                    })
                  }
                  type="date"
                  label="Data de fundação"
                  placeholder="00/00/00000"
                />
                <InputComponent
                  margin={'0 20px 20px 0'}
                  value={getFirstIfAny(juridicalForm.phones)?.number}
                  onChange={(input) =>
                    setJuridicalForm({
                      ...juridicalForm,
                      phones: [
                        {
                          ...(getFirstIfAny(juridicalForm.phones) ||
                            defaultJuridicalForm.phones[0]),
                          number: phoneMask(input.target.value),
                        },
                      ],
                    })
                  }
                  label="Telefone"
                  placeholder="(XX) 9 XXXX-XXXX"
                  error={validation.companyPhoneNumber}
                />
              </Flex>
            </Flex>
            <Flex justifyContent={'center'} w={'20%'}>
              <DropzoneModel
                fileUrl={profileUrlJuridical}
                setProfilePicUrl={setProfileUrlJuridical}
                type="profile"
              />
            </Flex>
          </Flex>
        </>
      )}
      <HeaderTextComponent mb={2} subTitle>
        Informações de pessoa física
      </HeaderTextComponent>
      <Flex mb={14} justifyContent={'space-between'}>
        <Flex w={'80%'} flexDir={'column'}>
          <Flex>
            <InputComponent
              margin={'0 20px 20px 0'}
              value={physicalForm.firstName}
              onChange={(input) =>
                setPhysicalForm({
                  ...physicalForm,
                  firstName: input.target.value,
                })
              }
              label="Nome"
              placeholder="Digite o nome"
              error={validation.firstName}
            />
            <InputComponent
              margin={'0 20px 20px 0'}
              value={physicalForm.lastName}
              onChange={(input) =>
                setPhysicalForm({
                  ...physicalForm,
                  lastName: input.target.value,
                })
              }
              label="Sobrenome"
              placeholder="Digite o sobrenome"
              error={validation.lastName}
            />
            <InputComponent
              margin={'0 20px 20px 0'}
              value={physicalForm.document}
              onChange={(input) =>
                setPhysicalForm({
                  ...physicalForm,
                  document: cpfMask(input.target.value),
                  accounts: [
                    {
                      ...physicalForm.accounts[0],
                      corporateDocument:
                        person === LocatorPersonType.Juridical
                          ? physicalForm.accounts[0].corporateDocument
                          : cpfMask(input.target.value),
                    },
                  ],
                })
              }
              label="CPF"
              placeholder="000.000.000-00"
              error={validation.document}
            />
          </Flex>
          <Flex>
            <InputComponent
              margin={'0 20px 20px 0'}
              value={physicalForm.displayName}
              onChange={(input) =>
                setPhysicalForm({
                  ...physicalForm,
                  displayName: input.target.value,
                })
              }
              label="Apelido"
              placeholder="Digite o apelido"
              error={validation.firstName}
            />
            <InputSelectComponent
              m={'0 20px 20px 0'}
              defaultValue={String(physicalForm.gender)}
              options={[
                { id: '0', name: 'Outro/Prefiro não informar' },
                { id: '1', name: 'Masculino' },
                { id: '2', name: 'Feminino' },
              ]}
              onChange={(input) =>
                setPhysicalForm({
                  ...physicalForm,
                  gender: Number(input.target.value),
                })
              }
              label="Gênero"
              placeholder="Selecione o seu gênero"
              error={validation.gender}
            />
            <InputComponent
              margin={'0 20px 20px 0'}
              value={physicalForm.phones[0]?.number ?? ''}
              onChange={(input) =>
                setPhysicalForm({
                  ...physicalForm,
                  phones: [
                    {
                      ...physicalForm.phones[0],
                      number: phoneMask(input.target.value),
                    },
                  ],
                })
              }
              label="Celular"
              placeholder="(XX) 9 XXXX-XXXX"
              error={validation.phoneNumber}
            />
          </Flex>
          <Flex>
            <InputComponent
              margin={'0 20px  20px  0'}
              value={physicalForm.date}
              onChange={(input) =>
                setPhysicalForm({ ...physicalForm, date: input.target.value })
              }
              type="date"
              label="Data de Nascimento"
              placeholder="00/00/00000"
              error={validation.date}
            />
            {person === LocatorPersonType.Physical && (
              <InputComponent
                margin={'0 20px  20px  0'}
                value={physicalForm.companyName}
                onChange={(input) =>
                  setPhysicalForm({
                    ...physicalForm,
                    companyName: input.target.value,
                  })
                }
                label="Empresa"
                placeholder="Digite a empresa"
              />
            )}

            <InputComponent
              margin={'0 20px  20px  0'}
              value={physicalForm.companyPosition}
              onChange={(input) =>
                setPhysicalForm({
                  ...physicalForm,
                  companyPosition: input.target.value,
                })
              }
              label="Posição na empresa"
              placeholder="Digite a posição na empresa"
            />
          </Flex>
          <Flex>
            <InputSelectComponent
              m={'0 20px 20px 0'}
              options={nacionalityList.map((e) => ({
                id: capitalizeFirstLetter(e.gentilico),
                name: `${capitalizeFirstLetter(e.gentilico)} - ${e.sigla}`,
              }))}
              defaultValue={physicalForm.nationality}
              onChange={(input) => {
                console.log('texto');
                setPhysicalForm({
                  ...physicalForm,
                  nationality: input.target.value,
                });
              }}
              label="Nacionalidade"
              placeholder={'selecione a nacionalidade'}
            />
            <InputSelectComponent
              m={'0 20px 20px 0'}
              options={newListSpouseOptions()}
              defaultValue={physicalForm.spouce?.id}
              onChange={(input) => {
                console.log('texto');
                setPhysicalForm({
                  ...physicalForm,
                  spouseId: input.target.value,
                });
              }}
              label="Cônjuge"
              placeholder={'selecione o cônjuge'}
            />
            <InputComponent
              margin={'0 20px 20px 0'}
              value={physicalForm.rg}
              onChange={(input) =>
                setPhysicalForm({
                  ...physicalForm,
                  rg: input.target.value,
                })
              }
              label="RG"
              placeholder="Digite o RG"
            />
          </Flex>
        </Flex>
        <Flex justifyContent={'center'} w={'20%'}>
          <DropzoneModel
            fileUrl={profileUrlPhysical}
            setProfilePicUrl={setProfileUrlPhysical}
            type="profile"
          />
        </Flex>
      </Flex>

      <HeaderTextComponent subTitle>
        Informações de endereço
      </HeaderTextComponent>
      <Flex mb={14} flexDir={'column'}>
        <Flex w={'66.6%'}>
          <InputComponent
            margin={'0 10px 10px 0'}
            value={physicalForm.addresses[0].zipcode}
            onChange={(input) => {
              setSearchCep(cleanNumberMask(input.target.value));
              setPhysicalForm({
                ...physicalForm,
                addresses: [
                  {
                    ...physicalForm.addresses[0],
                    zipcode: cepMask(input.target.value),
                  },
                ],
              });
            }}
            label="CEP"
            placeholder="00.000-000"
          />
          <InputComponent
            margin={'0 10px 10px 0'}
            value={physicalForm.addresses[0].district}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                addresses: [
                  {
                    ...physicalForm.addresses[0],
                    district: input.target.value,
                  },
                ],
              })
            }
            label="Bairro"
            placeholder="Digite o bairro"
          />
        </Flex>
        <Flex>
          <InputComponent
            margin={'0 10px 10px 0'}
            value={physicalForm.addresses[0].street}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                addresses: [
                  { ...physicalForm.addresses[0], street: input.target.value },
                ],
              })
            }
            label="Logradouro"
            placeholder="Digite o Logradouro"
          />
          <InputComponent
            margin={'0 10px 10px 0'}
            value={physicalForm.addresses[0].number}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                addresses: [
                  {
                    ...physicalForm.addresses[0],
                    number: input.target.value,
                  },
                ],
              })
            }
            label="Número"
            placeholder="Digite o número"
          />
          <InputComponent
            margin={'0 10px 10px 0'}
            value={physicalForm.addresses[0].complement}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                addresses: [
                  {
                    ...physicalForm.addresses[0],
                    complement: input.target.value,
                  },
                ],
              })
            }
            label="Complemento"
            placeholder="Digite o complemento"
          />
        </Flex>
        <Flex>
          <InputSelectComponent
            m={'0 10px 10px 0'}
            defaultValue={physicalForm.addresses[0].countryId}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                addresses: [
                  {
                    ...physicalForm.addresses[0],
                    countryId: input.target.value,
                  },
                ],
              })
            }
            options={countries}
            placeholder="Selecione o País"
            label="País"
          />
          <InputSelectComponent
            m={'0 10px 10px 0'}
            defaultValue={physicalForm.addresses[0].stateId}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                addresses: [
                  { ...physicalForm.addresses[0], stateId: input.target.value },
                ],
              })
            }
            options={states}
            placeholder="Selecione o estado"
            label="Estado"
          />
          <InputSelectComponent
            m={'0 10px 10px 0'}
            defaultValue={physicalForm.addresses[0].cityId}
            options={cities}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                addresses: [
                  { ...physicalForm.addresses[0], cityId: input.target.value },
                ],
              })
            }
            label="Cidade"
            placeholder="Selecione uma cidade"
          />
        </Flex>
      </Flex>

      <HeaderTextComponent mb={4} subTitle>
        Informações Bancárias
      </HeaderTextComponent>
      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={colSpan}>
          <InputAutoCompleteComponent
            value={getFirstIfAny(physicalForm.accounts)?.name}
            options={listBankAgencies.data?.map((item) => ({
              id: `${item?.bankAgencyId}||${item.name}`,
              name: item.name,
            }))}
            onChangeText={(input) =>
              setPhysicalForm({
                ...physicalForm,
                accounts: [
                  {
                    ...physicalForm.accounts[0],
                    name: input.target.value,
                  },
                ],
              })
            }
            onChangeOption={(input) => {
              const values = input.split('||');
              setPhysicalForm({
                ...physicalForm,
                accounts: [
                  {
                    ...physicalForm.accounts[0],
                    bankAgencyId: values[0],
                    name: values[1],
                  },
                ],
              });
            }}
            label="Banco"
            placeholder="Selecione um Banco"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            maxLength={9}
            type="number"
            value={getFirstIfAny(physicalForm.accounts)?.agency}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                accounts: [
                  { ...physicalForm.accounts[0], agency: input.target.value },
                ],
              })
            }
            label="Agência"
            placeholder="0000"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            maxLength={9}
            value={getFirstIfAny(physicalForm.accounts)?.account}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                accounts: [
                  { ...physicalForm.accounts[0], account: input.target.value },
                ],
              })
            }
            label="Conta com Dígito"
            placeholder="00000000"
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(12, 1fr)" mb={14} gap={6}>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={getFirstIfAny(physicalForm.accounts)?.corporateName}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                accounts: [
                  {
                    ...physicalForm.accounts[0],
                    corporateName: input.target.value,
                  },
                ],
              })
            }
            label="Nome"
            placeholder="Digite o nome"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={getFirstIfAny(physicalForm.accounts)?.corporateDocument}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                accounts: [
                  {
                    ...physicalForm.accounts[0],
                    corporateDocument:
                      input.target.value.length > 14
                        ? cnpjMask(input.target.value)
                        : cpfMask(input.target.value),
                  },
                ],
              })
            }
            label={person === LocatorPersonType.Juridical ? 'CPF/CNPJ' : 'CPF'}
            placeholder="000.000.000-00"
          />
        </GridItem>
      </Grid>
      <HeaderTextComponent mb={4} subTitle>
        Informações de usuário
      </HeaderTextComponent>
      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={physicalForm.user?.email}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                user: { ...physicalForm.user, email: input.target.value },
              })
            }
            label="email"
            placeholder="example@lll.com"
            error={validation.email}
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputSelectComponent
            options={[
              { id: '0', name: 'Usuário de sistema' },
              { id: '1', name: 'Locador' },
              { id: '2', name: 'Intermediário' },
              { id: '3', name: 'Recebedor' },
            ]}
            value={String(physicalForm.function)}
            disabled={props.function !== undefined}
            onChange={(input) => {
              setPhysicalForm({
                ...physicalForm,
                function: Number(input.target.value),
              });
            }}
            label="Função"
            placeholder="Selecione a função do usuário"
            error={validation.function}
          />
        </GridItem>

        <GridItem colSpan={colSpan}>
          <InputSelectComponent
            defaultValue={physicalForm.user?.profileId}
            value={physicalForm.user?.profileId}
            options={listProfiles.data?.data || []}
            onChange={(input) =>
              setPhysicalForm({
                ...physicalForm,
                user: { ...physicalForm.user, profileId: input.target.value },
              })
            }
            disabled={getLocatorProfile() !== undefined}
            label="Grupo de permissão"
            placeholder="Selecione um grupo de permissão"
            error={validation.profile}
          />
        </GridItem>
      </Grid>
    </ModalStructureComponent>
  );
}
