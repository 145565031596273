import {
  Flex,
  Grid,
  GridItem,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import useStateList, {
  useAddressCep,
  useCityList,
  useCountryList,
} from '../../../../services/StateServices';
import {
  cepMask,
  cleanNumberMask,
  cnpjMask,
} from '../../../../utils/functions/masks';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import { useDebounce } from '../../../../utils/functions/debounce';
import {
  RelatedEntity,
  getRelatedEntityDetails,
  saveRelatedEntity,
} from '../../../../services/RelatedEntityService';

export default function CreateRelatedEntityModalComponent(props: {
  id: string;
  showModal: boolean;
  setShowModal: () => void;
  reloadData?: () => void;
  onSuccess?: (id: string) => void;
}) {
  const toast = useToast();
  const defaultForm: RelatedEntity = React.useMemo(
    () => ({
      id: '',
      name: '',
      document: '',
      called: '',
      addressViewModel: {
        owner: '',
        ownerId: '',
        street: '',
        district: '',
        complement: '',
        zipcode: '',
        type: 1,
        cityId: '',
        stateId: '',
        countryId: '',
      },
    }),
    []
  );
  const colSpan = useBreakpointValue({ base: 12, sm: 3 });
  const [form, setForm] = React.useState<RelatedEntity>({
    ...defaultForm,
  });
  const { data: countries } = useCountryList();
  const { data: states } = useStateList(form.addressViewModel?.countryId ?? '');
  const { data: cities } = useCityList(form.addressViewModel?.stateId ?? '');

  const [, searchCep, setSearchCep] = useDebounce('');
  const { data: dataCep } = useAddressCep(searchCep);
  React.useEffect(() => {
    if (dataCep) {
      setForm((entity) => ({
        ...entity,
        addressViewModel: {
          ...entity.addressViewModel,
          street: dataCep.street,
          district: dataCep.district,
          countryId: dataCep.countryId,
          stateId: dataCep.stateId,
          cityId: dataCep.cityId,
        },
      }));
    }
  }, [dataCep, props.id]);

  useEffect(() => {
    if (props.id) {
      getRelatedEntityDetails(props.id).then((res) =>
        setForm({
          ...res,
          addressViewModel: {
            ...res.addressViewModel,
            zipcode: cepMask(res.addressViewModel.zipcode),
          },
        })
      );
    }
  }, [props.id]);
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        setForm(defaultForm);
        props.setShowModal();
      }}
      title={
        props.id ? `Editar entidade relacionada` : `Nova entidade relacionada`
      }
      maxW="1200px"
      maxH="85%"
      overflowX={'auto'}
      size="xl"
    >
      <HeaderTextComponent subTitle>
        Informações de pessoa física
      </HeaderTextComponent>
      <Grid
        templateColumns="repeat(12, 2fr)"
        marginTop={10}
        marginBottom={10}
        gap={6}
      >
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.name}
            onChange={(input) =>
              setForm({
                ...form,
                name: input.target.value,
              })
            }
            label="Nome"
            placeholder="Digite o nome"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.document}
            onChange={(input) =>
              setForm({
                ...form,
                document: cnpjMask(input.target.value),
              })
            }
            label="CNPJ"
            placeholder="00.000.000/0001-00"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.called}
            onChange={(input) =>
              setForm({
                ...form,
                called: input.target.value,
              })
            }
            label="Denominada"
            placeholder="Digite a denominada"
          />
        </GridItem>
      </Grid>
      <HeaderTextComponent subTitle>
        Informações de endereço
      </HeaderTextComponent>
      <Grid templateColumns="repeat(12, 1fr)" marginTop={10} gap={6}>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.addressViewModel?.zipcode}
            onChange={(input) => {
              setSearchCep(cleanNumberMask(input.target.value));
              setForm({
                ...form,
                addressViewModel: {
                  ...form.addressViewModel,
                  zipcode: cepMask(input.target.value),
                },
              });
            }}
            label="CEP"
            placeholder="00.000-000"
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(12, 2fr)" marginBottom={10} gap={6}>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.addressViewModel?.street}
            onChange={(input) =>
              setForm({
                ...form,
                addressViewModel: {
                  ...form.addressViewModel,
                  street: input.target.value,
                },
              })
            }
            label="Logradouro"
            placeholder="Digite o Logradouro"
          />
          <InputSelectComponent
            defaultValue={form.addressViewModel?.countryId}
            onChange={(input) =>
              setForm({
                ...form,
                addressViewModel: {
                  ...form.addressViewModel,
                  countryId: input.target.value,
                },
              })
            }
            options={countries}
            placeholder="Selecione o País"
            label="País"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.addressViewModel?.complement}
            onChange={(input) =>
              setForm({
                ...form,
                addressViewModel: {
                  ...form.addressViewModel,
                  complement: input.target.value,
                },
              })
            }
            label="Número"
            placeholder="Digite o número"
          />
          <InputSelectComponent
            defaultValue={form.addressViewModel?.stateId}
            onChange={(input) =>
              setForm({
                ...form,
                addressViewModel: {
                  ...form.addressViewModel,
                  stateId: input.target.value,
                },
              })
            }
            options={states}
            placeholder="Selecione o estado"
            label="Estado"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.addressViewModel?.district}
            onChange={(input) =>
              setForm({
                ...form,
                addressViewModel: {
                  ...form.addressViewModel,
                  district: input.target.value,
                },
              })
            }
            label="Bairro"
            placeholder="Digite o bairro"
          />
          <InputSelectComponent
            defaultValue={form.addressViewModel?.cityId}
            options={cities}
            onChange={(input) =>
              setForm({
                ...form,
                addressViewModel: {
                  ...form.addressViewModel,
                  cityId: input.target.value,
                },
              })
            }
            label="Cidade"
            placeholder="Selecione uma cidade"
          />
        </GridItem>
      </Grid>
      <Flex mb={5} justifyContent={'flex-end'}>
        <ButtonComponent
          margin={'0 10px 0 0'}
          variant={'outline'}
          onSubmit={() => {
            setForm(defaultForm);
            props.setShowModal();
          }}
        >
          Cancelar
        </ButtonComponent>
        <ButtonComponent
          isLoading={isLoading}
          disabled={isLoading}
          onSubmit={() => {
            setIsLoading(true);
            saveRelatedEntity({
              ...form,
              address: {
                ...form.addressViewModel,
                zipcode: cleanNumberMask(form.addressViewModel.zipcode),
              },
            })
              .then((res) => {
                showToast({
                  toast,
                  status: 'success',
                  title: 'Sucesso',
                  description: 'Salvo com sucesso',
                });
                setForm(defaultForm);
                props.reloadData?.();
                props.onSuccess?.(res.data.content.data.id);
                props.setShowModal();
              })
              .finally(() => setIsLoading(false));
          }}
        >
          {`Salvar`}
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
