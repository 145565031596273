import { PhysicalPerson } from '../../../services/PhysicalPersonService';
import React from 'react';
import MyRegisterFormComponent from '../../UI/organisms/MyRegisterFormComponent/MyRegisterFormComponent';

export default function CreatePhysicalPersonTemplateComponent() {
  const defaultPhysicalForm = {
    id: '',
    document: '',
    name: '',
    displayName: '',
    profilePicUrl: '',
    date: '',
    emails: [],
    phones: [
      {
        number: '',
        type: 3,
        typeDescription: 'mobile',
      },
    ],
    socialMedias: [],
    addresses: [
      {
        owner: '',
        ownerId: '',
        street: '',
        district: '',
        complement: '',
        zipcode: '',
        type: 1,
        cityId: '',
        coordenateId: '',
      },
    ],
    accounts: [],
    firstName: '',
    lastName: '',
    gender: 0,
    detail: '',
    companyId: '',
    employee: null,
    user: {
      username: '',
      email: '',
      profileId: '',
      permissions: [],
      customPermissions: false,
    },
  };
  const [form, setForm] = React.useState<PhysicalPerson>(defaultPhysicalForm);
  return <MyRegisterFormComponent setForm={setForm} form={form} />;
}
