import { FaEdit, FaInfo, FaTrash } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import React from 'react';
import TextComponent from '../../UI/atoms/TextComponent/TextComponent';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import {
  deleteLocation,
  getLocationReport,
  Location,
  LocationResp,
  useLocation,
} from '../../../services/LocationService';
import { DescriptionLabelComponent } from '../../UI/molecules/DescriptionlabelComponent/DescriptionLabelComponent';
import { CreateLocationModalComponent } from '../../UI/organisms/CreateLocationModalComponent/CreateLocationModalComponent';
import { showToast } from '../../../utils/showToast';
import DetailsModalComponent from '../../UI/molecules/DetailsModalComponent/DetailsModalComponent';

export default function LocationTemplateComponent() {
  const toast = useToast();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModal, setShowModal] = React.useState(false);
  const [detailsDialog, setDetailsDialog] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState<
    Location | undefined
  >();
  const listLocation = useLocation(selectedPage, pageSize, 'title');
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const modalCardInfo = [
    { item: 'Locador', description: selectedLocation?.locator?.name },
    { item: 'Tipo', description: selectedLocation?.typeDescription },
    { item: 'Metragem', description: selectedLocation?.footage + 'm²' },
    { item: 'CEP', description: selectedLocation?.zipcode },
    { item: 'Local', description: selectedLocation?.street },
    { item: 'Número', description: selectedLocation?.number },
    { item: 'Bairro', description: selectedLocation?.district },
    { item: 'Cidade', description: selectedLocation?.cityName },
    { item: 'Estado', description: selectedLocation?.stateName },
    { item: 'País', description: selectedLocation?.countryName },
  ];
  return (
    <>
      <Stack
        paddingBottom={5}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <TextComponent as={'b'} fontSize={'3xl'}>
          Local
        </TextComponent>
        <Flex>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              getLocationReport('title');
            }}
          >
            Exportar
          </ButtonComponent>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              setSelectedLocation(undefined);
              setShowModal(true);
            }}
          >
            Novo Local
          </ButtonComponent>
        </Flex>
      </Stack>
      <TableComponent
        ItemsHeader={[
          { item: 'Locador' },
          { item: 'Logradouro' },
          { item: 'Bairro' },
          { item: 'Cidade/Estado' },
          { item: 'Ação' },
        ]}
        isLoading={listLocation.isLoading}
        centered={true}
        emptyState={listLocation.data?.metadata.dataSize === 0}
        data={
          listLocation.data?.data.map((e: LocationResp, i: number) => ({
            items: [
              <DescriptionLabelComponent
                key={i}
                label={e?.locator?.name || ''}
                subLabel={String(e.footage)}
              />,
              e.street,
              e.district,
              `${e.cityName} - ${e.stateName}`,
              <>
                <IconButtonComponent
                  icon={<FaInfo />}
                  ariaLabel="info"
                  onSubmit={() => {
                    setSelectedLocation(e);
                    setDetailsDialog(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  ariaLabel="editar"
                  disabled={e.canBeChanged}
                  marginX={'8px'}
                  colorScheme={'green'}
                  onSubmit={() => {
                    setSelectedLocation(e);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTrash />}
                  ariaLabel="deletar"
                  colorScheme={'red'}
                  onSubmit={() => {
                    deleteLocation(e.id).then((res) => {
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description: 'Local desativado com sucesso',
                      });
                      listLocation.refetch();
                    });
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listLocation.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateLocationModalComponent
        showModal={showModal}
        selectedLocation={selectedLocation}
        setShowModal={() => {
          setSelectedLocation(undefined);
          setShowModal(false);
        }}
        reloadData={() => listLocation.refetch()}
      />
      <DetailsModalComponent
        isOpen={detailsDialog}
        title={'Local'}
        onClose={() => setDetailsDialog(false)}
        data={modalCardInfo}
      />
    </>
  );
}
