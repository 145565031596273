import { Box, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { AttendanceStatus } from '../../../../services/Attendance';
import { getStatusColorByTypeAttendance } from '../../../templates/AttendanceTemplate/AttendanceTemplateComponent';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';

type AttendanceDetailsProps = {
  label?: string;
  subLabel?: string;
  src?: string;
  hour?: string;
  lastUpdateAt?: string;
  closedAt?: string;
  subject?: string;
  style?: CSSProperties;
  status?: AttendanceStatus;
  ticket?: string;
};
type TextInformationProps = {
  text?: string;
  description?: string;
  status?: AttendanceStatus;
  width?: string;
  mb?: number;
  tooltip?: string;
};

export function TextInformation(props: TextInformationProps) {
  return (
    <Tooltip label={props.tooltip}>
      <Stack mb={props.mb} direction={'row'} width={props.width}>
        <Text fontWeight={'700'} as="a">
          {props.text}
        </Text>
        {props.status === 0 || props.status ? (
          getStatusColorByTypeAttendance(props.status)
        ) : (
          <Text noOfLines={1}>{props.description}</Text>
        )}
      </Stack>
    </Tooltip>
  );
}

export function ResponsibleDetails(props: AttendanceDetailsProps) {
  return (
    <Flex background={'white'} borderRadius={8} padding={5} style={props.style}>
      <Box width={'100%'}>
        <Stack marginBottom={3}>
          <AvatarLabelComponent
            size={'md'}
            label={props.label}
            subLabel={props.subLabel}
            src={props.src}
          />
        </Stack>

        <Stack mb={2} direction={'row'}>
          <TextInformation text={'Status:'} width="40%" status={props.status} />
          <TextInformation text={'Ticket:'} description={props.ticket} />
        </Stack>
        <TextInformation mb={2} text={'Aberto em:'} description={props.hour} />
        <TextInformation mb={2} text={'Assunto:'} description={props.subject} />
        {props.lastUpdateAt && (
          <TextInformation
            text={'Alterado em:'}
            description={props.lastUpdateAt}
            mb={2}
          />
        )}
        {props.closedAt && (
          <TextInformation
            text={'Fechado em:'}
            mb={2}
            description={props.closedAt}
          />
        )}
      </Box>
    </Flex>
  );
}
