import { Flex, Text } from '@chakra-ui/react';

export default function ContainerStepComponent(props: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Flex flexDirection={'column'} width={'100%'}>
      <Text textAlign={'left'} mb={8} fontSize={'20px'} fontWeight={'semibold'}>
        {props.title}
      </Text>
      <Flex flexDirection={'column'}>{props.children}</Flex>
    </Flex>
  );
}
