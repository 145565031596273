import { useQuery } from '@tanstack/react-query';
import { openFile } from '../utils/functions/utility';
import Api, { getRespContent } from './Api';

export type PersonMessagesResp = {
  personId: string;
  subject: string;
  description: string;
  id: string;
  createdAt: string;
  status: MessageStatus;
  statusDescripton: string;
  sentIn: string;
  wasRead: boolean;
  readIn: string;
  senderId: string;
  senderName: string;
  receiverName: string;
  attachmentsCount: number;
  attachments: [
    {
      id: string;
      type: string;
      owner: string;
      ownerId: string;
      url: string;
      externalId: string;
      displayName: string;
      createdAt: string;
    }
  ];
};
export type SendMessage = {
  personId: string;
  subject: string;
  description: string;
  attachmentIds: string[];
};
export type SendMessageMany = {
  subject: string;
  description: string;
  attachmentIds: string[];
  personIds: string[];
};

export enum MessageStatus {
  Delivered = 0,
  DeliveredWithoutEmailNotification = 1,
  DeliveredWithoutNotificationApp = 2,
  EmailAndAppNotificationNotDelivered = 3,
  Read = 4,
}
export function getMessageReport() {
  Api.get(`/messages/report`, {
    responseType: 'blob',
  }).then((response) => {
    openFile(response);
  });
}
export function usePersonMessagesAll(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string,
  status: string
  // receiverName?: string,
  // senderName?: string,
  // subject?: string
) {
  return useQuery(
    [
      `personMessagesAllList`,
      page,
      pageSize,
      search,
      searchField,
      status,
      // receiverName,
      // senderName,
      // subject,
    ],
    async () => {
      const resp = await Api.get(`/personMessages/all`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          Status: status,
          // ReceiverName: receiverName,
          // SenderName: senderName,
          // Subject: subject,
        },
      });
      return getRespContent<[PersonMessagesResp]>(resp);
    }
  );
}

export function usePersonMessagesById(
  personId: string | undefined,
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'name'
) {
  return useQuery(
    [`personMessagesByIdList`, page, pageSize, search, searchField],
    async () => {
      const resp = await Api.get(`/personMessages/person/${personId}`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
        },
      });
      return getRespContent<[PersonMessagesResp]>(resp);
    }
  );
}
export function postPersonMessages(props: SendMessage) {
  return Api.post(`/personMessages`, props);
}
export function putPersonMessageResend(id: string) {
  return Api.put(`/personMessages/resend/${id}`);
}
export function postPersonMessagesMany(props: SendMessageMany) {
  return Api.post(`/personMessages/Many`, props);
}
