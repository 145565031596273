import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { Flex, Stack, useToast, Tooltip, Badge } from '@chakra-ui/react';
import React from 'react';
import InputSelectComponent from '../../UI/atoms/InputSelectComponent/InputSelectComponent';
import AddPercentageModalComponent from '../../UI/molecules/AddPercentageModalCommponent/AddPercentageModalCommponent';
import {
  deleteReadjustmentIndex,
  useReadjustmentIndexYearReferences,
} from '../../../services/ReadjustmentIndexService';
import { useParams } from 'react-router-dom';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { showToast } from '../../../utils/showToast';
import { generateYearList } from '../../../utils/functions/generateYearList';

export default function IndexPerMonthComponent() {
  function descriptionIndex(index: string | number) {
    if (index === 1) return 'IGP-DI(FGV)';
    if (index === 2) return 'IGP-M(FGV)';
    if (index === 3) return 'INPC(IBGE)	';
    if (index === 4) return 'IPC-DI(FGV)	';
    if (index === 5) return 'IPCA(IBGE)	';
    if (index === 6) return 'IPCA-E(IBGE)';
    if (index === 7) return 'SELIC';
  }
  const toast = useToast();
  const params = useParams<{ index: string; state: any }>();
  const [showModal, setShowModal] = React.useState(false);
  const [year, setYear] = React.useState('2023');
  const [month, setMonth] = React.useState('');

  const listIndex = useReadjustmentIndexYearReferences(
    Number(params.index),
    year
  );

  React.useEffect(() => {
    listIndex.refetch();
  }, [listIndex, year]);

  function deleteIndex(id: string) {
    deleteReadjustmentIndex(id)
      .then(() => {
        listIndex.refetch();
        showToast({
          toast,
          status: 'success',
          title: 'Sucesso',
          description: 'Índice Excluir com sucesso',
        });
      })
      .catch(() => {
        showToast({
          toast,
          status: 'error',
          title: 'Erro',
          description: 'Erro ao Excluir índice',
        });
      });
  }
  
  return (
    <>
      <Stack
        paddingBottom={5}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <HeaderTextComponent goBack>{`índice - ${descriptionIndex(
          Number(params.index)
        )}`}</HeaderTextComponent>
      </Stack>
      <Stack
        direction={'row'}
        paddingBottom={5}
        justifyContent={'space-between'}
      >
        <Flex>
          <InputSelectComponent
            w={300}
            m={'0px 0px 10px 0px'}
            defaultValue={'2023'}
            options={generateYearList(2010)}
            onChange={(item) => {
              setYear(item.target.value);
            }}
            label=""
            placeholder="Selecione o Ano"
          />
        </Flex>
      </Stack>
      <TableComponent
        centered={true}
        ItemsHeader={[
          { item: 'Mês' },
          { item: 'Porcentagem' },
          { item: 'Contrato ativo' },
          { item: 'Ações' },
        ]}
        isLoading={false}
        data={
          listIndex.data?.map((e, i) => ({
            items: [
              e.monthName,
              e.value + '%',
              e.hasContracts ? (
                <Badge colorScheme="green">Sim</Badge>
              ) : (
                <Badge colorScheme="red">Não</Badge>
              ),
              <>
                <ButtonComponent
                  key={i}
                  onSubmit={() => {
                    setMonth(e.monthName);
                    setShowModal(true);
                  }}
                >
                  Adicionar Porcentagem
                </ButtonComponent>

                <ButtonComponent
                  margin={'0 10px'}
                  key={i}
                  colorScheme="red"
                  disabled={e.id === null || e.hasContracts}
                  onSubmit={() => {
                    deleteIndex(e.id);
                  }}
                >
                  <Tooltip
                    label={
                      e.id === null
                        ? ''
                        : 'índice vinculado ao contrato, impossivel Excluir'
                    }
                  >
                    Excluir
                  </Tooltip>
                </ButtonComponent>
              </>,
            ],
          })) || []
        }
      />
      <AddPercentageModalComponent
        reloadData={() => listIndex.refetch()}
        year={year}
        month={month}
        index={Number(params.index)}
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
      />
    </>
  );
}
