import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import {
  LocatorResp,
  getLocatorDetails,
  useLocator,
} from '../../../../services/LocatorService';
import { useDebounce } from '../../../../utils/functions/debounce';
import { personDocumentMask } from '../../../../utils/functions/masks';
import { getFirstIfAny } from '../../../../utils/functions/utility';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../molecules/PaginationComponent/PaginationComponent';
import SearchSelectComponent from '../../molecules/SearchSelectComponent/SearchSelectComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import CreateIntermediaryReceiverModalComponent from '../CreateIntermediaryModalComponent/CreateIntermediaryModalComponent';
import CreatePersonModalComponent from '../CreateLocatorModalComponent/CreateLocatorModalComponent';
import { PersonFunction } from '../../../../services/PhysicalPersonService';

export function SelectAttorneyModalComponent(props: {
  showModal: boolean;
  setShowModal: () => void;
  selectedAttorney: LocatorResp | undefined;
  setSelectedAttorney: (attorney: LocatorResp | undefined) => void;
}) {
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('name');
  const [showModalCreateAttorney, setShowModalCreateAttorney] =
    React.useState(false);
  const listAttorney = useLocator(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createdAt',
    'desc'
  );

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  function getList() {
    return listAttorney;
  }
  return (
    <>
      <ModalStructureComponent
        isOpen={props.showModal}
        onClose={() => props.setShowModal()}
        title={`Selecione procurador`}
        maxW="1200px"
        size="xl"
      >
        <Stack
          direction={'row'}
          paddingBottom={5}
          justifyContent={'space-between'}
        >
          <ButtonComponent onSubmit={() => setShowModalCreateAttorney(true)}>
            Criar procurador
          </ButtonComponent>
          <Flex>
            <SearchSelectComponent
              onChangeText={(input) => setSearch(input.target.value)}
              inputValue={searchInput}
              onClean={() => setSearch('')}
              onChange={(item) => {
                setSearchField(item.target.value);
              }}
              options={[
                { id: 'name', name: 'Nome' },
                { id: 'document', name: 'Documento' },
                { id: 'email', name: 'Email' },
              ]}
            />
          </Flex>
        </Stack>
        <TableComponent
          ItemsHeader={[
            { item: 'Selecionar' },
            { item: 'Procurador' },
            { item: 'Documento' },
            { item: 'Email' },
          ]}
          isLoading={getList().isLoading}
          centered={true}
          emptyState={getList().data?.metadata.dataSize === 0}
          data={
            getList().data?.data.map((e, i) => ({
              items: [
                <InputCheckComponent
                  key={i}
                  isChecked={props.selectedAttorney?.id === e.id}
                  onChange={() => {
                    if (props.selectedAttorney?.id === e.id) {
                      props.setSelectedAttorney(undefined);
                    } else {
                      props.setSelectedAttorney(e);
                    }
                  }}
                ></InputCheckComponent>,
                <AvatarLabelComponent
                  key={i}
                  label={e.name}
                  subLabel={'procurador'}
                  src={e.profilePicUrl}
                />,
                personDocumentMask(e.document, e.type),
                getFirstIfAny(e.emails),
              ],
            })) || []
          }
        />
        <PaginationComponent
          onSelectedPageChanged={onSelectedPageChanged}
          selectedPage={selectedPage}
          arrayLength={getList().data?.metadata.dataSize || 0}
          maxPageItens={pageSize}
        ></PaginationComponent>
        <Flex mb={5} justifyContent={'space-between'}>
          <ButtonComponent
            variant={'outline'}
            onSubmit={() => {
              props.setSelectedAttorney(undefined);
              props.setShowModal();
            }}
          >
            Descartar
          </ButtonComponent>
          <ButtonComponent
            onSubmit={() => {
              props.setShowModal();
            }}
          >
            Confirmar
          </ButtonComponent>
        </Flex>
      </ModalStructureComponent>
      <CreateIntermediaryReceiverModalComponent
        showModal={showModalCreateAttorney}
        setShowModal={() => setShowModalCreateAttorney(false)}
        reloadData={() => getList().refetch()}
        onSuccess={async (id) => {
          let newAttorney = undefined;
          newAttorney = await getLocatorDetails(id);
          props.setSelectedAttorney(newAttorney);
        }}
      />
      <CreatePersonModalComponent
        showModal={showModalCreateAttorney}
        setShowModal={() => {
          setShowModalCreateAttorney(false);
        }}
        reloadData={() => getList().refetch()}
        function={PersonFunction.Locator}
      />
    </>
  );
}
