import { Flex, Stack } from '@chakra-ui/react';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../molecules/PaginationComponent/PaginationComponent';
import { getFirstIfAny } from '../../../../utils/functions/utility';
import { personDocumentMask } from '../../../../utils/functions/masks';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import { PersonResp, usePerson } from '../../../../services/PersonService';
import { useDebounce } from '../../../../utils/functions/debounce';
import React from 'react';
import SearchSelectComponent from '../../molecules/SearchSelectComponent/SearchSelectComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';

export function SelectPersonModalComponent(props: {
  showModal: boolean;
  setShowModal: () => void;
  onAcceptModal: (result: PersonResp[]) => void;
}) {
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('name');
  const [selectedLocators, setSelectedLocators] = React.useState<any[]>([]);
  const listPerson = usePerson(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createdAt',
    'desc'
  );

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Pesquisar e Adicionar`}
      maxW="1200px"
      size="xl"
    >
      <Stack direction={'row'} paddingBottom={5} justifyContent={'end'}>
        <Flex>
          <SearchSelectComponent
            onChangeText={(input) => setSearch(input.target.value)}
            inputValue={searchInput}
            onClean={() => setSearch('')}
            onChange={(item) => {
              setSearchField(item.target.value);
            }}
            options={[
              { id: 'name', name: 'Nome' },
              { id: 'document', name: 'Documento' },
              { id: 'email', name: 'Email' },
            ]}
          />
        </Flex>
      </Stack>
      <TableComponent
        ItemsHeader={[
          {
            item: (
              <>
                Selecionar
                <InputCheckComponent
                  borderColor="black"
                  m={'0 0 0 10px'}
                  isChecked={listPerson.data?.data?.every((item) =>
                    selectedLocators.includes(item as any)
                  )}
                  onChange={() => {
                    if (
                      !listPerson.data?.data.every((item) =>
                        selectedLocators.includes(item as any)
                      )
                    )
                      listPerson.data?.data?.forEach((e) => {
                        if (
                          !selectedLocators.some(
                            (item) => item.personId === e.personId
                          )
                        )
                          setSelectedLocators((locator) => [...locator, e]);
                      });
                    else setSelectedLocators([]);
                  }}
                ></InputCheckComponent>
              </>
            ),
          },
          { item: 'Locador' },
          { item: 'Nome/ Razão Social' },
          { item: 'Documento' },
          { item: 'Email' },
        ]}
        isLoading={listPerson.isLoading}
        centered={true}
        emptyState={listPerson.data?.metadata.dataSize === 0}
        data={
          listPerson.data?.data.map((e: PersonResp, i: number) => ({
            items: [
              <InputCheckComponent
                key={i}
                isChecked={selectedLocators.some(
                  (item) => item.personId === e.personId
                )}
                onChange={() => {
                  if (
                    !selectedLocators.some(
                      (item) => item.personId === e.personId
                    )
                  )
                    setSelectedLocators([...selectedLocators, e]);
                  else {
                    setSelectedLocators(
                      selectedLocators.filter(
                        (item) => item.personId !== e.personId
                      )
                    );
                  }
                }}
              ></InputCheckComponent>,
              <AvatarLabelComponent
                key={i}
                label={e.name}
                src={e.profilePicUrl}
              />,
              e.displayName,
              personDocumentMask(e.document, e.type),
              getFirstIfAny(e.emails),
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listPerson.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          onSubmit={() => {
            props.onAcceptModal(selectedLocators);
            props.setShowModal();
          }}
        >
          Adicionar Selecionados
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
