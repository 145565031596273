import { useQuery } from '@tanstack/react-query';
import Api, { getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';

export type DetachedPaymentResp = {
  id: string;
  createdAt: string;
  title: string;
  description: string;
  value: number;
  effected: boolean;
  paymentDate: string;
  effectDate: string;
  wasApproved: boolean;
  answerDescription: string;
  answeredById: string;
  anseweredByName: string;
  personId: string;
  personName: string;
  contractId: string;
  documentId: string;
  locators: {
    locatorId: string;
    locatorName: string;
    value: number;
    effected: boolean;
    effectDate: string;
  }[];
};

export type DetachedPaymentTotalizer = {
  totalValue: number;
  currentPageValue: number;
}

export type DetachedPayment = {
  contractId: string;
  title: string;
  description: string;
  value: number;
  paymentDate: string;
  locatorIds: string[];
};


export function useDetachedPaymentTotalizer(
  contractId: string,
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'title',
  sort: string = 'name',
  direction: SortDirection = 'asc',
  pendingAnswer: boolean | null = null
) {
  return useQuery(
    [
      `DetachedPaymentListTotalizer`,
      page,
      search,
      searchField,
      sort,
      direction,
      pendingAnswer,
    ],
    async () => {
      const resp = await Api.get(`/DetachedPayment/${contractId}/totalizer`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          PendingAnswer: pendingAnswer,
        },
      });
      return getRespContent<DetachedPaymentTotalizer>(resp);
    },
    { enabled: !!contractId }
  );
}

export function useDetachedPayment(
  contractId: string,
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'title',
  sort: string = 'name',
  direction: SortDirection = 'asc',
  pendingAnswer: boolean | null = null
) {
  return useQuery(
    [
      `DetachedPaymentList`,
      page,
      search,
      searchField,
      sort,
      direction,
      pendingAnswer,
    ],
    async () => {
      const resp = await Api.get(`/DetachedPayment/${contractId}`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          PendingAnswer: pendingAnswer,
        },
      });
      return getRespContent<DetachedPaymentResp[]>(resp);
    },
    { enabled: !!contractId }
  );
}

export function postDetachedPayment(props: DetachedPayment) {
  return Api.post('/DetachedPayment', props);
}

export function putDetachedPaymentApproveDisapprove(
  id: string,
  approve: boolean,
  message: string
) {
  if (approve) return putDetachedPaymentApprove(id, message);
  return putDetachedPaymentDisapprove(id, message);
}

export function putDetachedPaymentApprove(id: string, message: string) {
  return Api.put(`/DetachedPayment/approve/${id}`, {
    message,
  });
}

export function putDetachedPaymentDisapprove(id: string, message: string) {
  return Api.put(`/DetachedPayment/reject/${id}`, {
    message,
  });
}
