import { useQuery } from '@tanstack/react-query';
import { exportFile } from '../utils/functions/utility';
import Api, { getResp, getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';
import { LocatorPersonType } from './LocatorService';

export type PersonResp = {
  document: string;
  name: string;
  contractCount: number;
  displayName: string;
  profilePicUrl: string;
  personId: string;
  date: string;
  addresses: {
    id: string;
    street: string;
    district: string;
    complement: string;
    zipcode: string;
    type: number;
    typeDescription: string;
    cityId: string;
    stateId: string;
    countryId: string;
    cityName: string;
    stateName: string;
    countryName: string;
    owner: string;
    ownerId: string;
  }[];
  emails: string[];
  phones: {
    number: string;
    type: number;
    typeDescription: string;
  }[];
  socialMedias: {
    url: string;
    type: number;
  }[];
  physicalOrJuridicalPersonId: string;
  hasBankAccounts: boolean;
  type: LocatorPersonType;
  isUserActive?:boolean;
};

export function usePerson(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'name',
  sort: string = 'name',
  direction: SortDirection = 'asc',
  personType?: number | null
) {
  return useQuery(
    [`personList`, page, sort, search, searchField, direction, personType],
    async () => {
      const resp = await Api.get(`/person`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          Function: personType,
        },
      });
      return getRespContent<PersonResp[]>(resp);
    }
  );
}

export function getPersonReport(
  search: string,
  searchField: string = 'name',
  sort: string = 'name',
  direction: SortDirection = 'asc'
) {
  Api.get(`/Person/report`, {
    params: {
      [searchField]: search,
      SortColumn: sort,
      SortDirection: direction,
    },
    responseType: 'blob',
  }).then((response) => {
    exportFile(response);
  });
}

export function usePersonSelect() {
  return useQuery([`personSelectList`], async () => {
    const resp = await Api.get(`/person`);
    return getResp<PersonResp[]>(resp);
  });
}
