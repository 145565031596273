import { HStack, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { FaFileAlt } from 'react-icons/fa';

type TagDocumentComponentProps = {
  onClose: () => void;
  title: string;
};

export function TagDocumentComponent(props: TagDocumentComponentProps) {
  return (
    <HStack maxWidth={250} spacing={4}>
      <Tag size={'md'} variant="solid" colorScheme="teal">
        <FaFileAlt size={15} color="white" />
        <TagLabel marginStart={2}>{props.title}</TagLabel>
        <TagCloseButton onClick={props.onClose} />
      </Tag>
    </HStack>
  );
}
