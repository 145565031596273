import { Flex, Stack, Image, useToast } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import Dropzone from 'react-dropzone';
import { FaPaperclip, FaTrash } from 'react-icons/fa';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { uploadFiles } from '../../organisms/uploadModel/UploadApi';
import { Card } from 'react-bootstrap';
import ArchiveImage from '../../../../assets/archive.png';
import LoadingComponent from '../../atoms/LoadingComponent/LoadingComponent';
import { showToast } from '../../../../utils/showToast';

export type FileInfo = {
  file: File;
  id: string;
  isLoading: boolean;
  internalId?: string;
};

export type DropZoneLIstComponentProps = {
  fileInfos: FileInfo[];
  setFileInfos: Dispatch<SetStateAction<FileInfo[]>>;
};

export default function DropZoneListComponent(
  props: DropZoneLIstComponentProps
) {
  const toast = useToast();
  return (
    <Flex width={'400px'} direction={'column'}>
      <Dropzone
        onDrop={async (acceptedFiles) => {
          const now = new Date().getTime().toString();
          const acceptedFilesInfos = acceptedFiles.map((file, i) => ({
            file,
            id: '',
            isLoading: true,
            internalId: now + '_' + i.toString(),
          }));
          try {
            props.setFileInfos((prevValue) => [
              ...prevValue,
              ...acceptedFilesInfos,
            ]);
            const responses = await uploadFiles(
              acceptedFiles,
              acceptedFilesInfos.map((fileInfo) => fileInfo.internalId)
            );
            props.setFileInfos((prevValue) => {
              for (const response of responses) {
                const fileInfo = prevValue.find(
                  (fileInfo) => fileInfo.internalId === response.internalId
                );
                console.log(fileInfo, prevValue);
                if (fileInfo) {
                  fileInfo.id = response.id;
                  fileInfo.isLoading = false;
                }
              }
              return [...prevValue];
            });
          } catch (error) {
            props.setFileInfos(props.fileInfos);
            showToast({
              toast,
              status: 'error',
              title: 'Erro',
              description:
                'Os arquivos excedem o limite permitido. Por favor, tente adicionar arquivos menores.',
            });
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Stack {...getRootProps()}>
            <ButtonComponent
              {...getInputProps()}
              size={undefined}
              style={{ borderRadius: 8 }}
              onSubmit={() => {}}
              leftIcon={<FaPaperclip />}
            >
              Anexar Arquivos
            </ButtonComponent>
          </Stack>
        )}
      </Dropzone>
      {props.fileInfos.map(({ file, isLoading }, i) => (
        <Card style={{ marginTop: 4, marginBottom: 4 }} key={i}>
          <Flex justifyContent={'space-between'} alignItems={'center'} p={2}>
            <>
              {isLoading ? (
                <Flex
                  w={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <LoadingComponent size={'lg'} />
                </Flex>
              ) : (
                <>
                  <Image w={8} h={8} src={ArchiveImage} />
                  <TextComponent>{addSpreadText(file.name)}</TextComponent>
                  <IconButtonComponent
                    toolTipText="Remover"
                    icon={<FaTrash />}
                    colorScheme="red"
                    variant={'ghost'}
                    width={'40px'}
                    p={0}
                    height={'20px'}
                    onSubmit={() => {
                      props.setFileInfos((prevValue) =>
                        prevValue.filter((_, index) => index !== i)
                      );
                    }}
                    ariaLabel={'Remover'}
                  />
                </>
              )}
            </>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
}
function addSpreadText(text: string) {
  if (text.length > 30) {
    return text.slice(0, 30) + '...';
  } else {
    return text;
  }
}
