import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';

export type EngineeringTypeResp = {
  id: string;
  createdAt: string;
  description: string;
  serialNumber: string;
  hasActiveContract: boolean;
  status: StatusEngineeringType;
  statusDescriptions: string;
};

export enum StatusEngineeringType {
  Active = 0,
  Inactive = 1,
}

export function useEngineeringTypeSelect() {
  return useQuery([`EngineeringTypeSelectList`], async () => {
    const resp = await Api.get(`/EngineeringType`);
    return getResp<EngineeringTypeResp[]>(resp);
  });
}

export function useEngineeringType(
  page: number,
  pageSize: number | null,
  search: string = '',
  searchField: string = 'serialNumber',
  sort: string = 'serialNumber',
  direction: SortDirection = 'asc',
  status: StatusEngineeringType
) {
  return useQuery(
    [`EngineeringTypeList`, page, search, searchField, sort, direction, status],
    async () => {
      const resp = await Api.get(`/EngineeringType`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          Status: status,
        },
      });
      return getRespContent<EngineeringTypeResp[]>(resp);
    }
  );
}
export function saveEngineeringType(props: EngineeringTypeResp) {
  if (props.id) {
    return putEngineeringType(props);
  }
  return postEngineeringType(props);
}

export function postEngineeringType(props: { description: string }) {
  return Api.post('/EngineeringType', props);
}

export function putEngineeringType(props: EngineeringTypeResp) {
  return Api.put(`/EngineeringType/${props.id}`, props);
}
export function putEngineeringTypeActive(id: string) {
  return Api.put(`/EngineeringType/active/${id}`);
}
export function putEngineeringDisable(id: string) {
  return Api.put(`/EngineeringType/disable/${id}`);
}
export function deleteEngineeringType(props: EngineeringTypeResp) {
  return Api.delete(`/EngineeringType/${props.id}`);
}
