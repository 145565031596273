import './ContainerComponent.scss';
import React, { CSSProperties } from 'react';
import { Box } from '@chakra-ui/react';

export type ContainerProps = {
  children: React.ReactNode;
  style?: CSSProperties;
  background?: string;
  width?: string;
  borderRadius?: string;
  padding?: string;
  boxShadow?: string;
};

export default function ContainerComponent(props: ContainerProps) {
  return (
    <Box
      background={props.background}
      width={props.width}
      style={{ ...props.style }}
      borderRadius={props.borderRadius}
      padding={props.padding}
      boxShadow={props.boxShadow}
    >
      {props.children}
    </Box>
  );
}
