import { Flex, Tag } from '@chakra-ui/react';
import { convertToMonetaryValue } from '../../../../utils/functions/masks';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';

type TotalizerProps = {
  total: number;
  currentPage: number;
};

export function Totalizer({ total, currentPage }: TotalizerProps) {
  return (
    <Flex mt={4} justifyContent={'flex-end'}>
      <Flex>
        <Flex>
          <Flex mr={4}>
            <HeaderTextComponent fontSize={18} subTitle>
              Valor total da página
            </HeaderTextComponent>
            <Tag ml={2}>{convertToMonetaryValue(currentPage)}</Tag>
          </Flex>
          <HeaderTextComponent fontSize={18} subTitle>
            Valor total
          </HeaderTextComponent>
          <Tag ml={2}>{convertToMonetaryValue(total)}</Tag>
        </Flex>
      </Flex>
    </Flex>
  );
}
