import { Flex, Text } from '@chakra-ui/react';

export default function DetailsComponent(props: {
  label: string;
  value: string;
  backgroundColor?: string;
}) {
  return (
    <Flex maxW={100} mb={8} flexDirection={'column'}>
      <Text fontSize={'medium'} color={'gray.500'}>
        {props.label}
      </Text>
      <Flex
        borderRadius={8}
        justifyContent={'center'}
        backgroundColor={props.backgroundColor}
      >
        <Text
          fontSize={'medium'}
          fontWeight={'semibold'}
          color={props.backgroundColor ? 'white' : 'brand.600'}
        >
          {props.value}
        </Text>
      </Flex>
    </Flex>
  );
}
