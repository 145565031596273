import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDebounce } from '../../../utils/functions/debounce';
import {
  PermissionGroup,
  PermissionGroupResp,
  deletePermissionGroup,
  savePermissionGroup,
  usePermission,
  usePermissionGroup,
} from '../../../services/ProfileService';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { FaEdit, FaInfo, FaTrash } from 'react-icons/fa';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import InputComponent from '../../UI/atoms/InputComponent/InputComponent';
import { SelectAutoCompleteComponent } from '../../UI/atoms/InputSelectComponent/InputSelectComponent';
import { showToast } from '../../../utils/showToast';
import SearchComponent from '../../UI/atoms/SearchComponent/SearchComponent';

const defaultForm = { id: '', name: '', description: '', permissionIds: [] };

export default function PermissionsTemplateComponent() {
  const toast = useToast();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [searchInput, , setSearch] = useDebounce('');
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalPermission, setShowModalPermission] = React.useState(false);
  const [form, setForm] = React.useState<PermissionGroup>(defaultForm);
  const permissionList = usePermission();
  const [permissionOptions, setPermissionOptions] = useState<string[]>([]);
  const [selectedPermissionGroup, setSelectedPermissionGroup] =
    React.useState<PermissionGroupResp>();
  React.useEffect(() => {
    if (selectedPermissionGroup) {
      setForm({
        ...selectedPermissionGroup,
        permissionIds: selectedPermissionGroup.permissions.map(
          (e) => e.permissionId
        ),
      });
      setPermissionOptions(
        selectedPermissionGroup.permissions.map((e) => e.permissionId)
      );
    } else setForm(defaultForm);
  }, [selectedPermissionGroup]);
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const permissionGroupList = usePermissionGroup(
    selectedPage,
    pageSize,
    searchInput
  );
  function savePermission() {
    setLoading(true);
    savePermissionGroup({
      id: form.id,
      description: form.description,
      name: form.name,
      permissionIds: permissionOptions,
    })
      .then(() => {
        showToast({
          toast,
          status: 'success',
          title: 'Sucesso',
          description: 'Grupo de permissão criado com sucesso',
        });
        permissionGroupList.refetch();
        setShowModal(false);
      })
      .catch(() => {
        showToast({
          toast,
          status: 'error',
          title: 'Erro',
          description: selectedPermissionGroup?.id
            ? 'Erro ao editar permissão'
            : 'Erro ao criar grupo de permissão',
        });
      })
      .finally(() => setLoading(false));
  }

  function removePermissionGroup(id: string) {
    deletePermissionGroup(id)
      .then(() => {
        permissionGroupList.refetch();

        showToast({
          toast,
          status: 'success',
          title: 'Sucesso',
          description: 'Grupo de permissão removido com sucesso',
        });
        setSelectedPermissionGroup(undefined);
      })
      .catch(() => {
        showToast({
          toast,
          status: 'error',
          title: 'Erro',
          description: 'Erro ao remover grupo de permissão',
        });
      });
  }

  function getTablePermissionToDiscriminator(discriminator: string) {
    const listDetaisPermision = selectedPermissionGroup?.permissions.map(
      (e) => e
    );
    const filteredList =
      listDetaisPermision?.filter(
        (item) => item.discriminator === discriminator
      ) || [];
    return (
      <>
        {filteredList?.length > 0 && (
          <TableComponent
            ItemsHeader={[{ item: discriminator }]}
            data={
              filteredList?.map((e) => ({
                items: [e.permissionDescription],
              })) || []
            }
            isLoading={false}
            emptyState={selectedPermissionGroup?.permissions.length === 0}
          />
        )}
      </>
    );
  }
  return (
    <>
      <Stack
        paddingBottom={2}
        alignItems={'center'}
        justifyContent={'space-between'}
        direction="row"
      >
        <Flex>
          <HeaderTextComponent goBack>Grupos de Permissões</HeaderTextComponent>
        </Flex>
        <Flex>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              setShowModal(true);
              setForm(defaultForm);
              setPermissionOptions([]);
            }}
          >
            Novo Cadastro
          </ButtonComponent>
          <SearchComponent
            value={searchInput}
            onChange={(input) => setSearch(input.target.value)}
            onClean={() => setSearch('')}
          />
        </Flex>
      </Stack>
      <TableComponent
        ItemsHeader={[
          { item: 'nome' },
          { item: 'descrição' },
          { item: 'ações' },
        ]}
        isLoading={permissionGroupList.isLoading}
        data={
          permissionGroupList.data?.data.map((e: PermissionGroupResp) => ({
            items: [
              e.name,
              e.description,
              <>
                <IconButtonComponent
                  icon={<FaInfo />}
                  ariaLabel="info"
                  toolTipText="Ver permissões"
                  backgroundColor="brand.500"
                  onSubmit={() => {
                    setSelectedPermissionGroup(e);
                    setShowModalPermission(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  disabled={!e.isEditable}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedPermissionGroup(e);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTrash />}
                  disabled={!e.isEditable}
                  ariaLabel="Delet"
                  toolTipText="Deletar"
                  colorScheme="red"
                  onSubmit={() => {
                    setSelectedPermissionGroup(e);
                    removePermissionGroup(e.id);
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={permissionGroupList.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>

      <ModalStructureComponent
        title={
          selectedPermissionGroup?.id ? 'Editar Cadastro' : 'Novo Cadastro'
        }
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedPermissionGroup(undefined);
        }}
        size="3xl"
        children={
          <Flex flexDir={'column'}>
            <Flex>
              <InputComponent
                margin={2}
                label="Nome"
                placeholder="Digite o nome do grupo"
                value={form.name}
                onChange={(input) => {
                  setForm({ ...form, name: input.target.value });
                }}
              />
              <InputComponent
                margin={2}
                label="Descrição"
                placeholder="Digite a descrição do grupo"
                value={form.description}
                onChange={(input) => {
                  setForm({ ...form, description: input.target.value });
                }}
              />
            </Flex>
            <Flex pb={200}>
              <SelectAutoCompleteComponent
                m={2}
                onChange={(e) => {
                
                  setPermissionOptions(e);
                }}
                options={permissionList.data?.map((e) => ({
                  id: e.id,
                  name: e.description,
                }))}
                placeholder="Selecione as permissões"
                label="Permissões"
                values={permissionOptions}
              />
            </Flex>
          </Flex>
        }
        footer={
          <Flex p={4}>
            <ButtonComponent
              margin={'0px 10px 0px 0px'}
              variant={'outline'}
              onSubmit={() => {
                setShowModal(false);
              }}
            >
              Cancelar
            </ButtonComponent>
            <ButtonComponent
              isLoading={loading}
              disabled={loading}
              onSubmit={() => savePermission()}
            >
              Salvar
            </ButtonComponent>
          </Flex>
        }
      />
      <ModalStructureComponent
        title="Permissões"
        isOpen={showModalPermission}
        onClose={() => {
          setShowModalPermission(false);
        }}
        children={
          <>
            {getTablePermissionToDiscriminator('CONTRATOS')}
            {getTablePermissionToDiscriminator('MENSAGENS')}
            {getTablePermissionToDiscriminator('ATENDIMENTO')}
            {getTablePermissionToDiscriminator('AVANÇADO')}
            {getTablePermissionToDiscriminator('CONFIGURACOES')}
            {getTablePermissionToDiscriminator('DASHBOARD')}
            {getTablePermissionToDiscriminator('CADASTRO')}
            {getTablePermissionToDiscriminator('APLICATIVO')}
          </>
        }
        size="6xl"
      />
    </>
  );
}
