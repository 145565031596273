import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';

export type ContractHistoryResp = {
  newValue: number;
  oldValue: number;
  description: string;
  id: string;
  createdAt: string;
  systemGenerated: boolean;
  personId: string;
  personName: string;
  contractId: string;
  contractSerialNumber: string;
  type: ContractHistoryType;
  typeDescription: string;
};
export type ContractHistory = {
  id?: string;
  newValue: number | string;
  oldValue: number | string;
  description: string;
  type: ContractHistoryType;
};

export function useContractHistory(
  enabled: boolean,
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'Description',
  sort: string = 'description',
  direction: SortDirection = 'asc'
) {
  return useQuery(
    [`ContractHistoryList`, page, search, searchField, sort, direction],
    async () => {
      const resp = await Api.get(`/ContractHistory`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
        },
      });
      return getRespContent<ContractHistoryResp[]>(resp);
    },
    { enabled: enabled }
  );
}

export function useContractHistorByContract(
  enabled: boolean,
  contractId: string | undefined,
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'description',
  sort: string = 'description',
  direction: SortDirection = 'asc'
) {
  return useQuery(
    [
      `ContractHistoryList`,
      page,
      search,
      searchField,
      sort,
      direction,
      contractId,
    ],
    async () => {
      const resp = await Api.get(`/Contract/${contractId}/History`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
        },
      });
      return getRespContent<ContractHistoryResp[]>(resp);
    },
    { enabled: enabled }
  );
}

export function useContractDetails(id: string) {
  return useQuery([`ContractHistoryDetails`], async () => {
    const resp = await Api.get(`/Contract/${id}`);
    return getResp<ContractHistoryResp>(resp);
  });
}

export function saveContractHistory(
  props: ContractHistory,
  contractId: string
) {
  if (props.id) {
    return putContractHistory(props);
  }
  return postContractHistory(props, contractId);
}

export function postContractHistory(
  props: ContractHistory,
  contractId: string
) {
  return Api.post(`/Contract/${contractId}/history`, props);
}

export function putContractHistory(props: ContractHistory) {
  return Api.put(`/ContractHistory/${props.id}`, props);
}

export function deleteContractHistory(id: string) {
  return Api.delete(`/ContractHistory/${id}`);
}

export enum ContractHistoryType {
  VALUE = 1,
  DATE = 2,
  INDEX = 3,
  PEOPLE = 4,
  OTHERS = 5,
}

export function getContractHistoryTypeOption() {
  return [
    {
      id: ContractHistoryType.VALUE.toString(),
      name: 'Valor',
    },
    {
      id: ContractHistoryType.DATE.toString(),
      name: 'Data',
    },
    {
      id: ContractHistoryType.INDEX.toString(),
      name: 'Índice',
    },
    {
      id: ContractHistoryType.OTHERS.toString(),
      name: 'Outros',
    },
  ];
}

export function getContractHistoryTypeLabel(type: ContractHistoryType) {
  if (type === ContractHistoryType.VALUE) return 'Valor';
  if (type === ContractHistoryType.DATE) return 'Data';
  if (type === ContractHistoryType.INDEX) return 'Índice';
  return '';
}
