import dayjs from 'dayjs';

export function formatDate(
  date: string | number | Date | undefined | null
): string {
  if (!date) return '';
  return dayjs(date).format('DD/MM/YYYY');
}

export function formatDateToForm(date: Date | null | undefined): string {
  if (!date) return '';
  return dayjs(date).format('YYYY-MM-DD');
}
export function formatDateTimeBrasilia(value: string): string {
  const dateTime = new Date(value);

  dateTime.setUTCMinutes(dateTime.getUTCMinutes() - 180);

  const year = dateTime.getUTCFullYear();
  const month = padZero(dateTime.getUTCMonth() + 1);
  const day = padZero(dateTime.getUTCDate());
  const hours = padZero(dateTime.getUTCHours());
  const minutes = padZero(dateTime.getUTCMinutes());

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

function padZero(value: number, size: number = 2): string {
  let valueString = value.toString();
  while (valueString.length < size) {
    valueString = '0' + valueString;
  }
  return valueString;
}
