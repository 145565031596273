import React from 'react';
import { Box, Tab, Tabs, Tooltip } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Permissions } from '../../../../routing/Routes';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

export type NavbarItemsProps = {
  buttons: NavbarButtonProps[];
  onCloseMenu?: () => void;
};

export type NavbarButtonProps = {
  label?: string;
  icon: React.ReactNode;
  link?: string;
  onSubmit?: () => void;
  testId?: string;
  permission?: Permissions;
  open?: boolean;
  subItem?: {
    label?: string;
    icon?: React.ReactNode;
    link?: string;
    onSubmit?: () => void;
    testId?: string;
    permission?: Permissions;
    tooltip?: string;
  }[];
};

export default function NavbarItemsComponent(props: NavbarItemsProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [buttons, setButtons] = React.useState(
    props.buttons.map((item) =>
      item.subItem?.some((subitem) => subitem.link === location.pathname)
        ? { ...item, open: true }
        : item
    )
  );
  return (
    <>
      <Tabs variant="soft-rounded">
        {buttons.map((button, index) => {
          return (
            <>
              <Tab
                mb={3}
                justifyContent={'flex-start'}
                data-testid={button.testId}
                onClick={(e) => {
                  button.link && navigate(button.link);
                  button.onSubmit?.();
                  if (button.subItem)
                    setButtons(
                      buttons.map((item, i) =>
                        i === index ? { ...item, open: !item.open } : item
                      )
                    );
                  !button.subItem && props.onCloseMenu?.();
                }}
                key={index}
                fontSize={14}
                _selected={{ textColor: 'auto', bg: 'auto' }}
                textColor={
                  location.pathname !== button?.link ? 'auto' : 'white'
                }
                bgColor={
                  location.pathname !== button?.link ? 'auto' : 'brand.500'
                }
                borderRadius={8}
                width={'100%'}
              >
                <Box paddingRight={'15px'}>{button.icon}</Box>
                <Box
                  paddingRight={'15px'}
                  width={'80%'}
                  display={'flex'}
                  textAlign={'left'}
                >
                  {button.label}
                </Box>
                {button.subItem ? (
                  <Box display={'flex'}>
                    {button.open ? (
                      <FaChevronDown size={'12px'} color="gray0" />
                    ) : (
                      <FaChevronRight size={'12px'} color="gray" />
                    )}
                  </Box>
                ) : null}
              </Tab>
              {button.open
                ? button.subItem?.map((subitem, subitemI) => {
                    return (
                      <Tooltip label={subitem.tooltip}>
                        <Tab
                          data-testid={subitem.testId}
                          onClick={(e) => {
                            subitem.link && navigate(subitem.link);
                            subitem.onSubmit?.();
                            props.onCloseMenu?.();
                          }}
                          key={`${index}-${subitemI}`}
                          fontSize={13}
                          _selected={{ textColor: 'auto', bg: 'auto' }}
                          textColor={
                            location.pathname !== subitem?.link
                              ? 'auto'
                              : 'white'
                          }
                          bgColor={
                            location.pathname !== subitem?.link
                              ? 'auto'
                              : 'brand.500'
                          }
                          borderRadius={8}
                          width={'100%'}
                        >
                          {/* <Box>{subitem.icon}</Box> */}
                          <Box
                            width={'100%'}
                            ml={'20px'}
                            display={'flex'}
                            textAlign={'left'}
                          >
                            <Box>・</Box>
                            {subitem.label}
                          </Box>
                        </Tab>
                      </Tooltip>
                    );
                  })
                : null}
            </>
          );
        })}
      </Tabs>
    </>
  );
}
