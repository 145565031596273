import { FaCheck, FaInfo, FaTimes } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import { formatDate } from '../../../utils/functions/formatDate';
import { AcceptMessageModalComponent } from '../../UI/organisms/AcceptMessageModalComponent/AcceptMessageModalComponent';
import { showToast } from '../../../utils/showToast';
import {
  putDetachedPaymentApproveDisapprove,
  useDetachedPayment,
  useDetachedPaymentTotalizer,
} from '../../../services/DetachedPaymentService';
import { useParams } from 'react-router-dom';
import { useContractDetails } from '../../../services/ContractService';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import { CreateDetachedPaymentModalComponent } from '../../UI/organisms/CreateDetachedPaymentModalComponent/CreateDetachedPaymentModalComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { Totalizer } from '../../UI/molecules/Totalizer/Totalizer';
import { LabelInfoDetails, notInfo } from '../../../pages/MyProfilePage';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import { convertToMonetaryValue } from '../../../utils/functions/masks';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';

export default function DetachedPaymentTemplateComponent() {
  const toast = useToast();
  const params = useParams<{ contractId: string }>();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModal, setShowModal] = React.useState(false);
  const [pendingAnswer, setPendingAnswer] = React.useState<boolean | null>(
    null
  );
  const contractDetails = useContractDetails(params.contractId || '');

  const listDetachedPayment = useDetachedPayment(
    params.contractId ?? '',
    selectedPage,
    pageSize,
    '',
    '',
    'createdAt',
    'desc',
    pendingAnswer
  );
  const detachedPaymentValues = useDetachedPaymentTotalizer(
    params.contractId ?? '',
    selectedPage,
    pageSize,
    '',
    '',
    'createdAt',
    'desc',
    pendingAnswer
  );

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  const tabName = [
    {
      title: 'Todos',
      onClick: () => {
        setPendingAnswer(false);
      },
    },
    {
      title: 'Ainda não respondidos',
      onClick: () => {
        setPendingAnswer(true);
      },
    },
  ];

  const [approve, setApprove] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [showModalApprove, setShowModalApprove] = React.useState(false);
  const [showModalDetails, setShowModalDetails] = React.useState(false);
  useEffect(() => {}, [params.contractId]);
  const detachedPaymentDetails = listDetachedPayment.data?.data[selectedIndex];
  return (
    <>
      <HeaderTextComponent mb={2} goBack>
        Pagamentos Extras
        {params.contractId &&
          ` contrato: ${contractDetails.data?.serialNumber}`}
      </HeaderTextComponent>
      <Stack
        direction={'row'}
        paddingBottom={5}
        justifyContent={'space-between'}
      >
        <TabsFilterComponent tabList={tabName} />
        <ButtonComponent onSubmit={() => setShowModal(true)}>
          Novo Pagamento Extra
        </ButtonComponent>
      </Stack>
      <TableComponent
        ItemsHeader={[
          {
            item: 'Criado em',
          },
          {
            item: 'Criador',
          },
          {
            item: 'Descrição',
          },
          {
            item: 'Pessoa que deu a resposta',
          },
          {
            item: 'Resposta',
          },
          {
            item: 'Aprovado?',
          },
          { item: 'Ação' },
        ]}
        isLoading={listDetachedPayment.isLoading}
        centered={true}
        emptyState={listDetachedPayment.data?.metadata.dataSize === 0}
        data={
          listDetachedPayment.data?.data.map((e, i) => ({
            items: [
              formatDate(e.createdAt),
              e.personName,
              e.description,
              notInfo(e.anseweredByName, 'Ainda não respondido'),
              notInfo(e.answerDescription, 'Ainda não respondido'),
              notInfo(e.wasApproved ? 'Sim' : 'Não', 'Não'),
              <>
                <IconButtonComponent
                  toolTipText="Detalhes"
                  icon={<FaInfo />}
                  ariaLabel="Não permitir"
                  disabled={e.wasApproved}
                  onSubmit={() => {
                    setSelectedId(e.id);
                    setSelectedIndex(i);
                    setShowModalDetails(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaCheck />}
                  toolTipText="Aprovar"
                  marginX={'8px'}
                  ariaLabel="Permitir"
                  colorScheme={'green'}
                  disabled={e.wasApproved}
                  onSubmit={() => {
                    setApprove(true);
                    setSelectedId(e.id);
                    setShowModalApprove(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTimes />}
                  toolTipText="Reprovar"
                  ariaLabel="Não permitir"
                  colorScheme={'red'}
                  disabled={e.wasApproved}
                  onSubmit={() => {
                    setApprove(false);
                    setSelectedId(e.id);
                    setShowModalApprove(true);
                  }}
                />
              </>,
            ],
          })) ?? []
        }
      />

      <Totalizer
        currentPage={detachedPaymentValues?.data?.data?.currentPageValue ?? 0}
        total={detachedPaymentValues?.data?.data?.totalValue ?? 0}
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listDetachedPayment.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateDetachedPaymentModalComponent
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
        contractDetails={contractDetails.data}
        reloadData={() => listDetachedPayment.refetch()}
      />
      <AcceptMessageModalComponent
        approve={approve}
        showModal={showModalApprove}
        setShowModal={() => setShowModalApprove(false)}
        onAcceptModal={(description) => {
          putDetachedPaymentApproveDisapprove(
            selectedId,
            approve,
            description
          ).then((res) => {
            setShowModalApprove(false);
            listDetachedPayment.refetch();
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: `Pagamento ${
                approve ? 'Aprovado' : 'Reprovado'
              } com sucesso`,
            });
          });
        }}
      />
      <ModalStructureComponent
        isOpen={showModalDetails}
        onClose={() => setShowModalDetails(false)}
        title={'Detalhes'}
        children={
          <Flex flexDir={'column'}>
            <Flex>
              <LabelInfoDetails
                label="Criado em"
                details={formatDate(detachedPaymentDetails?.createdAt)}
              />
              <LabelInfoDetails
                label="Criador"
                details={detachedPaymentDetails?.personName}
              />
            </Flex>
            <Flex>
              <LabelInfoDetails
                label="Descrição"
                details={detachedPaymentDetails?.description}
              />
              <LabelInfoDetails
                label="Pessoa que respondeu
                "
                details={notInfo(
                  detachedPaymentDetails?.anseweredByName,
                  'Ainda não respondido'
                )}
              />
            </Flex>
            <Flex>
              <LabelInfoDetails
                label="Foi aprovado?"
                details={detachedPaymentDetails?.wasApproved ? 'Sim' : 'Não'}
              />
              <LabelInfoDetails
                label="Título
                "
                details={detachedPaymentDetails?.title}
              />
            </Flex>
            <Flex>
              <LabelInfoDetails
                label="Valor"
                details={convertToMonetaryValue(
                  detachedPaymentDetails?.value ?? 0
                )}
              />
              <LabelInfoDetails
                label="Data de pagamento"
                details={formatDate(detachedPaymentDetails?.paymentDate)}
              />
            </Flex>
            <Flex flexDir={'column'}>
              <HeaderTextComponent mb={2} fontSize={18}>
                Locadores
              </HeaderTextComponent>
              <TableComponent
                ItemsHeader={[{ item: 'Nome' }]}
                data={
                  detachedPaymentDetails?.locators.map((e) => ({
                    items: [<AvatarLabelComponent label={e.locatorName} />],
                  })) || []
                }
                isLoading={false}
              />
            </Flex>
          </Flex>
        }
        size={'xl'}
      />
    </>
  );
}
