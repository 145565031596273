import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { Flex, useToast } from '@chakra-ui/react';
import InputComponent from '../../UI/atoms/InputComponent/InputComponent';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import React, { useState } from 'react';
import { phoneMask } from '../../../utils/functions/masks';
import InputCheckComponent from '../../UI/atoms/InputCheckComponent/InputCheckComponent';
import { ContactsType, useContacts } from '../../../services/ContactService';
import { showToast } from '../../../utils/showToast';
import { useUserData } from '../../../services/LoginService';
import {
  EntitiesByIdResp,
  putMiddlewareEntities,
  useEntitiesById,
  useEntityAttorney,
} from '../../../services/EntitiesService';
import { Card } from 'react-bootstrap';
import DropzoneModel from '../../UI/organisms/uploadModel/DropZoneModel';
import TextComponent from '../../UI/atoms/TextComponent/TextComponent';
import { SelectAttorneyModalComponent } from '../../UI/organisms/SelectAttorneyModalComponent/SelectAttorneyModalComponent';
import { LocatorResp } from '../../../services/LocatorService';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { FaTrash } from 'react-icons/fa';

const defaultFormContact = { phone: '', email: '', whatsapp: '' };
const defaultFormEntity: EntitiesByIdResp = {
  name: '',
  document: '',
  entityImage: '',
  contract: {
    id: '',
    name: '',
    description: '',
    startDate: '',
    endDate: '',
  },
  franchiseId: '',
  modulePermissions: [],
  address: {
    street: '',
    district: '',
    complement: '',
    zipcode: '',
    number: '',
    type: 0,
    cityId: '',
    cityName: '',
    stateId: '',
    stateName: '',
  },
};

export default function ConfigTemplateComponent() {
  const toast = useToast();
  const contacts = useContacts();
  const entityAttorney = useEntityAttorney();

  const user = useUserData();
  const tenantId = user?.entity?.id;
  const entityById = useEntitiesById(tenantId);
  const [formContact, setFormContact] = React.useState(defaultFormContact);
  const [formEntity, setFormEntity] = React.useState(defaultFormEntity);
  const [link, setLink] = React.useState('');
  const [type, setType] = React.useState<ContactsType>(ContactsType.General);
  const [isLoading, setIsLoading] = React.useState(false);
  const [EntityUrl, setEntityUrl] = React.useState('');
  const [showModalSelectAttorney, setShowModalSelectAttorney] =
    React.useState(false);
  const [selectedAttorney, setSelectedAttorney] = useState<
    LocatorResp | undefined
  >(undefined);


  React.useEffect(() => {
    if (entityAttorney.data) {
      setSelectedAttorney(entityAttorney.data);
    }
  }, [entityAttorney.data])

  React.useEffect(() => {
    if (entityById.data) {
      setEntityUrl(entityById.data?.entityImage ?? '');
      setFormEntity(entityById.data);
    }
    if (contacts.data) {
      setFormContact({
        phone: phoneMask(contacts.data.phone),
        email: contacts.data.email,
        whatsapp: phoneMask(contacts.data.whatsapp),
      });
      setLink(contacts.data.link);
      if (contacts.data.link) setType(1);
    }
  }, [contacts.data, entityById.data]);

  return (
    <>
      <HeaderTextComponent goBack>{'Configurações'}</HeaderTextComponent>
      <Flex mt={8} justifyContent={'center'}>
        <Card style={{ padding: 20, width: '90%' }}>
          <Flex flexDirection={'column'}>
            <HeaderTextComponent mb={4} subTitle>
              Parametrização Tela de Contato
            </HeaderTextComponent>
            <Flex mb={4}>
              <InputCheckComponent
                isChecked={type === ContactsType.General}
                onChange={() => setType(0)}
              >
                Telefone, Email e Whatsapp
              </InputCheckComponent>
              <InputCheckComponent
                m={'0 0 0 100px'}
                isChecked={type === ContactsType.Link}
                onChange={() => setType(1)}
              >
                Link
              </InputCheckComponent>
            </Flex>
            {type === ContactsType.General ? (
              <Flex>
                <InputComponent
                  label="Telefone"
                  placeholder="Digite o telefone"
                  value={formContact.phone}
                  onChange={(input) =>
                    setFormContact({
                      ...formContact,
                      phone: phoneMask(input.target.value),
                    })
                  }
                />

                <InputComponent
                  margin={'0 20px 0 20px'}
                  label="Email"
                  placeholder="Email"
                  value={formContact.email}
                  onChange={(input) =>
                    setFormContact({
                      ...formContact,
                      email: input.target.value,
                    })
                  }
                />
                <InputComponent
                  label="Whatsapp"
                  placeholder="Digite o Whatsapp"
                  value={formContact.whatsapp}
                  onChange={(input) =>
                    setFormContact({
                      ...formContact,
                      whatsapp: phoneMask(input.target.value),
                    })
                  }
                />
              </Flex>
            ) : (
              <InputComponent
                label="Link"
                placeholder="Digite o link"
                value={link}
                onChange={(input) => setLink(input.target.value)}
              />
            )}
          </Flex>

          <Flex mt={20} flexDir={'column'}>
            <HeaderTextComponent mb={4} subTitle>
              Dados da operadora
            </HeaderTextComponent>
            <Flex mb={4}>
              <Flex flexDir={'column'}>
                <TextComponent fontWeight={'medium'}>Imagem</TextComponent>
                <DropzoneModel
                  fileUrl={EntityUrl}
                  setProfilePicUrl={setEntityUrl}
                  type="profile"
                />
              </Flex>
              <Flex flexDir={'column'} ml={14} mt={8}>
                <Flex>
                <ButtonComponent
                  onSubmit={() => {
                    setShowModalSelectAttorney(true);
                  }}
                >
                  Adicionar procurador
                </ButtonComponent>
                </Flex>
                {selectedAttorney && (
                  <Flex alignItems={'center'} mt={8}>
                    <AvatarLabelComponent
                      size="md"
                      subLabel="Procurador"
                      label={selectedAttorney?.name}
                    />

                    <IconButtonComponent
                      icon={<FaTrash />}
                      marginX={4}
                      toolTipText="Remover procurador"
                      ariaLabel="Delet"
                      colorScheme={'red'}
                      onSubmit={() => {setSelectedAttorney(undefined)}}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex>
              <InputComponent
                label="Nome"
                placeholder="Digite o nome "
                value={formEntity.name}
                onChange={(input) =>
                  setFormEntity({
                    ...formEntity,
                    name: input.target.value,
                  })
                }
              />
              <InputComponent
                margin={'0 20px 0 20px'}
                label="CNPJ"
                placeholder="Digite o CNPJ"
                value={formEntity.document}
                onChange={(input) =>
                  setFormEntity({
                    ...formEntity,
                    document: input.target.value,
                  })
                }
              />
            </Flex>
          </Flex>
          <Flex justifyContent={'flex-end'} mt={10}>
            <ButtonComponent
              isLoading={isLoading}
              disabled={isLoading}
              colorScheme="blue"
              variant="solid"
              onSubmit={() => {
                setIsLoading(true);
                putMiddlewareEntities({
                  ...formEntity,
                  entityImage: EntityUrl,
                  attorneyId: selectedAttorney?.personId,
                  modulePermissions: formEntity.modulePermissions?.map(
                    (i) => i.permissionName
                  ),
                  contacts: {
                    ...formContact,
                    link: type === ContactsType.General ? '' : link,
                  },
                })
                  .then(() => {
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description: 'Salvo com sucesso',
                    });
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              Salvar
            </ButtonComponent>
          </Flex>
        </Card>
      </Flex>
      <SelectAttorneyModalComponent
        showModal={showModalSelectAttorney}
        setShowModal={() => setShowModalSelectAttorney(false)}
        selectedAttorney={selectedAttorney}
        setSelectedAttorney={setSelectedAttorney}
      />
    </>
  );
}
