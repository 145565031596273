import { Flex, Stack, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import {
  ContractFieldsResp,
  ContractUpdateMany,
  contractFieldsUpdateMany,
  useContractFields,
} from '../../../../services/ContractFieldsService';
import React from 'react';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import { showToast } from '../../../../utils/showToast';

type ContractConfigModalProps = {
  showModal: boolean;
  setShowModal: () => void;
};

export default function ContractConfigModalComponent(
  props: ContractConfigModalProps
) {
  const toast = useToast();
  const listContractFields = useContractFields();
  const [list, setList] = React.useState<ContractFieldsResp[]>([]);
  const [sendList, setSendList] = React.useState<ContractUpdateMany[]>([]);
  React.useEffect(() => {
    if (listContractFields.data) {
      setList(listContractFields.data.data);
    }
  }, [listContractFields.data]);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Configurações`}
      maxW="1200px"
      maxH="85%"
      overflowX={'auto'}
      size="xl"
    >
      <HeaderTextComponent fontSize="20px">
        Campos que serão mostrados no aplicativo
      </HeaderTextComponent>
      <Stack direction={'row'} paddingBottom={5} paddingTop={5}>
        <Flex direction={'row'} width={'100%'} wrap={'wrap'}>
          {list.map((item, i) => (
            <InputCheckComponent
              m="5px"
              w={'24%'}
              key={i}
              isChecked={item.isEnable}
              onChange={() => {
                setList(
                  list?.map((changedField) =>
                    changedField.id === item.id
                      ? { ...changedField, isEnable: !changedField.isEnable }
                      : changedField
                  )
                );
                setSendList((send) => {
                  if (send.some((sendItem) => sendItem.id === item.id)) {
                    return send.map((changedField) =>
                      changedField.id === item.id
                        ? { ...changedField, isEnable: !changedField.isEnable }
                        : changedField
                    );
                  }
                  return [...send, { id: item.id, isEnable: !item.isEnable }];
                });
              }}
            >
              <TextComponent lineHeight="15px">{item.field}</TextComponent>
            </InputCheckComponent>
          ))}
        </Flex>
      </Stack>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setList(listContractFields.data?.data ?? []);
            setSendList([]);
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          disabled={!list || list.length === 0}
          onSubmit={() => {
            contractFieldsUpdateMany(sendList).then((res) => {
              showToast({
                toast,
                status: 'success',
                title: 'Sucesso',
                description: 'Salvo com sucesso',
              });
              setSendList([]);
            });
            props.setShowModal();
          }}
        >
          Salvar
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
