import { Flex } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { FaEye } from 'react-icons/fa';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import { AddIcon } from '@chakra-ui/icons';
import { brand } from '../../../../theme';

type HeaderChatComponentProps = {
  style?: CSSProperties;
  onSubmit: () => void;
  name?: string;
  onPressShowModal: () => void;
};

export function HeaderChatComponent(props: HeaderChatComponentProps) {
  return (
    <Flex
      background={'brand.500'}
      borderRadius={8}
      justifyContent={'space-between'}
      padding={3}
      width={'100%'}
      marginTop={'10px'}
      style={props.style}
      alignItems={'center'}
    >
      <AvatarLabelComponent
        textColor="white"
        label={props.name}
        subLabel="Usuário"
        size={'md'}
      ></AvatarLabelComponent>

      <Flex>
        <IconButtonComponent
          icon={<AddIcon color={brand[500]} />}
          ariaLabel="info"
          colorScheme={'whiteAlpha'}
          backgroundColor="#fff"
          marginX={2}
          toolTipText="Adicionar observadores"
          onSubmit={() => props.onPressShowModal()}
        />
        <ButtonComponent
          colorScheme={'whiteAlpha'}
          backgroundColor="#fff"
          textColor={'#3182CE'}
          onSubmit={() => props.onSubmit()}
          leftIcon={<FaEye />}
        >
          Observadores
        </ButtonComponent>
      </Flex>
    </Flex>
  );
}
