import { FaEdit, FaInfo, FaRegTimesCircle, FaTrash } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import { DescriptionLabelComponent } from '../../UI/molecules/DescriptionlabelComponent/DescriptionLabelComponent';
import React, { useState } from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import DetailsModalComponent from '../../UI/molecules/DetailsModalComponent/DetailsModalComponent';
import { parseBrazilDate } from '../../../utils/functions/utility';
import { useUserData } from '../../../services/LoginService';
import {
  EngineeringTypeResp,
  StatusEngineeringType,
  deleteEngineeringType,
  putEngineeringDisable,
  putEngineeringTypeActive,
  saveEngineeringType,
  useEngineeringType,
} from '../../../services/EngineeringTypeService';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import InputComponent from '../../UI/atoms/InputComponent/InputComponent';
import { showToast } from '../../../utils/showToast';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import { CheckCircleIcon } from '@chakra-ui/icons';

const defaultForm = {
  id: '',
  description: '',
  serialNumber: '',
  status: 0,
  hasActiveContract: false,
  createdAt: '',
  statusDescriptions: '',
};
export default function EngineeringTypeTemplateComponent() {
  const [selectedEngineeringType, setSelectedEngineeringType] = React.useState<
    EngineeringTypeResp | undefined
  >();

  const user = useUserData();
  const modalCardInfo = [
    { item: 'Número', description: selectedEngineeringType?.serialNumber },
    { item: 'Descrição', description: selectedEngineeringType?.description },
    {
      item: 'Criado em',
      description: parseBrazilDate(selectedEngineeringType?.createdAt),
    },
    {
      item: 'Status',
      description: selectedEngineeringType?.statusDescriptions,
    },
    {
      item: 'Contrato Ativo?',
      description: selectedEngineeringType?.hasActiveContract ? 'Sim' : 'Não',
    },
  ];
  const toast = useToast();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [engineerStatus, setEngineerStatus] =
    React.useState<StatusEngineeringType>(StatusEngineeringType.Active);
  const listEngineeringTypes = useEngineeringType(
    selectedPage,
    pageSize,
    '',
    '',
    '',
    'asc',
    engineerStatus
  );
  const [detailsDialog, setDetailsDialog] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const [form, setForm] = React.useState<EngineeringTypeResp>(defaultForm);
  React.useEffect(() => {
    if (selectedEngineeringType)
      setForm({
        ...selectedEngineeringType,
      });
    else setForm(defaultForm);
  }, [selectedEngineeringType]);
  const tabName = [
    {
      title: 'Ativas',
      onClick: () => {
        setEngineerStatus(StatusEngineeringType.Active);
      },
    },
    {
      title: 'Desabilitadas',
      onClick: () => {
        setEngineerStatus(StatusEngineeringType.Inactive);
      },
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Stack mb={4}>
        <HeaderTextComponent goBack>Tipos de engenharia</HeaderTextComponent>
      </Stack>
      <Flex mb={2} justifyContent={'space-between'}>
        <Flex>
          <TabsFilterComponent tabList={tabName} />
        </Flex>
        <Flex>
          <ButtonComponent
            onSubmit={() => {
              setShowModal(true);
              setSelectedEngineeringType(defaultForm);
            }}
          >
            Novo Cadastro
          </ButtonComponent>
        </Flex>
      </Flex>

      <TableComponent
        ItemsHeader={[
          { item: 'Descrição' },
          { item: 'Proprietário' },
          { item: 'Contrato Vinculado' },

          { item: 'Ação' },
        ]}
        isLoading={listEngineeringTypes.isLoading}
        emptyState={listEngineeringTypes.data?.data.length === 0}
        centered={true}
        data={
          listEngineeringTypes.data?.data.map((e, i) => ({
            items: [
              <DescriptionLabelComponent
                label={e.description}
                subLabel={e.serialNumber}
                key={i}
              />,
              user?.entity?.name,
              e.hasActiveContract ? (
                <TagComponent size={'md'} colorScheme={'green'} text="Sim" />
              ) : (
                <TagComponent size={'md'} colorScheme={'red'} text="Não" />
              ),

              <>
                <IconButtonComponent
                  icon={
                    e.status === StatusEngineeringType.Active ? (
                      <FaRegTimesCircle color="white" />
                    ) : (
                      <CheckCircleIcon />
                    )
                  }
                  toolTipText={
                    e.status === StatusEngineeringType.Active
                      ? 'Desabilitar'
                      : 'Ativar'
                  }
                  ariaLabel="Disabled"
                  colorScheme={
                    e.status === StatusEngineeringType.Active
                      ? 'yellow'
                      : 'green'
                  }
                  onSubmit={() => {
                    e.status === StatusEngineeringType.Active
                      ? putEngineeringDisable(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description:
                              'Tipo de engenharia desabilitada com sucesso',
                          });
                          listEngineeringTypes.refetch();
                        })
                      : putEngineeringTypeActive(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description:
                              'Tipo de engenharia ativada com sucesso',
                          });
                          listEngineeringTypes.refetch();
                        });
                  }}
                />
                <IconButtonComponent
                  icon={<FaInfo />}
                  marginX={2}
                  ariaLabel="info"
                  toolTipText="Detalhes"
                  backgroundColor="#3182CE"
                  onSubmit={() => {
                    setSelectedEngineeringType(e);
                    setDetailsDialog(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  onSubmit={() => {
                    setSelectedEngineeringType(e);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTrash />}
                  ariaLabel="Delet"
                  toolTipText="Deletar"
                  colorScheme="red"
                  marginX={2}
                  disabled={e.hasActiveContract}
                  onSubmit={() => {
                    setSelectedEngineeringType(e);
                    deleteEngineeringType({ ...form, id: e.id }).then(() => {
                      listEngineeringTypes.refetch();
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description: 'Tipo de engenharia excluída com sucesso',
                      });
                    });
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listEngineeringTypes.data?.metadata?.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <DetailsModalComponent
        isOpen={detailsDialog}
        title={'Tipo de engenharia'}
        onClose={() => setDetailsDialog(false)}
        data={modalCardInfo}
      />
      <ModalStructureComponent
        title={
          selectedEngineeringType?.id ? 'Editar Cadastro' : 'Novo Cadastro'
        }
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        size="md"
        children={
          <Flex>
            <InputComponent
              label="Descrição"
              placeholder='Ex: "Engenharia de Software"'
              value={form.description}
              onChange={(input) => {
                setForm({ ...form, description: input.target.value });
              }}
            />
          </Flex>
        }
        footer={
          <Flex p={4}>
            <ButtonComponent
              margin={'0px 10px 0px 0px'}
              variant={'outline'}
              onSubmit={() => {
                setShowModal(false);
              }}
            >
              Cancelar
            </ButtonComponent>
            <ButtonComponent
              isLoading={isLoading}
              disabled={isLoading}
              onSubmit={() => {
                setIsLoading(true);
                saveEngineeringType(form)
                  .then(() => {
                    listEngineeringTypes.refetch();
                    setShowModal(false);
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description: 'Tipo de engenharia salva com sucesso',
                    });
                    setForm(defaultForm);
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              Salvar
            </ButtonComponent>
          </Flex>
        }
      />
    </>
  );
}
