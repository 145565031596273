import { Flex, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  saveTechnology,
  Technology,
  TypeTechnology,
} from '../../../../services/TechnologyService';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';

export function CreateTechnologyModalComponent(props: {
  showModal: boolean;
  selectedTechnology?: Technology;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      description: '',
      type: 0,
    }),
    []
  );
  const [form, setForm] = React.useState<Technology>(defaultForm);
  React.useEffect(() => {
    if (props.selectedTechnology)
      setForm({
        ...props.selectedTechnology,
      });
    else setForm(defaultForm);
  }, [props.selectedTechnology, defaultForm]);
  const [isLoading, setIsloading] = useState(false);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={props.selectedTechnology ? 'Editar Cadastro' : `Novo Cadastro`}
      maxW="1200px"
      size="xl"
    >
      <Text
        fontSize={'2xl'}
        fontWeight={'light'}
        color="gray.500"
        marginBottom={7}
      >
        O sistema gera um campo de ID automático para as tecnologias a partir do
        cadastro. O campo proprietário é preenchido automaticamente com o nome
        da empresa do usuário logado.
      </Text>
      <Flex mb={5} flexDirection={'row'}>
        <InputComponent
          label="Descrição"
          margin={'0 4% 0 0'}
          w={'48%'}
          value={form.description}
          onChange={(input) => {
            setForm({ ...form, description: input.target.value });
          }}
        />

        <Flex direction={'column'} w={'48%'}>
          <InputSelectComponent
            defaultValue={String(form.type)}
            onChange={(input) => {
              setForm({ ...form, type: Number(input.target.value) });
            }}
            options={[
              {
                id: String(TypeTechnology.C5G),
                name: '5G',
              },
              {
                id: String(TypeTechnology.C4G),
                name: '4G',
              },
              {
                id: String(TypeTechnology.C3G),
                name: '3G',
              },
              {
                id: String(TypeTechnology.Radio),
                name: 'Radio',
              },
              {
                id: String(TypeTechnology.Fiber),
                name: 'Fibra',
              },
              {
                id: String(TypeTechnology.Central),
                name: 'Central',
              },
            ]}
            placeholder="Selecione o tipo"
            label="Tipo"
          />
        </Flex>
      </Flex>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setForm(defaultForm);
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          isLoading={isLoading}
          disabled={isLoading}
          onSubmit={() => {
            setIsloading(true);
            saveTechnology(form)
              .then((res) => {
                setForm(defaultForm);
                props.setShowModal();
                props.reloadData?.();
                showToast({
                  toast,
                  status: 'success',
                  title: 'Sucesso',
                  description: 'Tecnologia salva com sucesso',
                });
              })
              .finally(() => setIsloading(false));
          }}
        >
          {props.selectedTechnology ? 'Salvar' : 'Criar Tecnologia'}
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
