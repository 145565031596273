import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ContainerComponent from '../../atoms/ContainerComponent/ContainerComponent';
import { CSSProperties, useState } from 'react';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { Box, Flex, Stack, useToast } from '@chakra-ui/react';
import {
  ConfirmCode,
  postRecoverPassword,
  postSetPassword,
  postValidate,
} from '../../../../services/LoginService';
import { showToast } from '../../../../utils/showToast';
import LinkComponent from '../../atoms/LinkComponent/LinkComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';

export type RegisterFormProps = {
  style?: CSSProperties;
  email: string;
  type: 'first' | 'forgot';
};

export default function ChangePasswordFormComponent(props: RegisterFormProps) {
  const toast = useToast();
  const [tokenScreen, setTokenScreen] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [setPasswordToken, setSetPasswordToken] = useState('');
  const [setPasswordTokenConfirm, setSetPasswordTokenConfirm] = useState('');

  function validateAndForgot(type: 'first' | 'forgot') {
    return type === 'first'
      ? postValidate({ email: props.email })
      : postRecoverPassword({ email: props.email });
  }

  function validToken() {
    if (!setPasswordToken || setPasswordToken !== setPasswordTokenConfirm)
      return false;
    return true;
  }

  function validPassword() {
    if (!password || password !== passwordConfirm) return false;
    return true;
  }

  const username = props.email;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRecover, setLoadingRecover] = useState(false);
  const location = useLocation();

  return (
    <>
      {tokenScreen && (
        <>
          <Flex
            w={600}
            mb={10}
            justifyContent={'center'}
            alignItems={'center'}
            flexDir={'column'}
          >
            <HeaderTextComponent mb={10} textAlign={'center'} subTitle>
              Digite o token enviado ao seu email
            </HeaderTextComponent>
            <HeaderTextComponent fontSize={14} goBack subTitle>
              <TextComponent mr={2} as={'b'} color={'brand.500'}>
                {props.email}
              </TextComponent>
              Por favor, confirme se o seu endereço de e-mail está correto. Caso
              contrário, clique em 'Voltar' para ajustar o e-mail
            </HeaderTextComponent>
          </Flex>
        </>
      )}
      <ContainerComponent style={props.style}>
        {tokenScreen ? (
          <Box>
            <Stack fontWeight={'500'} fontSize={'16px'} spacing={3}>
              <InputComponent
                borderRadius={15}
                autoFocus
                id="token"
                label={'Token'}
                type={'password'}
                value={setPasswordToken}
                onChange={(e) => setSetPasswordToken(e.target.value)}
                placeholder={'Digite o token'}
              />
              <InputComponent
                borderRadius={15}
                id="token"
                label={'Confirmar Token'}
                type={'password'}
                value={setPasswordTokenConfirm}
                onChange={(e) => setSetPasswordTokenConfirm(e.target.value)}
                placeholder={'Confirme o token'}
                error={!validToken() ? 'Confirme os dois campos' : undefined}
              />
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                flexDir={'row'}
              >
                <TextComponent mr={2} fontWeight={600} fontSize={'14px'}>
                  Não recebeu o token?
                </TextComponent>
                <Flex
                  cursor={'pointer'}
                  onClick={() => {
                    setLoadingRecover(true);
                    const type = location.pathname.includes('first')
                      ? 'first'
                      : 'forgot';
                    validateAndForgot(type)
                      .then((res) => {
                        showToast({
                          toast,
                          status: 'success',
                          title: 'Sucesso',
                          description: 'Verifique seu email',
                        });
                      })
                      .catch((err) => {
                        console.error(err);
                        showToast({
                          toast,
                          status: 'error',
                          title: 'Erro',
                          description: 'Email não cadastrado',
                        });
                      })
                      .finally(() => setLoadingRecover(false));
                  }}
                >
                  <TextComponent fontWeight={'medium'} color={'brand.500'}>
                    {loadingRecover ? 'Enviando...' : 'Reenviar'}
                  </TextComponent>
                </Flex>
              </Flex>
            </Stack>
            <Flex justifyContent={'center'}>
              <ButtonComponent
                isLoading={isLoading}
                disabled={!validToken() || isLoading}
                style={{ marginTop: 35 }}
                width={275}
                onSubmit={async () => {
                  setIsLoading(true);
                  try {
                    const result = await ConfirmCode(
                      props.email || '',
                      setPasswordTokenConfirm
                    );
                    const confirmPasswordRecoveryCode =
                      result.data.confirmPasswordRecoveryCode;
                    if (confirmPasswordRecoveryCode) {
                      setTokenScreen(false);
                    } else {
                      showToast({
                        toast,
                        status: 'error',
                        title: 'Error',
                        description: 'Token inválido',
                      });
                    }
                  } catch {
                    showToast({
                      toast,
                      status: 'error',
                      title: 'Error',
                      description: 'Token não válido',
                    });
                  } finally {
                    setIsLoading(false);
                  }
                }}
              >
                Avançar
              </ButtonComponent>
            </Flex>
          </Box>
        ) : (
          <Box>
            <Flex justifyContent={'center'}>
              <HeaderTextComponent mb={10} textAlign={'center'} subTitle>
                Crie sua senha
              </HeaderTextComponent>
            </Flex>

            <Stack fontWeight={'500'} fontSize={'16px'} spacing={3}>
              <InputComponent
                borderRadius={15}
                id="password"
                label={'Senha'}
                type={'Password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={'Digite a senha'}
              />
              <InputComponent
                borderRadius={15}
                id="password"
                label={'Confirmar Senha'}
                type={'Password'}
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                placeholder={'Confirme a senha'}
                error={!validPassword() ? 'Confirme os dois campos' : undefined}
              />
            </Stack>
            <ButtonComponent
              disabled={!validPassword()}
              style={{ marginTop: 35 }}
              width={275}
              onSubmit={() => {
                postSetPassword({ password, username, setPasswordToken }).then(
                  (res) => {
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description: 'Senha alterada com sucesso, tente Logar',
                    });
                    navigate('/login');
                  }
                );
              }}
            >
              Confirmar
            </ButtonComponent>
          </Box>
        )}
      </ContainerComponent>
      <TextComponent fontWeight={600} fontSize={'12px'}>
        Ja criou sua conta?
        <LinkComponent color={'brand.500'} to={'/login'}>
          {' '}
          Faça login
        </LinkComponent>
      </TextComponent>
    </>
  );
}
