import { Flex, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  ClauseResp,
  TypeClause,
  saveClause,
} from '../../../../services/ClauseService';

export function CreateClauseModalComponent(props: {
  showModal: boolean;
  selectedClause?: ClauseResp;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      description: '',
      title: '',
      type: 0,
    }),
    []
  );
  const [form, setForm] = React.useState<ClauseResp>(defaultForm);
  React.useEffect(() => {
    if (props.selectedClause)
      setForm({
        ...props.selectedClause,
      });
    else setForm(defaultForm);
  }, [props.selectedClause, defaultForm]);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={props.selectedClause ? 'Editar Cadastro' : `Novo Cadastro`}
      maxW="1200px"
      size="xl"
    >
      <Text
        fontSize={'2xl'}
        fontWeight={'light'}
        color="gray.500"
        marginBottom={7}
      >
        Informações da Cláusula
      </Text>
      <Flex mb={5} flexDirection={'row'}>
        <Flex direction={'column'} w={'48%'}>
          <InputSelectComponent
            defaultValue={String(form.type)}
            onChange={(input) => {
              setForm({ ...form, type: Number(input.target.value) });
            }}
            options={[
              {
                id: String(TypeClause.General),
                name: 'Geral',
              },
              {
                id: String(TypeClause.Renovation),
                name: 'Renovação',
              },
              {
                id: String(TypeClause.Closing),
                name: 'Encerramento/Distrato',
              },
            ]}
            placeholder="Selecione o tipo"
            label="Tipo"
          />
        </Flex>
        <Flex margin={'0 0 0 4%'} w={'100%'}>
          <InputComponent
            label="Título"
            w={'48%'}
            value={form.title}
            onChange={(input) => {
              setForm({ ...form, title: input.target.value });
            }}
          />
        </Flex>
      </Flex>
      <Flex mb={5} flexDirection={'row'}>
        <InputComponent
          label="Texto"
          type="textArea"
          margin={'0 4% 0 0'}
          w={'48%'}
          value={form.description}
          onChange={(input) => {
            setForm({ ...form, description: input.target.value });
          }}
        />
      </Flex>
      <Flex mb={5} justifyContent={'flex-end'}>
        <ButtonComponent
          margin={'0 8px 0 0'}
          variant={'outline'}
          onSubmit={() => {
            setForm(defaultForm);
            props.setShowModal();
          }}
        >
          Cancelar
        </ButtonComponent>
        <ButtonComponent
          isLoading={isLoading}
          disabled={isLoading}
          onSubmit={() => {
            setIsLoading(true);
            saveClause(form)
              .then((res) => {
                props.setShowModal();
                props.reloadData?.();
                showToast({
                  toast,
                  status: 'success',
                  title: 'Sucesso',
                  description: 'Cláusula salva com sucesso',
                });
                setForm(defaultForm);
              })
              .finally(() => setIsLoading(false));
          }}
        >
          {props.selectedClause ? 'Salvar' : 'Criar Cláusula'}
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
