import { FaCheck, FaInfo, FaTimes } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, Stack, Tag, Tooltip, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import { SortDirection } from '../../../services/PhysicalPersonService';
import {
  StatusBatchChanges,
  putBatchChangesApproveDisapprove,
  useBatchChanges,
} from '../../../services/BatchChangesService';
import { formatDate } from '../../../utils/functions/formatDate';
import { AcceptMessageModalComponent } from '../../UI/organisms/AcceptMessageModalComponent/AcceptMessageModalComponent';
import { showToast } from '../../../utils/showToast';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import { moneyMask } from '../../../utils/functions/masks';
import { LabelInfoDetails } from '../../../pages/MyProfilePage';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import { useNavigate } from 'react-router-dom';

import { StatusColorByTypeContract } from '../ContractTemplateComponent/ContractTemplateComponent';

export default function BatchChangesListTemplateComponent() {
  const toast = useToast();
  const navigation = useNavigate();
  const [indexChange, setIndexChange] = React.useState<number>(0);
  const [showModal, setShowModal] = React.useState(false);
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>('desc');
  const [sort, setSort] = React.useState<string>('createdAt');
  const [status, setStatus] = useState<StatusBatchChanges | undefined>(
    undefined
  );
  const listBatchChanges = useBatchChanges(
    selectedPage,
    pageSize,
    '',
    '',
    sort,
    sortDirection,
    status
  );
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  const tabName = [
    {
      title: 'Todos',
      onClick: () => {
        setStatus(undefined);
      },
    },
    {
      title: 'Pendentes',
      onClick: () => {
        setStatus(StatusBatchChanges.pending);
      },
    },
    {
      title: 'Aprovados',
      onClick: () => {
        setStatus(StatusBatchChanges.approved);
      },
    },
    {
      title: 'Reprovados',
      onClick: () => {
        setStatus(StatusBatchChanges.rejected);
      },
    },
  ];

  const [approve, setApprove] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [showModalApprove, setShowModalApprove] = React.useState(false);
  function getBgColorStatus(status: StatusBatchChanges | undefined) {
    if (status === StatusBatchChanges.approved) {
      return 'green';
    }
    if (status === StatusBatchChanges.pending) {
      return 'yellow';
    }
    if (status === StatusBatchChanges.rejected) {
      return 'red';
    }
  }
  const detailsChange = listBatchChanges?.data?.data[indexChange]?.changes;

  return (
    <>
      <Stack paddingBottom={5} justifyContent={'space-between'}>
        <HeaderTextComponent goBack>
          Lista de alterações em lote
        </HeaderTextComponent>
      </Stack>
      <Stack
        direction={'row'}
        paddingBottom={5}
        justifyContent={'space-between'}
      >
        <TabsFilterComponent tabList={tabName} />
        <ButtonComponent
          margin={'0 10px 0 0'}
          onSubmit={() => navigation(`/batch-changes`)}
        >
          Nova alteração
        </ButtonComponent>
      </Stack>
      <TableComponent
        ItemsHeader={[
          {
            item: 'Criado em',
          },
          {
            item: 'Criador',
          },
          {
            item: 'Descrição',
          },
          {
            item: 'Pessoa que deu a resposta',
          },
          {
            item: 'Resposta',
          },
          {
            item: 'status',
          },
          { item: 'Ação' },
        ]}
        isLoading={listBatchChanges.isLoading}
        centered={true}
        emptyState={listBatchChanges.data?.metadata.dataSize === 0}
        sorting={{ sortColumn: sort, direction: sortDirection }}
        onSortingChanged={(sort, dir) => {
          setSort(sort);
          setSortDirection(dir);
        }}
        data={
          listBatchChanges.data?.data.map((e, i) => ({
            items: [
              formatDate(e.createdAt),
              e.personName,
              e.description,
              e.answeredByName,
              e.answerDescription,
              <Tooltip
                label={
                  e.status !== 0 &&
                  `Data de ${
                    e.status === 1 ? 'Aprovação' : 'Reprovação'
                  } ${formatDate(e.answeredAt)}`
                }
              >
                <Tag colorScheme={getBgColorStatus(e.status ?? undefined)}>
                  {e.statusDescription}
                </Tag>
              </Tooltip>,
              <>
                <IconButtonComponent
                  toolTipText={'Detalhes'}
                  icon={<FaInfo />}
                  ariaLabel="Detalhes"
                  colorScheme={'blue'}
                  onSubmit={() => {
                    setIndexChange(i);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  disabled={!e.canBeAnsweredAgain}
                  toolTipText={
                    e.canBeAnsweredAgain ? 'Aprovar' : 'Já respondido'
                  }
                  icon={<FaCheck />}
                  marginX={'8px'}
                  ariaLabel="Permitir"
                  colorScheme={'green'}
                  onSubmit={() => {
                    setApprove(true);
                    setSelectedId(e.id);
                    setShowModalApprove(true);
                  }}
                />
                <IconButtonComponent
                  toolTipText={
                    e.canBeAnsweredAgain ? 'Reprovar' : 'Já respondido'
                  }
                  disabled={!e.canBeAnsweredAgain}
                  icon={<FaTimes />}
                  ariaLabel="Não permitir"
                  colorScheme={'red'}
                  onSubmit={() => {
                    setApprove(false);
                    setSelectedId(e.id);
                    setShowModalApprove(true);
                  }}
                />
              </>,
            ],
          })) ?? []
        }
      />

      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listBatchChanges.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <AcceptMessageModalComponent
        approve={approve}
        showModal={showModalApprove}
        setShowModal={() => setShowModalApprove(false)}
        onAcceptModal={(description) => {
          putBatchChangesApproveDisapprove(
            selectedId,
            approve,
            description
          ).then((res) => {
            setShowModalApprove(false);
            listBatchChanges.refetch();
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: `Mudanças ${
                approve ? 'Aprovadas' : 'Reprovadas'
              } com sucesso`,
            });
          });
        }}
      />
      <ModalStructureComponent
        title={'Detalhes da alteração em lote'}
        size={'lg'}
        maxW="90%"
        maxH="80%"
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        isCentered={true}
      >
        <Flex flexDir={'column'}>
          <Flex>
            <LabelInfoDetails
              label="Data de ativação"
              notMessage="Não alterado"
              details={formatDate(detailsChange?.activationDate)}
            />
            <LabelInfoDetails
              label="Data de Vencimento"
              notMessage="Não alterado"
              details={formatDate(detailsChange?.expireDate)}
            />
            <LabelInfoDetails
              label="Data base reajuste"
              notMessage="Não alterado"
              details={formatDate(detailsChange?.baseReadjustmentDate)}
            />
            <LabelInfoDetails
              label="Data de início"
              notMessage="Não alterado"
              details={formatDate(detailsChange?.startDate)}
            />
          </Flex>
          <Flex>
            <LabelInfoDetails
              label="Data de instalação"
              notMessage="Não alterado"
              details={formatDate(detailsChange?.installationDate)}
            />
            <LabelInfoDetails
              label="Data primeiro pagamento"
              notMessage="Não alterado"
              details={formatDate(detailsChange?.firstPaymentDate)}
            />
            <LabelInfoDetails
              label="ID Regional"
              notMessage="Não alterado"
              details={detailsChange?.regionalId}
            />
            <LabelInfoDetails
              label="ID Cluster"
              notMessage="Não alterado"
              details={detailsChange?.clusterId}
            />
            <LabelInfoDetails
              label="Matrícula IPTU"
              notMessage="Não alterado"
              details={detailsChange?.iptuRegistration}
            />
          </Flex>
          <Flex>
            <LabelInfoDetails
              label="Pagamento IPTU"
              notMessage="Não alterado"
              details={detailsChange?.iptuPayment ? 'Sim' : 'Não'}
            />
            <LabelInfoDetails
              label="Valor IPTU"
              notMessage="Não alterado"
              details={moneyMask(detailsChange?.iptuValue?.toString())}
            />
            <LabelInfoDetails
              label="M²"
              notMessage="Não alterado"
              details={detailsChange?.area?.toString()}
            />
            <LabelInfoDetails
              label="Mês subsequente ou adiantado"
              notMessage="Não alterado"
              details={
                detailsChange?.advancePayment ? 'subsequente' : 'adiantado'
              }
            />
            <LabelInfoDetails
              label="Índice de Reajuste"
              notMessage="Não alterado"
              details={detailsChange?.readjustmentIndexOption?.toString()}
            />
          </Flex>
          <Flex>
            <LabelInfoDetails
              label="Tipo de torre"
              notMessage="Não alterado"
              details={detailsChange?.towerTypeId}
            />
            <LabelInfoDetails
              label="Equipamento"
              notMessage="Não alterado"
              details={detailsChange?.equipmentId}
            />
            <LabelInfoDetails
              label="Periodicidade"
              notMessage="Não alterado"
              details={detailsChange?.frequency?.toString()}
            />
            <LabelInfoDetails
              label="Tecnologia"
              notMessage="Não alterado"
              details={detailsChange?.technologyId}
            />
            <LabelInfoDetails
              label="Tipo de engenharia"
              notMessage="Não alterado"
              details={detailsChange?.engineeringTypeId}
            />
          </Flex>
          <Flex>
            <LabelInfoDetails
              label="Renovação automática"
              notMessage="Não alterado"
              details={detailsChange?.automaticRenovation ? 'Sim' : 'Não'}
            />
            <LabelInfoDetails
              label="Reajuste automático"
              notMessage="Não alterado"
              details={detailsChange?.automaticReadjustment ? 'Sim' : 'Não'}
            />
            <LabelInfoDetails
              label="Alerta de vencimento"
              notMessage="Não alterado"
              details={detailsChange?.expirationAlert ? 'Sim' : 'Não'}
            />
            <LabelInfoDetails
              label="Valor"
              notMessage="Não alterado"
              details={moneyMask(detailsChange?.value?.toString())}
            />
            <LabelInfoDetails
              label="Origem"
              notMessage="Não alterado"
              details={detailsChange?.origin}
            />
          </Flex>
          <Flex>
            <LabelInfoDetails
              label="Dados"
              notMessage="Não alterado"
              details={detailsChange?.data}
            />
            <LabelInfoDetails
              label="Observações"
              notMessage="Não alterado"
              details={detailsChange?.comments}
            />
            <LabelInfoDetails
              label="Status do contrato"
              notMessage="Não alterado"
              details={detailsChange?.contractStatus?.toString()}
            />
          </Flex>
          <Flex mt={8} flexDir={'column'}>
            <HeaderTextComponent mb={2} fontSize={18} subTitle>
              Contratos selecioandos para alteração
            </HeaderTextComponent>
            <TableComponent
              centered
              ItemsHeader={[
                {
                  item: 'número de série',
                },
                {
                  item: 'identificador manual',
                },
                {
                  item: 'Status',
                },
                {
                  item: 'Valor',
                },
              ]}
              data={(
                listBatchChanges.data?.data[indexChange]?.contracts ?? []
              ).map((e) => ({
                items: [
                  e.serialNumber,
                  e.manualIdentifier,
                  StatusColorByTypeContract({ type: e.status }),
                  moneyMask(e.value.toString()),
                ],
              }))}
              isLoading={false}
            />
          </Flex>
        </Flex>
      </ModalStructureComponent>
    </>
  );
}
