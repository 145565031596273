import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { LocatorResp } from '../../../../services/LocatorService';
import { useDebounce } from '../../../../utils/functions/debounce';
import { personDocumentMask } from '../../../../utils/functions/masks';
import { getFirstIfAny } from '../../../../utils/functions/utility';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../molecules/PaginationComponent/PaginationComponent';
import SearchSelectComponent from '../../molecules/SearchSelectComponent/SearchSelectComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import {
  getIntermediaryDetails,
  useIntermediary,
} from '../../../../services/IntermediaryService';
import CreateIntermediaryReceiverModalComponent from '../CreateIntermediaryModalComponent/CreateIntermediaryModalComponent';
import {
  getReceiverDetails,
  useReceiver,
} from '../../../../services/ReceiverService';
import CreatePersonModalComponent from '../CreateLocatorModalComponent/CreateLocatorModalComponent';
import { PersonFunction } from '../../../../services/PhysicalPersonService';

export function SelectReceiverModalComponent(props: {
  showModal: boolean;
  setShowModal: () => void;
  isReceiver?: boolean;
  selectedIntermediaryReceiver: LocatorResp | undefined;
  setSelectedIntermediaryReceiver: (
    intermediaryReceiver: LocatorResp | undefined
  ) => void;
}) {
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('name');
  const [showModalCreateIntermediary, setShowModalCreateIntermediary] =
    React.useState(false);
  const listIntermediary = useIntermediary(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createdAt',
    'desc'
  );

  const listReceiver = useReceiver(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createdAt',
    'desc'
  );
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  function getList() {
    if (props.isReceiver) {
      return listReceiver;
    }
    return listIntermediary;
  }
  return (
    <>
      <ModalStructureComponent
        isOpen={props.showModal}
        onClose={() => props.setShowModal()}
        title={`Selecione o ${
          props.isReceiver ? 'recebedor' : 'intermediário'
        }`}
        maxW="1200px"
        size="xl"
      >
        <Stack
          direction={'row'}
          paddingBottom={5}
          justifyContent={'space-between'}
        >
          <ButtonComponent
            onSubmit={() => setShowModalCreateIntermediary(true)}
          >
            Criar {props.isReceiver ? 'recebedor' : 'intermediário'}
          </ButtonComponent>
          <Flex>
            <SearchSelectComponent
              onChangeText={(input) => setSearch(input.target.value)}
              inputValue={searchInput}
              onClean={() => setSearch('')}
              onChange={(item) => {
                setSearchField(item.target.value);
              }}
              options={[
                { id: 'name', name: 'Nome' },
                { id: 'document', name: 'Documento' },
                { id: 'email', name: 'Email' },
              ]}
            />
          </Flex>
        </Stack>
        <TableComponent
          ItemsHeader={[
            { item: 'Selecionar' },
            { item: props.isReceiver ? 'recebedor' : 'intermediário' },
            { item: 'Documento' },
            { item: 'Email' },
          ]}
          isLoading={getList().isLoading}
          centered={true}
          emptyState={getList().data?.metadata.dataSize === 0}
          data={
            getList().data?.data.map((e, i) => ({
              items: [
                <InputCheckComponent
                  key={i}
                  isChecked={props.selectedIntermediaryReceiver?.id === e.id}
                  onChange={() => {
                    if (props.selectedIntermediaryReceiver?.id === e.id) {
                      props.setSelectedIntermediaryReceiver(undefined);
                    } else {
                      props.setSelectedIntermediaryReceiver(e);
                    }
                  }}
                ></InputCheckComponent>,
                <AvatarLabelComponent
                  key={i}
                  label={e.name}
                  subLabel={props.isReceiver ? 'recebedor' : 'intermediário'}
                  src={e.profilePicUrl}
                />,
                personDocumentMask(e.document, e.type),
                getFirstIfAny(e.emails),
              ],
            })) || []
          }
        />
        <PaginationComponent
          onSelectedPageChanged={onSelectedPageChanged}
          selectedPage={selectedPage}
          arrayLength={getList().data?.metadata.dataSize || 0}
          maxPageItens={pageSize}
        ></PaginationComponent>
        <Flex mb={5} justifyContent={'space-between'}>
          <ButtonComponent
            variant={'outline'}
            onSubmit={() => {
              props.setSelectedIntermediaryReceiver(undefined);
              props.setShowModal();
            }}
          >
            Descartar
          </ButtonComponent>
          <ButtonComponent
            onSubmit={() => {
              props.setShowModal();
            }}
          >
            Confirmar
          </ButtonComponent>
        </Flex>
      </ModalStructureComponent>
      <CreateIntermediaryReceiverModalComponent
        isReceiver={props.isReceiver}
        showModal={showModalCreateIntermediary}
        setShowModal={() => setShowModalCreateIntermediary(false)}
        reloadData={() => getList().refetch()}
        onSuccess={async (id) => {
          let newIntermediary = undefined;
          if (props.isReceiver) {
            newIntermediary = await getReceiverDetails(id);
          } else {
            newIntermediary = await getIntermediaryDetails(id);
          }
          props.setSelectedIntermediaryReceiver(newIntermediary);
        }}
      />
      <CreatePersonModalComponent
        showModal={showModalCreateIntermediary}
        setShowModal={() => {
          setShowModalCreateIntermediary(false);
        }}
        reloadData={() => getList().refetch()}
        function={
          props.isReceiver
            ? PersonFunction.Receiver
            : PersonFunction.Intermediary
        }
      />
    </>
  );
}
