import { useQuery } from '@tanstack/react-query';
import Api, { getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';

export type ContractChangeData = {
  activationDate: string;
  expireDate: string;
  baseReadjustmentDate: string;
  startDate: string;
  dueDate: string;
  installationDate: string;
  firstPaymentDate: string;
  value: number;
  area: number;
  iptuValue: number;
  readjustmentIndex: number;
};

export type ContractChangeRequestResp = {
  newData: ContractChangeData;
  oldData: ContractChangeData;
  id: string;
  contractId: string;
  createdAt: string;
  anseweredAt: string;
  wasAnswered: true;
  wasAproved: true;
  answerMessage: string;
  isAction: true;
  type: number;
  typeDescription: string;
  actionContent: any;
  contractSerialNumber: string;
  locators: string[];
  contractStatus: number;
  contractStatusDescription: string;
  requestedBy: {
    personId: string;
    name: string;
    document: string;
  };
  answeredBy: {
    personId: string;
    name: string;
    document: string;
  };
};
export type ContractChangeRequest = {
  id: string;
  message: string;
  approve: boolean;
};

export function useContractChangeRequestContractId(
  contractId: string,
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'SerialNumber',
  sort: string = 'description',
  direction: SortDirection = 'asc',
  wasAproved: boolean | null,
  wasAnswered: boolean | null
) {
  return useQuery(
    [
      `ContractChangeRequestContractIdList`,
      contractId,
      page,
      pageSize,
      search,
      searchField,
      sort,
      direction,
      wasAproved,
      wasAnswered,
    ],
    async () => {
      const resp = await Api.get(`/ContractChangeRequest/${contractId}`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          WasAproved: wasAproved,
          WasAnswered: wasAnswered,
        },
      });
      return getRespContent<ContractChangeRequestResp[]>(resp);
    },
    { enabled: !!contractId }
  );
}
export function useContractChangeRequestAll(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'SerialNumber',
  sort: string = 'description',
  direction: SortDirection = 'asc',
  wasAproved: boolean | null,
  wasAnsweyrted: boolean | null
) {
  return useQuery(
    [
      `ContractChangeRequestAllList`,
      page,
      pageSize,
      search,
      searchField,
      sort,
      direction,
      wasAproved,
      wasAnsweyrted,
    ],
    async () => {
      const resp = await Api.get(`/ContractChangeRequest/all`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          WasAproved: wasAproved,
          WasAnswered: wasAnsweyrted,
        },
      });
      return getRespContent<ContractChangeRequestResp[]>(resp);
    }
  );
}
export function useContractChangeRequestTotalizer(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'SerialNumber',
  sort: string = 'description',
  direction: SortDirection = 'asc',
  wasAproved: boolean | null,
  wasAnswered: boolean | null
) {
  return useQuery(
    [
      `contractChangeTotalizer`,
      page,
      pageSize,
      search,
      searchField,
      sort,
      direction,
      wasAproved,
      wasAnswered,
    ],
    async () => {
      const resp = await Api.get(`ContractChangeRequest/totalizer`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          WasAproved: wasAproved,
          WasAnswered: wasAnswered,
        },
      });
      return getRespContent<{
        currentPageValue: number;
        curretFilterValue: number;
      }>(resp);
    }
  );
}
export function putContractChangeRequest(props: ContractChangeRequest) {
  if (props.approve) return putContractChangeRequestApprove(props);
  return putContractChangeRequestDisapprove(props);
}

export function putContractChangeRequestApprove(props: ContractChangeRequest) {
  return Api.put(`ContractChangeRequest/approve/${props.id}`, {
    message: props.message,
  });
}

export function putContractChangeRequestDisapprove(
  props: ContractChangeRequest
) {
  return Api.put(`/ContractChangeRequest/disapprove/${props.id}`, {
    message: props.message,
  });
}
