import { useQuery } from '@tanstack/react-query';
import Api, { getResp } from './Api';

export type ContactsResp = {
  email: string;
  phone: string;
  whatsapp: string;
  link: string;
};

export enum ContactsType {
  General = 0,
  Link = 1,
}

export function useContacts() {
  return useQuery([`Contacts`], async () => {
    const resp = await Api.get(`/Entity/contacts`);
    return getResp<ContactsResp>(resp);
  });
}

export function putContacts(props: ContactsResp) {
  return Api.put(`/Entity/contacts`, props);
}
