import React from 'react';
import FilterComponent from '../../molecules/FilterComponent/FilterComponent';
import { Flex } from '@chakra-ui/react';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import {
  FrequencyContract,
  StatusContract,
  useContractFilteredSearch,
} from '../../../../services/ContractService';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import { cleanNumberMask, moneyMask } from '../../../../utils/functions/masks';
import { useReadjustmentIndexSelect } from '../../../../services/ReadjustmentIndexService';
import { useTowerTypeSelect } from '../../../../services/TowerTypeService';
import { useEquipmentSelect } from '../../../../services/EquipmentService';
import { useTechnologySelect } from '../../../../services/TechnologyService';
import { useEngineeringTypeSelect } from '../../../../services/EngineeringTypeService';
import { useClauseSelect } from '../../../../services/ClauseService';
import { useLocationSelect } from '../../../../services/LocationService';

type FilterContractComponentProps = {
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  showFilter: boolean;
  filterOptions: useContractFilteredSearch | undefined;
  setFilterOptions: (filter: useContractFilteredSearch) => void;
};

const defaultForm = {
  frequency: null,
  automaticRenovation: null,
  expirationAlert: null,
  value: null,
  status: null,
  active: null,
  myContracts: null,
};

export function FilterContractComponent(props: FilterContractComponentProps) {
  const listReadjustmentIndex = useReadjustmentIndexSelect();
  const listTowerType = useTowerTypeSelect();
  const listEquipment = useEquipmentSelect();
  const listTechnology = useTechnologySelect();
  const listEngineeringType = useEngineeringTypeSelect();
  const listClauses = useClauseSelect();
  const listLocation = useLocationSelect();
  const [form, setForm] = React.useState<useContractFilteredSearch>(
    props.filterOptions ?? {
      ...defaultForm,
    }
  );
  function searchFilter() {
    return props.setFilterOptions({ ...form });
  }
  function cleanFilter() {
    return (
      setForm({ ...defaultForm }), props.setFilterOptions({ ...defaultForm })
    );
  }
  return (
    <FilterComponent
      onClean={cleanFilter}
      onSearch={searchFilter}
      setShowFilter={props.setShowFilter}
      showFilter={props.showFilter}
      bodyFilter={
        <Flex flexWrap={'wrap'}>
          <InputComponent
            margin={'10px 0 10px 0'}
            label="Número"
            placeholder="Busque por número"
            value={form.serialNumber}
            onChange={(e) => setForm({ ...form, serialNumber: e.target.value })}
          />
          <InputComponent
            margin={'10px 0 10px 0'}
            label="Locador"
            placeholder="Busque por locador"
            value={form.locator}
            onChange={(e) => setForm({ ...form, locator: e.target.value })}
          />
          <InputSelectComponent
            label="Local"
            placeholder="Busque pelo local"
            options={listLocation.data?.map((item) => ({
              id: item.id,
              name: `${item.street} - ${item.complement}`,
            }))}
            defaultValue={form.locationId}
            onChange={(e) =>
              setForm({
                ...form,
                locationId: e.target.value,
              })
            }
          />
          <InputComponent
            margin={'10px 0 10px 0'}
            label="Data de ativação"
            type="date"
            value={form.activationDate}
            onChange={(e) =>
              setForm({ ...form, activationDate: e.target.value })
            }
          />
          <InputComponent
            margin={'10px 0 10px 0'}
            label="Data de vencimento"
            type="date"
            value={form.expireDate}
            onChange={(e) => setForm({ ...form, expireDate: e.target.value })}
          />
          <InputSelectComponent
            label="Índice de Reajuste"
            placeholder="Busque pelo índice de reajuste"
            options={listReadjustmentIndex.data?.map((e) => ({
              id: e.id,
              name: e.indexDescription,
            }))}
            defaultValue={form.readjustmentIndexId}
            onChange={(e) =>
              setForm({
                ...form,
                readjustmentIndexId: e.target.value,
              })
            }
          />
          <InputSelectComponent
            label="Tipo de torre"
            placeholder="Busque pelo tipo da torre"
            options={listTowerType.data?.map((item) => ({
              id: item.id,
              name: item.description,
            }))}
            defaultValue={form.towerTypeId}
            onChange={(e) =>
              setForm({
                ...form,
                towerTypeId: e.target.value,
              })
            }
          />
          <InputSelectComponent
            label="Equipamento"
            placeholder="Busque pelo equipamento"
            options={listEquipment.data?.map((item) => ({
              id: item.id,
              name: item.description,
            }))}
            defaultValue={form.equipmentId}
            onChange={(e) =>
              setForm({
                ...form,
                equipmentId: e.target.value,
              })
            }
          />
          <InputSelectComponent
            m={'10px 0 10px 0'}
            defaultValue={form.frequency?.toString()}
            options={[
              { id: String(FrequencyContract.Anual), name: 'Anual' },
              { id: String(FrequencyContract.Bimestral), name: 'Bimestral' },
              { id: String(FrequencyContract.Mensal), name: 'Mensal' },
              { id: String(FrequencyContract.Semestral), name: 'Semestral' },
            ]}
            onChange={(e) => {
              setForm({
                ...form,
                frequency:
                  e.target?.value === '' ? null : Number(e.target?.value),
              });
            }}
            label="Periodicidade"
            placeholder="Busque por  periodicidade"
          />
          <InputSelectComponent
            m={'10px 0 10px 0'}
            defaultValue={form.automaticRenovation?.toString()}
            options={[
              { id: 'true', name: 'Sim' },
              { id: 'false', name: 'Não' },
            ]}
            onChange={(e) => {
              setForm({
                ...form,
                automaticRenovation:
                  e.target?.value === '' ? null : e.target?.value === 'true',
              });
            }}
            label="Renovação automática"
            placeholder="Busque por  Renovação automática"
          />
          <InputSelectComponent
            label="Tecnologia"
            placeholder="Busque por tecnologia"
            options={listTechnology.data?.map((item) => ({
              id: item.id,
              name: item.description,
            }))}
            defaultValue={form.technologyId}
            onChange={(e) =>
              setForm({
                ...form,
                technologyId: e.target.value,
              })
            }
          />
          <InputSelectComponent
            label="Tipo de engenharia"
            placeholder="Busque por tipo de engenharia"
            options={listEngineeringType.data?.map((item) => ({
              id: item.id,
              name: item.description,
            }))}
            defaultValue={form.engineeringTypeId}
            onChange={(e) =>
              setForm({
                ...form,
                engineeringTypeId: e.target.value,
              })
            }
          />
          <InputSelectComponent
            m={'10px 0 10px 0'}
            defaultValue={form.expirationAlert?.toString()}
            options={[
              { id: 'true', name: 'Sim' },
              { id: 'false', name: 'Não' },
            ]}
            onChange={(e) => {
              setForm({
                ...form,
                expirationAlert:
                  e.target?.value === '' ? null : e.target?.value === 'true',
              });
            }}
            label="Alerta de vencimento"
            placeholder="Busque por alerta de vencimento"
          />
          <InputComponent
            label="Valor"
            placeholder="R$ 55,00"
            value={form.textValue}
            onChange={(e) =>
              setForm({
                ...form,
                textValue: moneyMask(e.target.value),
                value: Number(cleanNumberMask(e.target.value)),
              })
            }
          />
          <InputComponent
            margin={'10px 0 10px 0'}
            label="Origem"
            placeholder="Busque por origem"
            value={form.origin}
            onChange={(e) => setForm({ ...form, origin: e.target.value })}
          />
          <InputComponent
            margin={'10px 0 10px 0'}
            label="Dados"
            placeholder="Busque por dados"
            value={form.data}
            onChange={(e) => setForm({ ...form, data: e.target.value })}
          />
          <InputComponent
            margin={'10px 0 10px 0'}
            label="Observações"
            placeholder="Busque por observações"
            value={form.comments}
            onChange={(e) => setForm({ ...form, comments: e.target.value })}
          />
          <InputSelectComponent
            m={'10px 0 10px 0'}
            defaultValue={form.status?.toString()}
            options={[
              { id: String(StatusContract.EmCadastramento), name: 'Pendente' },
              {
                id: String(StatusContract.PendenteDeAssinatura),
                name: 'Análise',
              },
              { id: String(StatusContract.Cancelado), name: 'Concluído' },
            ]}
            onChange={(e) => {
              setForm({
                ...form,
                status: e.target?.value === '' ? null : Number(e.target?.value),
              });
            }}
            label="Status"
            placeholder="Busque por  Status"
          />
          <InputSelectComponent
            m={'10px 0 10px 0'}
            defaultValue={form.active?.toString()}
            options={[
              { id: 'true', name: 'Sim' },
              { id: 'false', name: 'Não' },
            ]}
            onChange={(e) => {
              setForm({
                ...form,
                active:
                  e.target?.value === '' ? null : e.target?.value === 'true',
              });
            }}
            label="Ativo?"
            placeholder="Busque por ativo"
          />
          <InputSelectComponent
            m={'10px 0 10px 0'}
            defaultValue={form.myContracts?.toString()}
            options={[
              { id: 'true', name: 'Sim' },
              { id: 'false', name: 'Não' },
            ]}
            onChange={(e) => {
              setForm({
                ...form,
                myContracts:
                  e.target?.value === '' ? null : e.target?.value === 'true',
              });
            }}
            label="Meus contratos?"
            placeholder="Busque por meus contratos"
          />
          <InputSelectComponent
            label="Cláusula"
            placeholder="Busque por alguma cláusula"
            value={form.clauseId}
            options={listClauses.data?.map((item) => ({
              id: item.id,
              name: item.title,
            }))}
            onChange={(e) => {
              setForm({
                ...form,
                clauseId: e.target.value,
              });
            }}
          />
        </Flex>
      }
    />
  );
}
