import ButtonComponent from '../../../UI/atoms/ButtonComponent/ButtonComponent';
import TableComponent from '../../../UI/molecules/TableComponent/TableComponent';
import { PaginationComponent } from '../../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex } from '@chakra-ui/react';
import SearchComponent from '../../../UI/atoms/SearchComponent/SearchComponent';
import {
  usePermissions,
  usePermissionDialog,
} from '../../../../services/PermissionService';
import { PhysicalPersonResp } from '../../../../services/PhysicalPersonService';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { Dispatch, SetStateAction } from 'react';

export default function PermissionsModalComponent(props: {
  selectedUser?: PhysicalPersonResp;
  setSelectedUser?: Dispatch<SetStateAction<PhysicalPersonResp | undefined>>;
  onChangedPermission?: () => void;
}) {
  const {
    pageSize,
    selectedPage,
    searchInput,
    search,
    setSearch,
    onSelectedPageChanged,
    assignRemovePermission,
  } = usePermissionDialog();
  const listPermission = usePermissions(selectedPage, pageSize, search);
  function getAssignedPermission(permissionId: string) {
    const selectedPermissions = props.selectedUser?.permissions?.filter(
      (permission) => permission.permissionId === permissionId
    );
    const isProfile = selectedPermissions?.some((item) => item.isProfile);
    const isNotProfile = selectedPermissions?.some((item) => !item.isProfile);
    if (isProfile && isNotProfile) return 'Atribuído pelo perfil e manualmente';

    if (isProfile) return 'Atribuído pelo perfil';

    if (isNotProfile) return 'Atribuído manualmente';

    return 'Não Atribuído';
  }
  return (
    <>
      <TextComponent fontWeight={700} fontSize={'20px'}>
        Permissões do usuário
      </TextComponent>
      <Flex mb={5} justifyContent={'end'}>
        <SearchComponent
          value={searchInput}
          onClean={() => setSearch('')}
          onChange={(input) => setSearch(input.target.value)}
        />
      </Flex>
      <TableComponent
        ItemsHeader={[
          { item: 'Nome' },
          { item: 'Atribuído' },
          { item: 'Ações' },
        ]}
        emptyState={listPermission.data?.metadata.dataSize === 0}
        isLoading={listPermission.isLoading}
        data={
          listPermission.data?.data.map((e) => ({
            items: [
              e.name,
              getAssignedPermission(e.id),
              <>
                <ButtonComponent
                  margin={'0 0 0 5px'}
                  disabled={props.selectedUser?.permissions
                    ?.filter((item) => !item.isProfile)
                    .some((permission) => permission.permissionId === e.id)}
                  onSubmit={() => {
                    assignRemovePermission(e, props.selectedUser, true).then(
                      () => {
                        listPermission
                          .refetch()
                          .then(() => props.onChangedPermission?.());
                        if (props.selectedUser)
                          props.setSelectedUser?.({
                            ...props.selectedUser,
                            permissions: [
                              ...props.selectedUser.permissions,
                              {
                                ...e,
                                userId: props.selectedUser.id,
                                permissionId: e.id,
                                isProfile: false,
                              },
                            ],
                          });
                      }
                    );
                  }}
                >
                  Atribuir
                </ButtonComponent>
                <ButtonComponent
                  margin={'0 0 0 5px'}
                  disabled={
                    !props.selectedUser?.permissions
                      ?.filter((item) => !item.isProfile)
                      .some((permission) => permission.permissionId === e.id)
                  }
                  colorScheme={'red'}
                  onSubmit={() => {
                    assignRemovePermission(e, props.selectedUser, false).then(
                      () => {
                        listPermission
                          .refetch()
                          .then(() => props.onChangedPermission?.());
                        if (props.selectedUser)
                          props.setSelectedUser?.({
                            ...props.selectedUser,
                            permissions: props.selectedUser.permissions.filter(
                              (item) =>
                                item.permissionId !== e.id || item.isProfile
                            ),
                          });
                      }
                    );
                  }}
                >
                  Remover
                </ButtonComponent>
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listPermission.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
    </>
  );
}
