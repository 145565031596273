import './InputComponent.scss';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { ChangeEventHandler, KeyboardEventHandler, CSSProperties } from 'react';

export type InputProps = {
  className?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  type?: string;
  value?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  size?: string;
  borderRadius?: number;
  style?: CSSProperties;
  w?: string | number;
  margin?: string | number;
  marginTop?: string | number;
  marginBottom?: string | number;
  maxW?: string | number;
  maxLength?: number;
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
  error?: string;
  autoFocus?: boolean;
  max?: string;
  ref?: any;
};

export default function InputComponent(props: InputProps) {
  return (
    <FormControl
      mt={props.marginTop}
      margin={props.margin}
      w={props.w}
      mb={props.marginBottom}
      isInvalid={!!props.error}
      maxW={props.maxW}
    >
      <FormLabel mb={'0'} style={props.style}>
        {props.label}
      </FormLabel>
      {props.type === 'textArea' ? (
        <Textarea
          autoFocus={props.autoFocus}
          variant={props.variant || 'outline'}
          borderRadius={props.borderRadius}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange}
          onKeyUp={props.onKeyUp}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeholder}
          size={props.size || 'md'}
          maxLength={props.maxLength}
        />
      ) : (
        <Input
          ref={props.ref}
          autoFocus={props.autoFocus}
          variant={props.variant || 'outline'}
          borderRadius={props.borderRadius}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange}
          onKeyUp={props.onKeyUp}
          onKeyDown={props.onKeyDown}
          max={props.max}
          placeholder={props.placeholder}
          type={props.type}
          size={props.size || 'md'}
          maxLength={props.maxLength}
        />
      )}
      <FormErrorMessage mt={'0'}>{props.error}</FormErrorMessage>
    </FormControl>
  );
}
