import { Flex, Grid, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  StatusContract,
  postContractGeneratePDF,
  putContractReplaceDocument,
  putContractStatus,
  useContractDetails,
} from '../../../services/ContractService';
import { ContractAdjustmentModalComponent } from '../../UI/organisms/ContractAdjustmentModalComponent/ContractAdjustmentModalComponent';
import { ContractDetailsActionsComponent } from '../../UI/organisms/ContractDetailsActionsComponent/ContractDetailsActionsComponent';
import { ContractDetailsInfoComponent } from '../../UI/organisms/ContractDetailsInfoComponent/ContractDetailsInfoComponent';
import { ContractRenovationModalComponent } from '../../UI/organisms/ContractRenovationModalComponent/ContractRenovationModalComponent';
import { ContractTerminationModalComponent } from '../../UI/organisms/ContractTerminationModalComponent/ContractTerminationModalComponent';
import { AttendanceModalComponent } from '../../UI/organisms/AttendanceModalComponent/AttendanceModalComponent';
import { ContractSendForSignatureModalComponent } from '../../UI/organisms/ContractSendForSignatureModalComponent/ContractSendForSignatureModalComponent';
import { showToast } from '../../../utils/showToast';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import DropzoneModel from '../../UI/organisms/uploadModel/DropZoneModel';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import InputSelectComponent from '../../UI/atoms/InputSelectComponent/InputSelectComponent';

export function ContractDetailsTemplateComponent() {
  const params = useParams<{ id: string }>();
  const toast = useToast();
  const contract = useContractDetails(params.id || '');
  const navigate = useNavigate();
  const [showModalRenovation, setShowModalRenovation] = React.useState(false);
  const [showModalAdjustment, setShowModalAdjustment] = React.useState(false);
  const [showModalTermination, setShowModalTermination] = React.useState(false);
  const [showModalAttendance, setShowModalAttendance] = React.useState(false);
  const [showModalReplaceDocument, setShowModalReplaceDocument] =
    React.useState(false);
  const [showModalSendForSignature, setShowModalSendForSignature] =
    React.useState(false);
  const [newFile, setFile] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [replaceDocumentId, setReplaceDocumentId] = React.useState('');
  const [changeStatusModal, setChangeStatusModal] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState<StatusContract>();
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  return (
    <Grid margin={0} templateColumns="repeat(12, 2fr)">
      <ContractDetailsInfoComponent
        onClick={() => contract.remove()}
        contract={contract.data}
        onSubmitHistoryButton={() =>
          window.open(`/contract-history/${contract.data?.id}`, '_blank')
        }
        onSubmitTransactionsButton={() =>
          window.open(`/transaction/${contract.data?.id}`, '_blank')
        }
        onSubmitAttendanceButton={() => setShowModalAttendance(true)}
        onSubmitSigningButton={() => setShowModalSendForSignature(true)}
        onSubmitGeneratePDFButton={() => {
          postContractGeneratePDF(contract.data?.id ?? '').then((res) => {
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: `PDF gerado com sucesso`,
            });
            contract.refetch();
          });
        }}
      />
      <ContractDetailsActionsComponent
        onSubmitChangeStatus={() => setChangeStatusModal(true)}
        onSubmitReplaceDocumentButton={(documentId) => {
          setFile('');
          setReplaceDocumentId(documentId);
          setShowModalReplaceDocument(true);
        }}
        contract={contract.data}
        onSubmitRenovateButton={() => {
          setShowModalRenovation(true);
        }}
        onSubmitAdjustmentButton={() => setShowModalAdjustment(true)}
        onSubmitTerminateButton={() => setShowModalTermination(true)}
        onSubmitAddButton={() => navigate(`/new-contract/${contract.data?.id}`)}
        reloadData={() => contract.refetch()}
        onSubmitEditDocumentButton={(documentId) =>
          navigate(`/new-contract/${contract.data?.id}`)
        }
      />
      <ContractRenovationModalComponent
        selectedContract={contract.data}
        showModal={showModalRenovation}
        setShowModal={() => setShowModalRenovation(false)}
        reloadData={() => contract.refetch()}
      />
      <ContractAdjustmentModalComponent
        selectedContract={contract.data}
        showModal={showModalAdjustment}
        setShowModal={() => setShowModalAdjustment(false)}
        reloadData={() => contract.refetch()}
      />
      <ContractTerminationModalComponent
        selectedContract={contract.data}
        showModal={showModalTermination}
        setShowModal={() => setShowModalTermination(false)}
        reloadData={() => contract.refetch()}
      />
      <AttendanceModalComponent
        showModal={showModalAttendance}
        setShowModal={() => setShowModalAttendance(false)}
        attendances={contract.data?.locatorAttendances ?? []}
      />
      <ContractSendForSignatureModalComponent
        selectedContract={contract.data}
        showModal={showModalSendForSignature}
        setShowModal={() => setShowModalSendForSignature(false)}
        reloadData={() => contract.refetch()}
      />
      <ModalStructureComponent
        footer={
          <Flex p={4}>
            <ButtonComponent
              margin={'0 10px 0 0'}
              variant={'outline'}
              onSubmit={() => {
                setFile('');
                setShowModalReplaceDocument(false);
              }}
            >
              Descartar
            </ButtonComponent>
            <ButtonComponent
              disabled={newFile.length === 0 || isLoading}
              onSubmit={() => {
                setIsLoading(true);
                putContractReplaceDocument(
                  contract.data?.id ?? '',
                  replaceDocumentId,
                  newFile
                )
                  .then(() => {
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description: `Documento substituído com sucesso`,
                    });
                    contract.refetch();
                    setShowModalReplaceDocument(false);
                  })
                  .finally(() => setIsLoading(false));
              }}
            >
              Substituir
            </ButtonComponent>
          </Flex>
        }
        children={
          <Flex
            flexDir={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Flex mb={8}>
              Por favor, selecione o arquivo para fazer a substituição
            </Flex>
            <DropzoneModel
              onUploadComplete={(input) => {
                setFile(input[0].id);
                setIsLoading(false);
              }}
              type="archive"
            />
          </Flex>
        }
        isOpen={showModalReplaceDocument}
        size={'xl'}
        title="Substituir Documento "
        onClose={() => setShowModalReplaceDocument(false)}
      />
      <ModalStructureComponent
        footer={
          <Flex p={4}>
            <ButtonComponent
              margin={'0 10px 0 0'}
              variant={'outline'}
              onSubmit={() => {
                setNewStatus(contract.data?.contractStatus);
                setChangeStatusModal(false);
              }}
            >
              Descartar
            </ButtonComponent>
            <ButtonComponent
              isLoading={isLoadingStatus}
              disabled={
                newStatus?.toString() === StatusContract.Cancelado.toString() ||
                isLoadingStatus
              }
              onSubmit={() => {
                setIsLoadingStatus(true);
                putContractStatus(contract.data?.id ?? '', newStatus ?? 0)
                  .then(() => {
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description: 'Status alterado com sucesso',
                    });
                  })
                  .finally(() => {
                    setIsLoadingStatus(false);
                    setChangeStatusModal(false);
                    contract.refetch();
                  });
              }}
            >
              Alterar
            </ButtonComponent>
          </Flex>
        }
        children={
          <Flex
            flexDir={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Flex mb={8}>
              Por favor, selecione o status desejado para fazer a alteração
            </Flex>
            <InputSelectComponent
              defaultValue={contract.data?.contractStatus.toString()}
              onChange={(e) => {
                setNewStatus(e.target.value);
              }}
              options={[
                { id: StatusContract.Assinado.toString(), name: 'Assinado' },
                { id: StatusContract.Cancelado.toString(), name: 'Cancelado' },
                {
                  id: StatusContract.EmCadastramento.toString(),
                  name: 'Em cadastramento',
                },
                {
                  id: StatusContract.EmElaboraçãoDeMinuta.toString(),
                  name: 'Em elaboração de minuta',
                },

                {
                  id: StatusContract.PendenteDeAssinatura.toString(),
                  name: 'Pendente de assinatura',
                },
                {
                  id: StatusContract.PendenteDeAtivação.toString(),
                  name: 'Pendente de ativação',
                },
              ]}
              placeholder="Selecione o Status"
              label="Status do contrado"
            />
            {newStatus?.toString() === StatusContract.Cancelado.toString() && (
              <Flex color={'red'} mt={2}>
                Para alterar o status do contrato para 'Cancelado', é necessário
                realizar um distrato.
              </Flex>
            )}
          </Flex>
        }
        isOpen={changeStatusModal}
        size={'xl'}
        title="Alterar status do contrato  "
        onClose={() => {
          setNewStatus(contract.data?.contractStatus);
          setChangeStatusModal(false);
        }}
      />
    </Grid>
  );
}
