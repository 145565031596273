import {
  Flex,
  Grid,
  GridItem,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import {
  FaBuffer,
  FaChartLine,
  FaCheck,
  FaPeopleArrows,
  FaTimes,
} from 'react-icons/fa';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import TagComponent from '../../atoms/TagComponent/TagComponent';
import ModalStructureComponent from '../ModalStructureComponent/ModalStructureComponent';
import TableComponent from '../TableComponent/TableComponent';
import {
  CampaignStatus,
  NegotiationStatus,
  putCampaignCancel,
  putCampaignCancelAllNegotiations,
  putCampaignFinish,
  useCampaignContractDetails,
  useCampaignDetails,
} from '../../../../services/CampaignsService';
import { parseDateAndHour } from '../../../../utils/functions/utility';
import { showToast } from '../../../../utils/showToast';
import {
  PaginationComponent,
  usePagination,
} from '../PaginationComponent/PaginationComponent';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertToMonetaryValue } from '../../../../utils/functions/masks';
import { LabelInfoDetails } from '../../../../pages/MyProfilePage';
import { ContractAdjustmentModalComponent } from '../../organisms/ContractAdjustmentModalComponent/ContractAdjustmentModalComponent';
import { ContractRenovationModalComponent } from '../../organisms/ContractRenovationModalComponent/ContractRenovationModalComponent';
import { ContractTerminationModalComponent } from '../../organisms/ContractTerminationModalComponent/ContractTerminationModalComponent';
import { useContractDetails } from '../../../../services/ContractService';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';

export function CampaignDetailsModal(props: {
  id: string;
  showModal: boolean;
  reloadData: () => void;
  setShowModal: () => void;
}) {
  const colSpanOnBoarding = useBreakpointValue({ base: 12, sm: 8 });
  const toast = useToast();
  const navigate = useNavigate();
  const { selectedPage, setSelectedPage } = usePagination();
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const campaign = useCampaignDetails(props.id);
  const campaignDetails = campaign.data;
  const campaignContracts = useCampaignContractDetails(
    props.id,
    selectedPage,
    4
  );
  const [showModalRenovation, setShowModalRenovation] = useState(false);
  const [showModalAdjustment, setShowModalAdjustment] = useState(false);
  const [showModalTermination, setShowModalTermination] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState('');
  const contract = useContractDetails(selectedContractId);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal();
      }}
      title={`Detalhes da campanha`}
      maxW="98%"
      size="6xl"
    >
      <>
        <Flex flexDir={'column'}>
          <Flex>
            <LabelInfoDetails
              label="Campanha"
              details={`id ${campaignDetails?.serialNumber}` || ''}
            />
            <LabelInfoDetails
              label="Valor Somado"
              details={`${convertToMonetaryValue(
                campaignDetails?.totalValue ?? 0
              )}`}
            />
            <LabelInfoDetails
              label="Status"
              children={getStatusByType(campaignDetails?.status || 0)}
            />
            <LabelInfoDetails
              label="Responsável"
              details={campaignDetails?.responsibleName || ''}
            />
            <LabelInfoDetails
              label="Contratos"
              details={String(campaignDetails?.count) || ''}
            />
          </Flex>
          <Flex mb={2} justifyContent={'flex-end'}>
            <ButtonComponent
              margin={'0 10px 0 0'}
              variant={'outline'}
              onSubmit={() => {
                putCampaignCancelAllNegotiations(campaignDetails?.id).then(
                  () => {
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description:
                        'Todas as negociações pendentes foram canceladas.',
                    });
                  }
                );
              }}
              colorScheme={'yellow'}
            >
              Cancelar negociações pendentes
            </ButtonComponent>
            <ButtonComponent
              margin={'0 10px 0 0'}
              variant={'outline'}
              onSubmit={() => {
                putCampaignCancel(campaignDetails?.id).then((res) => {
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Concluido com sucesso',
                  });
                  props.reloadData();
                  props.setShowModal();
                });
              }}
              colorScheme={'red'}
            >
              Cancelar
            </ButtonComponent>
            <ButtonComponent
              variant={'outline'}
              margin={'0 10px 0 0'}
              onSubmit={() => {
                putCampaignFinish(campaignDetails?.id).then((res) => {
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Concluido com sucesso',
                  });
                  props.reloadData();
                  props.setShowModal();
                });
              }}
            >
              Concluir
            </ButtonComponent>
          </Flex>

          <TableComponent
            ItemsHeader={[
              { item: 'Número do Contrato' },
              { item: 'Locador' },
              { item: 'Data' },
              { item: 'Situação de negociação' },
              { item: 'Valor' },
              { item: 'Ação' },
            ]}
            isLoading={campaignContracts.isLoading}
            centered={true}
            data={
              campaignContracts.data?.data.map((e, i) => ({
                items: [
                  e.serialNumber,
                  <Flex justify={'center'}>
                    <AvatarLabelComponent label={e.locatorName} />
                  </Flex>,
                  parseDateAndHour(e.activationDate),
                  <TagComponent
                    size="md"
                    text={e.negotiationStatusDescription}
                    colorScheme={getColorNegotiationStatus(e.negotiationStatus)}
                  />,

                  convertToMonetaryValue(e.value),
                  <Flex justifyContent={'center'}>
                    <IconButtonComponent
                      marginX={1}
                      variant={'outline'}
                      onSubmit={() => {
                        navigate(`/contract-history/${e.contractId}`);
                      }}
                      ariaLabel="play"
                      icon={<FaBuffer />}
                      toolTipText="histórico do contrato"
                    />

                    <IconButtonComponent
                      marginX={1}
                      variant={'outline'}
                      onSubmit={() => {
                        setSelectedContractId(e.contractId);
                        navigate(`/new-contract/${e.contractId}`);
                      }}
                      ariaLabel="Aditivo"
                      icon={<FaPeopleArrows />}
                      toolTipText="Aditivo"
                    />

                    <IconButtonComponent
                      marginX={1}
                      variant={'outline'}
                      onSubmit={() => {
                        setSelectedContractId(e.contractId);
                        setShowModalRenovation(true);
                      }}
                      ariaLabel="Renovação"
                      icon={<FaCheck />}
                      toolTipText="Renovação"
                    />

                    <IconButtonComponent
                      marginX={1}
                      variant={'outline'}
                      colorScheme="red"
                      onSubmit={() => {
                        setSelectedContractId(e.contractId);
                        setShowModalTermination(true);
                      }}
                      ariaLabel="Distrato"
                      icon={<FaTimes />}
                      toolTipText="Distrato"
                    />

                    <IconButtonComponent
                      marginX={1}
                      variant={'outline'}
                      onSubmit={() => {
                        setSelectedContractId(e.contractId);
                        setShowModalAdjustment(true);
                      }}
                      ariaLabel="Reajuste"
                      icon={<FaChartLine />}
                      toolTipText="Reajuste"
                    />
                  </Flex>,
                ],
              })) || []
            }
          />
          <PaginationComponent
            onSelectedPageChanged={onSelectedPageChanged}
            selectedPage={selectedPage}
            arrayLength={campaignContracts.data?.metadata.dataSize || 0}
            maxPageItens={4}
          ></PaginationComponent>
        </Flex>
        <Grid margin={0} templateColumns="repeat(12, 2fr)">
          <GridItem
            display={'flex'}
            flexDirection={'column'}
            colSpan={colSpanOnBoarding}
            padding={'20px'}
            paddingTop={'0px'}
          ></GridItem>
        </Grid>

        <ContractRenovationModalComponent
          selectedContract={contract.data}
          showModal={showModalRenovation}
          setShowModal={() => {
            setShowModalRenovation(false);
          }}
          reloadData={() => contract.refetch()}
        />
        <ContractAdjustmentModalComponent
          selectedContract={contract.data}
          showModal={showModalAdjustment}
          setShowModal={() => {
            setShowModalAdjustment(false);
          }}
          reloadData={() => contract.refetch()}
        />
        <ContractTerminationModalComponent
          selectedContract={contract.data}
          showModal={showModalTermination}
          setShowModal={() => {
            setShowModalTermination(false);
          }}
          reloadData={() => contract.refetch()}
        />
      </>
    </ModalStructureComponent>
  );
}

export function getColorNegotiationStatus(status: NegotiationStatus) {
  switch (status) {
    case NegotiationStatus.NotStarted:
      return 'gray';
    case NegotiationStatus.InProgress:
      return 'blue';
    case NegotiationStatus.Canceled:
      return 'yellow';
    case NegotiationStatus.Closed:
      return 'red';
  }
}
export function getStatusByType(type: CampaignStatus) {
  switch (type) {
    case CampaignStatus.Ativo:
      return <TagComponent size={'md'} colorScheme={'blue'} text="Ativo" />;
    case CampaignStatus.Cancelado:
      return <TagComponent size={'md'} colorScheme={'red'} text="Cancelado" />;
    default:
      return (
        <TagComponent size={'md'} colorScheme={'green'} text="Finalizado" />
      );
  }
}
