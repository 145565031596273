import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { Flex, Stack } from '@chakra-ui/react';
import { useReadjustmentIndexResume } from '../../../services/ReadjustmentIndexService';

import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { useNavigate } from 'react-router-dom';

export default function IndexTemplateComponent() {
  const listIndex = useReadjustmentIndexResume();
  const navigate = useNavigate();

  return (
    <>
      <Stack
        paddingBottom={2}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <HeaderTextComponent goBack>Índices</HeaderTextComponent>
      </Stack>
      <TableComponent
        ItemsHeader={[
          { item: 'Código' },
          { item: 'Descrição' },
          { item: 'Último' },
          { item: 'Anterior' },
          { item: 'No Ano' },
          { item: 'Ações' },
        ]}
        isLoading={listIndex.isLoading}
        data={
          listIndex.data?.data.map((e, i) => ({
            items: [
              e.index,
              e.indexDescription,
              e.lastIndex.value + '%',
              e.previousIndex.value + '%',
              e.year + '%',
              <Flex>
                <ButtonComponent
                  key={i}
                  onSubmit={() => {
                    navigate(`/indexPerMonth/${e.index}`, {
                      indexDescription: e.indexDescription,
                    } as any);
                  }}
                >
                  Detalhes
                </ButtonComponent>
              </Flex>,
            ],
          })) ?? []
        }
      />
    </>
  );
}
