import { useQuery } from '@tanstack/react-query';
import Api from './Api';

export type SpaceObjectResp = {
  name: string;
  description: string;
  id: string;
  ownerId: string;
  owner: string;
  localizationId: string;
  localization: {
    id: string;
    latitude: string;
    longitude: string;
    description: string;
    localizationType: number;
  };
};

export function useSpaceObject() {
  return useQuery([`Space-Object`], async () => {
    const resp = await Api.get(`/SpaceObject`);
    return resp.data.content;
  });
}
