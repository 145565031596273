import { Flex } from '@chakra-ui/react';
import { ChangeEventHandler, useState } from 'react';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import SearchComponent from '../../atoms/SearchComponent/SearchComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';

export type DateRange = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

type Option = {
  id: string;
  name: string;
  type?: 'search' | 'date' | 'select' | 'date-range';
  options?: {
    id: string;
    name: string;
  }[];
  onChageSelect?: ChangeEventHandler<HTMLSelectElement>;
  onChangeDateRange?: (range: DateRange) => void;
  dateRange?: DateRange;
};

type SearchComponentProps = {
  onChangeText?: ChangeEventHandler<HTMLInputElement>;
  selectDefaultValue?: string;
  inputValue?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  onClean: () => void;
  type?: 'search';
  options?: Option[];
};

export default function SearchSelectComponent(props: SearchComponentProps) {
  const [selectedOption, setSelectedOption] = useState(props.options?.[0]);

  function InputType(type: 'search' | 'date' | 'select' | 'date-range') {
    if (type === 'date') {
      return (
        <InputComponent
          w={300}
          type="date"
          onChange={props.onChangeText}
          placeholder="Selecione a data"
        />
      );
    }
    if (type === 'select') {
      return (
        <InputSelectComponent
          w={300}
          label=""
          options={selectedOption?.options}
          onChange={selectedOption?.onChageSelect}
        />
      );
    }
    if (type === 'date-range') {
      return (
        <Flex alignItems={'center'}>
          <Flex alignItems={'center'}>
            <TextComponent mr={1}>De</TextComponent>
            <InputComponent
              w={150}
              margin={'0 10px 0 0'}
              max="9999-12-31"
              type="date"
              onChange={(e) => {
                selectedOption?.onChangeDateRange?.({
                  startDate: new Date(e.target.value),
                  endDate: selectedOption?.dateRange?.endDate,
                });
              }}
              placeholder="Selecione a data inicial"
            />
          </Flex>
          <Flex alignItems={'center'}>
            <TextComponent mr={1}>Até</TextComponent>
            <InputComponent
              w={150}
              type="date"
              max="9999-12-31"
              onChange={(e) => {
                selectedOption?.onChangeDateRange?.({
                  startDate: selectedOption?.dateRange?.startDate,
                  endDate: new Date(e.target.value),
                });
              }}
              placeholder="Selecione a data final"
            />
          </Flex>
        </Flex>
      );
    } else {
      return (
        <SearchComponent
          maxWidth={300}
          onChange={props.onChangeText}
          value={props.inputValue}
          onClean={props.onClean}
        />
      );
    }
  }
  return (
    <Flex alignItems={'center'}>
      {InputType(selectedOption?.type || 'search')}
      <InputSelectComponent
        m={'0 0 0 10px'}
        w={'300px'}
        label=""
        defaultValue={props.selectDefaultValue}
        onChange={(e) => {
          props.onChange?.(e);
          const option = props.options?.find(
            (option) => option.id === e.target.value
          );
          setSelectedOption(option);
        }}
        options={props.options}
      />
    </Flex>
  );
}
