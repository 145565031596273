import { Flex } from '@chakra-ui/react';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import React from 'react';

export function AcceptMessageModalComponent(props: {
  showModal: boolean;
  approve: boolean;
  onAcceptModal: (message: string) => void;
  setShowModal: () => void;
}) {
  const [message, setMessage] = React.useState('');
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={props.approve ? `Aprovar` : 'Reprovar'}
      size="lg"
    >
      <Flex
        mb={5}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <InputComponent
          label={`Mensagem de ${props.approve ? 'Aprovação' : 'Reprovação'}`}
          placeholder="Digite a mensagem"
          value={message}
          onChange={(input) => setMessage(input.target.value)}
        />
      </Flex>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setMessage('');
            props.setShowModal();
          }}
        >
          Cancelar
        </ButtonComponent>
        <ButtonComponent
          disabled={!message}
          colorScheme={props.approve ? 'green' : 'red'}
          onSubmit={() => {
            props.onAcceptModal(message);
            setMessage('');
          }}
        >
          {props.approve ? 'Aprovar' : 'Reprovar'}
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
