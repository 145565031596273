import { FaBuffer, FaEdit, FaInfoCircle, FaTimes } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { Flex, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import { useSpaceObject } from '../../../services/SpaceObject';
import React from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { CreateCampaignsModalComponent } from '../../UI/molecules/CreateCampaignsModalComponent/CreateCampaignsModalComponent';
import {
  invalidateCampaign,
  putCampaignCancel,
  useCampaign,
  useCampaignHistories,
  useCampaignTotalizer,
} from '../../../services/CampaignsService';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { CampaignDetailsModal } from '../../UI/molecules/CampaignDetailsModal/CampaignDetailsModal';
import { useQueryClient } from '@tanstack/react-query';
import { showToast } from '../../../utils/showToast';
import ConfirmModalComponent from '../../UI/organisms/ConfirmModalComponent/ConfirmModalComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import { convertToMonetaryValue } from '../../../utils/functions/masks';
import { Totalizer } from '../../UI/molecules/Totalizer/Totalizer';
import SearchSelectComponent from '../../UI/molecules/SearchSelectComponent/SearchSelectComponent';
import { useDebounce } from '../../../utils/functions/debounce';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import { formatDate } from '../../../utils/functions/formatDate';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';

export default function CampaignsTemplateComponent() {
  const listTower = useSpaceObject();
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = React.useState(false);
  const [showModalHistory, setShowModalHistory] = React.useState(false);
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('ContractSerialNumber');
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  const campaignList = useCampaign(selectedPage, pageSize, search, searchField);
  const campaignTotalizer = useCampaignTotalizer(
    selectedPage,
    pageSize,
    search,
    searchField
  ).data?.data;
  const campaingnHistoriesList = useCampaignHistories(
    selectedId,
    selectedPage,
    pageSize
  );
  const toast = useToast();

  return (
    <>
      <Flex mb={2} justifyContent={'space-between'}>
        <Flex>
          <HeaderTextComponent goBack>Campanhas</HeaderTextComponent>
        </Flex>
        <Flex alignItems={'center'}>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => setShowModal(true)}
          >
            Nova campanha
          </ButtonComponent>
          <SearchSelectComponent
            onChangeText={(input) => setSearch(input.target.value)}
            inputValue={searchInput}
            onClean={() => setSearch('')}
            onChange={(item) => {
              setSearch('');
              setSearchField(item.target.value);
            }}
            options={[
              { id: '', name: 'Selecionar' },
              { id: 'ContractSerialNumber', name: 'Número do contrato' },
              { id: 'CampaignSerialNumber', name: 'Número da campanha' },
            ]}
          />
        </Flex>
      </Flex>

      <TableComponent
        ItemsHeader={[
          { item: 'Campanha' },
          { item: 'Responsável' },
          { item: 'Valor Somado' },
          { item: 'Contratos' },
          { item: 'Tipo' },
          { item: 'Status' },
          { item: 'Ações' },
        ]}
        emptyState={campaignList.data?.data.length === 0}
        isLoading={listTower.isLoading}
        centered={true}
        data={
          campaignList.data?.data.map((e, i) => ({
            items: [
              e.serialNumber,
              <AvatarLabelComponent label={e.responsibleName} />,
              convertToMonetaryValue(e.totalValue),
              e.count,
              <TagComponent
                colorScheme={e.type === 0 ? 'blue' : 'purple'}
                size="md"
                text={e.typeDescription}
              />,
              <TagComponent
                colorScheme={getColorByStatusCampaings(e.statusDescription)}
                size="md"
                text={e.statusDescription}
              />,

              <>
                <IconButtonComponent
                  icon={<FaTimes />}
                  toolTipText="Cancelar"
                  ariaLabel="Cancelar"
                  backgroundColor="#FFC100"
                  onSubmit={() => {
                    setSelectedId(e.id);
                    setShowConfirmModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedId(e.id);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaInfoCircle />}
                  ariaLabel="info"
                  toolTipText="Detalhes"
                  backgroundColor="#3182CE"
                  color={'#fff'}
                  onSubmit={() => {
                    setSelectedId(e.id);
                    setShowDetailsModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaBuffer />}
                  margin={2}
                  ariaLabel="history"
                  toolTipText="Histórico"
                  backgroundColor=""
                  color={'#fff'}
                  onSubmit={() => {
                    setSelectedId(e.id);
                    setShowModalHistory(true);
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <Totalizer
        currentPage={campaignTotalizer?.currentPageValue ?? 0}
        total={campaignTotalizer?.totalValue ?? 0}
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={campaignList.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateCampaignsModalComponent
        campaignId={selectedId}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
          setSelectedId('');
        }}
        reloadData={campaignList.refetch}
        isLoading={campaignList.isLoading}
      />
      <CampaignDetailsModal
        id={selectedId || ''}
        showModal={showDetailsModal}
        setShowModal={() => {
          invalidateCampaign(selectedId, queryClient);
          setShowDetailsModal(false);
        }}
        reloadData={campaignList.refetch}
      />
      <ConfirmModalComponent
        isOpen={showConfirmModal}
        title={'Deseja realmente cancelar essa campanha?'}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          putCampaignCancel(selectedId).then((res) => {
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: 'Cancelado com sucesso',
            });
            invalidateCampaign(selectedId, queryClient);
            campaignList.refetch();
          });
        }}
      />
      <ModalStructureComponent
        isOpen={showModalHistory}
        onClose={() => setShowModalHistory(false)}
        title={'Histórico da campanha'}
        isCentered={true}
        children={
          <>
            <TableComponent
              ItemsHeader={[
                { item: 'Usuário' },
                { item: 'Data de criação' },
                { item: 'Tipo' },
                { item: 'Descrição' },
              ]}
              isLoading={campaingnHistoriesList.isLoading}
              centered={true}
              emptyState={campaingnHistoriesList.data?.data.length === 0}
              data={
                campaingnHistoriesList.data?.data.map((e) => ({
                  items: [
                    e.personName,
                    formatDate(e.createdAt),
                    e.typeDescription,
                    e.description,
                  ],
                })) || []
              }
            />
            <PaginationComponent
              onSelectedPageChanged={onSelectedPageChanged}
              selectedPage={selectedPage}
              arrayLength={campaingnHistoriesList.data?.metadata.dataSize || 0}
              maxPageItens={pageSize}
            ></PaginationComponent>
          </>
        }
        size={'6xl'}
      />
    </>
  );
}
function getColorByStatusCampaings(statusDescription: string) {
  if (statusDescription === 'Concluído') {
    return 'green';
  }
  if (statusDescription === 'Cancelado') {
    return 'red';
  }
  if (statusDescription === 'Ativa') {
    return 'purple';
  } else {
    return 'blue';
  }
}
