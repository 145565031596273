import { useQuery } from '@tanstack/react-query';
import Api, { getRespContent } from './Api';

export type ContractAndIndexResp = {
  name: string;
  index: number;
  contractCount: number;
  totalValue: number;
};
export function useContractAndIndexDashboard() {
  return useQuery([`ContractAndIndexList`], async () => {
    const resp = await Api.get(`/Contract/index-amount-dashboard`, {});
    return getRespContent<ContractAndIndexResp[]>(resp);
  });
}
export type ContractCardsResp = {
  count: number;
  totalValue: number;
  averageValue: number;
};
export function useContractCardsDashboard() {
  return useQuery([`ContractCardsList`], async () => {
    const resp = await Api.get(`contract/cards-dashboard`, {});
    return getRespContent<ContractCardsResp>(resp);
  });
}
export type ReadjustmentIndexResp = {
  index: number;
  name: string;
  lastYearValue: number;
  references: {
    index: number;
    reference: string;
    monthValue: number;
  }[];
};
export function useReadjustmentIndexDashboard() {
  return useQuery([`ReadjustmentIndexList`], async () => {
    const resp = await Api.get(`/ReadjustmentIndex/dashboard`);
    return getRespContent<ReadjustmentIndexResp[]>(resp);
  });
}
export type LocationResp = {
  region: string;
  count: number;
  totalValue: number;
};
export function useLocationDashboard() {
  return useQuery([`LocationList`], async () => {
    const resp = await Api.get(`/Location/region-dashboard`);
    return getRespContent<LocationResp[]>(resp);
  });
}
export type ContractDetailsResp = {
  count: number;
  expiredCount: number;
  expiredValue: number;
  willExpireCount: number;
  willExpireValue: number;
  expiringInUpToThreeMonthCount: number;
  expiringInUpToThreeMonthValue: number;
  expiringInUpToOneYearCount: number;
  expiringInUpToOneYearValue: number;
  expiringInOverAYearCount: number;
  expiringInOverAYearValue: number;
  totalValue: number;
  averageValue: number;
};
export function useContractDetailsDashboard() {
  return useQuery([`contractDetailsList`], async () => {
    const resp = await Api.get(`/contract/details-dashboard`);
    return getRespContent<ContractDetailsResp>(resp);
  });
}

export type locatorStatementResp = {
  year: string;
  references: {
    month: number;
    value: number;
    count: number;
  }[];
}[];

export function useLocatorStatementDashboard() {
  return useQuery([`locatorStatementList`], async () => {
    const resp = await Api.get(`/locatorStatement/dashboard`);
    return getRespContent<locatorStatementResp>(resp);
  });
}
export type CampaignDashboardResp = {
  renovationCount: number;
  renovationValue: number;
  readjustmentCount: number;
  readjustmentValue: number;
  inProgressContractCount: number;
  finishedContractCount: number;
  totalsContractCount: number;
  renovationContractCount: number;
  ReadjustmentContractCount: number;
};
export function useCampaignDashboard() {
  return useQuery([`campaign/dashboard`], async () => {
    const resp = await Api.get(`campaign/dashboard`);
    return getRespContent<CampaignDashboardResp>(resp);
  });
}
