import { SortDirection } from './PhysicalPersonService';
import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { LocatorPhysicalPerson, LocatorResp } from './LocatorService';

export function useIntermediary(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'name',
  sort: string = 'createdAt',
  direction: SortDirection = 'desc'
) {
  return useQuery(
    [`IntermediaryList`, page, sort, search, searchField, direction],
    async () => {
      const resp = await Api.get(`/Intermediary`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
        },
      });
      return getRespContent<LocatorResp[]>(resp);
    }
  );
}

export function useIntermediarySelect() {
  return useQuery([`IntermediarySelect`], async () => {
    const resp = await Api.get(`/Intermediary/select`);
    return getResp<LocatorResp[]>(resp);
  });
}

export function postIntermediary(props: LocatorPhysicalPerson) {
  return Api.post('/Intermediary', props);
}

export function putIntermediary(props: LocatorPhysicalPerson) {
  return Api.put(`/Intermediary/${props.id}`, props);
}

export function saveIntermediary(intermediary: LocatorPhysicalPerson) {
  if (intermediary.id) {
    return putIntermediary(intermediary);
  } else {
    return postIntermediary(intermediary);
  }
}

export function deleteIntermediary(id: string) {
  return Api.delete(`/Intermediary/${id}`);
}

export function getIntermediaryDetails(id: string) {
  return Api.get(`/Intermediary/${id}`).then((response) => {
    return getResp<LocatorResp>(response);
  });
}
