import Api, { getResp } from './Api';
import { useQuery } from '@tanstack/react-query';
import { SortDirection } from './PhysicalPersonService';

export type Attendance = {
  title: string;
  description: string;
  subjectId: string;
  attachmentIds: string[];
  requesterId: string;
};

export type AttendanceSubject = {
  subject: string;
  description: string;
  mandatoryAttachment: boolean;
  id: string;
  registeredById: string;
  registeredByName: string;
  createdAt: Date | string;
  defaultResponses: [
    {
      subjectId: string;
      response: string;
      id: string;
      createdAt: Date | string;
    }
  ];
};
export function useAttendanceSubject() {
  return useQuery([`attendanceSubjectList`], async () => {
    const resp = await Api.get(`/AttendanceSubject/without-params`);
    return getResp<AttendanceSubject[]>(resp);
  });
}
export function useAttendanceAttendants(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'name',
  sort: string = 'name',
  direction: SortDirection = 'asc'
) {
  return useQuery([`AttendanceAttendantsList`], async () => {
    const resp = await Api.get(`Attendance/attendants`, {
      params: {
        Offset: page,
        Limit: pageSize,
        [searchField]: search,
        SortColumn: sort || '',
        SortDirection: direction,
      },
    });
    return resp.data.content;
  });
}
export function postAttendanceSubject(data: Attendance) {
  return Api.post(`/Attendance/manual-register`, data);
}
export function putAttendanceViewers(id: string, userId: string) {
  return Api.put(`attendance/${id}/viewer/${userId}`);
}
export function deleteAttendanceViewer(id: string) {
  return Api.delete(`viewer/${id}`);
}
