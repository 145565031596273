import { Flex } from '@chakra-ui/react';
import HeaderTextComponent from '../HeaderTextComponent/HeaderTextComponent';

type HeaderCardComponentProps = {
  title: string;
  headerComponentRigth?: React.ReactNode;
  mb?: string | number;
};
export default function HeaderCardComponent(props: HeaderCardComponentProps) {
  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      mb={props.mb || 4}
    >
      <HeaderTextComponent fontSize={20} as={'b'}>
        {props.title}
      </HeaderTextComponent>
      {props.headerComponentRigth}
    </Flex>
  );
}
