import { Stack } from '@chakra-ui/react';
import { parseHour } from '../../../../utils/functions/utility';
import TextComponent from '../../atoms/TextComponent/TextComponent';

export function MessageComponent(item: {
  person: string;
  message: string;
  time: string;
  fromRequester: boolean;
  currentUserIsRequester: boolean;
}) {
  const isRight = item.currentUserIsRequester
    ? !item.fromRequester
    : item.fromRequester;
  return (
    <Stack
      style={{ alignItems: isRight ? 'flex-end' : 'flex-start' }}
      mx={4}
      my={4}
    >
      <TextComponent fontSize={'14px'} color={'brand.500'} fontWeight={'700'}>
        {item.person}
      </TextComponent>
      <TextComponent style={{ marginTop: 0 }} fontSize={'14px'}>
        {item.message}
      </TextComponent>
      <TextComponent style={{ marginTop: 0 }} fontSize={'12px'} color={'gray'}>
        {parseHour(item.time)}
      </TextComponent>
    </Stack>
  );
}
