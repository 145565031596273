import { SortDirection } from './PhysicalPersonService';
import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { LocatorPhysicalPerson, LocatorResp } from './LocatorService';

export function useReceiver(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'name',
  sort: string = 'createdAt',
  direction: SortDirection = 'desc'
) {
  return useQuery(
    [`ReceiverList`, page, sort, search, searchField, direction],
    async () => {
      const resp = await Api.get(`/Receiver`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
        },
      });
      return getRespContent<LocatorResp[]>(resp);
    }
  );
}

export function useReceiverSelect() {
  return useQuery([`ReceiverSelect`], async () => {
    const resp = await Api.get(`/Receiver/select`);
    return getResp<LocatorResp[]>(resp);
  });
}

export function postReceiver(props: LocatorPhysicalPerson) {
  return Api.post('/Receiver', props);
}

export function putReceiver(props: LocatorPhysicalPerson) {
  return Api.put(`/Receiver/${props.id}`, props);
}

export function saveReceiver(Receiver: LocatorPhysicalPerson) {
  if (Receiver.id) {
    return putReceiver(Receiver);
  } else {
    return postReceiver(Receiver);
  }
}

export function deleteReceiver(id: string) {
  return Api.delete(`/Receiver/${id}`);
}

export function getReceiverDetails(id: string) {
  return Api.get(`/Receiver/${id}`).then((response) => {
    return getResp<LocatorResp>(response);
  });
}
