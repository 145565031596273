import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';

export type RelatedEntity = {
  id: string;
  name: string;
  document: string;
  called: string;
  addressViewModel: {
    owner: string;
    ownerId: string;
    street: string;
    district: string;
    complement: string;
    zipcode: string;
    type: number;
    cityId: string;
    stateId: string;
    countryId: string;
    coordenateId?: string;
    coordinates?: {
      latitude: string;
      longitude: string;
      description: string;
      localizationType: number;
    };
  };
};

export type RelatedEntityResp = {
  id: string;
  name: string;
  document: string;
  called: string;
  address: {
    owner: string;
    ownerId: string;
    street: string;
    district: string;
    complement: string;
    zipcode: string;
    type: number;
    cityId: string;
    stateId: string;
    countryId: string;
    coordenateId?: string;
    coordinates?: {
      latitude: string;
      longitude: string;
      description: string;
      localizationType: number;
    };
  };
};
export function useRelatedEntity(
  page: number,
  pageSize: number | null,
  sort: string = 'title'
) {
  return useQuery([`RelatedEntityList`, page, sort], async () => {
    const resp = await Api.get(`/RelatedEntity`, {
      params: {
        Offset: page,
        Limit: pageSize,
        SortColumn: sort,
      },
    });
    return getRespContent<RelatedEntity[]>(resp);
  });
}

export function getRelatedEntityDetails(id: string) {
  return Api.get(`/RelatedEntity/${id}`).then((response) => {
    return getResp<RelatedEntity>(response);
  });
}

export function saveRelatedEntity(props: RelatedEntityResp) {
  if (props.id) {
    return putRelatedEntity(props);
  }
  return postRelatedEntity(props);
}

export function postRelatedEntity(props: RelatedEntityResp) {
  return Api.post('/RelatedEntity', props);
}

export function putRelatedEntity(props: RelatedEntityResp) {
  return Api.put(`/RelatedEntity/${props.id}`, props);
}

export function deleteRelatedEntity(id: string) {
  return Api.delete(`/RelatedEntity/${id}`);
}
