import React from 'react';
import { Flex } from '@chakra-ui/react';
import EmptyImage from '../../../../assets/empty.png';
import ImageComponent from '../ImageComponent/ImageComponent';
import HeaderTextComponent from '../HeaderTextComponent/HeaderTextComponent';
type EmptyStateProps = {
  title?: string;
};
export default function EmptyStateComponent(props: EmptyStateProps) {
  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
      <Flex mt={8}>
        <HeaderTextComponent
          subTitle
          children={props.title || 'Não foram encontrados resultados'}
        />
      </Flex>
      <ImageComponent width="60%" src={EmptyImage} />
    </Flex>
  );
}
