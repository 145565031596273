import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import LoadingComponent from '../../atoms/LoadingComponent/LoadingComponent';
import { Flex } from '@chakra-ui/react';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';

type DashboardMapType = {
  isLoading?: boolean;
  latitude?: number;
  longitude?: number;
  width: number;
  height: number;
};

export default function DashboardMap(props: DashboardMapType) {
  const latitude = props.latitude || -76.253897;
  const longetude = props.longitude || 23.514825;
  function isValidatePosition() {
    if (
      latitude === -76.253897 ||
      latitude === undefined ||
      latitude === null ||
      latitude === 0
    ) {
      return false;
    }
    if (
      longetude === 23.514825 ||
      longetude === undefined ||
      longetude === null ||
      longetude === 0
    ) {
      return false;
    } else {
      return true;
    }
  }
  return (
    <MapContainer
      style={{
        height: props.height || '450px',
        width: props.width || '450px',
      }}
      center={[latitude, longetude]}
      zoom={17}
      scrollWheelZoom={false}
    >
      {props.isLoading ? (
        <Flex
          height={'100%'}
          w={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <LoadingComponent />
        </Flex>
      ) : (
        <>
          {!isValidatePosition() ? (
            <Flex
              w={'100%'}
              h={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <HeaderTextComponent fontSize={14} subTitle>
                Cordendas não encontradas
              </HeaderTextComponent>
            </Flex>
          ) : (
            <>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[latitude, longetude]}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Marker>
            </>
          )}
        </>
      )}
    </MapContainer>
  );
}
