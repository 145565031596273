import { Flex, Spinner } from '@chakra-ui/react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import React, { useEffect, useRef } from 'react';
import InputComponent from './UI/atoms/InputComponent/InputComponent';

function Render(status: Status) {
  if (status === Status.FAILURE) return <>Componente de erro</>;
  return <Spinner />;
}
const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
interface MapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children: React.ReactNode;
}

const MyMap: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
    if (map) {
      map.setOptions(options);
    }
  }, [ref, map, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <Flex ref={ref} w={'100%'} h={'100%'} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default function MyMapComponent(props: {
  zoom: number;
  point: google.maps.LatLngLiteral | undefined;
  setPoint: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral>>;
}) {
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>();

  useEffect(() => {
    if (props.point) {
      setCenter(props.point);
    }
  }, [props.point]);

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (!e.latLng) return;
    props.setPoint({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  return (
    <Flex w={'100%'} h={500} flexDir={'column'}>
      <Flex mb={2}>
        <InputComponent
          margin={'0 10px 0 0'}
          label="Latitude"
          type="number"
          value={props.point?.lat.toString()}
          onChange={(event) =>
            props.setPoint({
              lng: props.point?.lng ?? 0,
              lat: Number(event.target.value),
            })
          }
        />
        <InputComponent
          margin={'0 0 0 0'}
          label="Longitude"
          type="number"
          value={props.point?.lng.toString() ?? ''}
          onChange={(event) =>
            props.setPoint({
              lat: props.point?.lat ?? 0,
              lng: Number(event.target.value),
            })
          }
        />
      </Flex>

      <Wrapper
        apiKey={'AIzaSyDiEshphegxQrvMgUikPYjpK_pwb1GVWPE'}
        render={Render}
      >
        <MyMap
          onClick={onClick}
          center={
            center || {
              lat: 0,
              lng: 0,
            }
          }
          zoom={props.zoom}
        >
          <Marker position={props.point} />
        </MyMap>
      </Wrapper>
    </Flex>
  );
}
