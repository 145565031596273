import { Flex, Stack, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import {
  ContractResp,
  contractTermination,
} from '../../../../services/ContractService';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import { moneyMask } from '../../../../utils/functions/masks';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import { useDetachedPayment } from '../../../../services/DetachedPaymentService';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import { FaPlus } from 'react-icons/fa';
import { CreateDetachedPaymentModalComponent } from '../CreateDetachedPaymentModalComponent/CreateDetachedPaymentModalComponent';

export function ContractTerminationModalComponent(props: {
  showModal: boolean;
  selectedContract?: ContractResp;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const [showModal, setShowModal] = React.useState(false);
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      motivation: '',
      terminationDate: '',
      detachedPaymentId: '',
    }),
    []
  );
  const detachedPayments = useDetachedPayment(
    props.selectedContract?.id ?? '',
    0,
    100,
    ''
  );
  const [form, setForm] = React.useState(defaultForm);
  React.useEffect(() => {
    if (props.selectedContract)
      setForm({
        id: props.selectedContract.id,
        motivation: '',
        terminationDate: '',
        detachedPaymentId: '',
      });
    else setForm(defaultForm);
  }, [props.selectedContract, defaultForm]);
  return (
    <>
      <ModalStructureComponent
        footer={
          <Flex p={4}>
            <ButtonComponent
              margin={'0 10px 0 0'}
              variant={'outline'}
              onSubmit={() => {
                setForm({
                  id: props.selectedContract?.id || '',
                  motivation: '',
                  terminationDate: '',
                  detachedPaymentId: '',
                });
                props.setShowModal();
              }}
            >
              Descartar
            </ButtonComponent>
            <ButtonComponent
              onSubmit={() => {
                contractTermination(form).then((res) => {
                  props.setShowModal();
                  props.reloadData?.();
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Pedido de alteração realizado com sucesso.',
                  });
                });
              }}
            >
              Salvar
            </ButtonComponent>
          </Flex>
        }
        isOpen={props.showModal}
        onClose={() => props.setShowModal()}
        title={'Distrato/Encerramento'}
        maxW="1200px"
        size="lg"
      >
        <Text fontSize={'sm'} fontWeight={'600'} marginBottom={7}>
          Preencha com os dados do encerramento (Valor pago:{' '}
          {moneyMask(
            props.selectedContract?.transactions?.totalValue?.toString()
          )}{' '}
          de {moneyMask(props.selectedContract?.value?.toString())})
        </Text>
        <Stack mb={5} direction={'row'} spacing={'4%'} alignItems={'end'}>
          <InputComponent
            label="Data de encerramento"
            type="date"
            value={form.terminationDate}
            onChange={(input) => {
              setForm({ ...form, terminationDate: input.target.value });
            }}
          />
          <InputSelectComponent
            label="Pagamento extra (Opcional)"
            placeholder="Selecione o pagamento extra"
            defaultValue={form.detachedPaymentId}
            options={detachedPayments.data?.data?.map((item) => ({
              id: item.id,
              name: item.description,
            }))}
            onChange={(input) => {
              setForm({ ...form, detachedPaymentId: input.target.value });
            }}
          />
          <IconButtonComponent
            icon={<FaPlus />}
            ariaLabel="adicionar"
            toolTipText="Adicionar Pagamento"
            onSubmit={() => setShowModal(true)}
          />
        </Stack>
        <Stack mb={5} direction={'row'} spacing={'4%'}>
          <InputComponent
            label="Motivo do encerramento"
            type="textArea"
            value={form.motivation}
            onChange={(input) => {
              setForm({ ...form, motivation: input.target.value });
            }}
          />
        </Stack>
      </ModalStructureComponent>
      <CreateDetachedPaymentModalComponent
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
        contractDetails={props.selectedContract}
        reloadData={() => detachedPayments.refetch()}
      />
    </>
  );
}
