import { QueryClient, useQuery } from '@tanstack/react-query';
import Api, { getRespContent } from './Api';
import { ReadjustmentIndex } from './ReadjustmentIndexService';
import { SortDirection } from './PhysicalPersonService';

export enum NegotiationHistoryStatus {
  Null = 0,
  Negotiation = 1,
  Open = 2,
  Finished = 3,
}

export type NegotiationHistoryResp = {
  deadlineInMonths: number;
  totalValue: number;
  startingReference: Date | string;
  endingReference: Date | string;
  readjustmentIndex: ReadjustmentIndex;
  id: string;
  createdAt: Date | string;
  contractId: string;
  description: string;
  status: number;
};

export type NegotiationCurrentDataResp = {
  value: number;
  monthValue: number;
  deadlineOfContractInMonths: number;
  startingReference: Date;
  endingReference: Date;
  readjustmentIndexOption: ReadjustmentIndex;
  readjustmentIndexId: string;
  readjustmentIndexPercentage: number;
  baseReadjustmentDate: Date;
  area: number;
  valuePerArea: number;
};
export type CreateNegotiationApi = {
  deadlineInMonths: number;
  totalValue: number;
  startingReference: string;
  endingReference: string;
  readjustmentIndex?: ReadjustmentIndex;
  description?: string;
  status?: NegotiationHistoryStatus;
};
export type CreateNegotiationResp = {
  id: string;
  contractId: string;
  monthsInNegotiation: number;
  deadlineOfContractInMonths: number;
  finalValue: number;
  valueVariation: number;
  valueVariationPercentage: number;
  yearValueVariation: number;
  totalVariation: number;
  valuePerArea: number;
  readjustmentIndexOption: number;
  readjustmentIndexId: string;
  readjustmentIndexPercentage: number;
  baseReadjustmentDate: Date;
};

export function useNegotiationHistory(
  disabled: boolean,
  contractId: string | undefined,
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'description',
  sort: string = 'description',
  direction: SortDirection = 'asc'
) {
  return useQuery(
    [
      `NegotiationHistoryList`,
      page,
      search,
      searchField,
      sort,
      direction,
      contractId,
    ],
    async () => {
      const resp = await Api.get(`/negotiationHistory/contract/${contractId}`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
        },
      });
      return getRespContent<NegotiationHistoryResp[]>(resp);
    },
    { enabled: !disabled }
  );
}

export function invalidateNegotiationHistory(
  contractId: string,
  queryClient: QueryClient
) {
  queryClient.invalidateQueries({
    queryKey: [`NegotiationHistoryList`, contractId],
  });
}

export function useNegotiationCurrentData(
  disabled: boolean,
  contractId: string | undefined
) {
  return useQuery(
    [`NegotiationCurrentData`, contractId],
    async () => {
      const resp = await Api.get(
        `/negotiationHistory/current-data/${contractId}`
      );
      return getRespContent<NegotiationCurrentDataResp>(resp);
    },
    { enabled: !disabled }
  );
}

export const CreateNegotiation = async (
  contractId: string,
  data: CreateNegotiationApi
) => {
  const resp = await Api.post(`/negotiationHistory/${contractId}`, {
    deadlineInMonths: data.deadlineInMonths,
    totalValue: data.totalValue,
    startingReference: data.startingReference,
    endingReference: data.endingReference,
    readjustmentIndex: data.readjustmentIndex,
    description: data.description,
    status: data.status,
  });
  return getRespContent<CreateNegotiationResp>(resp);
};

export const EditNegotiation = async (
  negotiationId: string,
  data: CreateNegotiationApi
) => {
  const resp = await Api.put(`/negotiationHistory/${negotiationId}`, {
    deadlineInMonths: data.deadlineInMonths,
    totalValue: data.totalValue,
    startingReference: data.startingReference,
    endingReference: data.endingReference,
    readjustmentIndex: data.readjustmentIndex,
    description: data.description,
    status: data.status,
  });
  return getRespContent<CreateNegotiationResp>(resp);
};

export function useNegotiationCurrentDataPreview(
  disabled: Boolean,
  contractId: string | undefined,
  body: CreateNegotiationApi
) {
  return useQuery(
    [`NegotiationCurrentDataPreview`, contractId, body],
    async () => {
      const resp = await Api.get(
        `/negotiationHistory/current-data/${contractId}/preview`,
        {
          params: body,
        }
      );
      return getRespContent<NegotiationCurrentDataResp>(resp);
    },
    { enabled: !disabled }
  );
}
