import { Flex, Stack } from '@chakra-ui/react';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import React from 'react';
import { FaEllipsisH } from 'react-icons/fa';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { defaultContractColumns } from '../../../templates/ContractTemplateComponent/ContractTemplateComponent';

type SelectTableFieldsModalProps = {
  showModal: boolean;
  columns: TableColumns | undefined;
  setColumns: React.Dispatch<React.SetStateAction<TableColumns | undefined>>;
  setShowModal: () => void;
};

export type TableColumns = {
  item: string;
  selected?: boolean;
  sort?: string;
}[];

export function getHeaders(
  columns: TableColumns | undefined,
  openModal: () => void
) {
  if (columns)
    return [
      ...columns.filter((item) => item.selected),
      {
        item: (
          <Flex alignItems={'center'} justifyContent={'right'} width={'100%'}>
            Ações
            <IconButtonComponent
              toolTipText="Alterar colunas da"
              margin={'0 0 0 60px'}
              icon={<FaEllipsisH />}
              ariaLabel="Selecione os campos"
              height={'15px'}
              onSubmit={() => {
                openModal();
              }}
            />
          </Flex>
        ),
      },
    ];
  return [];
}

export function SelectTableFieldsModalComponent(
  props: SelectTableFieldsModalProps
) {
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Trocar colunas da tabela`}
      size="xl"
    >
      <Stack direction={'row'} paddingBottom={5}>
        <Flex direction={'row'} width={'100%'} wrap={'wrap'}>
          {props.columns?.map((item, i) => (
            <InputCheckComponent
              m="5px"
              w={'30%'}
              key={i}
              isChecked={item.selected}
              onChange={() =>
                props.setColumns(
                  props.columns?.map((changedField) =>
                    changedField.item === item.item
                      ? { ...changedField, selected: !changedField.selected }
                      : changedField
                  )
                )
              }
            >
              <TextComponent lineHeight="15px" textTransform={'capitalize'}>
                {item.item}
              </TextComponent>
            </InputCheckComponent>
          ))}
        </Flex>
      </Stack>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            props.setShowModal();
            props.setColumns(defaultContractColumns)
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          onSubmit={() => {
            props.setShowModal();
           
          }}
        >
          Ok
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
