import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ENABLE_MIDDLEWARE } from '../environment';
import { getFirstIfAny } from '../utils/functions/utility';
import { showToast } from '../utils/showToast';
import Api, { getApi, getResp, getRespContent, Middleware } from './Api';
import { removeLocalStorage, useLocalStorage } from './LocalStorage';

export type UserData = {
  token: string;
  personId: string;
  permissions: string[];
  userId: string;
  onBoarding: boolean;
  entity?: {
    id: string;
    name: string;
    entityImage?: string;
  };
};

type UserContextType = {
  user: UserData | undefined;
  setUser: (user: UserData) => void;
  logoff: (reload?: boolean) => void;
};

const defaultUserData: UserData | undefined = undefined;
//TODO: Add other user fields
export const UserContext = React.createContext<UserContextType>({
  user: defaultUserData,
  setUser: () => {},
  logoff: () => {},
});
export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useLocalStorage<UserData | undefined>(
    'userData',
    defaultUserData
  );
  const logoff = async (forbidden = false) => {
    removeLocalStorage('userData');
    setUser(undefined);
    if (forbidden) {
      localStorage.setItem('forbidden', '1');
    }
    window.location.reload();
  };
  return (
    <UserContext.Provider value={{ user: user ?? undefined, setUser, logoff }}>
      {children}
    </UserContext.Provider>
  );
}
function useUser() {
  return React.useContext(UserContext);
}

export function useLogoff() {
  const { logoff } = useUser();
  return logoff;
}

export function useUserData() {
  const { user } = useUser();
  return user;
}

export function useSetUserData() {
  const { setUser } = useUser();
  return setUser;
}

export default function useLogin() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const toast = useToast();
  const { mutate, isLoading } = useMutation(postLogin, {
    onSuccess: (data) => {
      //TODO: Invalidate user-data query
      if (data.onBoarding) {
        showToast({
          toast,
          status: 'info',
          title: 'Atenção',
          description:
            'Para completar seu cadastro e conseguir acessar seu perfil acesse a parte de onboarding',
          duration: 30000,
        });
      }
      setUser(data);
      navigate('/dashboard');
      return data;
    },
  });
  return {
    login: mutate,
    isLoading,
    user,
  };
}

export function useGoogleLogin() {
  const { user, setUser } = useUser();
  const { mutate, isLoading } = useMutation(googleLogin, {
    onSuccess: (data) => {
      //TODO: Invalidate user-data query
      setUser(data);
      return data;
    },
  });
  return {
    login: mutate,
    isLoading,
    user,
  };
}

type LoginResponse = {
  token: string;
  personId: string;
  permissions: string[];
  userId: string;
  onBoarding: boolean;
  entity?: {
    id: string;
    name: string;
    entityImage?: string;
  };
};

type LoginMiddlewareResponse = {
  token: string;
  entities: {
    id: string;
    name: string;
    entityImage?: string;
  }[];
  franchises: string[];
  permissions: {
    permissionName: string;
    permissionEntity: string;
    permissionFranchise: string;
    isGLobal: boolean;
  }[];
};

async function postLogin({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<LoginResponse> {
  if (ENABLE_MIDDLEWARE) {
    return await loginMiddleware(email, password);
  }
  return await Api.post(`/login`, {
    username: email,
    password,
  }).then(getResp<LoginResponse>);
}

async function loginMiddleware(username: string, password: string) {
  const middlewareResp = await Middleware.post(`/user/login`, {
    username,
    password,
  }).then(getResp<LoginMiddlewareResponse>);
  //pedir rota para dados de login
  const apiResp = await Api.post(`/login-middleware`, null, {
    headers: {
      Authorization: `Bearer ${middlewareResp.token}`,
      TenantId: getFirstIfAny(middlewareResp.entities)?.id || '',
    },
  }).then(getResp<LoginResponse>);
  apiResp.entity = getFirstIfAny(middlewareResp.entities) || undefined;
  apiResp.permissions = middlewareResp.permissions.map(
    (item) => item.permissionName
  );
  apiResp.token = middlewareResp.token;
  return apiResp;
}

export function postRecoverPassword({ email }: { email: string }) {
  if (ENABLE_MIDDLEWARE)
    return Middleware.post('/user/recover-password', {
      email,
    });
  else
    return Api.post('/recover-password', {
      email,
    });
}
export function postValidate({ email }: { email: string }) {
  if (ENABLE_MIDDLEWARE)
    return Middleware.post('/user/validate', {
      email,
    });
  else
    return Api.post('/recover-password', {
      email,
    });
}
export function postSetPassword({
  password,
  username,
  setPasswordToken,
}: {
  password: string;
  username: string;
  setPasswordToken: string;
}) {
  if (ENABLE_MIDDLEWARE)
    return Middleware.post('/user/set-password', {
      password,
      email: username,
      setPasswordToken,
    });
  else
    return Api.post('/set-password', {
      password,
      username,
      setPasswordToken,
    });
}

export function postInsertUser({
  username,
  email,
  password,
}: {
  username: string;
  email: string;
  password: string;
}) {
  if (ENABLE_MIDDLEWARE)
    return Middleware.post('/user/insertUserOnboarding', {
      username,
      email,
      password,
    });
  else
    return Api.post('/insert-user-onboarding', {
      username,
      email,
      password,
    });
}

export const ConfirmCode = async (email: string, setPasswordToken: string) => {
  const response = await Middleware.post(
    '/user/confirm-password-recovery-code',
    {
      setPasswordToken: setPasswordToken,
      email: email,
    }
  );
  return getRespContent<{
    confirmPasswordRecoveryCode: boolean;
  }>(response);
};

function googleLogin({ code }: { code: string }) {
  return getApi()
    .get('/Google/login', {
      params: {
        code,
      },
    })
    .then(getResp<LoginResponse>);
}
