import {
  Flex,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Image,
} from '@chakra-ui/react';

type CardPercentageComponentProps = {
  title: string;
  value?: string | number;
  percentage?: string | number;
  subTitle?: string;
  subTitleType?: 'increase' | 'decrease';
  cardWidth?: string | number;
  bg?: string;
  detailsColor?: string;
  quantity?: string | number;
  icon?: string;
  m?: string | number;
  p?: string | number;
  borderRadius?: string | number;
};
export default function CardPercentageComponent(
  props: CardPercentageComponentProps
) {
  return (
    <Flex
      w={props.cardWidth || 240}
      borderRadius={props.borderRadius || 8}
      backgroundColor={props.bg || 'brand.500'}
      padding={props.p || 4}
      m={props.m || 0}
    >
      <StatGroup width={'100%'} color={'white'}>
        <Stat>
          <Flex alignItems={'flex-start'} justifyContent={'space-between'}>
            <Flex flexDirection={'column'}>
              <StatLabel fontSize={16}>{props.title}</StatLabel>
              <StatHelpText mb={0}>
                {props.subTitle}
                {props.subTitleType && (
                  <StatArrow
                    boxSize={2}
                    ml={2}
                    color={'white'}
                    type={props.subTitleType}
                  />
                )}
              </StatHelpText>
            </Flex>
            {props.icon && (
              <Flex mb={4}>
                <Image boxSize={'30px'} objectFit="cover" src={props.icon} />
              </Flex>
            )}

            {props.percentage && (
              <Flex
                backgroundColor={'white'}
                color={props.detailsColor || '#FCA574'}
                paddingLeft={'14px'}
                paddingRight={'14px'}
                paddingBottom={'4px'}
                paddingTop={'4px'}
                borderRadius={18}
                fontSize={14}
                fontWeight={'medium'}
              >
                {props.percentage}%
              </Flex>
            )}
          </Flex>

          <Flex alignItems={'center'} flexDir={'column'}>
            {props.value && (
              <Flex alignItems={'center'}>
                <StatNumber fontSize={26}>{props.value}</StatNumber>
              </Flex>
            )}
            {props.quantity || Number(props.quantity) === 0 ? (
              <Flex alignItems={'center'}>
                <StatLabel fontWeight={'light'} fontSize={14} mr={2}>
                  {'Quantidade:'}
                </StatLabel>
                <StatNumber fontSize={14}>{props.quantity}</StatNumber>
              </Flex>
            ) : (
              <></>
            )}
          </Flex>
        </Stat>
      </StatGroup>
    </Flex>
  );
}
