import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';

export type ConsiderationResp = {
  id: string;
  content: string;
  createdAt?: string;
  hasContracts?: boolean;
  status?: StatusConsideration;
};
export enum StatusConsideration {
  Active = 0,
  Inactive = 1,
}

export function useConsideration(
  page: number,
  pageSize: number | null,
  status?: StatusConsideration
) {
  return useQuery([`ConsiderationList`, page, pageSize, status], async () => {
    const resp = await Api.get(`/Consideration`, {
      params: {
        Offset: page,
        Limit: pageSize,
        Status: status,
      },
    });
    return getRespContent<ConsiderationResp[]>(resp);
  });
}

export function useConsiderationSelect() {
  return useQuery([`ConsiderationListSelect`], async () => {
    const resp = await Api.get(`/Consideration`, {
      params: {
        Limit: 100,
      },
    });
    return getResp<ConsiderationResp[]>(resp);
  });
}

export function DeleteConsideration(id: string) {
  return Api.delete(`/Consideration/${id}`);
}

export function saveConsideration(props: ConsiderationResp) {
  if (props.id) {
    return putConsideration(props);
  }
  return postConsideration(props);
}

export function postConsideration(props: ConsiderationResp) {
  return Api.post('/Consideration', props);
}

export function putConsideration(props: ConsiderationResp) {
  return Api.put(`/Consideration/${props.id}`, props);
}

export function putConsiderationActive(id: string) {
  return Api.put(`/Consideration/active/${id}`);
}
export function putConsiderationDisable(id: string) {
  return Api.put(`/Consideration/disable/${id}`);
}
