import {
  Flex,
  Image,
  Tooltip,
  Box,
  Tag,
  TableContainer,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
} from '@chakra-ui/react';
import HeaderTextComponent from '../components/UI/atoms/HeaderTextComponent/HeaderTextComponent';
import TableComponent from '../components/UI/molecules/TableComponent/TableComponent';
import {
  ContractAndIndexResp,
  ReadjustmentIndexResp,
  locatorStatementResp,
  useCampaignDashboard,
  useContractAndIndexDashboard,
  useContractCardsDashboard,
  useContractDetailsDashboard,
  useLocationDashboard,
  useLocatorStatementDashboard,
  useReadjustmentIndexDashboard,
} from '../services/DashboardService';
import DashboardTableComponent from '../components/UI/molecules/DashboardTableComponent/DashboardTableComponent';
import CardPercentageComponent from '../components/UI/atoms/CardPercentageComponent/CardPercentageComponent';
import RegionIcon from '../assets/images/region.png';
import MoneyIcon from '../assets/images/dolar.png';
import welcomeIcon from '../assets/images/welcomeNotBg.png';
import welComeLeftIcon from '../assets/images/welcomeLeft.png';
import welComeRightIcon from '../assets/images/welcomeRight.png';
import MapIcon from '../assets/images/regionMap1.png';
import CalendarIcon from '../assets/images/calendar.png';
import { convertToMonetaryValue } from '../utils/functions/masks';
import {
  PhysicalPerson,
  useMyProfile,
} from '../services/PhysicalPersonService';
import LoadingComponent from '../components/UI/atoms/LoadingComponent/LoadingComponent';
import DashBoardPieComponent from '../components/UI/molecules/DashboardPieComponent/DashboardPieComponent';
import { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { getSum } from '../utils/functions/utility';
import './dashboard.css';

function formatMonth(date: string) {
  const asDate = new Date(date);
  return (
    asDate.toLocaleString('pt-BR', { month: 'long' }) +
    '/' +
    asDate.getFullYear().toString()
  );
}
export default function DashboardsPage() {
  const person = useMyProfile();
  if (person.isLoading) {
    return (
      <Flex
        borderRadius={8}
        borderWidth={1}
        alignItems={'center'}
        justifyContent={'center'}
        height={'80vh'}
      >
        <LoadingComponent />
      </Flex>
    );
  }

  return <DashboardsBody person={person.data} />;
}
function DashboardsBody(props: { person?: PhysicalPerson }) {
  // const person = useMyProfile();
  const contractsAndIndexDashboardList = useContractAndIndexDashboard();
  const readjustmentIndexDashboardList = useReadjustmentIndexDashboard();
  const locationDashboardList = useLocationDashboard();
  const constractCardsDashboardList = useContractCardsDashboard();
  const contractDetailsDashboardList = useContractDetailsDashboard();
  const locatorStatementDashboarList = useLocatorStatementDashboard();
  const campaignDashboardList = useCampaignDashboard();
  const locationOrdenatList = locationDashboardList.data?.data.sort((a, b) =>
    a.region.localeCompare(b.region)
  );

  const lastMonthsIndexes =
    readjustmentIndexDashboardList.data?.data?.[0].references
      .map((r) => formatMonth(r.reference))
      .filter((_, i) => i < 3)
      .reverse() ?? [];

  function bgCardRegionColor(
    region: 'Norte' | 'Nordeste' | 'Centro-Oeste' | 'Sudeste' | 'Sul' | string
  ) {
    if (region === 'Norte') {
      return 'teal.500';
    }
    if (region === 'Centro-Oeste') {
      return 'green.500';
    }
    if (region === 'Sul') {
      return 'purple.500';
    }
    if (region === 'Nordeste') {
      return 'cyan.500';
    }
    if (region === 'Sudeste') {
      return 'brand.500';
    } else {
      return 'gray.500';
    }
  }

  const hasAccessDashboard =
    props.person?.permissions?.includes('dashboard-view');

  return (
    <Flex justifyContent={'center'} alignItems={'center'}>
      <Card style={{ maxWidth: 1324 }}>
        {!hasAccessDashboard ? (
          <Flex borderRadius={8} p={4} borderWidth={1} flexDir={'column'}>
            <Flex justifyContent={'center'}>
              <HeaderTextComponent>{`${props.person?.name}, Seja bem vindo ao sistema da TELLINK `}</HeaderTextComponent>
            </Flex>

            <Flex
              alignItems={'center'}
              justifyContent={'space-between'}
              height={'80vh'}
            >
              <Image
                boxSize={'40%'}
                objectFit={'contain'}
                src={welComeLeftIcon}
                alt={'Mapa do Brasil'}
              />
              <Image
                boxSize={'40%'}
                objectFit={'contain'}
                src={welcomeIcon}
                alt={'Mapa do Brasil'}
              />
              <Image
                boxSize={'40%'}
                objectFit={'contain'}
                src={welComeRightIcon}
                alt={'Mapa do Brasil'}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex direction={'column'}>
            <Flex>
              <Flex p={2} w={'70%'} borderWidth={1} flexDir={'column'}>
                <Flex flexDir={'column'}>
                  <HeaderTextComponent mb={2} as={'b'} fontSize={20}>
                    Estatísticas por Região
                  </HeaderTextComponent>
                  <Flex alignItems={'center'}>
                    <Box>
                      {locationOrdenatList?.map((e, i) => (
                        <CardPercentageComponent
                          p={2}
                          borderRadius={'0px'}
                          bg={bgCardRegionColor(e.region)}
                          icon={RegionIcon}
                          title={`${e.region}`}
                          value={`${convertToMonetaryValue(e.totalValue)}`}
                          quantity={e.count || 0}
                        />
                      ))}
                    </Box>
                    <Box
                      position="relative"
                      borderRadius={8}
                      justifyContent={'center'}
                    >
                      <InfoFloat
                        bottom="45%"
                        right="50%"
                        tooltip={locationDashboardList.data?.data[0]?.region}
                        infoTitle={`${convertToMonetaryValue(
                          locationDashboardList.data?.data[0]?.totalValue ?? 0
                        )}`}
                        fs={12}
                        color="black"
                      />
                      <InfoFloat
                        top="38%"
                        right="23%"
                        tooltip={locationDashboardList.data?.data[1]?.region}
                        infoTitle={`${convertToMonetaryValue(
                          locationDashboardList.data?.data[1]?.totalValue ?? 0
                        )}`}
                        fs={12}
                        color="black"
                      />
                      <InfoFloat
                        top="27%"
                        right="60%"
                        tooltip={locationDashboardList.data?.data[2]?.region}
                        infoTitle={`${convertToMonetaryValue(
                          locationDashboardList.data?.data[2]?.totalValue ?? 0
                        )}`}
                        fs={12}
                        color="white"
                      />
                      <InfoFloat
                        top="59%"
                        right="28%"
                        tooltip={locationDashboardList.data?.data[3]?.region}
                        infoTitle={`${convertToMonetaryValue(
                          locationDashboardList.data?.data[3]?.totalValue ?? 0
                        )}`}
                        fs={12}
                        color="white"
                      />
                      <InfoFloat
                        top="76%"
                        right="40%"
                        tooltip={locationDashboardList.data?.data[4]?.region}
                        infoTitle={`${convertToMonetaryValue(
                          locationDashboardList.data?.data[4]?.totalValue ?? 0
                        )}`}
                        fs={12}
                        color="white"
                      />
                      <Image
                        boxSize={'100%'}
                        objectFit={'contain'}
                        src={MapIcon}
                      ></Image>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
              <Flex ml={2} p={2} borderWidth={1} flexDir={'column'} w={'30%'}>
                <HeaderTextComponent mb={2} as={'b'} fontSize={20}>
                  Estatísticas de contratos
                </HeaderTextComponent>

                <Flex w={'100%'} justifyContent={'center'}>
                  <Box>
                    <CardPercentageComponent
                      icon={CalendarIcon}
                      p={2}
                      borderRadius={'0px'}
                      bg={'gray.700'}
                      title={`Vencidos`}
                      value={convertToMonetaryValue(
                        contractDetailsDashboardList.data?.data.expiredValue ??
                          0
                      )}
                      quantity={
                        contractDetailsDashboardList.data?.data.expiredCount ??
                        0
                      }
                    />
                    <CardPercentageComponent
                      icon={CalendarIcon}
                      p={2}
                      borderRadius={'0px'}
                      bg={'gray.500'}
                      title={`A vencer`}
                      value={convertToMonetaryValue(
                        contractDetailsDashboardList.data?.data
                          .willExpireValue ?? 0
                      )}
                      quantity={
                        contractDetailsDashboardList.data?.data
                          .willExpireCount ?? 0
                      }
                    />
                    <CardPercentageComponent
                      icon={CalendarIcon}
                      p={2}
                      borderRadius={'0px'}
                      bg={'gray.700'}
                      title={`Em até 3 meses`}
                      subTitle="A vencer"
                      subTitleType="decrease"
                      value={convertToMonetaryValue(
                        contractDetailsDashboardList.data?.data
                          .expiringInUpToThreeMonthValue ?? 0
                      )}
                      quantity={
                        contractDetailsDashboardList.data?.data
                          .expiringInUpToThreeMonthCount ?? 0
                      }
                    />

                    <CardPercentageComponent
                      icon={CalendarIcon}
                      p={2}
                      borderRadius={'0px'}
                      bg={'gray.500'}
                      title={`Em até 12 meses`}
                      subTitle="A vencer"
                      subTitleType="decrease"
                      value={convertToMonetaryValue(
                        contractDetailsDashboardList.data?.data
                          .expiringInUpToOneYearValue ?? 0
                      )}
                      quantity={
                        contractDetailsDashboardList.data?.data
                          .expiringInUpToOneYearCount ?? 0
                      }
                    />
                    <CardPercentageComponent
                      icon={CalendarIcon}
                      p={2}
                      borderRadius={'0px'}
                      bg={'gray.700'}
                      title={`Em mais de 12 meses`}
                      subTitle="A vencer"
                      subTitleType="decrease"
                      value={convertToMonetaryValue(
                        contractDetailsDashboardList.data?.data
                          .expiringInOverAYearValue ?? 0
                      )}
                      quantity={
                        contractDetailsDashboardList.data?.data
                          .expiringInOverAYearValue ?? 0
                      }
                    />
                  </Box>
                </Flex>
              </Flex>
            </Flex>

            <Flex mt={2} p={2} borderWidth={1} flexDir={'column'}>
              <HeaderTextComponent mb={2} as={'b'} fontSize={20}>
                Referência: Base total (sem período)
              </HeaderTextComponent>
              <Flex justifyContent={'space-between'}>
                <CardPercentageComponent
                  title="Quantidade de contratos"
                  subTitle="Contratos"
                  subTitleType="decrease"
                  icon={MoneyIcon}
                  value={
                    constractCardsDashboardList.data?.data.count.toString() ?? 0
                  }
                  bg="purple.500"
                  cardWidth={360}
                />
                <CardPercentageComponent
                  title="Valor total nominal do aluguel"
                  subTitle="Aluguel"
                  subTitleType="decrease"
                  icon={MoneyIcon}
                  value={convertToMonetaryValue(
                    constractCardsDashboardList.data?.data.totalValue ?? 0
                  )}
                  bg="green.500"
                  cardWidth={360}
                />
                <CardPercentageComponent
                  title="Valor médio nominal do aluguel"
                  subTitle="Aluguel"
                  subTitleType="decrease"
                  icon={MoneyIcon}
                  value={convertToMonetaryValue(
                    constractCardsDashboardList.data?.data.averageValue ?? 0
                  )}
                  bg="brand.500"
                  cardWidth={360}
                />
              </Flex>
            </Flex>
            <Flex mt={2} p={2} borderWidth={1} justifyContent={'space-between'}>
              <Flex w={'74%'} flexDir={'column'}>
                <HeaderTextComponent mb={2} as={'b'} fontSize={20}>
                  Campanhas (detalhes)
                </HeaderTextComponent>
                <TableComponent
                  ItemsHeader={[
                    { item: 'Nome ' },
                    { item: 'Quantidade' },
                    { item: 'Valor Nominal' },
                  ]}
                  isLoading={campaignDashboardList.isLoading}
                  data={[
                    {
                      items: [
                        'Campanha de Renovação',
                        campaignDashboardList.data?.data.renovationCount,
                        convertToMonetaryValue(
                          campaignDashboardList.data?.data.renovationValue ?? 0
                        ),
                      ],
                    },
                    {
                      items: [
                        'Campanha de Reajuste',
                        campaignDashboardList.data?.data.readjustmentCount,
                        convertToMonetaryValue(
                          campaignDashboardList.data?.data.readjustmentValue ??
                            0
                        ),
                      ],
                    },
                  ]}
                />
              </Flex>
              <Flex>
                <DashBoardPieComponent
                  dashSize={160}
                  header={{
                    title: 'Contratos em Andamento X concluído',
                  }}
                  total={
                    campaignDashboardList.data?.data.totalsContractCount ?? 0
                  }
                  colorScale={['#3182CE', '#38A169']}
                  data={[
                    {
                      x: 'Em andamento',
                      y: getPercentage(
                        campaignDashboardList.data?.data
                          .inProgressContractCount ?? 0,
                        campaignDashboardList.data?.data.totalsContractCount ??
                          0
                      ),
                    },
                    {
                      x: 'Concluído',
                      y: getPercentage(
                        campaignDashboardList.data?.data
                          .finishedContractCount ?? 0,
                        campaignDashboardList.data?.data.totalsContractCount ??
                          0
                      ),
                    },
                  ]}
                />
              </Flex>
            </Flex>

            <Flex mt={2}>
              <Flex w={'68%'}>
                <DashboardTableComponent
                  title="Indicadores de inflação"
                  subTitle="(últimos três meses)"
                  tHead={['', ...lastMonthsIndexes, 'Acumulado 12 meses']}
                  tBody={
                    readjustmentIndexDashboardList.data?.data?.map(
                      (e: ReadjustmentIndexResp) => [
                        e.name,
                        (e.references[2]?.monthValue ?? 0) + '%',
                        (e.references[1]?.monthValue ?? 0) + '%',
                        (e.references[0]?.monthValue ?? 0) + '%',
                        e.lastYearValue + '%',
                      ]
                    ) || []
                  }
                />
              </Flex>
              <Flex ml={2} p={2} borderWidth={1} flexDir={'column'}>
                <HeaderTextComponent mb={2} as={'b'} fontSize={20}>
                  Quantidade contratos x Índices
                </HeaderTextComponent>
                <TableComponent
                  ItemsHeader={[
                    { item: 'Índice ' },
                    { item: 'Valor nominal' },
                    { item: 'Quantidade' },
                  ]}
                  isLoading={contractsAndIndexDashboardList.isLoading}
                  data={
                    contractsAndIndexDashboardList.data?.data?.map(
                      (e: ContractAndIndexResp) => ({
                        items: [
                          e.name,
                          convertToMonetaryValue(e.totalValue),
                          e.contractCount,
                        ],
                      })
                    ) || []
                  }
                />
              </Flex>
            </Flex>

            <Flex mt={2} p={2} borderWidth={1} flexDir={'column'}>
              <HeaderTextComponent mb={2} as={'b'} fontSize={20}>
                Estatísticas por vencimento e mês
              </HeaderTextComponent>
              <TableVariant data={locatorStatementDashboarList.data?.data} />
            </Flex>
          </Flex>
        )}
      </Card>
    </Flex>
  );
}
function TableVariant({ data }: { data?: locatorStatementResp }) {
  const months = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ];
  return (
    <TableContainer>
      <Table variant="unstyled">
        <Thead
          color={'white'}
          borderTopLeftRadius={8}
          borderTopRightRadius={8}
          bg={'gray.400'}
        >
          <Tr>
            <Td textAlign={'center'} fontWeight={'semibold'} rowSpan={2}>
              ANOS
            </Td>
            <Td textAlign={'center'} fontWeight={'semibold'} rowSpan={2}>
              ACRÉCISMOS{' '}
            </Td>
          </Tr>

          <Tr>
            {months.map((e) => (
              <Td textAlign={'center'} fontWeight={'semibold'}>
                {e.toUpperCase()}
              </Td>
            ))}
            <Td>Total</Td>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((e) => (
            <Fragment key={e.year}>
              <Tr>
                <Td rowSpan={2}>{e.year}</Td>
                <Td>Quantidade</Td>
                {e.references.map((e) => (
                  <Td textAlign={'center'}>{e.count}</Td>
                ))}
                <Td>{getSum(e.references.map((r) => r.count))}</Td>
              </Tr>
              <Tr>
                <Td>Valor</Td>
                {e.references.map((e) => (
                  <Td textAlign={'center'}>
                    {convertToMonetaryValue(e.value)}
                  </Td>
                ))}
                <Td>
                  {convertToMonetaryValue(
                    getSum(e.references.map((r) => r.value))
                  )}
                </Td>
              </Tr>
              <Flex
                bg={'gray.300'}
                position={'absolute'}
                w={'96%'}
                height={0.5}
              ></Flex>
            </Fragment>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function InfoFloat(props: {
  infoTitle?: string;
  tooltip?: string | number;
  color?: string;
  fs?: string | number;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}) {
  return (
    <Tooltip cursor={'pointer'} label={props.tooltip}>
      <Tag
        top={props.top}
        bottom={props.bottom}
        left={props.left}
        right={props.right}
        position={'absolute'}
        colorScheme="brand"
      >
        {props.infoTitle}
      </Tag>
    </Tooltip>
  );
}

function getPercentage(value: number, total: number) {
  const percentageValue = (value / total) * 100;
  return `${percentageValue.toFixed(2)} %`;
}
