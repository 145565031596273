import { Flex, Stack, Tag, Tooltip, useToast } from '@chakra-ui/react';
import HeaderTextComponent from '../components/UI/atoms/HeaderTextComponent/HeaderTextComponent';
import TableComponent from '../components/UI/molecules/TableComponent/TableComponent';
import ModalStructureComponent from '../components/UI/molecules/ModalStructureComponent/ModalStructureComponent';
import React, { useEffect, useState } from 'react';
import InputComponent from '../components/UI/atoms/InputComponent/InputComponent';
import ButtonComponent from '../components/UI/atoms/ButtonComponent/ButtonComponent';
import DropZoneListComponent, {
  FileInfo,
} from '../components/UI/molecules/DropZoneListComponent/DropZoneListComponent';
import { SelectLocatorModalComponent } from '../components/UI/organisms/SelectLocatorModalComponent/SelectLocatorModalComponent';
import { showToast } from '../utils/showToast';
import { useParams } from 'react-router-dom';
import {
  MessageStatus,
  PersonMessagesResp,
  postPersonMessages,
  putPersonMessageResend,
  usePersonMessagesById,
} from '../services/MessagesService';
import { DownloadIcon } from '@chakra-ui/icons';
import IconButtonComponent from '../components/UI/atoms/ButtonComponent/IconButton';
import {
  formatDate,
  formatDateTimeBrasilia,
} from '../utils/functions/formatDate';
import { addSpreadText, downloadFiles } from '../utils/functions/utility';
import { LocatorPersonType } from '../services/LocatorService';
import { usePhysicalPersonById } from '../services/PhysicalPersonService';
import { useJuridicalPersonById } from '../services/JuridicalPersonService';
import { UseQueryResult } from '@tanstack/react-query';
import {
  PaginationComponent,
  usePagination,
} from '../components/UI/molecules/PaginationComponent/PaginationComponent';

function usePhysicalOrJuridicalPerson(
  physicalOrJuridicalPersonId?: string,
  type?: LocatorPersonType
) {
  const physical = usePhysicalPersonById(
    physicalOrJuridicalPersonId ?? '',
    type === LocatorPersonType.Physical
  );
  const juridical = useJuridicalPersonById(
    physicalOrJuridicalPersonId ?? '',
    type === LocatorPersonType.Juridical
  );
  return type === LocatorPersonType.Physical ? physical : juridical;
}

function getTypeFromPath(path?: string) {
  return path === 'physical'
    ? LocatorPersonType.Physical
    : LocatorPersonType.Juridical;
}

export default function PersonMessagePage() {
  const params = useParams<{
    id: string;
    physicalOrJuridicalPersonId: string;
    type: 'physical' | 'juridical';
  }>();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const personMessagesList = usePersonMessagesById(
    params.id,
    selectedPage,
    pageSize,
    ''
  );
  const person = usePhysicalOrJuridicalPerson(
    params.physicalOrJuridicalPersonId,
    getTypeFromPath(params.type)
  );
  const personName = person.data?.name;
  const [loadingMessageResend, setLoadingMessageResend] = React.useState(false);
  const [showModalNewMessage, setShowModalNewMesage] = React.useState(false);
  const [indexMessage, setIndexMessage] = useState<number | null>(null);

  useEffect(() => {
    personMessagesList.refetch();
  }, [personMessagesList]);

  return (
    <Flex flexDir={'column'}>
      <HeaderTextComponent goBack>Mensagens enviadas</HeaderTextComponent>
      <Flex justifyContent={'end'} mb={2}>
        <ButtonComponent
          onSubmit={() => {
            setShowModalNewMesage(true);
          }}
        >
          Nova Mensagem
        </ButtonComponent>
      </Flex>
      <Flex>
        <PersonMessagesTableComponent
          pagination={{ pageSize, selectedPage, setSelectedPage }}
          indexMessage={indexMessage}
          loadingMessageResend={loadingMessageResend}
          personMessagesList={personMessagesList}
          setIndexMessage={setIndexMessage}
          setLoadingMessageResend={setLoadingMessageResend}
        />
      </Flex>
      <NewMessageSend
        showModal={showModalNewMessage}
        onClose={() => {
          setShowModalNewMesage(false);
        }}
        receiver={{ id: params.id ?? '', name: personName ?? '' }}
      />
    </Flex>
  );
}
export function NewMessageSend(props: {
  showModal: boolean;
  onClose: () => void;
  receiver: { id: string; name: string };
}) {
  const toast = useToast();
  const [showModalLocator, setShowModalLocator] = React.useState(false);
  const [message, setMessage] = React.useState<{
    subject: string;
    message: string;
    receiver: { id: string; name: string };
    attachmentIds: string[];
  }>({ subject: '', message: '', receiver: props.receiver, attachmentIds: [] });
  const [loadingMessage, setIsLoadingMessage] = React.useState(false);
  const [fileInfos, setFileInfos] = React.useState<FileInfo[]>([]);
  const fileIds = fileInfos.map((fileInfo) => fileInfo.id);

  useEffect(() => {
    setMessage((prevValue) => ({ ...prevValue, receiver: props.receiver }));
  }, [props.receiver]);

  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        props.onClose();
        setMessage({
          attachmentIds: [],
          message: '',
          receiver: {
            id: props.receiver.id,
            name: props.receiver.name,
          },
          subject: '',
        });
        setFileInfos([]);
      }}
      size={'6xl'}
      title="Nova Mensagem"
      children={
        <>
          <Flex flexDir={'column'}>
            <HeaderTextComponent mb={10} subTitle>
              Mensageria
            </HeaderTextComponent>
            <Stack spacing={4}>
              <Flex>
                <InputComponent
                  label="Destinatário"
                  placeholder="Destinatário"
                  value={message.receiver.name}
                  disabled
                />

                <Flex alignItems={'flex-end'}>
                  {/* <ButtonComponent
                    width={'300px'}
                    onSubmit={() => setShowModalLocator(true)}
                  >
                    Selecione os Destinatários
                  </ButtonComponent> */}
                </Flex>
              </Flex>
              <InputComponent
                label="Assunto"
                placeholder="Digite o assunto"
                value={message.subject}
                onChange={(input) =>
                  setMessage({ ...message, subject: input.target.value })
                }
              />
              <InputComponent
                type="textArea"
                label="Mensagem"
                placeholder="Digite a mensagem"
                value={message.message}
                onChange={(input) =>
                  setMessage({ ...message, message: input.target.value })
                }
              />
              <DropZoneListComponent
                fileInfos={fileInfos}
                setFileInfos={setFileInfos}
              />
            </Stack>
          </Flex>
          <SelectLocatorModalComponent
            onAcceptModal={(result) => {
              setMessage({ ...message, receiver: props.receiver });
            }}
            showModal={showModalLocator}
            setShowModal={() => setShowModalLocator(false)}
          />
        </>
      }
      footer={
        <Flex m={4}>
          <ButtonComponent
            isLoading={loadingMessage}
            disabled={loadingMessage}
            onSubmit={() => {
              setIsLoadingMessage(true);
              postPersonMessages({
                description: message.message,
                subject: message.subject,
                personId: message.receiver.id,
                attachmentIds: fileIds,
              })
                .then(() => {
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Enviado com sucesso',
                  });
                })
                .finally(() => {
                  setIsLoadingMessage(false);
                  props.onClose();
                  setMessage({
                    attachmentIds: [],
                    message: '',
                    receiver: {
                      id: props.receiver.id,
                      name: props.receiver.name,
                    },
                    subject: '',
                  });
                  setFileInfos([]);
                });
            }}
          >
            Enviar
          </ButtonComponent>
        </Flex>
      }
    />
  );
}
export function PersonMessagesTableComponent(props: {
  pagination: {
    pageSize: number;
    selectedPage: number;
    setSelectedPage: (page: number) => void;
  };
  setLoadingMessageResend: (b: boolean) => void;
  loadingMessageResend: boolean;
  setIndexMessage: (i: number) => void;
  indexMessage: number | null;
  personMessagesList: UseQueryResult<
    {
      data: [PersonMessagesResp];
      metadata: {
        sortColumn: string;
        sortDirection: string;
        offset: number;
        limit: number;
        quickSearch: string;
        dataSize: number;
      };
    },
    unknown
  >;
}) {
  const toast = useToast();

  function onSelectedPageChanged(page: number) {
    props.pagination.setSelectedPage(page);
  }
  const headerTableMessage = [
    {
      item: 'Data e hora de envio',
    },
    {
      item: 'Remetente',
    },
    {
      item: 'Destinatário',
    },
    {
      item: 'Assunto',
    },
    {
      item: 'Descrição ',
    },
    {
      item: 'Download de anexos ',
    },
    {
      item: 'Status ',
    },
    {
      item: 'Ações ',
    },
  ];

  function downloadFile(e: PersonMessagesResp) {
    downloadFiles(e.attachments.map((a) => a.url));
  }

  function getStatusBgColor(status: MessageStatus) {
    if (status === MessageStatus.Delivered) return 'blue';
    if (status === MessageStatus.DeliveredWithoutEmailNotification)
      return 'yellow';
    if (status === MessageStatus.DeliveredWithoutNotificationApp)
      return 'yellow';
    if (status === MessageStatus.EmailAndAppNotificationNotDelivered)
      return 'red';
    if (status === MessageStatus.Read) return 'green';
  }
  return (
    <Flex flexDir={'column'}>
      <TableComponent
        ItemsHeader={headerTableMessage}
        centered={true}
        emptyState={props.personMessagesList.data?.metadata.dataSize === 0}
        data={
          props.personMessagesList.data?.data.map((e, i) => ({
            items: [
              formatDateTimeBrasilia(e.sentIn),
              e.senderName,
              e.receiverName,
              e.subject,
              <Tooltip label={e.description}>
                {addSpreadText(e.description, 28)}
              </Tooltip>,

              <IconButtonComponent
                toolTipText={
                  e.attachments.length <= 0 ? 'Sem anexos' : 'Baixar anexos'
                }
                disabled={e.attachments.length <= 0}
                icon={<DownloadIcon />}
                ariaLabel="Anexos"
                onSubmit={() => {
                  downloadFile(e);
                }}
              />,
              <Tooltip
                label={
                  e.statusDescripton === 'Lida'
                    ? `Data de leitura ${formatDate(e.readIn)}`
                    : ''
                }
              >
                <Tag size={'sm'} whiteSpace={'break-spaces'} w={160} colorScheme={getStatusBgColor(e.status)}>
                  {e.statusDescripton}
                </Tag>
              </Tooltip>,
              <Tooltip
                label={
                  e.wasRead &&
                  'Não e possivel reenviar menssages que já foram lidas'
                }
              >
                <Flex>
                  <ButtonComponent
                    disabled={e.wasRead}
                    isLoading={
                      i === props.indexMessage
                        ? props.loadingMessageResend
                        : false
                    }
                    onSubmit={() => {
                      props.setIndexMessage(i);
                      props.setLoadingMessageResend(true);
                      putPersonMessageResend(e.id)
                        .then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description: 'Reenviado com sucesso',
                          });
                        })
                        .finally(() => props.setLoadingMessageResend(false));
                    }}
                  >
                    Reenviar
                  </ButtonComponent>
                </Flex>
              </Tooltip>,
            ],
          })) || []
        }
        isLoading={props.personMessagesList.isLoading}
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={props.pagination.selectedPage}
        arrayLength={props.personMessagesList.data?.metadata.dataSize || 0}
        maxPageItens={props.pagination.pageSize}
      ></PaginationComponent>
    </Flex>
  );
}
