import { As, Flex, ResponsiveValue, Text } from '@chakra-ui/react';
import IconButtonComponent from '../ButtonComponent/IconButton';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export type HeaderTextProps = {
  children: React.ReactNode;
  color?: string;
  subTitle?: boolean;
  fontSize?: string | number;
  mb?: string | number;
  mt?: string | number;
  textAlign?: ResponsiveValue<any>;
  as?: As;
  maxW?: string;
  goBack?: boolean;
  onClick?: () => void;
  backLink?: string;
};

export default function HeaderTextComponent(props: HeaderTextProps) {
  const navigate = useNavigate();
  return (
    <Flex onClick={props.onClick} alignItems={'center'}>
      {props.goBack && (
        <IconButtonComponent
          variant={'link'}
          color={'brand.500'}
          toolTipText="Voltar"
          icon={<ChevronLeftIcon fontSize={26} />}
          ariaLabel="editar"
          colorScheme={'green'}
          onSubmit={() => {
            if (props.backLink) {
              navigate(props.backLink);
              return;
            }
            navigate(-1);
          }}
        />
      )}

      <Text
        maxW={props.maxW}
        mt={props.mt}
        mb={props.mb}
        color={props.subTitle ? props.color || 'gray.500' : props.color}
        fontSize={
          props.subTitle ? props.fontSize || '2xl' : props.fontSize || '3xl'
        }
        fontWeight={props.subTitle ? 'light' : 'semibold'}
        textAlign={props.textAlign}
      >
        {props.children}
      </Text>
    </Flex>
  );
}
