import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  useToast,
} from '@chakra-ui/react';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import useStateList, {
  useAddressCep,
  useCityList,
  useCountryList,
} from '../../../../services/StateServices';
import {
  PhysicalPerson,
  savePhysicalPerson,
} from '../../../../services/PhysicalPersonService';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import DropzoneModel from '../uploadModel/DropZoneModel';
import { showToast } from '../../../../utils/showToast';
import React, { useState } from 'react';
import {
  cepMask,
  cleanNumberMask,
  cpfMask,
  phoneMask,
} from '../../../../utils/functions/masks';
import {
  filterProfiles,
  profileRemove,
  useProfileSelect,
} from '../../../../services/ProfileService';
import {
  getFirstIfAny,
  validateOptionalFields,
} from '../../../../utils/functions/utility';
import { useUserData } from '../../../../services/LoginService';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '../../../../utils/functions/debounce';

export type ProfileFormProps = {
  form: PhysicalPerson;
  setForm:
    | React.Dispatch<React.SetStateAction<PhysicalPerson | undefined>>
    | React.Dispatch<React.SetStateAction<PhysicalPerson>>;
};

export default function MyRegisterFormComponent(props: ProfileFormProps) {
  const toast = useToast();
  const [profileUrl, setProfileUrl] = useState(props.form.profilePicUrl);
  const user = useUserData();
  const listProfiles = useProfileSelect();
  const { data: countries } = useCountryList();
  const { data: states } = useStateList(
    props.form?.addresses[0].countryId || ''
  );
  const { data: cities } = useCityList(props.form?.addresses[0].stateId || '');
  const navigate = useNavigate();

  const [, searchCep, setSearchCep] = useDebounce('');
  const { data: dataCep } = useAddressCep(searchCep);
  React.useEffect(() => {
    if (dataCep) {
      props.setForm((form: any) => ({
        ...form,
        addresses: [
          {
            ...form.addresses[0],
            street: dataCep.street,
            district: dataCep.district,
            countryId: dataCep.countryId,
            stateId: dataCep.stateId,
            cityId: dataCep.cityId,
          },
        ],
      }));
    }
  }, [dataCep, props]);
  return (
    <Box backgroundColor={'white'} padding={'20px'}>
      <Flex flexDirection={'column'} mb={'30px'}>
        <HeaderTextComponent>
          {props.form.id ? 'Meu Cadastro' : 'Cadastrar Usuário'}
        </HeaderTextComponent>
        <HeaderTextComponent subTitle>
          {props.form.id
            ? 'Você pode editar suas informações pessoais de cadastro'
            : 'Crie uma novo usuário'}
        </HeaderTextComponent>
      </Flex>
      <Flex>
        <Stack spacing={5} width={'90%'} marginRight={'5%'}>
          <HeaderTextComponent subTitle>
            Informações pessoais
          </HeaderTextComponent>
          <InputComponent
            value={props.form.firstName}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                firstName: input.target.value,
              })
            }
            label="Nome"
            placeholder="Digite o nome"
          />
          <InputComponent
            value={props.form.lastName}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                lastName: input.target.value,
              })
            }
            label="Sobrenome"
            placeholder="Digite o sobrenome"
          />
          <InputComponent
            value={props.form.displayName}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                displayName: input.target.value,
              })
            }
            label="Apelido"
            placeholder="Digite o nome fantasia"
          />
          <InputComponent
            value={props.form.document}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                document: cpfMask(input.target.value),
              })
            }
            label="CPF"
            placeholder="000.000.000-00"
          />
          <InputComponent
            value={props.form.date}
            onChange={(input) =>
              props.setForm({ ...props.form, date: input.target.value })
            }
            type="date"
            label="Data de Nascimento"
            placeholder="00/00/00000"
          />
          <FormControl>
            <InputSelectComponent
              defaultValue={String(props.form.gender)}
              options={[
                { id: '1', name: 'Masculino' },
                { id: '2', name: 'Feminino' },
              ]}
              onChange={(input) =>
                props.setForm({
                  ...props.form,
                  gender: Number(input.target.value),
                })
              }
              label="Gênero"
              placeholder="Selecione o seu gênero"
            />
          </FormControl>
          <InputComponent
            value={props.form.phones[0].number}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                phones: [
                  {
                    ...props.form.phones[0],
                    number: phoneMask(input.target.value),
                  },
                ],
              })
            }
            label="Celular"
            placeholder="(XX) 9 XXXX-XXXX"
          />
          <FormControl>
            <InputSelectComponent
              defaultValue={props.form.user?.profileId}
              options={filterProfiles(listProfiles.data || [])}
              onChange={(input) =>
                props.setForm({
                  ...props.form,
                  user: { ...props.form.user, profileId: input.target.value },
                })
              }
              label="Perfil"
              placeholder="Selecione um perfil"
            />
          </FormControl>
          <Flex alignItems={'center'}>
            <FormLabel>Foto</FormLabel>
            <DropzoneModel
              setProfilePicUrl={setProfileUrl}
              fileUrl={props.form.profilePicUrl}
              type="profile"
            />
          </Flex>
        </Stack>
        <Stack spacing={5} width={'100%'}>
          <HeaderTextComponent subTitle>
            Informações de endereço
          </HeaderTextComponent>
          <InputComponent
            value={props.form.addresses[0].zipcode}
            onChange={(input) => {
              setSearchCep(cleanNumberMask(input.target.value));
              props.setForm({
                ...props.form,
                addresses: [
                  {
                    ...props.form.addresses[0],
                    zipcode: cepMask(input.target.value),
                  },
                ],
              });
            }}
            label="CEP"
            placeholder="00.000-000"
          />
          <InputComponent
            value={props.form.addresses[0].street}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                addresses: [
                  {
                    ...props.form.addresses[0],
                    street: input.target.value,
                  },
                ],
              })
            }
            label="Logradouro"
            placeholder="Digite o Logradouro"
          />
          <InputComponent
            value={props.form.addresses[0].complement}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                addresses: [
                  {
                    ...props.form.addresses[0],
                    complement: input.target.value,
                  },
                ],
              })
            }
            label="Número"
            placeholder="Digite o número"
          />
          <InputComponent
            value={props.form.addresses[0].district}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                addresses: [
                  {
                    ...props.form.addresses[0],
                    district: input.target.value,
                  },
                ],
              })
            }
            label="Bairro"
            placeholder="Digite o bairro"
          />
          <FormControl>
            <InputSelectComponent
              defaultValue={props.form.addresses[0].countryId}
              onChange={(input) =>
                props.setForm({
                  ...props.form,
                  addresses: [
                    {
                      ...props.form.addresses[0],
                      countryId: input.target.value,
                    },
                  ],
                })
              }
              options={countries}
              placeholder="Selecione o País"
              label="País"
            />
          </FormControl>
          <FormControl>
            <InputSelectComponent
              defaultValue={props.form.addresses[0].stateId}
              onChange={(input) =>
                props.setForm({
                  ...props.form,
                  addresses: [
                    {
                      ...props.form.addresses[0],
                      stateId: input.target.value,
                    },
                  ],
                })
              }
              options={states}
              placeholder="Selecione o estado"
              label="Estado"
            />
          </FormControl>
          <FormControl>
            <InputSelectComponent
              defaultValue={props.form.addresses[0].cityId}
              options={cities}
              onChange={(input) =>
                props.setForm({
                  ...props.form,
                  addresses: [
                    {
                      ...props.form.addresses[0],
                      cityId: input.target.value,
                    },
                  ],
                })
              }
              label="Cidade"
              placeholder="Selecione uma cidade"
            />
          </FormControl>
          <InputComponent
            margin={'0 0 10px 0'}
            value={props.form.user?.email}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                user: { ...props.form.user, email: input.target.value },
              })
            }
            label="email"
            placeholder="example@lll.com"
          />
        </Stack>
      </Flex>

      <Flex justifyContent={'end'}>
        <ButtonComponent
          margin={'0 20px 0 20px'}
          disabled={validateOptionalFields(props.form, [
            'employee',
            'profilePicUrl',
            'companyId',
            'id',
            'detail',
            'name',
          ])}
          colorScheme="blue"
          variant="solid"
          onSubmit={() => {
            savePhysicalPerson({
              ...props.form,
              document: cleanNumberMask(props.form.document),
              name: `${props.form.firstName} ${props.form.lastName}`,
              phones: [
                {
                  ...props.form.phones[0],
                  number: cleanNumberMask(props.form.phones[0].number),
                },
              ],
              addresses: [
                {
                  ...props.form.addresses[0],
                  zipcode: cleanNumberMask(props.form.addresses[0].zipcode),
                },
              ],
              profilePicUrl: profileUrl,
              user: {
                ...props.form.user,
                username: !props.form.id
                  ? props.form.user?.email
                  : props.form.user?.username,
                entities: [user?.entity?.id || ''],
              },
            }).then((res) => {
              showToast({
                toast,
                status: 'success',
                title: 'Sucesso',
                description: 'Salvo com sucesso',
              });
              if (
                props.form?.id &&
                getFirstIfAny(props.form.profiles)?.id !==
                  props.form.user?.profileId
              ) {
                profileRemove({
                  profileId: getFirstIfAny(props.form.profiles)?.id || '',
                  userId: props.form.user?.id || '',
                  entityId: user?.entity?.id || '',
                });
              }
              navigate(`/person`);
            });
          }}
        >
          Salvar
        </ButtonComponent>
      </Flex>
    </Box>
  );
}
