import { FaEdit, FaInfo, FaRegTimesCircle, FaTrash } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import { DescriptionLabelComponent } from '../../UI/molecules/DescriptionlabelComponent/DescriptionLabelComponent';
import React from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { showToast } from '../../../utils/showToast';
import DetailsModalComponent from '../../UI/molecules/DetailsModalComponent/DetailsModalComponent';
import { parseBrazilDate } from '../../../utils/functions/utility';
import {
  deleteEquipment,
  EquipmentResp,
  getEquipmentReport,
  putEquipmentActive,
  putEquipmentDisable,
  StatusEquipment,
  useEquipment,
} from '../../../services/EquipmentService';
import { CreateEquipmentModalComponent } from '../../UI/organisms/CreateEquipmentModalComponent/CreateEquipmentModalComponent';
import { useUserData } from '../../../services/LoginService';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';

export default function EquipmentTemplateComponent() {
  const [selectedEquipment, setSelectedEquipment] = React.useState<
    EquipmentResp | undefined
  >();
  const toast = useToast();
  const user = useUserData();
  const modalCardInfo = [
    { item: 'Número', description: selectedEquipment?.serialNumber },
    { item: 'Descrição', description: selectedEquipment?.description },
    {
      item: 'Criado em',
      description: parseBrazilDate(selectedEquipment?.createdAt),
    },
    { item: 'Tipo', description: selectedEquipment?.typeDescription },
    { item: 'Status', description: selectedEquipment?.statusDescription },
    {
      item: 'Contrato Ativo?',
      description: selectedEquipment?.hasActiveContract ? 'Sim' : 'Não',
    },
  ];
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModal, setShowModal] = React.useState(false);
  const [statusEquipment, setStatusEquipment] = React.useState<StatusEquipment>(
    StatusEquipment.Active
  );
  const listEquipment = useEquipment(
    selectedPage,
    pageSize,
    '',
    'serialNumber',
    'serialNumber',
    'asc',
    statusEquipment
  );
  const [detailsDialog, setDetailsDialog] = React.useState(false);

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const tabName = [
    {
      title: 'Ativos',
      onClick: () => {
        setStatusEquipment(StatusEquipment.Active);
      },
    },
    {
      title: 'Desabilitados',
      onClick: () => {
        setStatusEquipment(StatusEquipment.Inactive);
      },
    },
  ];
  return (
    <>
      <Stack mb={4}>
        <HeaderTextComponent goBack>Tipos de Equipamento</HeaderTextComponent>
      </Stack>
      <Flex mb={2} justifyContent={'space-between'}>
        <Flex>
          <TabsFilterComponent tabList={tabName} />
        </Flex>
        <Flex>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              getEquipmentReport();
            }}
          >
            Exportar
          </ButtonComponent>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              setSelectedEquipment(undefined);
              setShowModal(true);
            }}
          >
            Novo Cadastro
          </ButtonComponent>
        </Flex>
      </Flex>

      <TableComponent
        ItemsHeader={[
          { item: 'Descrição' },
          { item: 'Proprietário' },
          { item: 'Status' },
          { item: 'Tipo' },
          { item: 'Contrato Vinculado' },
          { item: 'Ação' },
        ]}
        isLoading={listEquipment.isLoading}
        centered={true}
        data={
          listEquipment.data?.data.map((e, i) => ({
            items: [
              <DescriptionLabelComponent
                label={e.description}
                subLabel={e.serialNumber}
                key={i}
              />,
              user?.entity?.name,
              e.status === StatusEquipment.Active ? (
                <TagComponent size={'md'} colorScheme={'green'} text="Ativo" />
              ) : (
                <TagComponent size={'md'} colorScheme={'red'} text="Inativo" />
              ),
              e.typeDescription,

              e.hasActiveContract ? (
                <TagComponent size={'md'} colorScheme={'green'} text="Sim" />
              ) : (
                <TagComponent size={'md'} colorScheme={'red'} text="Não" />
              ),
              <>
                <IconButtonComponent
                  icon={
                    e.status === StatusEquipment.Active ? (
                      <FaRegTimesCircle color="white" />
                    ) : (
                      <CheckCircleIcon />
                    )
                  }
                  toolTipText={
                    e.status === StatusEquipment.Active
                      ? 'Desabilitar'
                      : 'Ativar'
                  }
                  ariaLabel="Disabled"
                  colorScheme={
                    e.status === StatusEquipment.Active ? 'yellow' : 'green'
                  }
                  onSubmit={() => {
                    e.status === StatusEquipment.Active
                      ? putEquipmentDisable(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description:
                              'Tipo de equipamento desabilitado com sucesso',
                          });
                          listEquipment.refetch();
                        })
                      : putEquipmentActive(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description:
                              'Tipo de equipamento ativado com sucesso',
                          });
                          listEquipment.refetch();
                        });
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedEquipment(e);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaInfo />}
                  ariaLabel="info"
                  toolTipText="Detalhes"
                  backgroundColor="#3182CE"
                  onSubmit={() => {
                    setSelectedEquipment(e);
                    setDetailsDialog(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTrash />}
                  disabled={e.hasActiveContract}
                  marginX={2}
                  toolTipText={'Apagar'}
                  ariaLabel="Delete"
                  colorScheme={'red'}
                  onSubmit={() => {
                    deleteEquipment(e.id).then(() => {
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description: 'Torre desativada com sucesso',
                      });
                      listEquipment.refetch();
                    });
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listEquipment.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateEquipmentModalComponent
        selectedEquipment={selectedEquipment}
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
        reloadData={() => listEquipment.refetch()}
      />
      <DetailsModalComponent
        isOpen={detailsDialog}
        title={'Equipamento'}
        onClose={() => setDetailsDialog(false)}
        data={modalCardInfo}
      />
    </>
  );
}
