import { Flex, useToast } from '@chakra-ui/react';
import HeaderTextComponent from '../components/UI/atoms/HeaderTextComponent/HeaderTextComponent';
import TableComponent from '../components/UI/molecules/TableComponent/TableComponent';
import IconButtonComponent from '../components/UI/atoms/ButtonComponent/IconButton';
import AvatarLabelComponent from '../components/UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import {
  PaginationComponent,
  usePagination,
} from '../components/UI/molecules/PaginationComponent/PaginationComponent';
import {
  deleteRelatedEntity,
  useRelatedEntity,
} from '../services/RelatedEntityService';
import React, { useState } from 'react';
import { cnpjMask } from '../utils/functions/masks';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { showToast } from '../utils/showToast';
import CreateRelatedEntityModalComponent from '../components/UI/organisms/CreateRelatedEntityModalComponent/CreateRelatedEntityModalComponent';
import ButtonComponent from '../components/UI/atoms/ButtonComponent/ButtonComponent';

export default function EntitysPage() {
  const toast = useToast();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModalCreateRelatedEntity, setShowModalCreateRelatedEntity] =
    React.useState(false);

  const listRelatedEntity = useRelatedEntity(selectedPage, pageSize, 'name');

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const [selectedId, setSelectedId] = useState('');
  return (
    <>
      <Flex mb={2} justifyContent={'space-between'} alignItems={'center'}>
        <HeaderTextComponent goBack>Entidades</HeaderTextComponent>
        <ButtonComponent
          onSubmit={() => {
            setSelectedId('');
            setShowModalCreateRelatedEntity(true);
          }}
        >
          Criar entidade relacionada
        </ButtonComponent>
      </Flex>

      <TableComponent
        ItemsHeader={[
          { item: 'Entidade' },
          { item: 'Documento' },
          { item: 'Ações' },
        ]}
        isLoading={listRelatedEntity.isLoading}
        centered={true}
        emptyState={listRelatedEntity.data?.metadata.dataSize === 0}
        data={
          listRelatedEntity.data?.data.map((e, i) => ({
            items: [
              <AvatarLabelComponent
                key={i}
                label={e.name}
                subLabel={'Entidade relacionada'}
                src={''}
              />,
              cnpjMask(e.document),
              <>
                <IconButtonComponent
                  icon={<FaEdit />}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedId(e.id);
                    setShowModalCreateRelatedEntity(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTrash />}
                  ariaLabel="delet"
                  toolTipText="Apagar"
                  colorScheme="red"
                  marginX={2}
                  onSubmit={() => {
                    deleteRelatedEntity(e.id)
                      .then(() => {
                        showToast({
                          toast,
                          status: 'success',
                          title: 'Sucesso',
                          description: 'Apagado com sucesso',
                        });
                      })
                      .finally(() => listRelatedEntity.refetch());
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listRelatedEntity.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateRelatedEntityModalComponent
        id={selectedId}
        showModal={showModalCreateRelatedEntity}
        setShowModal={() => {
          setSelectedId('');
          setShowModalCreateRelatedEntity(false);
        }}
        reloadData={() => listRelatedEntity.refetch()}
      />
    </>
  );
}
