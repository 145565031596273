import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';
import { openFile } from '../utils/functions/utility';

export type EquipmentResp = {
  description: string;
  type: TypeEquipment;
  id: string;
  createdAt: string;
  serialNumber: string;
  hasActiveContract: boolean;
  status: StatusEquipment;
  statusDescription: string;
  typeDescription: string;
};

export type Equipment = {
  id?: string;
  description: string;
  type: TypeEquipment;
};

export enum TypeEquipment {
  Null = 0,
  Repeater = 1,
  Central = 2,
  Fiber = 3,
  Radio = 4,
}

export enum StatusEquipment {
  Active = 0,
  Inactive = 1,
}

export function useEquipment(
  page: number,
  pageSize: number | null,
  search: string = '',
  searchField: string = 'serialNumber',
  sort: string = 'serialNumber',
  direction: SortDirection = 'asc',
  status: StatusEquipment
) {
  return useQuery(
    [`EquipmentList`, page, search, searchField, sort, direction, status],
    async () => {
      const resp = await Api.get(`/Equipment`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          Status: status,
        },
      });
      return getRespContent<EquipmentResp[]>(resp);
    }
  );
}

export function useEquipmentSelect() {
  return useQuery([`EquipmentListSelect`], async () => {
    const resp = await Api.get(`/Equipment/select`);
    return getResp<EquipmentResp[]>(resp);
  });
}

export function saveEquipment(props: Equipment) {
  if (props.id) {
    return putEquipment(props);
  }
  return postEquipment(props);
}

export function postEquipment(props: Equipment) {
  return Api.post('/Equipment', props);
}

export function putEquipment(props: Equipment) {
  return Api.put(`/Equipment/${props.id}`, props);
}
export function putEquipmentActive(id: string) {
  return Api.put(`/Equipment/active/${id}`);
}
export function putEquipmentDisable(id: string) {
  return Api.put(`/Equipment/disable/${id}`);
}

export function deleteEquipment(id: string) {
  return Api.delete(`/Equipment/${id}`);
}

export function getEquipmentReport(
  search: string = '',
  searchField: string = 'serialNumber',
  sort: string = 'serialNumber',
  direction: SortDirection = 'asc'
) {
  Api.get(`/Equipment/report`, {
    params: {
      [searchField]: search,
      SortColumn: sort,
      SortDirection: direction,
    },
    responseType: 'blob',
  }).then((response) => {
    openFile(response);
  });
}
