import { Flex, Stack, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  convertToMonetaryValue,
} from '../../../../utils/functions/masks';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../molecules/PaginationComponent/PaginationComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import {
  postContractRoutines,
  useContractRoutinesContractsFiltered,
  useContractRoutinesContractsFilteredAll,
} from '../../../../services/ContractRoutinesService';
import { useLocalStorage } from '../../../../services/LocalStorage';
import {
  getReadjustmentIndexContract,
  useContractFilteredSearch,
} from '../../../../services/ContractService';
import { useUserData } from '../../../../services/LoginService';
import { FilterContractComponent } from '../FilterContractComponent/FilterContractComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import {
  formatDate,
  formatDateToForm,
} from '../../../../utils/functions/formatDate';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import { showToast } from '../../../../utils/showToast';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';

export function CreateContractRoutinesModalComponent(props: {
  showModal: boolean;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const user = useUserData();
  const toast = useToast();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showFilter, setShowFilter] = React.useState(false);
  const prevYear = new Date();
  prevYear.setFullYear(prevYear.getFullYear() - 1);
  const [startingReference, setStartingReference] = React.useState(
  ''
  );
  const nextYear = new Date();
  nextYear.setFullYear(nextYear.getFullYear() + 1);
  const [endingReference, setEndingReference] = React.useState(
   ''
  );
  const [type, setType] = React.useState('renovation');
  const [readjustmentIndexOption, setReadjustmentIndexOption] =
    React.useState(1);
  const [renewalTime, setRenewalTime] = React.useState(
    formatDateToForm(nextYear)
  );
  const [numberOfRenewals, setNumberOfRenewals] = React.useState(1);
  const [filterOptions, setFilterOptions] =
    useLocalStorage<useContractFilteredSearch>(
      `${user?.userId}-contract-routines`,
      {
        frequency: null,
        automaticRenovation: true,
        expirationAlert: null,
        value: null,
        status: null,
        active: null,
        myContracts: null,
      }
    );
  const listContract = useContractRoutinesContractsFiltered(
    selectedPage,
    pageSize,
    startingReference,
    endingReference,
    'createdAt',
    'desc',
    filterOptions
  );

  const allContracts = useContractRoutinesContractsFilteredAll(
    startingReference,
    endingReference,
    filterOptions
  );
  const [selectedContractsIds, setSelectedContractsIds] = React.useState<
    string[]
  >([]);

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Criar Rotina Automatizada`}
      maxW="1200px"
      size="xl"
    >
      <Flex>
        <HeaderTextComponent fontSize={20} subTitle>
          Filtros
        </HeaderTextComponent>
      </Flex>
      <Stack
        direction={'row'}
        paddingBottom={10}
        spacing={'2%'}
        justifyContent={'end'}
        alignItems={'end'}
      >
        <InputComponent
          label="Data de ínicio"
          type="date"
          value={startingReference}
          onChange={(input) => setStartingReference(input.target.value)}
        />
        <InputComponent
          label="Data de fim"
          type="date"
          value={endingReference}
          onChange={(input) => setEndingReference(input.target.value)}
        />
        <FilterContractComponent
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
      </Stack>
      <Flex>
        <HeaderTextComponent fontSize={20} subTitle>
          Preencha os campos e selecione contratos para criar sua rotina
        </HeaderTextComponent>
      </Flex>
      <Stack direction={'row'} spacing={'2%'}>
        <InputSelectComponent
          m={'0px 0px 10px 0px'}
          defaultValue={type}
          options={[
            { id: 'renovation', name: 'Renovação Automática' },
            { id: 'readjustment', name: 'Reajuste' },
            { id: 'termination', name: 'Término Automático' },
          ]}
          onChange={(item) => {
            setType(item.target.value);
          }}
          label="Tipo"
        />
        {type === 'readjustment' && (
          <InputSelectComponent
            label="Índice de Reajuste"
            options={getReadjustmentIndexContract()}
            defaultValue={readjustmentIndexOption.toString()}
            onChange={(input) =>
              setReadjustmentIndexOption(Number(input.target.value))
            }
          />
        )}
        {type === 'renovation' && (
          <>
            <InputComponent
              label="Data de renovação"
              type="date"
              value={renewalTime}
              onChange={(input) => setRenewalTime(input.target.value)}
            />
            <InputComponent
              label="Número de renovações"
              type="number"
              value={numberOfRenewals.toString()}
              onChange={(input) =>
                setNumberOfRenewals(Number(input.target.value))
              }
            />
          </>
        )}
      </Stack>

      <TableComponent
        ItemsHeader={[
          {
            item: (
              <>
                Selecionar
                <InputCheckComponent
                  borderColor="black"
                  m={'0 0 0 10px'}
                  isChecked={allContracts.data?.every((item) =>
                    selectedContractsIds.includes(item)
                  )}
                  onChange={() => {
                    if (
                      !allContracts.data?.every((item) =>
                        selectedContractsIds.includes(item)
                      )
                    )
                      allContracts.data?.forEach((e) => {
                        if (!selectedContractsIds.some((item) => item === e))
                          setSelectedContractsIds((contractIds) => [
                            ...contractIds,
                            e,
                          ]);
                      });
                    else setSelectedContractsIds([]);
                  }}
                ></InputCheckComponent>
              </>
            ),
          },
          { item: 'Número' },
          { item: 'Status' },
          { item: 'Data de ativação' },
          { item: 'Data de expiração' },
          { item: 'Valor' },
        ]}
        isLoading={listContract.isLoading}
        centered={true}
        emptyState={listContract.data?.metadata.dataSize === 0}
        data={
          listContract.data?.data.map((e, i) => ({
            items: [
              <InputCheckComponent
                key={i}
                isChecked={selectedContractsIds.some((item) => item === e.id)}
                onChange={() => {
                  if (!selectedContractsIds.some((item) => item === e.id))
                    setSelectedContractsIds([...selectedContractsIds, e.id]);
                  else {
                    setSelectedContractsIds(
                      selectedContractsIds.filter((item) => item !== e.id)
                    );
                  }
                }}
              ></InputCheckComponent>,
              e.serialNumber,
              e.contractStatusDescription,
              formatDate(e.activationDate),
              formatDate(e.expireDate),
              convertToMonetaryValue(e.value),
            ],
          })) ?? []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listContract.data?.metadata.dataSize ?? 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setSelectedContractsIds([]);
            setType('renovation');
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          isLoading={isLoading}
          disabled={isLoading || selectedContractsIds.length === 0}
          onSubmit={() => {
            setIsLoading(true);
            postContractRoutines(
              selectedContractsIds,
              type,
              renewalTime,
              numberOfRenewals,
              readjustmentIndexOption
            )
              .then((res) => {
                props.setShowModal();
                props.reloadData?.();
                showToast({
                  toast,
                  status: 'success',
                  title: 'Sucesso',
                  description: `Rotina Automatizada criada com sucesso`,
                });
              })
              .finally(() => setIsLoading(false));
          }}
        >
          Adicionar
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
