import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ContainerComponent from '../../atoms/ContainerComponent/ContainerComponent';
import { CSSProperties, useState } from 'react';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { Box, Stack } from '@chakra-ui/react';
import LinkComponent from '../../atoms/LinkComponent/LinkComponent';
import { postRecoverPassword } from '../../../../services/LoginService';
import { useNavigate } from 'react-router-dom';

export type RegisterFormProps = {
  style?: CSSProperties;
  isFirstAccess: boolean;
};

export default function ForgotPasswordFormComponent(props: RegisterFormProps) {


  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <ContainerComponent style={props.style}>
        <Stack fontWeight={'500'} fontSize={'16px'} spacing={1}>
          <Box>
            <InputComponent
              borderRadius={15}
              id="email"
              label={'Email'}
              type={'Email'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={'Digite seu email'}
            />
          </Box>
        </Stack>
        <ButtonComponent
          disabled={email.length === 0}
          isLoading={isLoading}
          style={{ marginTop: 35 }}
          width={275}
          children={'Enviar'}
          onSubmit={() => {
            if (props.isFirstAccess) {
              navigate(`/change-password/${email}/first`);
            } else {
              setIsLoading(true);
              postRecoverPassword({ email: email })
                .then(() => {
                  navigate(`/change-password/${email}/forgot`);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          }}
        />
      </ContainerComponent>
      <TextComponent fontWeight={600} fontSize={'12px'}>
        Email enviado?
        <LinkComponent color={'brand.500'} to={'/login'}>
          {' '}
          Faça login
        </LinkComponent>
      </TextComponent>
    </>
  );
}
