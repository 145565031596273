import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';
import { openFile } from '../utils/functions/utility';

export type TowerTypeResp = {
  description: string;
  type: TypeTowerType;
  id: string;
  createdAt: string;
  status: StatusTowerType;
  statusDescription: string;
  typeDescription: string;
  serialNumber: string;
  hasActiveContract: boolean;
};

export type TowerType = {
  id?: string;
  description: string;
  type: TypeTowerType;
};

export enum TypeTowerType {
  Null = 0,
  Retail = 1,
  Supermarket = 2,
  LogisticShed = 3,
  CommercialHalls = 4,
  Telecommunications = 5,
}

export enum StatusTowerType {
  Active = 0,
  Inactive = 1,
}

export function useTowerType(
  page: number,
  pageSize: number | null,
  search: string = '',
  searchField: string = 'serialNumber',
  sort: string = 'serialNumber',
  direction: SortDirection = 'asc',
  status?: StatusTowerType
) {
  return useQuery(
    [`TowerTypeList`, page, search, searchField, sort, direction, status],
    async () => {
      const resp = await Api.get(`/TowerType`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          Status: status,
        },
      });
      return getRespContent<TowerTypeResp[]>(resp);
    }
  );
}

export function useTowerTypeSelect() {
  return useQuery([`TowerTypeListSelect`], async () => {
    const resp = await Api.get(`/TowerType/select`);
    return getResp<TowerTypeResp[]>(resp);
  });
}

export function saveTowerType(props: TowerType) {
  if (props.id) {
    return putTowerType(props);
  }
  return postTowerType(props);
}

export function postTowerType(props: TowerType) {
  return Api.post('/TowerType', props);
}

export function putTowerType(props: TowerType) {
  return Api.put(`/TowerType/${props.id}`, props);
}
export function putTowerTypeActive(id: string) {
  return Api.put(`/TowerType/active/${id}`);
}
export function putTowerTypeDisable(id: string) {
  return Api.put(`/TowerType/disable/${id}`);
}

export function deleteTowerType(id: string) {
  return Api.delete(`/TowerType/${id}`);
}

export function getTowerTypeReport(
  search: string = '',
  searchField: string = 'serialNumber',
  sort: string = 'serialNumber',
  direction: SortDirection = 'asc'
) {
  Api.get(`/TowerType/report`, {
    params: {
      [searchField]: search,
      SortColumn: sort,
      SortDirection: direction,
    },
    responseType: 'blob',
  }).then((response) => {
    openFile(response);
  });
}
