import { useQuery } from '@tanstack/react-query';
import Api, { getRespContent } from './Api';
import { Contract } from './ContractService';
import { SortDirection } from './PhysicalPersonService';
export enum StatusBatchChanges {
  pending = 0,
  approved = 1,
  rejected = 2,
}
export type BatchChangesResp = {
  id: string;
  createdAt: string;
  changesJson: string;
  description: string;
  answerDescription: string;
  answeredById: string;
  answeredByName: string;
  answeredAt: string;
  personId: string;
  personName: string;
  status: StatusBatchChanges | null;
  statusDescription: string;
  canBeAnsweredAgain: boolean;
  changes: Contract;
  contracts: {
    id: string;
    manualIdentifier: string;
    serialNumber: string;
    status: number;
    contractStatusDescription: string;
    value: number;
  }[];
};

export type BatchChanges = {
  changes: Contract;
  contractIds: string[];
  description: string;
};

export function useBatchChanges(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'title',
  sort: string = 'name',
  direction: SortDirection = 'asc',
  status: StatusBatchChanges | undefined
) {
  return useQuery(
    [`BatchChangesList`, page, search, searchField, sort, direction, status],
    async () => {
      const resp = await Api.get(`/BatchChanges`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
          Status: status,
        },
      });
      return getRespContent<BatchChangesResp[]>(resp);
    }
  );
}

export function postBatchChanges(props: BatchChanges) {
  return Api.post('/BatchChanges', props);
}

export function putBatchChangesApproveDisapprove(
  id: string,
  approve: boolean,
  description: string
) {
  if (approve) return putBatchChangesApprove(id, description);
  return putBatchChangesDisapprove(id, description);
}

export function putBatchChangesApprove(id: string, description: string) {
  return Api.put(`/BatchChanges/approve/${id}`, {
    description,
  });
}

export function putBatchChangesDisapprove(id: string, description: string) {
  return Api.put(`/BatchChanges/disapprove/${id}`, {
    description,
  });
}
