import { Flex, FormControl, useToast } from '@chakra-ui/react';
import React, { Dispatch } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import {
  ClauseResp,
  TypeClause,
  useClauseSelect,
} from '../../../../services/ClauseService';
import { showToast } from '../../../../utils/showToast';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import ContainerStepComponent from '../../atoms/ContainerStepComponent/ContainerStepComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';

type ContractStepperClauseComponentProps = {
  selectedClauseGeneral: (ClauseResp | undefined)[];
  setSelectedClauseGeneral: Dispatch<(ClauseResp | undefined)[]>;
  selectedClauseRenovation: (ClauseResp | undefined)[];
  setSelectedClauseRenovation: Dispatch<(ClauseResp | undefined)[]>;
  selectedClauseClosing: (ClauseResp | undefined)[];
  setSelectedClauseClosing: Dispatch<(ClauseResp | undefined)[]>;
};

export default function ContractStepperClauseComponent(
  props: ContractStepperClauseComponentProps
) {
  const toast = useToast();
  const listClauses = useClauseSelect();
  return (
    <ContainerStepComponent title="Cláusulas">
      <Flex flexDirection={'row'}>
        <Flex w={'32%'} mr={'2%'} flexDirection={'column'}>
          <FormControl>
            <InputSelectComponent
              label="Gerais"
              placeholder="Selecione alguma para adicionar"
              value="null"
              options={listClauses.data
                ?.filter((item) => item.type === TypeClause.General)
                .map((item) => ({ id: item.id, name: item.title }))}
              onChange={(input) => {
                if (
                  !props.selectedClauseGeneral.some(
                    (item) => item?.id === input.target.value
                  )
                ) {
                  props.setSelectedClauseGeneral([
                    ...props.selectedClauseGeneral,
                    listClauses.data?.filter(
                      (item) => item.id === input.target.value
                    )[0],
                  ]);
                } else {
                  showToast({
                    toast,
                    status: 'info',
                    title: 'Atenção',
                    description: 'Essa cláusula já foi adicionada',
                    position: 'top-left',
                  });
                }
              }}
            />
          </FormControl>
          {props.selectedClauseGeneral.map((item, i) => (
            <Flex
              key={i}
              direction={'row'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottom="1px solid black"
            >
              <Flex direction={'column'}>
                <TextComponent fontWeight={'500'}>{item?.title}</TextComponent>
                <TextComponent fontWeight={'400'}>
                  {item?.description}
                </TextComponent>
              </Flex>
              <IconButtonComponent
                icon={<FaWindowClose />}
                ariaLabel="Remover"
                variant={'clear'}
                textColor={'red'}
                onSubmit={() =>
                  props.setSelectedClauseGeneral([
                    ...props.selectedClauseGeneral.filter(
                      (selected) => selected?.id !== item?.id
                    ),
                  ])
                }
              />
            </Flex>
          ))}
        </Flex>
        <Flex w={'32%'} mr={'2%'} flexDirection={'column'}>
          <FormControl>
            <InputSelectComponent
              label="Renovação/ Reajuste"
              placeholder="Selecione alguma para adicionar"
              value="null"
              options={listClauses.data
                ?.filter((item) => item.type === TypeClause.Renovation)
                .map((item) => ({ id: item.id, name: item.title }))}
              onChange={(input) => {
                if (
                  !props.selectedClauseRenovation.some(
                    (item) => item?.id === input.target.value
                  )
                ) {
                  props.setSelectedClauseRenovation([
                    ...props.selectedClauseRenovation,
                    listClauses.data?.filter(
                      (item) => item.id === input.target.value
                    )[0],
                  ]);
                } else {
                  showToast({
                    toast,
                    status: 'info',
                    title: 'Atenção',
                    description: 'Essa cláusula já foi adicionada',
                    position: 'top-left',
                  });
                }
              }}
            />
          </FormControl>
          {props.selectedClauseRenovation.map((item, i) => (
            <Flex
              key={i}
              direction={'row'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottom="1px solid black"
            >
              <Flex direction={'column'}>
                <TextComponent fontWeight={'500'}>{item?.title}</TextComponent>
                <TextComponent fontWeight={'400'}>
                  {item?.description}
                </TextComponent>
              </Flex>
              <IconButtonComponent
                icon={<FaWindowClose />}
                ariaLabel="Remover"
                variant={'clear'}
                textColor={'red'}
                onSubmit={() =>
                  props.setSelectedClauseRenovation([
                    ...props.selectedClauseRenovation.filter(
                      (selected) => selected?.id !== item?.id
                    ),
                  ])
                }
              />
            </Flex>
          ))}
        </Flex>
        <Flex w={'32%'} flexDirection={'column'}>
          <FormControl>
            <InputSelectComponent
              label="Encerramento/ Distrato"
              placeholder="Selecione alguma para adicionar"
              value="null"
              options={listClauses.data
                ?.filter((item) => item.type === TypeClause.Closing)
                .map((item) => ({ id: item.id, name: item.title }))}
              onChange={(input) => {
                if (
                  !props.selectedClauseClosing.some(
                    (item) => item?.id === input.target.value
                  )
                ) {
                  props.setSelectedClauseClosing([
                    ...props.selectedClauseClosing,
                    listClauses.data?.filter(
                      (item) => item.id === input.target.value
                    )[0],
                  ]);
                } else {
                  showToast({
                    toast,
                    status: 'info',
                    title: 'Atenção',
                    description: 'Essa cláusula já foi adicionada',
                    position: 'top-left',
                  });
                }
              }}
            />
          </FormControl>
          {props.selectedClauseClosing.map((item, i) => (
            <Flex
              key={i}
              direction={'row'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottom="1px solid black"
            >
              <Flex direction={'column'}>
                <TextComponent fontWeight={'500'}>{item?.title}</TextComponent>
                <TextComponent fontWeight={'400'}>
                  {item?.description}
                </TextComponent>
              </Flex>
              <IconButtonComponent
                icon={<FaWindowClose />}
                ariaLabel="Remover"
                variant={'clear'}
                textColor={'red'}
                onSubmit={() =>
                  props.setSelectedClauseClosing([
                    ...props.selectedClauseClosing.filter(
                      (selected) => selected?.id !== item?.id
                    ),
                  ])
                }
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </ContainerStepComponent>
  );
}
