import { Flex, Stack, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { cnpjMask } from '../../../../utils/functions/masks';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../molecules/PaginationComponent/PaginationComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import {
  RelatedEntity,
  deleteRelatedEntity,
  getRelatedEntityDetails,
  useRelatedEntity,
} from '../../../../services/RelatedEntityService';
import CreateRelatedEntityModalComponent from '../CreateRelatedEntityModalComponent/CreateRelatedEntityModalComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { showToast } from '../../../../utils/showToast';

export function SelectRelatedEntityModalComponent(props: {
  showModal: boolean;
  setShowModal: () => void;
  selectedRelatedEntity: RelatedEntity | undefined;
  setSelectedRelatedEntity: React.Dispatch<
    React.SetStateAction<RelatedEntity | undefined>
  >;
}) {
  const toast = useToast();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModalCreateRelatedEntity, setShowModalCreateRelatedEntity] =
    React.useState(false);

  const listRelatedEntity = useRelatedEntity(selectedPage, pageSize, 'name');

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const [selectedId, setSelectedId] = useState('');
  return (
    <>
      <ModalStructureComponent
        isOpen={props.showModal}
        onClose={() => props.setShowModal()}
        title={`Selecione a entidade relacionada`}
        maxW="1200px"
        size="xl"
      >
        <Stack
          direction={'row'}
          paddingBottom={5}
          justifyContent={'space-between'}
        >
          <ButtonComponent
            onSubmit={() => {
              setSelectedId('');
              setShowModalCreateRelatedEntity(true);
            }}
          >
            Criar entidade relacionada
          </ButtonComponent>
        </Stack>
        <TableComponent
          ItemsHeader={[
            { item: 'Selecionar' },
            { item: 'Entidade' },
            { item: 'Documento' },
            { item: 'Ações' },
          ]}
          isLoading={listRelatedEntity.isLoading}
          centered={true}
          emptyState={listRelatedEntity.data?.metadata.dataSize === 0}
          data={
            listRelatedEntity.data?.data.map((e, i) => ({
              items: [
                <InputCheckComponent
                  key={i}
                  isChecked={props.selectedRelatedEntity?.id === e.id}
                  onChange={() => {
                    if (props.selectedRelatedEntity?.id === e.id) {
                      props.setSelectedRelatedEntity(undefined);
                    } else {
                      props.setSelectedRelatedEntity(e);
                    }
                  }}
                ></InputCheckComponent>,
                <AvatarLabelComponent
                  key={i}
                  label={e.name}
                  subLabel={'Entidade relacionada'}
                  src={''}
                />,
                cnpjMask(e.document),
                <>
                  <IconButtonComponent
                    icon={<FaEdit />}
                    ariaLabel="Edit"
                    toolTipText="Editar"
                    backgroundColor="#4B49AC"
                    marginX={2}
                    onSubmit={() => {
                      setSelectedId(e.id);
                      setShowModalCreateRelatedEntity(true);
                    }}
                  />
                  <IconButtonComponent
                    icon={<FaTrash />}
                    ariaLabel="delet"
                    toolTipText="Apagar"
                    colorScheme="red"
                    marginX={2}
                    onSubmit={() => {
                      deleteRelatedEntity(e.id)
                        .then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description: 'Apagado com sucesso',
                          });
                        })
                        .finally(() => listRelatedEntity.refetch());
                    }}
                  />
                </>,
              ],
            })) || []
          }
        />
        <PaginationComponent
          onSelectedPageChanged={onSelectedPageChanged}
          selectedPage={selectedPage}
          arrayLength={listRelatedEntity.data?.metadata.dataSize || 0}
          maxPageItens={pageSize}
        ></PaginationComponent>
        <Flex mb={5} justifyContent={'space-between'}>
          <ButtonComponent
            variant={'outline'}
            onSubmit={() => {
              props.setSelectedRelatedEntity(undefined);
              props.setShowModal();
            }}
          >
            Descartar
          </ButtonComponent>
          <ButtonComponent
            onSubmit={() => {
              props.setShowModal();
            }}
          >
            Confirmar
          </ButtonComponent>
        </Flex>
      </ModalStructureComponent>
      <CreateRelatedEntityModalComponent
        id={selectedId}
        showModal={showModalCreateRelatedEntity}
        setShowModal={() => {
          setSelectedId('');
          setShowModalCreateRelatedEntity(false);
        }}
        reloadData={() => listRelatedEntity.refetch()}
        onSuccess={async (id) => {
          if (id === undefined) {
            return;
          }
          const newRelatedEntity = await getRelatedEntityDetails(id);
          props.setSelectedRelatedEntity(newRelatedEntity);
        }}
      />
    </>
  );
}
