import { Checkbox, Flex, Stack, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import { DescriptionLabelComponent } from '../DescriptionlabelComponent/DescriptionLabelComponent';
import TableComponent from '../TableComponent/TableComponent';
import {
  StatusContract,
  useContract,
} from '../../../../services/ContractService';
import {
  PaginationComponent,
  usePagination,
} from '../PaginationComponent/PaginationComponent';
import React, { useEffect, useState } from 'react';
import { parseDateAndHour } from '../../../../utils/functions/utility';
import { StatusColorByTypeContract } from '../../../templates/ContractTemplateComponent/ContractTemplateComponent';
import {
  invalidateCampaign,
  saveCampaign,
  useCampaignDetails,
} from '../../../../services/CampaignsService';
import { showToast } from '../../../../utils/showToast';
import { useQueryClient } from '@tanstack/react-query';
import { useDebounce } from '../../../../utils/functions/debounce';
import SearchSelectComponent from '../SearchSelectComponent/SearchSelectComponent';

export enum campaignsStatus {
  pending = 0,
  inProgress = 1,
  canceled = 2,
}

export function CreateCampaignsModalComponent(props: {
  campaignId?: string;
  showModal: boolean;
  setShowModal: () => void;
  reloadData: () => void;
  isLoading: boolean;
}) {
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const toast = useToast();
  const [selectedContracts, setSelectedContracts] = React.useState<string[]>(
    []
  );

  const Campaign = useCampaignDetails(props.campaignId || '');
  const queryClient = useQueryClient();
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('SerialNumber');
  const [contractStatus, setContractStatus] = React.useState('');

  const contractList = useContract(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createdAt',
    'desc',
    null,
    null,
    '',
    '',
    '',
    '',
    contractStatus
  );

  useEffect(() => {
    if (Campaign.data) {
      const CampaignContracts = Campaign.data?.contracts;
      const ContractIds = CampaignContracts?.map((e) => e.contractId);
      setSelectedContracts([...(ContractIds ?? [])]);
    }
  }, [Campaign.data]);

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  function handleCheckBoxChange(id: string) {
    if (!selectedContracts.some((item) => item === id))
      setSelectedContracts([...selectedContracts, id]);
    else {
      setSelectedContracts(selectedContracts.filter((item) => item !== id));
    }
  }
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal();
        setSelectedContracts([]);
        invalidateCampaign(props.campaignId || '', queryClient);
      }}
      title={
        props.campaignId
          ? 'Editar contratos da campanha'
          : `Adicionar contratos a campanha`
      }
      maxW="1200px"
      size="xl"
    >
      <>
        <Stack
          marginBottom={3}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Flex>
            <SearchSelectComponent
              onChangeText={(input) => setSearch(input.target.value)}
              inputValue={searchInput}
              onClean={() => setSearch('')}
              onChange={(item) => {
                setSearchField(item.target.value);
              }}
              options={[
                {
                  id: 'ContractStatus',
                  name: 'Status',
                  type: 'select',
                  options: [
                    { id: '', name: 'Todos' },
                    {
                      id: StatusContract.PendenteDeAssinatura.toString(),
                      name: 'Análise',
                    },
                    {
                      id: StatusContract.Cancelado.toString(),
                      name: 'Concluído',
                    },
                    {
                      id: StatusContract.EmCadastramento.toString(),
                      name: 'Pendente',
                    },
                  ],
                  onChageSelect: (e) => setContractStatus(e.target.value),
                },
              ]}
            />
          </Flex>
        </Stack>
        <TableComponent
          emptyState={contractList.data?.data.length === 0}
          ItemsHeader={[
            {
              item: 'Selecionar',
            },
            { item: 'Número do contrato' },
            { item: 'Operadora' },
            { item: 'Data de vigência' },
            { item: 'status' },
          ]}
          isLoading={contractList.isLoading}
          centered={true}
          data={
            contractList.data?.data.map((e) => ({
              items: [
                <Checkbox
                  isChecked={selectedContracts.some((item) => item === e.id)}
                  onChange={() => handleCheckBoxChange(e.id)}
                  borderColor={'black'}
                ></Checkbox>,
                <DescriptionLabelComponent
                  label={e.serialNumber}
                  subLabel={'Contrato'}
                />,
                e.createdByName,
                parseDateAndHour(e.activationDate),
                StatusColorByTypeContract({ type: e.contractStatus }),
              ],
            })) || []
          }
        />
        <PaginationComponent
          onSelectedPageChanged={onSelectedPageChanged}
          selectedPage={selectedPage}
          arrayLength={contractList.data?.metadata.dataSize || 0}
          maxPageItens={pageSize}
        ></PaginationComponent>
        <Stack alignItems={'flex-end'}>
          <ButtonComponent
            isLoading={isLoading}
            disabled={isLoading}
            onSubmit={() => {
              setIsLoading(true);
              saveCampaign(selectedContracts, props.campaignId)
                .then((res) => {
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Salvo com sucesso',
                  });
                  props.reloadData();
                  setSelectedContracts([]);
                  props.setShowModal();
                })
                .finally(() => setIsLoading(false));
            }}
          >
            {props.campaignId ? 'Editar' : 'Adicionar'}
          </ButtonComponent>
        </Stack>
      </>
    </ModalStructureComponent>
  );
}
