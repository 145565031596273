import { useQuery } from '@tanstack/react-query';
import Api, { getRespContent } from './Api';
export type TotalizerResp = {
  totalValue: number;
  currentPageValue: number;
};
export type TransactionResp = {
  id: string;
  transactionType: number;
  transactionTypeDescription: string;
  fromSystem: boolean;
  fromBank: boolean;
  date: string;
  notes: string;
  paymentMethod: string;
  amount: number;
  editable: boolean;
};

export function useTransaction(
  page: number,
  pageSize: number | null,
  search: string = '',
  searchField: string = 'notes'
) {
  return useQuery([`TransactionList`, page, search, searchField], async () => {
    const resp = await Api.get(`/transaction`, {
      params: {
        Offset: page,
        Limit: pageSize,
        [searchField]: search,
      },
    });
    return getRespContent<TransactionResp[]>(resp);
  });
}

type TransactionContractIdResp = {
  contractSerialNumber: string;
  effected: boolean;
  effectedAt: Date | string;
  id: string;
  lastUpdateAt: string;
  locatorName: string;
  reference: string;
  value: number;
};
export function useTransactionContractId(
  id: string,
  page: number,
  pageSize: number | null
) {
  return useQuery([`contractTransactionList`, page, pageSize], async () => {
    const resp = await Api.get(`contract/transaction/${id}`, {
      params: {
        Offset: page,
        Limit: pageSize,
      },
    });
    return getRespContent<TransactionContractIdResp[]>(resp);
  });
}

export function useTransactionContractIdTotalizer(
  id: string,
  page: number,
  pageSize: number | null
) {
  return useQuery(
    [`contractTransactionTotalizer`, page, pageSize],
    async () => {
      const resp = await Api.get(`contract/transaction/${id}/totalizer`, {
        params: {
          Offset: page,
          Limit: pageSize,
        },
      });
      return getRespContent<TotalizerResp>(resp);
    }
  );
}
