import { FC } from 'react';
import {
  FaSignOutAlt,
  FaEdit,
  FaColumns,
  FaIcons,
  FaHeadphones,
  FaBullhorn,
  FaCog,
  FaHome,
} from 'react-icons/fa';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LoginTemplateComponent from '../components/templates/LoginTemplate/LoginTemplateComponent';
import RegisterTemplateComponent from '../components/templates/RegisterTemplate/RegisterTemplateComponent';
import NavbarComponent from '../components/UI/molecules/NavbarComponent/NavbarComponent';
import { useLogoff, useUserData } from '../services/LoginService';
import OnboardingTemplateComponent from '../components/templates/OnboardingTemplate/OnboardingTemplateComponent';
import PhysicalPersonTemplateComponent from '../components/templates/PhysicalPersonTemplate/PhysicalPersonTemplateComponent';
import DashboardsBody from '../pages/DashboardsPage';
import WelcomeComponent from '../components/UI/atoms/WelcomeComponent';
import UsersEditTemplateComponent from '../components/templates/UsersEditTemplate/UsersEditTemplateComponent';
import { CardScreenContainer } from '../components/UI/atoms/CardScreenContainer/CardScreenContainer';
import SchedulingPage from '../pages/SchedulingPage';
import TermsAndPoliciesPage from '../pages/TermsAndPolicies.page';
import ProfileTemplateComponent from '../components/templates/ProfileTemplate/ProfileTemplateComponent';
import ForgotPasswordTemplateComponent from '../components/templates/ForgotPasswordTemplate/ForgotPasswordTemplateComponent';
import PermissionsTemplateComponent from '../components/templates/PermissionsTemplate/PermissionsTemplateComponent';
import ChangePasswordTemplateComponent from '../components/templates/ChangePasswordTemplate/ChangePasswordTemplateComponent';
import JuridicalPersonTemplateComponent from '../components/templates/JuridicalPersonTemplate/JuridicalPersonTemplateComponent';
import MessageTemplatesTemplateComponent from '../components/templates/MessageTemplatesTemplate/MessageTemplatesTemplateComponent';
import AttendanceTemplateComponent from '../components/templates/AttendanceTemplate/AttendanceTemplateComponent';
import { ChatTemplateComponent } from '../components/templates/ChatTemplateComponent/ChatTemplateComponent';
import AttendanceClientTemplateComponent from '../components/templates/AttendanceClientTemplate/AttendanceClientTemplateComponent';
import TowerTemplateComponent from '../components/templates/TowerTemplateComponent/TowerTemplateComponent';
import ContractStepperTemplateComponent from '../components/templates/ContractStepperTemplate/ContractStepperTemplateComponent';
import CampaignsTemplateComponent from '../components/templates/CampaignsTemplateComponent/CampaignsTemplateComponent';
import ContractTemplateComponent from '../components/templates/ContractTemplateComponent/ContractTemplateComponent';
import LocationTemplateComponent from '../components/templates/LocationTemplateComponent/LocationTemplateComponent';
import { NavbarButtonProps } from '../components/UI/atoms/NavbarItemsComponent/NavbarItemsComponent';
import PersonTemplateComponent from '../components/templates/LocatorTemplateComponent/LocatorTemplateComponent';
import { ContractDetailsTemplateComponent } from '../components/templates/ContractDetailsTemplateComponent/ContractDetailsTemplateComponent';
import TechnologyTemplateComponent from '../components/templates/TechnologyTemplateComponent/TechnologyTemplateComponent';
import EquipmentTemplateComponent from '../components/templates/EquipmentTemplateComponent/EquipmentTemplateComponent';
import EngineeringTypeTemplateComponent from '../components/templates/EngineeringTypeTemplateComponent/EngineeringTypeTemplateComponent';
import IndexTemplateComponent from '../components/templates/IndexTemplateComponent/IndexTemplateComponent';
import IndexPerMonthComponent from '../components/templates/IndexPerMonth/IndexPerMonthTemplateComponent';
import ContractHistoryTemplateComponent from '../components/templates/ContractHistoryTemplateComponent/ContractHistoryTemplateComponent';
import CreatePhysicalPersonTemplateComponent from '../components/templates/CreatePhysicalPersonTemplateComponent/CreatePhysicalPersonTemplateComponent';
import TransactionTemplateComponent from '../components/templates/TransactionTemplateComponent/TransactionTemplateComponent';
import ClauseTemplateComponent from '../components/templates/ClauseTemplateComponent/ClauseTemplateComponent';
import UserTemplateComponent from '../components/templates/UserTemplateComponent/UserTemplateComponent';
import ContractRoutinesTemplateComponent from '../components/templates/ContractRoutinesTemplateComponent/ContractRoutinesTemplateComponent';
import BatchChangesTemplateComponent from '../components/templates/BatchChangesTemplateComponent/BatchChangesTemplateComponent';
import BatchChangesStepperTemplateComponent from '../components/templates/BatchChangesStepperTemplateComponent/BatchChangesStepperTemplateComponent';
import ConfigTemplateComponent from '../components/templates/ConfigTemplateComponent/ConfigTemplateComponent';
import ContractChangeRequestTemplateComponent from '../components/templates/ContractChangeRequestTemplateComponent/ContractChangeRequestTemplateComponent';
import DataImportTemplateComponent from '../components/templates/DataImportTemplateComponent/DataImportTemplateComponent';
import BatchChangesListTemplateComponent from '../components/templates/BatchChangesListTemplateComponent/BatchChangesListTemplateComponent';
import DetachedPaymentTemplateComponent from '../components/templates/DetachedPaymentTemplateComponent/DetachedPaymentTemplateComponent';
import ConsiderationTemplateComponent from '../components/templates/ConsiderationTemplateComponent/ConsiderationTemplateComponent';
import NegotiationHistoryTemplateComponent from '../components/templates/NegotiationHistoryTemplateComponent/NegotiationHistoryTemplateComponent';
import MyProfilePage from '../pages/MyProfilePage';
import { useMyProfile } from '../services/PhysicalPersonService';
import PersonMessagePage from '../pages/PersonMessagePage';
import MessagingPage from '../pages/MessagingPage';
import EntitysPage from '../pages/EntitysPage';

export type Permissions = 'AttendanceManager';
function MainPage() {
  const logoff = useLogoff();
  const user = useUserData();
  const person = useMyProfile();
  const hasAccessDashboard = person.data?.profiles
    ?.map((e) => e.name)
    .includes('Administrador');
  const buttons: NavbarButtonProps[] = [
    {
      icon: !hasAccessDashboard ? <FaHome /> : <FaIcons />,
      label: !hasAccessDashboard ? 'Home' : 'Dashboard',
      link: '/dashboard',
      testId: 'Dashboard',
    },
    {
      icon: <FaEdit />,
      label: 'Cadastros',
      subItem: [
        {
          label: 'Pessoas',
          link: '/person',
          testId: 'person',
          tooltip: 'Partes envolvidas no contrato.',
        },
        {
          label: 'Índices',
          link: '/index',
          testId: 'index',
        },
        {
          label: 'Entidades',
          link: '/entitys',
          testId: 'entitys',
        },
        {
          label: 'Grupos de Permissões',
          testId: 'permissionGroups',
          link: '/permission-groups',
        },

        {
          link: '/tower',
          label: 'Tipos de Torres',
          testId: 'tower',
        },
        {
          label: 'Tecnologias',
          link: '/technology',
        },
        {
          label: 'Tipos de Equipamento',
          link: '/equipment',
        },
        {
          label: 'Tipos de engenharia',
          link: 'engineering',
        },
        {
          label: 'Cláusulas',
          link: '/clause',
        },
        {
          label: 'Considerações',
          link: '/consideration',
        },
      ],
    },
    {
      icon: <FaColumns />,
      label: 'Gestão',
      testId: 'general',
      subItem: [
        {
          label: 'Contratos',
          link: '/contract',
          testId: 'Contract',
        },
        {
          label: 'Pedidos de mudança de contrato',
          link: '/contract-change',
          testId: 'ContractChange',
        },
        {
          icon: <FaBullhorn />,
          link: '/campaigns',
          label: 'Campanhas',
          testId: 'campaigns',
        },
      ],
    },
    {
      icon: <FaHeadphones />,
      link: '/called',
      label: 'Atendimentos',
      testId: 'Called',
    },
    {
      icon: <FaColumns />,
      label: 'Rotinas avançadas',
      testId: 'general',
      subItem: [
        {
          label: 'Rotinas Automatizadas',
          link: '/contract-routines',
        },
        {
          label: 'Alteraçoes em lote',
          testId: 'batchChangeList',
          link: '/batch-changes-list',
        },

        {
          label: 'Importação de dados',
          link: '/data-import',
          testId: 'data-import',
        },
        {
          label: 'Mensageria',
          link: '/messaging',
          testId: 'messaging',
        },
      ],
    },

    // {
    //   icon: <FaPhoneSquare />,
    //   link: '/attendance-client',
    //   label: 'Meus Atendimentos',
    //   testId: 'attendance-client',
    // },

    {
      icon: <FaCog />,
      link: '/config',
      label: 'Configurações',
      testId: 'config',
    },
    {
      icon: <FaSignOutAlt />,
      link: '',
      label: 'Sair',
      testId: 'Sair',
      onSubmit: () => {
        logoff();
      },
    },
  ];
  return (
    <main>
      <div>
        <NavbarComponent
          buttons={buttons.filter(
            (item) =>
              !item.permission ||
              user?.permissions.includes(item?.permission || '')
          )}
        />
        <CardScreenContainer>
          <Outlet />
        </CardScreenContainer>
      </div>
    </main>
  );
}

export const RoutesComponent: FC = () => {
  const user = useUserData();
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={user ? <Navigate to="/" /> : <LoginTemplateComponent />}
        />
        <Route
          path="/register"
          element={user ? <Navigate to="/" /> : <RegisterTemplateComponent />}
        />
        <Route
          path="/forgot-password"
          element={
            user ? <Navigate to="/" /> : <ForgotPasswordTemplateComponent />
          }
        />
        <Route
          path="/first-access"
          element={
            user ? <Navigate to="/" /> : <ForgotPasswordTemplateComponent />
          }
        />
        <Route
          path="/change-password/:email/:type"
          element={
            user ? <Navigate to="/" /> : <ChangePasswordTemplateComponent />
          }
        />
        <Route path="/onboarding" element={<OnboardingTemplateComponent />} />
        <Route
          path="/"
          element={!user ? <Navigate to="/login" /> : <MainPage />}
        >
          <Route index element={<WelcomeComponent />} />
          <Route path="/dashboard" element={<DashboardsBody />} />
          <Route path="/contract" element={<ContractTemplateComponent />} />
          <Route
            path="/contract-change"
            element={<ContractChangeRequestTemplateComponent />}
          />
          <Route
            path="/contract-change/:id"
            element={<ContractChangeRequestTemplateComponent />}
          />
          <Route path="/user" element={<PhysicalPersonTemplateComponent />} />
          <Route path="/location" element={<LocationTemplateComponent />} />
          <Route path="/person" element={<PersonTemplateComponent />} />

          <Route path="/index" element={<IndexTemplateComponent />} />
          <Route
            path="/juridical"
            element={<JuridicalPersonTemplateComponent />}
          />
          <Route path="/called" element={<AttendanceTemplateComponent />} />
          <Route
            path="/attendance-client"
            element={<AttendanceClientTemplateComponent />}
          />
          <Route
            path="/email-template"
            element={<MessageTemplatesTemplateComponent />}
          />
          <Route path="/scheduling" element={<SchedulingPage />} />
          <Route
            path="/permission-groups"
            element={<PermissionsTemplateComponent />}
          />
          <Route
            path="/batch-changes"
            element={<BatchChangesTemplateComponent />}
          />
          <Route
            path="/batch-changes-list"
            element={<BatchChangesListTemplateComponent />}
          />
          <Route
            path="/batch-stepper/:id"
            element={<BatchChangesStepperTemplateComponent />}
          />
          <Route path="/terms" element={<TermsAndPoliciesPage />} />
          <Route
            path="/user-register/:id/:type"
            element={<UsersEditTemplateComponent />}
          />
          <Route path="/chat/:id" element={<ChatTemplateComponent />} />
          <Route
            path="/contract-details/:id"
            element={<ContractDetailsTemplateComponent />}
          />
          <Route
            path="/contract-history"
            element={<ContractHistoryTemplateComponent />}
          />
          <Route
            path="/contract-history/:id"
            element={<ContractHistoryTemplateComponent />}
          />
          <Route
            path="/negotiation-history/:id"
            element={<NegotiationHistoryTemplateComponent />}
          />
          <Route
            path="/user-register"
            element={<UsersEditTemplateComponent />}
          />
          <Route path="/profile" element={<ProfileTemplateComponent />} />
          <Route path="/my-profile" element={<MyProfilePage />} />

          <Route
            path="/physicalperson-register"
            element={<CreatePhysicalPersonTemplateComponent />}
          />
          <Route path="/tower" element={<TowerTemplateComponent />} />
          <Route path="/clause" element={<ClauseTemplateComponent />} />
          <Route
            path="/consideration"
            element={<ConsiderationTemplateComponent />}
          />
          <Route path="/technology" element={<TechnologyTemplateComponent />} />
          <Route path="/equipment" element={<EquipmentTemplateComponent />} />
          <Route
            path="/transaction"
            element={<TransactionTemplateComponent />}
          />
          <Route
            path="/transaction/:id"
            element={<TransactionTemplateComponent />}
          />
          <Route
            path="/engineering"
            element={<EngineeringTypeTemplateComponent />}
          />
          <Route
            path="/new-contract"
            element={<ContractStepperTemplateComponent />}
          />
          <Route
            path="/new-contract/:id"
            element={<ContractStepperTemplateComponent />}
          />
          <Route path="/campaigns" element={<CampaignsTemplateComponent />} />
          <Route
            path="/indexPerMonth/:index"
            element={<IndexPerMonthComponent />}
          />
          <Route path="/users" element={<UserTemplateComponent />} />
          <Route path="/config" element={<ConfigTemplateComponent />} />
          <Route
            path="/contract-routines"
            element={<ContractRoutinesTemplateComponent />}
          />
          <Route
            path="/data-import"
            element={<DataImportTemplateComponent />}
          />
          <Route
            path="/detached-payment/:contractId"
            element={<DetachedPaymentTemplateComponent />}
          />
          <Route
            path="/person/message/:id/:type/:physicalOrJuridicalPersonId"
            element={<PersonMessagePage />}
          />
          <Route path="/messaging" element={<MessagingPage />} />
          <Route path="/entitys" element={<EntitysPage />} />
        </Route>
      </Routes>
    </>
  );
};
