import { Flex, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  ConsiderationResp,
  saveConsideration,
} from '../../../../services/ConsiderationService';

export function CreateConsiderationModalComponent(props: {
  showModal: boolean;
  selectedConsideration?: ConsiderationResp;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      content: '',
    }),
    []
  );
  const [form, setForm] = React.useState<ConsiderationResp>(defaultForm);
  React.useEffect(() => {
    if (props.selectedConsideration)
      setForm({
        ...props.selectedConsideration,
      });
    else setForm(defaultForm);
  }, [props.selectedConsideration, defaultForm]);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={props.selectedConsideration ? 'Editar Cadastro' : `Novo Cadastro`}
      maxW="1200px"
      size="xl"
    >
      <Text
        fontSize={'2xl'}
        fontWeight={'light'}
        color="gray.500"
        marginBottom={7}
      >
        Informações da Consideração
      </Text>
      <Flex mb={5} flexDirection={'row'}>
        <Flex w={'100%'}>
          <InputComponent
            label="Título"
            type="textArea"
            value={form.content}
            onChange={(input) => {
              setForm({ ...form, content: input.target.value });
            }}
          />
        </Flex>
      </Flex>
      <Flex mb={5} justifyContent={'flex-end'}>
        <ButtonComponent
          margin={'0 4px 0 0'}
          variant={'outline'}
          onSubmit={() => {
            setForm(defaultForm);
            props.setShowModal();
          }}
        >
          Cancelar
        </ButtonComponent>
        <ButtonComponent
          isLoading={isLoading}
          disabled={isLoading}
          onSubmit={() => {
            setIsLoading(true);
            saveConsideration(form)
              .then((res) => {
                props.setShowModal();
                props.reloadData?.();
                showToast({
                  toast,
                  status: 'success',
                  title: 'Sucesso',
                  description: 'Consideração salva com sucesso',
                });
                setForm(defaultForm);
              })
              .finally(() => setIsLoading(false));
          }}
        >
          {props.selectedConsideration ? 'Salvar' : 'Criar Consideração'}
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
