import { Spinner, Stack } from '@chakra-ui/react';
import { ChangeEventHandler } from 'react';
import Dropzone from 'react-dropzone';
import { FaPaperclip, FaPaperPlane } from 'react-icons/fa';
import { Text } from '../../../../stories/atoms/TextComponent.stories';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import { TagDocumentComponent } from '../TagDocumentComponent.tsx/TagDocumentComponent';

type InputChatComponentProps = {
  files?: {
    onClose: () => void;
    title: string;
  }[];
  isFilesLoading?: boolean;
  disabledButtons?: boolean;
  onSubmitSendButton: () => void;
  onSubmitattachmentButton?: () => void;
  inputValue?: string;
  onChangeInput?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onDrop?: (acceptedFiles: any) => void;
  defaultResponses?: {
    response: string;
    onSubmitDefaultReponse: () => void;
  }[];
};

export function InputChatComponent(props: InputChatComponentProps) {
  const isButtonDisabled = props.disabledButtons || props.isFilesLoading;
  return (
    <Stack direction={'column'} alignItems={'center'}>
      <Stack width={'100%'} paddingX={61} direction="row">
        {props.defaultResponses?.map((e, i) => (
          <ButtonComponent
            disabled={isButtonDisabled}
            style={{ height: 27 }}
            key={i}
            onSubmit={() => e.onSubmitDefaultReponse()}
          >
            {e.response}
          </ButtonComponent>
        ))}
      </Stack>
      <Stack alignItems={'center'} direction={'row'} width="100%">
        <Dropzone onDrop={props.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <Stack {...getRootProps()}>
              <IconButtonComponent
                {...getInputProps()}
                style={{ borderRadius: 29 }}
                width={50}
                height={50}
                disabled={isButtonDisabled}
                ariaLabel="attachment button"
                onSubmit={() => props.onSubmitattachmentButton}
                icon={<FaPaperclip />}
              />
            </Stack>
          )}
        </Dropzone>
        <InputComponent
          value={props.inputValue}
          onChange={props.onChangeInput}
          placeholder="Escreva ao Usuário"
          disabled={isButtonDisabled}
        />
        <IconButtonComponent
          style={{ borderRadius: 29 }}
          width={57}
          disabled={isButtonDisabled}
          height={50}
          ariaLabel="send button"
          onSubmit={() => props.onSubmitSendButton()}
          icon={<FaPaperPlane />}
        />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={props.isFilesLoading ? 'center' : undefined}
        width={'100%'}
        paddingX={61}
      >
        {props.isFilesLoading ? (
          <>
            <Text color={'brand.500'}>Aguarde</Text>
            <Spinner size={'md'} color="brand.500" />
          </>
        ) : (
          props.files?.map((e) => (
            <TagDocumentComponent onClose={e.onClose} title={e.title} />
          ))
        )}
      </Stack>
    </Stack>
  );
}
