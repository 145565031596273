import { Flex, Stack, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  ContractHistoryResp,
  ContractHistoryType,
  getContractHistoryTypeLabel,
  getContractHistoryTypeOption,
  saveContractHistory,
} from '../../../../services/ContractHistoryService';
import { validateOptionalFields } from '../../../../utils/functions/utility';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import {
  clearMoneyMask,
  moneyMask,
} from '../../../../utils/functions/masks';

export function CreateContractHistoryModalComponent(props: {
  showModal: boolean;
  contractId: string;
  selectedContract?: ContractHistoryResp;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      oldValue: '',
      newValue: '',
      description: '',
      type: 1,
    }),
    []
  );
  const [form, setForm] = React.useState(defaultForm);
  React.useEffect(() => {
    if (props.selectedContract)
      setForm({
        id: props.selectedContract.id,
        oldValue: props.selectedContract.oldValue.toString(),
        newValue: props.selectedContract.newValue.toString(),
        description: props.selectedContract.description,
        type: props.selectedContract.type,
      });
    else setForm(defaultForm);
  }, [props.selectedContract, defaultForm]);

  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        setForm(defaultForm);
        props.setShowModal();
      }}
      title={'Histórico Personalizado'}
      maxW="1200px"
      size="lg"
    >
      <Text fontSize={'sm'} fontWeight={'600'} marginBottom={7}>
        Crie ou edite um histórico personalizado
      </Text>
      <Stack mb={5} direction={'row'} spacing={'4%'}>
        <InputSelectComponent
          label="Tipo"
          options={getContractHistoryTypeOption()}
          defaultValue={props.selectedContract?.type.toString()}
          disabled={!!props.selectedContract}
          placeholder="Selecione o tipo"
          onChange={(input) => {
            setForm({
              ...form,
              type: Number(input.target.value),
              oldValue: '',
              newValue: '',
            });
          }}
        />
        <InputComponent
          label={`${getContractHistoryTypeLabel(form.type)} Anterior`}
          placeholder="valor"
          type={form.type === ContractHistoryType.DATE ? 'date' : 'text'}
          value={
            form.type === ContractHistoryType.VALUE
              ? moneyMask(form.oldValue)
              : form.oldValue
          }
          onChange={(input) => {
            setForm({
              ...form,
              oldValue:
                form.type === ContractHistoryType.VALUE
                  ? moneyMask(input.target.value)
                  : input.target.value,
            });
          }}
        />
        <InputComponent
          label={`${getContractHistoryTypeLabel(form.type)} Atual`}
          placeholder="valor"
          type={form.type === ContractHistoryType.DATE ? 'date' : 'text'}
          value={
            form.type === ContractHistoryType.VALUE
              ? moneyMask(form.newValue)
              : form.newValue
          }
          onChange={(input) => {
            setForm({
              ...form,
              newValue:
                form.type === ContractHistoryType.VALUE
                  ? moneyMask(input.target.value)
                  : input.target.value,
            });
          }}
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'4%'}>
        <InputComponent
          label="Descrição"
          type="textArea"
          value={form.description}
          onChange={(input) => {
            setForm({ ...form, description: input.target.value });
          }}
        />
      </Stack>
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setForm(defaultForm);
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          disabled={validateOptionalFields(form, ['id'])}
          onSubmit={() => {
            saveContractHistory(
              {
                description: form.description,
                id: form.id,
                newValue:
                  form.type === ContractHistoryType.VALUE
                    ? Number(clearMoneyMask(form.newValue))
                    : form.newValue,
                oldValue:
                  form.type === ContractHistoryType.VALUE
                    ? Number(clearMoneyMask(form.oldValue))
                    : form.oldValue,
                type: form.type,
              },
              props.contractId
            ).then((res) => {
              props.setShowModal();
              props.reloadData?.();
              showToast({
                toast,
                status: 'success',
                title: 'Sucesso',
                description: 'Salvo com sucesso',
              });
            });
          }}
        >
          Salvar
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
