import { useQuery } from '@tanstack/react-query';
import Api, { getRespContent } from './Api';

export type ContractFieldsResp = {
  field: string;
  description: string;
  isEnable: boolean;
  id: string;
  createdAt: string;
};

export type ContractUpdateMany = {
  isEnable: boolean;
  id: string;
};

export function useContractFields() {
  return useQuery([`ContractFieldsList`], async () => {
    const resp = await Api.get(`/ContractFields/without-pagination`);
    return getRespContent<ContractFieldsResp[]>(resp);
  });
}

export function contractFieldsUpdateMany(props: ContractUpdateMany[]) {
  return Api.put(`/ContractFields/update-many`, props);
}
