import { PhysicalPersonResp } from './PhysicalPersonService';
import { usePagination } from './../components/UI/molecules/PaginationComponent/PaginationComponent';
import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getApi, getRespContent } from './Api';
import { useDebounce } from '../utils/functions/debounce';
import { showToast } from '../utils/showToast';
import { useUserData } from './LoginService';

enum management {
  Nenhuma = 0,
  Atribuir = 1,
  Remover = 2,
  Completo = 3,
}

export type PermissionResp = {
  id: string;
  code: string;
  name: string;
  description: string;
  users: Permission[];
};

export type Permission = {
  userId: string;
  permissionId: string;
  entityId?: string;
  management?: management;
  managementText?: string;
  permissionName?: string;
  userName?: string;
  isProfile?: boolean;
};

export type Permissions = {
  items: Permission[];
};

export function usePermissions(
  page: number,
  pageSize: number | null,
  search: string
) {
  return useQuery([`permissionList`, page, search], async () => {
    const resp = await getApi().get(`/permission`, {
      params: {
        Offset: page,
        Limit: pageSize,
        QuickSearch: search,
      },
    });
    return getRespContent<PermissionResp[]>(resp);
  });
}

export function permissionAssign(props: Permissions) {
  return getApi().post(`/Permission/Assign`, props);
}

export function permissionRemove(props: Permissions) {
  return getApi().post(`/Permission/Remove`, props);
}

export function usePermissionDialog() {
  const toast = useToast();
  const userData = useUserData();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [searchInput, search, setSearch] = useDebounce('');
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  function assignRemovePermission(
    permission: PermissionResp | undefined,
    user: PhysicalPersonResp | undefined,
    assign: boolean
  ) {
    const permissionList: Permissions = {
      items: [
        {
          userId: user?.userId || '',
          permissionId: permission?.id || '',
          entityId: userData?.entity?.id,
        },
      ],
    };
    if (assign) {
      return permissionAssign(permissionList).then((res) => {
        showToast({
          toast,
          status: 'success',
          title: 'Sucesso',
          description: 'Permissão atribuída com sucesso',
        });
      });
    } else {
      return permissionRemove(permissionList).then((res) => {
        showToast({
          toast,
          status: 'success',
          title: 'Sucesso',
          description: 'Permissão removida com sucesso',
        });
      });
    }
  }
  return {
    pageSize,
    selectedPage,
    searchInput,
    search,
    setSearch,
    onSelectedPageChanged,
    assignRemovePermission,
  };
}
