import { Stack, Text, TextProps as ChakraTextProps } from '@chakra-ui/react';

type DescriptionLabelProps = {
  label: string;
  subLabel: string;
  textColor?: string;
  size?: ChakraTextProps['fontSize'];
  mb?: number;
};

export function DescriptionLabelComponent(props: DescriptionLabelProps) {
  return (
    <Stack
      paddingLeft={2}
      marginBottom={props.mb}
      direction={'column'}
      alignItems="flex-start"
    >
      {props.subLabel && (
        <Text
          style={{ marginTop: 0 }}
          color={props.textColor || 'gray'}
          fontSize={props.size || 'md'}
        >
          {props.subLabel}
        </Text>
      )}
      <Text
        fontWeight={'600'}
        color={props.textColor && props.textColor}
        fontSize={props.size || 'md'}
      >
        {props.label}
      </Text>
    </Stack>
  );
}
