import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ContainerComponent from '../../atoms/ContainerComponent/ContainerComponent';
import useLogin, { useUserData } from '../../../../services/LoginService';
import { CSSProperties, useEffect, useState } from 'react';
import { Box, Flex, Stack, useToast } from '@chakra-ui/react';
import LinkComponent from '../../atoms/LinkComponent/LinkComponent';
import { showToast } from '../../../../utils/showToast';

export type LoginFormProps = {
  style?: CSSProperties;
};

export default function LoginFormComponent(props: LoginFormProps) {
  const toast = useToast();
  const { login, isLoading } = useLogin();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useUserData();
  useEffect(() => {
    if (localStorage.getItem('forbidden') === '1') {
      showToast({
        toast,
        status: 'info',
        title: 'Informação',
        description:
          'Usuário não possui permissão para acessar o sistema Web. Acesse pelo aplicativo.',
      });
      localStorage.removeItem('forbidden');
    }
  });
  return (
    <>
      <ContainerComponent style={props.style}>
        <Stack fontWeight={'500'} fontSize={'16px'} spacing={1}>
          <Box mb={'11px'}>
            <InputComponent
              borderRadius={15}
              id="email"
              label={'Email'}
              type={'email'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={'Digite o email'}
            />
          </Box>
          <InputComponent
            borderRadius={15}
            id="password"
            label={'Senha'}
            type={'Password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={'Digite a senha'}
          />
        </Stack>

        <Flex
          mt={4}
          justifyContent={'space-between'}
          pb={'20px'}
          fontWeight={500}
        >
          <Box>
            <LinkComponent to="/first-access">Primeiro Acesso</LinkComponent>
          </Box>
          <Box>
            <LinkComponent to="/forgot-password">Esqueci a senha</LinkComponent>
          </Box>
        </Flex>
        <ButtonComponent
          isLoading={isLoading}
          disabled={isLoading}
          width={275}
          children={'Entrar'}
          onSubmit={() => {
            login({ email, password });
          }}
        />
      </ContainerComponent>
      <Flex mt={2}>
        {/* <TextComponent fontWeight={'600'} fontSize={'12px'}>
          Não tem uma conta?
          <LinkComponent color={'brand.500'} to={'/register'}>
            Cadastre-se gratuitamente
          </LinkComponent>
        </TextComponent> */}
      </Flex>
    </>
  );
}
