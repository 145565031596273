import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import {
  Flex,
  Box,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import React from 'react';
import DropzoneModel from '../../UI/organisms/uploadModel/DropZoneModel';
import { showToast } from '../../../utils/showToast';
import { postContractExelImport } from '../../../services/ContractService';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { EmptyState } from '../../../stories/atoms/EmptyStateComponent.stories';

export default function DataImportTemplateComponent() {
  const toast = useToast();
  const navigate = useNavigate();
  const [file, setFile] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);
  const [listError, setListError] = React.useState<
    { line: 1; errors: string[] }[]
  >([]);
 
  return (
    <Box backgroundColor={'white'} padding={'20px'}>
      <HeaderTextComponent goBack>Dados</HeaderTextComponent>
      <Flex mt={8} justifyContent={'center'}>
        <Card style={{ padding: 18, width: '90%' }}>
          <Flex justifyContent={'space-between'}>
            <Flex w={'50%'}>
              <Flex flexDir={'column'}>
                <HeaderTextComponent fontSize={20} textAlign={'center'}>
                  Importação de dados
                </HeaderTextComponent>
                <HeaderTextComponent
                  fontSize={18}
                  subTitle
                  textAlign={'center'}
                >
                  Você pode importar dados de contratos através de um arquivo
                  excel
                </HeaderTextComponent>
                <Flex mt={10} justifyContent={'center'}>
                  <ButtonComponent
                    margin={'0px 0 0 0'}
                    colorScheme="blue"
                    variant="solid"
                    onSubmit={() => {
                      window.open(
                        'https://t31090697.p.clickup-attachments.com/t31090697/543d4265-13d2-42c1-bec8-5519e7c1d58c/ModeloImporta%C3%A7%C3%A3o.xlsx'
                      );
                    }}
                  >
                    Baixar Exemplo
                  </ButtonComponent>
                </Flex>
                <Flex justifyContent={'center'} pt={'30px'}>
                  <DropzoneModel
                    onUploadComplete={(input) => {
                      setFile(input[0].id);
                      setIsLoading(false);
                    }}
                    type="archive"
                  />
                </Flex>
                <Flex mt={8} justifyContent={'center'}>
                  <ButtonComponent
                    margin={'0px 0 0 0'}
                    colorScheme="blue"
                    variant="solid"
                    disabled={!file || isLoading}
                    isLoading={isLoading}
                    onSubmit={() => {
                      setListError([]);

                      setIsLoading(true);
                      postContractExelImport(file)
                        .then((res) => {
                          setListError(res.data.content.data.itens);
                          if (
                            res.data.content.data.thereWereProblemsImporting
                          ) {
                            showToast({
                              toast,
                              status: 'error',
                              title: 'Erro',
                              description:
                                'Não foi possível importar o contrato. Verifique o arquivo',
                            });
                          } else {
                            showToast({
                              toast,
                              status: 'success',
                              title: 'Sucesso',
                              description: `${
                                res.data.content.data.importedEntitiesCount
                              } Contrato${
                                res.data.content.data.importedEntitiesCount ===
                                1
                                  ? ''
                                  : 's'
                              } importado com sucesso`,
                            });
                            navigate('/contract');
                          }
                        })
                        .finally(() => {
                          setIsLoading(false);
                        });
                    }}
                  >
                    Importar
                  </ButtonComponent>
                </Flex>
              </Flex>
            </Flex>
            <Flex w={'50%'}>
              <Flex w={'100%'} flexDir={'column'}>
                <HeaderTextComponent fontSize={20} textAlign={'center'}>
                  Relatório da importação
                </HeaderTextComponent>
                <HeaderTextComponent
                  fontSize={18}
                  subTitle
                  textAlign={'center'}
                >
                  Você pode visualizar o resultado do processamento do arquivo
                </HeaderTextComponent>
                {listError.length > 0 && (
                  <Flex mt={4} flexDir={'column'}>
                    <Flex
                      borderRadius={4}
                      p={2}
                      justifyContent={'space-between'}
                      bg={'gray.100'}
                    >
                      <HeaderTextComponent fontSize={16}>
                        Lista de Erros
                      </HeaderTextComponent>
                    </Flex>

                    {listError.map((e) => (
                      <AccordionModel
                        itens={{
                          titleSection: e.line,
                          descriptionList: e.errors,
                        }}
                      />
                    ))}
                  </Flex>
                )}

                {listError.length <= 0 && <EmptyState />}
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
}
function AccordionModel(props: {
  itens: {
    titleSection: string | number;
    descriptionList: string[];
  };
}) {
  return (
    <Accordion allowToggle>
      <AccordionItem defaultChecked>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {`Linha - ${props.itens.titleSection}`}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {props.itens.descriptionList.map((e) => (
            <>
              <Alert mb={2} status="error">
                <AlertIcon />

                <AlertDescription>{e}</AlertDescription>
              </Alert>
            </>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
