import { useParams } from 'react-router-dom';
import LoginStructureComponent from '../../UI/molecules/LoginStructureComponent/LoginStructureComponent';
import ChangePasswordFormComponent from '../../UI/organisms/ChangePasswordFormComponent/ChangePasswordFormComponent';

export default function ChangePasswordTemplateComponent() {
  const params = useParams<{ email: string; type: 'forgot' | 'first' }>();

  return (
    <LoginStructureComponent subtitle={''}>
      <ChangePasswordFormComponent
        type={params.type || 'forgot'}
        email={params.email || ''}
      />
    </LoginStructureComponent>
  );
}
