import { FaEdit, FaInfo, FaRegTimesCircle, FaTrash } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import { DescriptionLabelComponent } from '../../UI/molecules/DescriptionlabelComponent/DescriptionLabelComponent';
import React from 'react';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { CreateTowerModalComponent } from '../../UI/organisms/CreateTowerModalComponent/CreateTowerModalComponent';
import {
  deleteTowerType,
  getTowerTypeReport,
  putTowerTypeActive,
  putTowerTypeDisable,
  StatusTowerType,
  TowerTypeResp,
  useTowerType,
} from '../../../services/TowerTypeService';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { showToast } from '../../../utils/showToast';
import DetailsModalComponent from '../../UI/molecules/DetailsModalComponent/DetailsModalComponent';
import { parseBrazilDate } from '../../../utils/functions/utility';
import { useUserData } from '../../../services/LoginService';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import { CheckCircleIcon } from '@chakra-ui/icons';

export default function TowerTemplateComponent() {
  const [selectedTower, setSelectedTower] = React.useState<
    TowerTypeResp | undefined
  >();
  const toast = useToast();
  const user = useUserData();
  const modalCardInfo = [
    { item: 'Número', description: selectedTower?.serialNumber },
    { item: 'Descrição', description: selectedTower?.description },
    {
      item: 'Criado em',
      description: parseBrazilDate(selectedTower?.createdAt),
    },
    { item: 'Tipo', description: selectedTower?.typeDescription },
    { item: 'Status', description: selectedTower?.statusDescription },
    {
      item: 'Contrato Ativo?',
      description: selectedTower?.hasActiveContract ? 'Sim' : 'Não',
    },
  ];
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [showModal, setShowModal] = React.useState(false);
  const [StatusTower, setStatusTower] = React.useState<StatusTowerType>(
    StatusTowerType.Active
  );
  const listTowers = useTowerType(
    selectedPage,
    pageSize,
    undefined,
    undefined,
    undefined,
    undefined,
    StatusTower
  );
  const [detailsDialog, setDetailsDialog] = React.useState(false);
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const tabName = [
    {
      title: 'Ativas',
      onClick: () => {
        setStatusTower(StatusTowerType.Active);
      },
    },
    {
      title: 'Desabilitadas',
      onClick: () => {
        setStatusTower(StatusTowerType.Inactive);
      },
    },
  ];
  return (
    <>
      <Stack mb={4}>
        <HeaderTextComponent goBack>Tipos de torres</HeaderTextComponent>
      </Stack>
      <Flex mb={2} justifyContent={'space-between'}>
        <Flex>
          <TabsFilterComponent tabList={tabName} />
        </Flex>
        <Flex alignItems={'end'}>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              getTowerTypeReport();
            }}
          >
            Exportar
          </ButtonComponent>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              setSelectedTower(undefined);
              setShowModal(true);
            }}
          >
            Novo Cadastro
          </ButtonComponent>
        </Flex>
      </Flex>

      <TableComponent
        ItemsHeader={[
          { item: 'Descrição' },
          { item: 'Proprietário' },
          { item: 'Status' },
          { item: 'Tipo' },
          { item: 'Contrato Vinculado' },
          { item: 'Ação' },
        ]}
        isLoading={listTowers.isLoading}
        centered={true}
        data={
          listTowers.data?.data.map((e, i) => ({
            items: [
              <DescriptionLabelComponent
                label={e.description}
                subLabel={e.serialNumber}
                key={i}
              />,
              user?.entity?.name,

              e.status === StatusTowerType.Active ? (
                <TagComponent
                  size="md"
                  colorScheme="green"
                  text={e.statusDescription}
                />
              ) : (
                <TagComponent
                  size="md"
                  colorScheme="red"
                  text={e.statusDescription}
                />
              ),
              e.typeDescription,
              e.hasActiveContract ? (
                <TagComponent size={'md'} colorScheme={'green'} text="Sim" />
              ) : (
                <TagComponent size={'md'} colorScheme={'red'} text="Não" />
              ),
              <>
                <IconButtonComponent
                  icon={
                    e.status === StatusTowerType.Active ? (
                      <FaRegTimesCircle color="white" />
                    ) : (
                      <CheckCircleIcon />
                    )
                  }
                  toolTipText={
                    e.status === StatusTowerType.Active
                      ? 'Desabilitar'
                      : 'Ativar'
                  }
                  ariaLabel="Disabled"
                  colorScheme={
                    e.status === StatusTowerType.Active ? 'yellow' : 'green'
                  }
                  onSubmit={() => {
                    e.status === StatusTowerType.Active
                      ? putTowerTypeDisable(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description: 'Torre desabilitada com sucesso',
                          });
                          listTowers.refetch();
                        })
                      : putTowerTypeActive(e.id).then(() => {
                          showToast({
                            toast,
                            status: 'success',
                            title: 'Sucesso',
                            description: 'Torre ativada com sucesso',
                          });
                          listTowers.refetch();
                        });
                  }}
                />
                <IconButtonComponent
                  icon={<FaEdit />}
                  ariaLabel="Edit"
                  toolTipText="Editar"
                  backgroundColor="#4B49AC"
                  marginX={2}
                  onSubmit={() => {
                    setSelectedTower(e);
                    setShowModal(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaInfo />}
                  ariaLabel="info"
                  toolTipText="Detalhes"
                  backgroundColor="#3182CE"
                  onSubmit={() => {
                    setSelectedTower(e);
                    setDetailsDialog(true);
                  }}
                />
                <IconButtonComponent
                  icon={<FaTrash />}
                  disabled={e.hasActiveContract}
                  marginX={2}
                  toolTipText={'Apagar'}
                  ariaLabel="Delete"
                  colorScheme={'red'}
                  onSubmit={() => {
                    deleteTowerType(e.id).then((res) => {
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description: 'Torre desativada com sucesso',
                      });
                      listTowers.refetch();
                    });
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listTowers.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateTowerModalComponent
        selectedTower={selectedTower}
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
        reloadData={() => listTowers.refetch()}
      />
      <DetailsModalComponent
        isOpen={detailsDialog}
        title={'Torre'}
        onClose={() => setDetailsDialog(false)}
        data={modalCardInfo}
      />
    </>
  );
}
